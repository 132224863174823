import {ResponsiveState} from 'plugins/redux/initClient'
import {reducer as untypeResponsive} from 'redux-mediaquery'
export const responsive = untypeResponsive as (
	state: ResponsiveState | undefined,
	action: any
) => ResponsiveState

export {default as modal} from './ducks/modal'
export {default as table} from 'app/Components/Table/duck'
export {default as searchRentals} from 'state/ducks/searchRentals-duck'
export {default as favoritesAndShare} from 'state/ducks/favoritesAndShare-duck'
export {default as visitedHouses} from 'state/ducks/visitedHouses-duck'
export {default as mobileNav} from 'app/Components/Page/Header/MobileHeader/MobileNav-duck'
export {reducer as form} from 'app/Components/Form'
export {default as globalSearch} from 'app/backoffice/TopBar/searchBox/searchBox-duck'
