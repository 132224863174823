import {useBlogPostsQuery} from 'app/_server/queries/blogPosts'
import BlogPostsSlider from 'app/Components/Carousels/BlogpostsSlider'
import {useI18n} from 'plugins/i18n'
import React from 'react'

const RecentBlogposts = () => {
	const {getText} = useI18n()
	const {data, loading} = useBlogPostsQuery({onlyOnline: true, limit: 4})
	if (loading || !data?.blogPosts) return null
	return (
		<div className="flex w-full flex-col bg-[#BBB]/30 py-6">
			<h2 className="mb-4 ml-6 text-2xl leading-8 font-bold">
				{getText('recentBlogpost')}
			</h2>
			<BlogPostsSlider blogposts={data.blogPosts.items} />
			<button
				type="button"
				className="bg-primary text-onPrimary mt-6 w-5/6 self-center rounded-md p-2"
			>
				{getText('allBlogposts')}
			</button>
		</div>
	)
}
export default RecentBlogposts
