import React from 'react'
import {PhoneIcon, MailIcon, HeartIcon} from 'app/Components/Icon'
import Nav from './Nav'
import {_, useI18n} from 'plugins/i18n'
import styled from 'styled-components'
import {
	GreenWhiteTheme,
	WhiteTheme,
	cut,
	getBg,
	ThemeProvider,
	whiteTheme,
	useResponsive,
	GreenBlackTheme,
} from 'app/styles'
import {clearSearch, setNew, setPromo} from 'state/ducks/searchRentals-duck'
import {HouseCounRegionList} from 'app/Components/SearchRentals/RentalContentList'
import {useHouseSearchOptions} from 'app/_server/queries/houses'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {Line} from 'app/Components/Layout/Display'
import Dropdown, {ContentWrap} from 'app/Components/Inputs/Dropdown'
import {ThemedBox} from 'app/Components/Layout'
import {searchBasePath} from 'app/lib'
import {useQuery} from 'plugins/apollo'
import SocialIcons from '../SocialIcons'
import InfoTip from 'app/Components/Tooltip'
import {COMPANY_INFO} from 'app/constants'
import {landingPageByUrlUserQuery} from 'app/_server/queries/landingPages'
import LastMinuteBar from './LastMinuteBar'
import Logo from './Logo'
import LanguagePicker from './LanguagePicker'
import MobileHeader from './MobileHeader'
import {useSearch} from 'app/hooks'
import {TextInput} from 'app/Components/Inputs'
import {triggerSidebar} from './MobileHeader/MobileNav-duck'

const {phone, phoneLabel, email} = COMPANY_INFO

const MainHeaderBackground = styled(ThemedBox)`
	position: relative;
	z-index: 1;
	background: ${getBg};
	height: 4em;
	${cut};
`
export const MainHeader = styled(MainHeaderBackground)`
	padding: 1em 2em;
	background: transparent;
	margin-top: -4em;
	z-index: 1;
	top: 0;
	left: 0;
`

const DesktopSearchBar = () => {
	const {getText} = useI18n()
	const {handleChange, handleSearch, houseName} = useSearch()

	return (
		<TextInput
			noLabel
			icon="search"
			value={houseName}
			onChange={handleChange}
			onEnterKey={handleSearch}
			onIconClick={handleSearch}
			placeholder={getText(`searchByName`)}
		/>
	)
}

const MobileDropdown = styled(Dropdown)`
	${ContentWrap} {
		margin: 0 -1em;
	}
`

const MobileRegionList = styled(props => (
	<GreenBlackTheme>
		<HouseCounRegionList showOverlay={false} {...props} />
	</GreenBlackTheme>
))`
	width: 100%;
`

const DesktopDropdown = styled(Dropdown)``

const RegionList = props => (
	<ThemeProvider theme={whiteTheme}>
		<HouseCounRegionList {...props} />
	</ThemeProvider>
)

const Header = () => {
	const {isPhone} = useResponsive('isPhone')
	const i18n = useI18n()
	const {lang} = i18n
	const selectedRegion = useSelector(state => state.searchRentals.region)
	const hasFavourites = useSelector(state => {
		return !!Object.entries(state.favoritesAndShare)
			.filter(i => i[1])
			.map(i => i[0]).length
	})
	const {data: searchOptionsData, loading} = useHouseSearchOptions()
	const {data: rentYouHouseLPData, loading: loading2} = useQuery(
		landingPageByUrlUserQuery,
		{
			variables: {url: 'rent-your-house', lang},
		}
	)
	const dispatch = useDispatch()

	if (loading || loading2) {
		return null
	}

	const {locations: optionsList = []} = searchOptionsData.searchOptions || {}

	const clearIsNewAndIsPromo = () => {
		dispatch(setNew(false))
		dispatch(setPromo(false))
	}

	const rentYourHouseUrl =
		rentYouHouseLPData?.landingPageByUrl?.urlI18n[lang] || ''

	const routes = [
		{
			label: isPhone ? (
				<MobileDropdown
					placeholder={_('navDestinations')}
					value={selectedRegion}
					type="text"
					Content={MobileRegionList}
					contentProps={{
						optionsList,
						onChange: () => dispatch(triggerSidebar()),
					}}
					mobile
				/>
			) : (
				<ThemedBox>
					<DesktopDropdown
						placeholder={_('navDestinations')}
						value={selectedRegion}
						type="text"
						Content={RegionList}
						contentProps={{
							optionsList,
							onChange: clearIsNewAndIsPromo,
						}}
					/>
				</ThemedBox>
			),
		},
		{
			path: `${searchBasePath(i18n)}?isNew`,
			label: _('navNew'),
			onChange: () => dispatch(clearSearch()),
		},
		{
			path: `${searchBasePath(i18n)}?isPromo`,
			label: _('navPromotions'),
			onChange: () => dispatch(clearSearch()),
		},
		{path: `/xx/about`, label: _('navAbout')},
		{path: `/xx/blog`, label: _('navBlog')},
		{path: `/xx/${rentYourHouseUrl}`, label: _('rentYourHouse')},
		{path: `/xx/contact`, label: _('navContact')},
	]

	if (hasFavourites) {
		routes.push({path: `/xx/favourites`, label: <HeartIcon outline />})
	}
	if (isPhone) {
		routes.push(
			{label: <Line />},
			{
				label: (
					<a href={`tel:${phone}`}>
						<div className="flex w-full items-center justify-start gap-2">
							<PhoneIcon />
							{phoneLabel}
						</div>
					</a>
				),
			},
			{
				label: (
					<a href={`mailto:${email}`}>
						<div className="flex w-full items-center justify-start gap-2">
							<MailIcon />
							{email}
						</div>
					</a>
				),
			},
			{label: <Line />},
			{
				label: (
					<div className="w-40">
						<SocialIcons />
					</div>
				),
			},
			{
				label: <LanguagePicker />,
			}
		)
	}

	return (
		<GreenWhiteTheme>
			<div className="fixed z-30 mx-auto w-screen max-w-[1600px]">
				<LastMinuteBar />
				<MainHeaderBackground cut leftCut cutSize={1.5} />
				{isPhone ? (
					<MobileHeader routes={routes} />
				) : (
					<MainHeader gap="0.5em" spaceBetween>
						<Logo />
						<Nav gap="0.5em" routes={routes} />
						<div className="flex h-[1.25em] w-auto items-center gap-[0.5em]">
							<WhiteTheme>
								<DesktopSearchBar />
							</WhiteTheme>
							<Line vertical length="1em" opacity="0.5" />
							<InfoTip tooltipText={phoneLabel} place="bottom">
								<a href={`tel:${phone}`}>
									<PhoneIcon width="1em" />
								</a>
							</InfoTip>
							<Line vertical length="1em" opacity="0.5" />
							<LanguagePicker />
						</div>
					</MainHeader>
				)}
			</div>
			{/* Take up almost the same space as relative header, allow for children to change bg behind cutout  */}
			<div className="min-h-[80px] w-full" aria-hidden="true" />
		</GreenWhiteTheme>
	)
}

export default Header
