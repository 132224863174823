import React, {Fragment, useEffect, useState} from 'react'
import HousePageBreadcrumbs from './Breadcrumbs'
import HouseImagesSlider from './HouseImagesSlider'
import {DateRangeValue, useI18n} from 'plugins/i18n'
import Text from 'app/Components/twComponents/Text'
import Icon, {
	glyphs,
	LocationIcon,
	ShareIcon,
	StarIconNew,
} from 'app/Components/Icon'
import serializeNode from 'plugins/rich-text/rawText'
import {useSelector} from 'plugins/redux/hooks'
import {ReservationCard} from 'app/Components/Reservation'
import {HtmlContent} from 'plugins/rich-text'
import Promotions from 'app/Components/House/Promotions'
import {useGetFeatures} from 'plugins/i18n/graphql/queries'
import {groupBy} from 'lodash-es'
import Facilities from './Facilities'
import {MoneyValue} from 'app/Components/Numbers'
import {calcPriceRanges} from 'app/lib'
import {COND_INCLUDED} from 'app/_server/database/constants'
import {Availabilities, Surroundings} from 'app/Components/Panels'
import {MapWrapper} from 'app/Components/House/HousedetailTabs'
import WhyCap from '../HomePage/WhyCap'
import ReviewsSection from '../HomePage/ReviewsSections'
import PopularDestinations from '../HomePage/PopularDestinations'
import Newsletter from '../HomePage/NewsletterSignup'
import HelpSection from '../HomePage/HelpSection'
import {TextBox} from 'app/Components/House/HouseDomainDescription/HouseDomainDescription'

const Label = ({text}) => {
	return (
		<div className="bg-primary/30 text-primary flex items-center justify-center px-4 py-2">
			<Text variant="small">{text}</Text>
		</div>
	)
}

const HouseReviewScore = ({score}) => {
	const {getText} = useI18n()
	return Number(score) ? (
		<div className="bg-secondary text-onSecondary inline-flex items-center justify-center space-x-1 rounded-lg px-4 py-1">
			<StarIconNew height="1rem" />
			<Text>{score}</Text>
			<Text variant="small">{getText('reviews').toLowerCase()}</Text>
		</div>
	) : null
}

const HouseLocation = ({house}) => {
	const {getText} = useI18n()

	return (
		<div className="flex flex-row items-center space-x-1 py-4">
			<LocationIcon height="1rem" />
			<Text variant="small" className="text-onTertiary">
				{[house.placeName, getText(house.regionId)].filter(Boolean).join(', ')}
			</Text>
		</div>
	)
}

const TitleWithDescription = ({house}: {readonly house: House}) => {
	const {getText} = useI18n()
	const {description, name, capacity} = house || {}
	const [isExpanded, setIsExpanded] = useState(false)

	const serializedDesc =
		typeof description === 'object' ? serializeNode(description) : description

	const shortDescLength = 200

	const descriptionLength = serializedDesc.length

	useEffect(() => {
		if (descriptionLength < shortDescLength) {
			setIsExpanded(true)
		}
	}, [descriptionLength])

	let shortDescription = serializedDesc.slice(0, Math.max(0, shortDescLength))
	shortDescription =
		shortDescription.slice(
			0,
			Math.max(
				0,
				Math.min(shortDescription.length, shortDescription.lastIndexOf(' '))
			)
		) + '...'

	return (
		<div className="text-onBackground">
			<Text className="mb-2" variant="heading">
				{name} {getText('for')} {capacity} {getText('persons')}
			</Text>
			<div
				className={`relative ${isExpanded ? '' : 'max-h-20 overflow-hidden'}`}
			>
				<p
					className={`${isExpanded ? '' : 'bg-linear-to-b from-transparent via-white to-white'}`}
				>
					{isExpanded
						? (description && <TextBox html={description} />) || serializedDesc
						: shortDescription}
				</p>
				{!isExpanded && (
					<div className="absolute right-0 bottom-0 left-0 h-8 bg-linear-to-t from-white to-transparent" />
				)}
			</div>
			<button
				type="button"
				onClick={() => setIsExpanded(!isExpanded)}
				className="text-primary flex cursor-pointer items-center justify-center p-2"
			>
				{isExpanded ? getText('readLessDesc') : getText('readMoreDesc')}
			</button>
		</div>
	)
}

const FeatureBox = ({icon, text}) => {
	return (
		<div className="bg-primary/30 text-primary flex items-center space-x-3 p-3 shadow-xs">
			<div className="text-capGreen flex h-6 w-6 items-center">
				<Icon className="fill-primary" glyph={glyphs[icon]} />
			</div>
			<Text variant="small">{text}</Text>
		</div>
	)
}

const FeatureLabels = ({house}) => {
	const {data, loading} = useGetFeatures()
	const {getText} = useI18n()
	const featuresToShow = data?.features
		.filter(o => house.features.includes(o.id))
		.slice(0, 4)
		.sort((a, b) => {
			if (a.order === undefined) return 1
			if (b.order === undefined) return -1
			return a.order - b.order
		})

	return loading ? null : (
		<div className="my-4 space-y-1">
			<div className="grid grid-cols-2 gap-4">
				<FeatureBox
					icon="BEDROOM"
					text={`${house.bedroomCount} ${getText('bedrooms').toLowerCase()}`}
				/>
				<FeatureBox
					icon="BATHROOM"
					text={`${house.bathroomCount} ${getText('bathrooms').toLowerCase()}`}
				/>
				{featuresToShow?.map((feature, i) => (
					<FeatureBox key={i} text={getText(feature.id)} icon={feature.icon} />
				))}
			</div>
		</div>
	)
}

const HouseSloganAndPromotions = ({house}) => {
	const {slogan, promotions} = house
	const hasPromotions = promotions && promotions.length > 0
	return slogan || hasPromotions ? (
		<div className="text-primary my-6 w-full bg-[#E5FED0] px-4 py-2">
			<HtmlContent value={house.slogan} />
			<Promotions value={house.promotions} />
		</div>
	) : null
}

export const Accordion = ({title, children}) => {
	const [isOpen, setIsOpen] = useState(false)

	const toggleAccordion = () => {
		setIsOpen(!isOpen)
	}

	return (
		<div className="text-onBackground my-4 w-full rounded-lg border border-solid border-gray-300">
			<div
				onClick={toggleAccordion}
				className="flex cursor-pointer items-center justify-between p-4"
			>
				<Text variant="heading2">{title}</Text>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className={`h-6 w-6 transform transition-transform duration-300 ${
						isOpen ? 'rotate-180' : ''
					}`}
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M5 15l7-7 7 7"
					/>
				</svg>
			</div>
			{isOpen && (
				<div className="border-t border-gray-300 px-4">{children}</div>
			)}
		</div>
	)
}

const PricesPerWeek = ({house}) => {
	const {getText} = useI18n()

	const {prices, extras} = house
	const ranges = calcPriceRanges(prices)
	const groupedValues = groupBy(extras, 'condition')

	return (
		<Accordion title={getText('pricePerWeek')}>
			<div className="space-y-2 py-2">
				<div className="space-y-4 bg-gray-200 p-4">
					{Object.keys(groupedValues).map((conditionId, i) => (
						<div key={i}>
							<h3 className="p-2 font-bold">{getText(conditionId)}</h3>
							<ul>
								{groupedValues[conditionId]
									?.map(({optionId, price, per, required}, idx) => (
										<li key={idx} className="ml-6 list-disc py-1">
											{required || conditionId === COND_INCLUDED ? (
												getText(optionId)
											) : (
												<>
													{getText('optional')} {getText(optionId)}
												</>
											)}
											{price ? (
												<>
													{` `}
													(<MoneyValue value={price} />
													{per && <> {getText(`a$per:${per}`)}</>})
												</>
											) : null}
										</li>
									))
									.filter(Boolean)}
							</ul>
						</div>
					))}
				</div>
				{ranges &&
					ranges.length &&
					ranges.map((range, i) => (
						<Fragment key={i}>
							<div className="flex w-full justify-between">
								<DateRangeValue value={range} showNights={false} />
								<MoneyValue value={range.price} />
							</div>
							{i !== ranges.length - 1 && (
								<hr className="border-t border-gray-200" />
							)}
						</Fragment>
					))}
			</div>
		</Accordion>
	)
}
const Availability = ({house}) => {
	const {getText} = useI18n()
	return (
		<Accordion title={getText('availabilities')}>
			<Availabilities item={house} />
		</Accordion>
	)
}

const Surroundingz = ({house}) => {
	const {getText} = useI18n()
	return (
		<Accordion title={getText('surroundings')}>
			<Surroundings item={house} />
			<MapWrapper item={house} />
		</Accordion>
	)
}

const Tips = ({house}) => {
	const {getText} = useI18n()
	const noTranslation = getText('noTranslation')

	return house.tips &&
		typeof house.tips === 'string' &&
		!house.tips.startsWith(noTranslation) ? (
		<Accordion title={getText('tips')}>{house.tips}</Accordion>
	) : null
}

const MobileHousePage: React.FC<{readonly house: House}> = ({house}) => {
	const {getText} = useI18n()
	const searchedPeriod = useSelector(state => state.searchRentals.period)
	return (
		<div>
			<HousePageBreadcrumbs house={house} />
			<HouseImagesSlider images={house.images} />
			<div className="px-4">
				<div className="flex flex-row justify-between py-5">
					<div className="flex flex-row space-x-2">
						<Label text={getText(house.type)} />
						<Label text={`${house.capacity} ${getText('persons')}`} />
					</div>
					<div className="flex items-center p-2">
						<ShareIcon className="fill-primary cursor-pointer" />
					</div>
				</div>
				<HouseReviewScore score={house.score} />
				<HouseLocation house={house} />
				<TitleWithDescription house={house} />
				<FeatureLabels house={house} />
				<HouseSloganAndPromotions house={house} />
				<ReservationCard {...{...house, searchedPeriod}} />
				<Facilities house={house} />
				<PricesPerWeek house={house} />
				<Availability house={house} />
				<Surroundingz house={house} />
				<Tips house={house} />
			</div>
			<div>
				<div className="px-6">
					<WhyCap />
				</div>
				<ReviewsSection house={house} />
				<PopularDestinations />
				<Newsletter />
				<HelpSection />
			</div>
		</div>
	)
}
export default MobileHousePage
