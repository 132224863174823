import React from 'react'
import {specialsQuery} from 'app/_server/queries/specials'
import {graphql, useQuery} from 'plugins/apollo'
import {Img as Image, HeadBox, SpacesBox} from 'app/Components/Layout/Display'
import OrigSlickSlider from 'app/Components/Carousels/SlickSlider'
import styled from 'styled-components'
import Loading from 'app/Components/Loading'
import Icon, {glyphs} from 'app/Components/Icon'
import {_, LangLink, useI18n} from 'plugins/i18n'
import LazyLoad from 'react-lazyload'
import {useResponsive} from 'app/styles'
import {landingPageByUrlUserQuery} from 'app/_server/queries/landingPages'
import {compose} from 'redux'

const SlickSlider = styled(OrigSlickSlider)`
	.slick-track {
		display: flex;
	}
	&.slick-slider {
		width: 100%;
	}
`

const Arrow = styled(({left, slideCount, currentSlide, ...p}) => (
	<Icon {...p} />
))`
	position: absolute;
	cursor: pointer;
	z-index: 1;
	height: 50px;
	width: 50px;
	top: calc(50% - 25px);
	${p => (p.left ? 'left: 0' : 'right: 0')};
`

const NextArrow = ({className, style, ...props}) => (
	<Arrow {...props} glyph={glyphs.arrowRight} />
)

const PrevArrow = ({className, style, ...props}) => (
	<Arrow {...props} left glyph={glyphs.arrowLeft} />
)

const settings = {
	arrows: 1,
	slidesToScroll: 1,
	draggable: false,
	infinite: false,
	autoplay: 0,
	autoplaySpeed: 5000,
	pauseOnHover: 1,
	nextArrow: <NextArrow />,
	prevArrow: <PrevArrow />,
}

export const SpecialMiniature: React.FC<{
	readonly title?: string
	readonly image?: object
	readonly icon?: string
}> = ({title, image, icon}) => (
	<div className="relative flex w-full items-center justify-center">
		<LazyLoad height={300} offset={100}>
			<Image ratio={3 / 4} value={image} />
		</LazyLoad>
		<div className="bg-capGreen absolute right-0 bottom-0 left-0 p-4 text-center text-white">
			{title}
		</div>
		{icon && (
			<div className="absolute bottom-8 w-full text-center">
				<Icon color="white" glyph={glyphs[icon]} size="1.5em" />
			</div>
		)}
	</div>
)

const SpecialsListItem = ({special, isPhone, lang}) => {
	const {id, title, image, icon} = special
	const {data, previousData} = useQuery(landingPageByUrlUserQuery, {
		variables: {url: `specials/${id.slice(3)}`, lang},
	})
	const landing = data?.landingPageByUrl || previousData?.landingPageByUrl
	if (!landing) return null

	return (
		<div key={id} className="flex w-full items-center justify-center pr-3">
			<LangLink
				className="w-full"
				to={landing.redirectUrl || landing.urlI18n[lang]}
			>
				<SpecialMiniature {...{title, image, icon, isPhone}} />
			</LangLink>
		</div>
	)
}
export const SpecialsList: React.FC<{
	readonly specials?: Array<{
		id: string
		[key: string]: any
	}>
	readonly isPhone?: boolean
}> = ({specials = [], isPhone}) => {
	const {lang} = useI18n()

	return (
		<div className="flex min-h-[33rem] w-full flex-col items-start justify-start">
			<HeadBox resize={0.9} underline>
				{_('specials')}
			</HeadBox>
			<SlickSlider
				{...{
					...settings,
					slidesToShow: isPhone ? 2.2 : 4.2,
				}}
			>
				{specials.map(special => (
					<SpecialsListItem
						key={special.id}
						special={special}
						isPhone={isPhone}
						lang={lang}
					/>
				))}
			</SlickSlider>
		</div>
	)
}

const Specials = ({data: {loading, specials}}) => {
	const {isPhone} = useResponsive('isPhone')
	if (loading && !specials) {
		return <Loading />
	}

	if (!specials) {
		return _`noSpecials`
	}

	const {items: sp} = specials

	return (
		<SpacesBox fullWidth vertical volume={8}>
			<SpacesBox horizontal self>
				<SpecialsList specials={sp} isPhone={isPhone} />
			</SpacesBox>
		</SpacesBox>
	)
}
export default compose(
	graphql(specialsQuery, {
		options: () => ({variables: {showOnHomepage: true}}),
	})
)(Specials)
