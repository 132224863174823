import {BrowserRouter as Router} from 'react-router-dom'
import wrapApp from './wrapApp'

const init = context => {
	context.Router = Router
	const supportsHistory = 'pushState' in globalThis.window.history
	const forceRefresh = !supportsHistory
	// const basename = config.stratokit.basename || undefined
	const basename = undefined
	context.routerProps = {forceRefresh, basename}
}

const initHooks = hooks => {
	hooks.render.initRenderContext.tap('react-router-deluxe', init)
	hooks.render.wrapApp.tap('react-router-deluxe', wrapApp)
}

export default initHooks
