import {makeSplitPoint} from 'plugins/react'
import Value from './TextSection'

export default {
	label: 'Simply Text',
	group: 'content',
	Value,
	defaultValue: {textI18n: undefined},
	Form: makeSplitPoint(() => import('./TextForm'), {ssr: false}),
}
