import React from 'react'
import {SpacesBox} from 'app/Components/Layout/Display'
import {useI18n} from 'plugins/i18n'

const Place = ({
	place: {km, min, name, typeId, timeId},
}: {
	readonly place: House['distances'][0]
}) => {
	const {getText} = useI18n()
	if (!name && !typeId) {
		return null
	}
	const distance = km
		? `: ${km} km`
		: min
			? `: ${min} min ${timeId && getText(timeId)}`
			: ''
	return (
		<span className="font-thin">
			{typeId ? getText(typeId) : name}
			{distance}
		</span>
	)
}

const NearbyPlaces = ({
	distances,
	displayBy,
}: {
	readonly distances: House['distances']
	readonly displayBy: string
}) => {
	return (
		<div className="flex w-auto flex-col items-start justify-center">
			{distances.map((place, i) => {
				return place[displayBy] && <Place key={i} place={place} />
			})}
		</div>
	)
}

export const Surroundings = ({item}: {readonly item: House}) => {
	const {getText} = useI18n()
	const distances =
		item.distances && item.distances.length > 0 && item.distances
	return distances ? (
		<SpacesBox vertical between>
			<h3>{getText('surroundings')}</h3>
			<div
				className="grid w-full items-start justify-start gap-x-8 gap-y-4 text-base"
				style={{gridTemplateColumns: 'repeat(auto-fill, minmax(20em, 1fr))'}}
			>
				<NearbyPlaces distances={distances} displayBy="name" />
				<NearbyPlaces distances={distances} displayBy="typeId" />
			</div>
		</SpacesBox>
	) : null
}
