import {I18nMetaForm} from 'app/Components/Inputs'
import smoothscroll from 'smoothscroll-polyfill'

const initClientHooks = (hooks: Hooks) => {
	// Add polyfill for smooth scrolling on Safari etc
	smoothscroll.polyfill()
	hooks.render.initRenderContext.tap('app', context => {
		context.I18nMetaForm = I18nMetaForm
	})
}

export default initClientHooks
