import React, {useCallback, useEffect, useState} from 'react'
import {get} from 'lodash-es'
import {
	Route,
	useHistory,
	useLocation,
	useRouteMatch,
} from 'plugins/react-router-deluxe'
import {langs} from './lib'
import {useI18n} from './I18nContext'

const {prefix} = __CONFIG__
const langRegex = new RegExp(
	`^${prefix && `(${prefix})?`}(/(${langs.join('|')}|xx))?(/|$)`
)
const langMatch = `${prefix}/:lang(${langs.join('|')}|xx)`

const EnsureLang = ({
	reload,
	routeArgs,
}: {
	readonly reload?: boolean
	readonly routeArgs: any
}) => {
	const i18n = useI18n()
	const [iLang, setILang] = useState(i18n.lang)
	const [uLang, setULang] = useState<string | undefined>(undefined)
	const [iWant, setIWant] = useState<string | null>(null)
	const [uWant, setUWant] = useState<string | null>(null)
	const [lastLangUrl, setLastLangUrl] = useState<string | null>(null)

	const history = useHistory()
	const location = useLocation()
	const match = useRouteMatch()

	const getUrlLang = useCallback(() => {
		const lang = get(match, 'params.lang')
		return lang && lang !== 'xx' ? lang : undefined
	}, [match])

	const setUrlToLang = useCallback(
		(lang: string) => {
			if (lang === lastLangUrl) return
			setLastLangUrl(lang)

			const noLangPath = location.pathname.replace(langRegex, '/')
			const pathname = `${prefix}/${lang}${noLangPath}`

			if (pathname === location.pathname) {
				setLastLangUrl(null)
				return
			}

			if (reload) {
				window.location.assign(pathname)
			} else {
				history.replace({...location, pathname})
			}
		},
		[history, lastLangUrl, location, reload]
	)

	const ensureUrlCompliance = useCallback(() => {
		const nextULang = getUrlLang()

		if (lastLangUrl === nextULang) setLastLangUrl(null)

		if (i18n.lang !== iLang) {
			setILang(i18n.lang)
			if (iWant === i18n.lang) setIWant(null)
			if (!iWant) setUWant(i18n.lang)
		}

		if (nextULang !== uLang) {
			setULang(nextULang)
			if (nextULang) {
				if (uWant === nextULang) setUWant(null)
			} else if (!uWant) {
				setUWant(iWant || iLang)
			}
			if (!uWant) setIWant(nextULang)
		}

		if (iWant === iLang) setIWant(null)
		if (uWant === uLang) setUWant(null)

		if (iWant) {
			i18n.setLang?.(iWant)
		} else if (uWant) {
			setUrlToLang(uWant)
		}
	}, [getUrlLang, i18n, iLang, iWant, lastLangUrl, setUrlToLang, uLang, uWant])

	useEffect(() => {
		ensureUrlCompliance()
	}, [
		iLang,
		uLang,
		iWant,
		uWant,
		match,
		getUrlLang,
		lastLangUrl,
		i18n,
		setUrlToLang,
		ensureUrlCompliance,
	])

	return <Route {...routeArgs} />
}

// TODO implement with useI18n + useRef + running ensureUrl from Route renderfn directly
const LangRoute = args => (
	<Route path={langMatch}>
		{props => <EnsureLang {...props} routeArgs={args} />}
	</Route>
)

export default LangRoute
