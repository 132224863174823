import React from 'react'
import PropTypes from 'prop-types'
import {styled, ThemeProvider, themes} from 'app/styles'
import {mergeWith, get} from 'lodash-es'
import {SectionsContext} from './Sections/Provider'
import LazyLoad from 'react-lazyload'

const SectionDom = styled.div`
	width: 100%;
`

const mapToValue = templateData => (objValue, path) => {
	const value = get(templateData, path)
	if (typeof value === 'undefined') {
		return objValue
	}
	return value
}

const SectionValue = props => {
	const {templateData, inEditor, isNarrow, isServer} = props
	const sections = React.useContext(SectionsContext)

	// get value from FormField or direct
	const val = props.type
		? {
				type: props.type.input.value,
				wide: props.wide.input.value,
				lazy: props.lazy.input.value,
				theme: props.theme.input.value,
				value: props.value.input.value,
				mapping: props.mapping.input.value,
			}
		: props.value || {}
	const {type, wide, lazy, mapping, hide} = val

	if (!inEditor && (isNarrow ? hide === 'narrow' : hide === 'other'))
		return null

	if (!type) return inEditor ? <SectionDom>Pick a type</SectionDom> : null
	const section = sections[type]
	if (!section) return <SectionDom>Unknown section type {type}</SectionDom>

	const value = {...section.defaultValue, ...val.value}
	if (templateData && mapping) {
		mergeWith(value, mapping, mapToValue(templateData))
	}

	const out = (
		<section.Value
			value={value}
			// Used by Template to widen section
			className={wide && 'wide'}
			templateData={templateData}
			isNarrow={isNarrow}
			isServer={isServer}
			inEditor={inEditor}
		/>
	)

	const theme = val.theme || section.defaultTheme
	const themeDef = theme && themes[theme]
	const withTheme = themeDef ? (
		<ThemeProvider theme={themeDef}>{out}</ThemeProvider>
	) : (
		out
	)
	return lazy ? (
		isServer ? null : (
			<LazyLoad height={300} offset={100}>
				{withTheme}
			</LazyLoad>
		)
	) : (
		withTheme
	)
}
SectionValue.propTypes = {
	inEditor: PropTypes.bool,
	mapping: PropTypes.object,
	templateData: PropTypes.object,
	type: PropTypes.any,
	theme: PropTypes.string,
	value: PropTypes.object,
	wide: PropTypes.bool,
	lazy: PropTypes.bool,
	isNarrow: PropTypes.bool,
	isServer: PropTypes.bool,
}

export default SectionValue
