import wrapApp from './wrapApp'
import postError from './postError'

const initHooks = hooks => {
	hooks.render.wrapApp.tap('error-reporter', wrapApp)

	// Install a global error handler
	if (typeof window !== 'undefined') window.addEventListener('error', postError)
}

export default initHooks
