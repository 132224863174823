import React, {FunctionComponent} from 'react'
import {CalendarInput, InputField} from 'app/Components/Inputs'
import {firstDatesAroundRange, calcSwitchRange, makePlainRange} from 'app/lib'
import {flatten} from 'lodash-es'
import {areIntervalsOverlapping} from 'date-fns'
import {makeSwitchDayStyle} from './helpers'
import {ISODateToNumArr} from 'plugins/i18n'

const ReservationCalendarInput: FunctionComponent<
	ReservationCalendarPropsType
> = ({
	blockedRanges,
	numberOfCalendars = 1,
	useSwitchRange,
	switchDay,
	dateRange,
	name = 'dateRange',
	...rest
}) => {
	let selectedStartFix, selectedEndFix

	const hasDateRange = !!(dateRange?.start && dateRange.end)

	let selectedDateRange = hasDateRange
		? {
				start: new Date(dateRange.start.toString()),
				end: new Date(dateRange.end.toString()),
			}
		: undefined

	const selectedFixDateRange =
		selectedDateRange &&
		makePlainRange(selectedDateRange.start, selectedDateRange.end)

	// selected date range to display
	const blockedFormatted =
		blockedRanges?.map(({beginDate, endDate, isOption}) => ({
			start: beginDate,
			end: endDate,
			type: isOption ? 'OPTION' : 'BLOCK',
		})) || []

	if (selectedFixDateRange) {
		if (useSwitchRange && switchDay) {
			// extend date range to nearest switchDay's dates or blocked dates
			selectedDateRange = calcSwitchRange(selectedFixDateRange, switchDay)
		}

		// select overlapping ranges
		const overlappedRanges = selectedDateRange
			? blockedFormatted.filter(bF =>
					areIntervalsOverlapping(selectedDateRange!, {
						start: new Date(...ISODateToNumArr(bF.start)),
						end: new Date(...ISODateToNumArr(bF.end)),
					})
				)
			: []

		selectedStartFix = selectedFixDateRange.start
		selectedEndFix = selectedFixDateRange.end

		if (overlappedRanges.length) {
			const overlappedDates = flatten(
				overlappedRanges.map(oR => [oR.start, oR.end])
			)

			// narrow overlapping ranges, if they includes blocked dates
			const {left, right} = firstDatesAroundRange(
				selectedFixDateRange,
				overlappedDates
			)

			selectedStartFix = left || selectedStartFix
			selectedEndFix = right || selectedEndFix
		}

		// extend selected date range to display (if needed)
		blockedFormatted.push({
			start: selectedStartFix,
			end: selectedEndFix,
			type: 'SWITCHRANGE',
		})
	}

	/** @type {Partial<CalendarInputPropsType>} */
	const calendarSettings = {
		minimumDate: new Date(),
		showLegend: false,
		styleDate: makeSwitchDayStyle(switchDay),
		...rest,
		value: selectedDateRange,
		dateStates: blockedFormatted,
		numberOfCalendars,
		selectionType: 'range',
		switchDateStep: 'month',
	}

	// @ts-ignore
	return <InputField name={name} Input={CalendarInput} {...calendarSettings} />
}

export default ReservationCalendarInput
