import {makeSplitPoint} from 'plugins/react'
import Value from './SeoSection'

export default {
	label: 'SEO',
	group: 'content',
	Value,
	Form: makeSplitPoint(() => import('./SeoForm'), {ssr: false}),
	defaultValue: {titleI18n: null, descriptionI18n: null, keywordsI18n: null},
}
