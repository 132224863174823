import React, {ComponentProps, useEffect, useRef, useState} from 'react'
import Btn from 'app/Components/Buttons/Button'
import Dropdown from 'app/Components/Inputs/Dropdown'
import Form from 'app/Components/Form'
import {Box, ThemedBox} from 'app/Components/Layout'
import {Line} from 'app/Components/Layout/Display'
import {MoneyValue as OrigMoneyValue} from 'app/Components/Numbers'
import {ReservationCalendarInput} from '.'
import {
	BeigeGreenTheme,
	BeigeTheme,
	goldColor,
	orangeColor,
	getFg,
	styled,
	ColorProps,
	useResponsive,
} from 'app/styles'
import {
	_,
	DATE_REVERSE_2_FORMAT,
	dateFormatter,
	LangLink,
	useI18n,
} from 'plugins/i18n'
import {fixDate, makePlainRange, toStringRange, ensureRange, cn} from 'app/lib'
import {calcStayPrice} from 'app/lib/calcStayPrice'
import {calcFirstAvailableDate} from 'app/lib/calcPrice'
import {useSelector} from 'plugins/redux/hooks'
import {InputField} from 'app/Components/Inputs'
import {DomainHouseSelect} from 'app/Components/House/HouseDomainDescription'
import {FavIcon} from 'app/Components/Icon'
import {useFavoriteToggle, useReservationPath} from 'app/hooks'
import ReservationPeriodInputMobile from './ReservationPeriodInputMobile'
import ReservationPriceMobile from './ReservationPriceMobile'
import {addWeeks} from 'date-fns'

const CardContainer = styled(ThemedBox).attrs({
	relative: true,
	maxWidth: '20em',
})`
	box-shadow: 0 0 7px rgba(50, 50, 50, 0.32);
	margin-top: -8em;
	padding-top: ${p => p.hasLabel && '1em'};
`
const CardLabel = styled(({isNew, ...rest}) => (
	<Box absolute left inline {...rest} />
))`
	left: 1em;
	top: -0.6em;
	padding: 0 0.5em;
	color: white;
	background: ${p => (p.isNew ? goldColor : orangeColor)};
`
const MoneyValue = styled(OrigMoneyValue)`
	font-size: 2em;
	font-weight: lighter;
	${p => p.theme.moneyValueCss};
`
const BasePrice = styled(OrigMoneyValue)`
	font-size: 1.1em;
	font-weight: lighter;
	opacity: 0.6;
	text-decoration: line-through;
	${p => p.theme.moneyValueCss};
`

const Info = styled.div<{hide?: boolean} & ColorProps>`
	color: ${getFg};
	visibility: ${p => p.hide && 'hidden'};
`

const Head = ({
	currentPrice,
	regularPrice,
	hasSelected,
}: {
	readonly currentPrice: number
	readonly regularPrice: number
	readonly hasSelected: boolean
}) => (
	<div>
		<Info secondary>{hasSelected ? _`priceForPeriod` : _`priceFrom`}</Info>
		<Box gap left wrap>
			<MoneyValue value={currentPrice} />
			{currentPrice < regularPrice && <BasePrice value={regularPrice} />}
			{hasSelected ? false : _`perWeek`}
		</Box>
	</div>
)

const Buttons = ({houseId, slug, ...rest}) => {
	const {isFavorite, onToggleFavorite} = useFavoriteToggle(houseId)
	const reservationPath = useReservationPath(slug)
	return (
		<Box className="mt-4" gap spaceBetween {...rest}>
			<Btn to={reservationPath} disabled={!slug} fullWidth large>
				{_('resRequest')}
			</Btn>
			<BeigeGreenTheme>
				<Btn large onClick={() => onToggleFavorite()}>
					<FavIcon active={isFavorite} />
				</Btn>
			</BeigeGreenTheme>
		</Box>
	)
}

/** Visible when compRef component is not in view */
const StickyPhoneButtons = ({
	houseId,
	slug,
	compRef,
}: {
	readonly compRef: React.MutableRefObject<null>
} & ComponentProps<typeof PhoneButtons>) => {
	const [show, setShow] = useState(false)

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setShow(!entry?.isIntersecting)
			},
			{
				threshold: 0.1, // Trigger when 10% of the element is visible
			}
		)

		const curr = compRef.current
		if (curr) {
			observer.observe(curr)
		}

		return () => {
			if (curr) {
				observer.unobserve(curr)
			}
		}
	}, [compRef])

	return (
		<div
			className={cn(
				'fixed bottom-1 w-full max-w-[92%] transition-all duration-300',
				show ? 'opacity-100' : 'pointer-events-none opacity-0'
			)}
		>
			<PhoneButtons {...{houseId, slug}} />
		</div>
	)
}

export const PhoneButtons = ({
	houseId,
	slug,
	buttonsRef,
}: {
	readonly houseId: House['id']
	readonly slug: House['slug']
	readonly buttonsRef?: React.MutableRefObject<null>
}) => {
	const {getText} = useI18n()
	const {isFavorite, onToggleFavorite} = useFavoriteToggle(houseId)
	const reservationPath = useReservationPath(slug)
	return (
		<div ref={buttonsRef} className="my-4 flex w-full space-x-2">
			<LangLink
				className="bg-primary text-onPrimary flex grow justify-center rounded-md py-2 font-bold"
				to={reservationPath}
			>
				<button type="button" disabled={!slug}>
					{getText('resRequest')}
				</button>
			</LangLink>
			<button
				className="flex items-center justify-center rounded-md border border-solid border-black bg-white px-2"
				type="button"
				onClick={() => onToggleFavorite()}
			>
				<FavIcon height="1.5em" width="1.5em" active={isFavorite} />
			</button>
		</div>
	)
}

const Card: React.FC<{
	readonly blockedRanges: Pick<DateRangeItem, 'beginDate' | 'endDate'>[]
	readonly id: string
	readonly isNew: boolean
	readonly isPromo: boolean
	readonly prices: HousePrice[]
	readonly slug: string
	readonly switchDay: number
	readonly domainId: string
	readonly isDomain: boolean
	readonly promotions: House['promotions']
}> = props => {
	const {isPhone} = useResponsive('isPhone')
	const selectedRange = useSelector(
		state => state.form?.reservationCard?.values?.dateRange
	)
	const initialDateRange = useSelector(state => state.searchRentals.period)
	const reservationButtonsRef = useRef(null)
	const {
		id: houseId,
		isPromo,
		domainId,
		isNew,
		isDomain,
		blockedRanges,
		slug,
		switchDay,
		prices = [],
	} = props

	let range
	// We saw some runtime errors where prices was not loaded yet
	let currentPrice, regularPrice
	if (prices.length > 0) {
		range =
			selectedRange &&
			makePlainRange(fixDate(selectedRange.start), fixDate(selectedRange.end))

		if (range) {
			currentPrice = calcStayPrice({
				range,
				house: props,
				includePromotions: true,
			})
			regularPrice = calcStayPrice({range, house: props})
		} else {
			const sellPrices = prices.map(p => p.sell).filter(Boolean)
			if (sellPrices.length > 0) {
				currentPrice = Math.min(...sellPrices)
			}
		}
	}

	const focusDate = selectedRange
		? new Date(selectedRange.start)
		: calcFirstAvailableDate(prices)

	const newOrPromo = isNew ? 'isNew' : isPromo ? 'isPromo' : undefined

	return isPhone ? (
		<div className="flex flex-col gap-4">
			<Form
				buttonProps={{
					houseId,
					slug,
				}}
				formId="reservationCard"
				initialValues={{dateRange: initialDateRange}}
				preventNavigation={false}
				enableReinitialize={false}
				noButtons
			>
				<div className="flex flex-col gap-2">
					{/* {isDomain && <DomainHouseSelect domainId={domainId} />} */}
					<ReservationPeriodInputMobile
						blockedRanges={blockedRanges}
						initialDate={focusDate}
						switchDay={switchDay}
					/>
				</div>
				<ReservationPriceMobile {...{range, house: props}} />
				{/* Button need to be inside form to have access to selectedRange */}
				<PhoneButtons {...{houseId, slug, buttonsRef: reservationButtonsRef}} />
				{/* <span>
					{_`FreeCancellationSlogan`}{' '}
					{dateFormatter(addWeeks(new Date(), 2), DATE_REVERSE_2_FORMAT)}
				</span> */}
				<StickyPhoneButtons
					{...{
						houseId,
						slug,
						compRef: reservationButtonsRef,
					}}
				/>
			</Form>
		</div>
	) : (
		<CardContainer hasLabel={newOrPromo}>
			{newOrPromo && <CardLabel {...{isNew}}>{_(newOrPromo)}</CardLabel>}
			<BeigeTheme>
				<Box margin="1em" column gap left>
					{currentPrice && (
						<Head
							{...{
								currentPrice,
								regularPrice,
								hasSelected: Boolean(selectedRange),
							}}
						/>
					)}
					<Line length="3em" secondary />
					<Form
						buttonProps={{
							slug,
							houseId,
						}}
						formId="reservationCard"
						initialValues={{dateRange: initialDateRange}}
						preventNavigation={false}
						Buttons={Buttons}
						enableReinitialize={false}
					>
						<div className="flex w-full flex-col items-center justify-center gap-4">
							{isDomain && <DomainHouseSelect domainId={domainId} />}
							<InputField
								Input={Dropdown}
								name="dateRange"
								label={_`period`}
								placeholder={_`stayRange`}
								format={v => toStringRange(v)}
								parse={ensureRange}
							>
								<ReservationCalendarInput
									blockedRanges={blockedRanges}
									initialDate={focusDate}
									switchDay={switchDay}
								/>
							</InputField>
						</div>
					</Form>
				</Box>
			</BeigeTheme>
		</CardContainer>
	)
}

const WrappedCard = ({...props}) => {
	const domainHouse = useSelector(
		state => state.form?.reservationCard?.values?.domainHouse
	)
	return props.isDomain ? (
		<Card {...props} {...domainHouse} />
	) : (
		<Card {...props} />
	)
}

export default WrappedCard
