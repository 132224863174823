import React from 'react'
import {SpacesWrap} from 'app/Components/Layout/Display'
import Box from 'app/Components/Layout/Box'
import {_} from 'plugins/i18n'
import {HouseDomainDescription} from 'app/Components/House/HouseDomainDescription'
import {ReservationCard} from 'app/Components/Reservation'
import {Template} from 'plugins/templates'
import {useQuery} from 'plugins/apollo'
import {houseDomainQuery} from 'app/_server/queries/houseDomains'
import Loading from 'app/Components/Loading'
import {HTTP404} from 'plugins/react-router-deluxe'
import MiniMap from './MiniMap'
import {useResponsive} from 'app/styles'
import {useSelector} from 'plugins/redux/hooks'

const DomainPage = ({house}) => {
	const {isPhone} = useResponsive('isPhone')
	const favorites = useSelector(state => state.favoritesAndShare)
	const {domainId} = house
	const {data: domainData, loading: domainDataLoading} = useQuery(
		houseDomainQuery,
		{variables: {id: domainId}}
	)
	if (!domainData) {
		if (domainDataLoading) return <Loading />
		return (
			<>
				<HTTP404 />
				{_('missingDomain')}
			</>
		)
	}

	const domain = domainData.houseDomain
	const {regionId, images, distances} = domain
	const templateData = {
		domainImages: images,
		distances,
		...house,
	}
	const description = (
		<HouseDomainDescription
			main
			isPhone={isPhone}
			{...{
				domainData: domain,
				favorites,
			}}
		/>
	)
	return (
		<>
			<Template id="domainInfoTop" templateData={templateData} />
			<SpacesWrap volumes={[8, 2]}>
				{isPhone ? (
					description
				) : (
					<div className="flex w-full items-start justify-center gap-8">
						{description}
						<Box column right {...{width: '35%'}}>
							<ReservationCard isDomain {...{domainId}} />
							<MiniMap {...{regionId}} />
						</Box>
					</div>
				)}
			</SpacesWrap>
			<Template id="domainInfoBottom" templateData={templateData} />
		</>
	)
}

export default DomainPage
