// Differences from react-router Route:
// * `listen` prop enables listening to history changes
//   this simplifies use under shouldComponentUpdate parents, @connect
// * regular children are only rendered if route matches
// * `component` is renamed to `Component`
// * you can pass `props` to Component

import React from 'react'
import {Route as OrigRoute, RouteProps} from 'react-router-dom'

const DeluxeRoute: React.FC<
	RouteProps & {
		readonly Component?: React.ElementType
		readonly props?: Record<string, any>
		readonly children?: React.ReactNode
		readonly component?: React.ComponentType<any> // deprecated
	}
> = ({
	Component,
	component, // deprecated
	props,
	children,
	...rest
}) => {
	const renderCmpWProps = (rProps: any) => {
		const RenderComponent = Component || component
		if (RenderComponent) {
			return <RenderComponent {...rProps} {...props} />
		}
		return null
	}

	const renderChildren = () => children

	if (props) {
		// Allow passing props to component
		return <OrigRoute {...rest} render={renderCmpWProps} />
	}

	if (children != null && typeof children !== 'function') {
		// Don't render non-function children if no match
		return <OrigRoute {...rest} render={renderChildren} />
	}

	return (
		<OrigRoute {...rest} component={Component || component}>
			{children}
		</OrigRoute>
	)
}

export default DeluxeRoute
