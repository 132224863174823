export const BACKOFFICE = 'backoffice'
export const LANDING_PAGES = 'landingPages'
export const PDF = 'pdf'
export const PAYMENT = 'payment'
export const VOUCHER = 'voucher'
export const REPORT = 'report'
export const CONTRACT_PRINT = 'contract-print'
export const AGENT_CONTRACT_REPORT = 'agent-contract-report'

export const COMPANY_INFO: CompanyInfo = {
	address: {
		city: 'Gentbrugge',
		pcode: 9050,
		street: 'Henri Pirennelaan 61',
	},
	bank1: {
		name: 'BNP Paribas Fortis',
		IBAN: 'BE46 2900 4094 5236',
		BIC: 'GEBABEBB',
	},
	bank2: {
		name: 'Belfius',
		IBAN: 'BE46 0688 9758 0736',
		BIC: 'GKCCBEBB',
	},
	companyId: 'BE 0803 344 694',
	email: 'info@cap.be',
	name: 'Cap Holiday Rentals BV',
	phone: '+3293623142',
	phoneLabel: '+32 9 362 31 42',
	url: 'https://www.cap.be',
	urlLabel: 'www.cap.be',
}
