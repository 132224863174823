import React from 'react'
import {Link} from 'react-router-dom'
import {useI18n} from './I18nContext'

const {prefix} = __CONFIG__

const LangLink: typeof Link = props => {
	const {lang} = useI18n()
	const {to: origTo} = props
	let to
	if (origTo) {
		if (typeof origTo === 'object') {
			if (origTo.pathname?.startsWith(`/xx/`))
				to = {
					...origTo,
					pathname: `${prefix}/${lang}${origTo.pathname.slice(3)}`,
				}

			// this is a hotfix for searchurl, remove when possible
			if (typeof (origTo as any).search === 'object')
				to = {...origTo, ...to, search: undefined}
		} else if (origTo.startsWith) {
			if (origTo.startsWith(`/xx/`)) {
				to = `${prefix}/${lang}${origTo.slice(3)}`
			}
		} else {
			to = origTo
			// eslint-disable-next-line no-console
			console.warn(`LangLink: Don't know how to handle "to":`, to)
		}
	}

	if (to) return <Link {...{...props, to}} />
	return <Link {...props} />
}

export default LangLink
