import React, {cloneElement, Children, ReactNode} from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import {useResponsive} from 'app/styles'

const RTooltip = styled(ReactTooltip)`
	pointer-events: auto !important;
	background-color: #d5d8cd !important;
	color: black !important;
	&:hover {
		visibility: visible !important;
		opacity: 1 !important;
	}
	&:after {
		border-top-color: #d5d8cd !important;
		border-bottom-color: #d5d8cd !important;
	}
`

let lastId = 0
const Tooltip: React.FC<{
	readonly tooltipText?: ReactNode
	readonly children?: ReactNode
	readonly className?: string
	[key: string]: any // To allow any additional props to be passed to RTooltip
}> = ({children, tooltipText, className, ...props}) => {
	const {isServer} = useResponsive('isServer')
	if (!children) return null

	const id = `${lastId++}`
	const child = Children.only(children)
	const tipped = cloneElement(child as React.ReactElement, {
		'data-tip': true,
		'data-for': id,
		className: [child.props.className, className].filter(Boolean).join(' '),
	})

	return (
		<>
			{tipped}
			{!isServer && (
				<RTooltip
					offset={{top: -8}}
					delayHide={200}
					effect="solid"
					{...props}
					id={id}
					uuid={`tt${id}`} // If we don't pass this, Tooltip will use random UUIDs
				>
					{tooltipText}
				</RTooltip>
			)}
		</>
	)
}
export default Tooltip
