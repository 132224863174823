import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {SpacesWrap, HeadBox} from 'app/Components/Layout/Display'
import {housesListQuery} from 'app/_server/queries/houses'
import {Query} from 'plugins/apollo'
import {NewOrPromo} from 'app/Components/House'
import {_} from 'plugins/i18n'
import CarouselLightbox from '../housedetailSlideshow/LightBoxCarousel'
import SmallThumbnailGrid from 'app/Components/Layout/SmallThumbnailGrid'

const House = styled.div`
	cursor: pointer;
`

const DomainSection = ({templateData = {}, isPhone}) => {
	const [selectedHouse, setSelectedHouse] = useState(null)
	const {domainId} = templateData
	if (!domainId) return <span>domainId is missing</span>
	const isMobileNew = isPhone
	return (
		<SpacesWrap volumes={[8, 2]}>
			<Query
				query={housesListQuery}
				variables={{domainId, idNotIn: [templateData.id]}}
			>
				{({data}) => {
					if (!data.houses) return false
					const items = [...data.houses.items, templateData]
					return (
						<div className="flex w-full flex-col items-start justify-center gap-8">
							<HeadBox lvl={4}>{_`AvailableHouses`}</HeadBox>
							<SmallThumbnailGrid itemNum={isPhone ? 1 : 4}>
								{items
									.map(house => ({...house, description: null}))
									.map(house => (
										<House
											key={house.id}
											onClick={() => setSelectedHouse(house)}
										>
											<NewOrPromo
												label
												preventRedirect
												{...{house, isMobileNew}}
											/>
										</House>
									))}
							</SmallThumbnailGrid>
						</div>
					)
				}}
			</Query>
			<CarouselLightbox
				images={selectedHouse?.images}
				isOpen={!!selectedHouse}
				onClose={() => setSelectedHouse(null)}
			/>
		</SpacesWrap>
	)
}

DomainSection.propTypes = {
	templateData: PropTypes.object,
	isPhone: PropTypes.bool,
}

export default connect(({responsive: r}) => ({isPhone: r.isPhone}))(
	DomainSection
)
