import {makeSplitPoint} from 'plugins/react'

// Prevent import loops via Inputs
const I18nInputAsync = makeSplitPoint(() => import('./I18nInput'), {ssr: false})
export const I18nTextInput = makeSplitPoint(() =>
	import('./I18nInput').then(i => i.I18nTextInput)
)
export const I18nTextareaInput = makeSplitPoint(() =>
	import('./I18nInput').then(i => i.I18nTextareaInput)
)
export const I18nHtmlInput = makeSplitPoint(() =>
	import('./I18nInput').then(i => i.I18nHtmlInput)
)

export const SectionSelect = makeSplitPoint(
	() => import('../react/SectionSelect')
)

export default I18nInputAsync
