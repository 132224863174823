import {roundRate, roundMoney, makeValidRange, makePlainRange} from 'app/lib'
import {
	COND_PAYINADVANCE,
	PER_FIXED,
	PER_PERSON,
	PER_PERSONWEEK_PRORATED,
	PER_PERSONWEEK,
	PER_WEEK_PRORATED,
	PER_WEEK,
} from 'app/_server/database/constants'
import {ISODateToNumArr} from 'plugins/i18n/react/dateFormatter'
import {calcFullStayPrice, calcStayPrice} from './calcStayPrice'

import {calcNumDays} from './dateHelpers'

export const calcFirstAvailableDate = (
	prices: Pick<HousePrice, 'sell' | 'date'>[],
	startDate?: DateISOString
) => {
	const date = startDate ? new Date(...ISODateToNumArr(startDate)) : new Date()
	const fMDate = prices.find(p => p.sell && p.date && new Date(p.date) >= date)
	if (!fMDate) return

	return new Date(fMDate.date)
}

export const withRequiredExtras = (
	options: Extra[],
	house: Pick<House, 'extras'>
): Extra[] => {
	const selectedIds = new Set(options.map(e => e?.optionId))
	const notSelectedRequiredExtras = (house?.extras || []).filter(
		e => e.required && !selectedIds.has(e.optionId)
	)

	const houseExtrasObject = house?.extras?.reduce(
		(obj, item) => Object.assign(obj, {[item.optionId]: item}),
		{}
	)
	const selectedOptionsAllData: Extra[] = options.map(option => ({
		...(houseExtrasObject && houseExtrasObject[option.optionId]),
		...option,
	}))
	return [...selectedOptionsAllData, ...notSelectedRequiredExtras]
}

export const calcOptionPrice = (
	option: Extra,
	stayDays: number,
	onlyRequired?: boolean
) => {
	let calculatedPrice = 0

	const fullWeeks = Math.ceil(stayDays / 7) || 0
	const price = Number(option.price) || 0
	const count =
		Number(option.count) || (!onlyRequired || option.required ? 1 : 0)

	switch (option.per) {
		case PER_WEEK: {
			calculatedPrice = price * fullWeeks * count
			break
		}
		case PER_PERSONWEEK: {
			calculatedPrice = price * fullWeeks * count
			break
		}
		case PER_PERSONWEEK_PRORATED: {
			calculatedPrice = ((price * stayDays) / 7) * count
			break
		}
		case PER_WEEK_PRORATED: {
			calculatedPrice = (price * stayDays) / 7
			break
		}
		case PER_FIXED: {
			calculatedPrice = price * count
			break
		}
		// eslint-disable-next-line unicorn/no-useless-switch-case
		case PER_PERSON:
		default: {
			// On FM contracts there's no PER
			calculatedPrice = price * count
		}
	}
	return calculatedPrice
}

export const optionsTotalPrices = ({
	options,
	stayDays,
	optionsAdjust,
	onlyRequired,
}: {
	options: Contract['options']
	stayDays: Contract['prices']['stayDays']
	optionsAdjust: Contract['rentalFees']['optionsAdjust']
	onlyRequired?: boolean
}) => {
	let optionsBase = 0
	let optionsThere = 0

	if (options) {
		for (const o of options) {
			const calculatedPrice = calcOptionPrice(o, stayDays, onlyRequired)
			if (o.condition === COND_PAYINADVANCE) optionsBase += calculatedPrice
			else optionsThere += calculatedPrice
		}
	}
	optionsBase = roundMoney(optionsBase)
	optionsThere = roundMoney(optionsThere)
	const optionsTotal = roundMoney(optionsBase + (optionsAdjust || 0))

	return {
		optionsBase,
		optionsThere,
		optionsTotal,
	}
}

export const calcPrices = (
	house: Pick<
		House,
		| 'id'
		| 'name'
		| 'prices'
		| 'promotions'
		| 'payThere'
		| 'guarantee'
		| 'taxCategory'
	>,
	contract: Pick<
		NotSure<Contract, 'rentalFees'>,
		| 'beginDate'
		| 'derived'
		| 'endDate'
		| 'hasInsurance'
		| 'options'
		| 'rentalFees'
		| 'fmId'
		| 'hasTouristTax'
		| 'adults'
		| 'kids'
		| 'babies'
	> & {prices?: Partial<ContractPrices>},
	platform?: TouristTaxPlatform
): ContractPrices => {
	const housePricesError = () => {
		throw new Error(
			`calcPrices: house "${house?.id}" prices are undefined. House is not for sale.`
		)
	}

	const {
		beginDate,
		derived,
		endDate,
		hasInsurance,
		hasTouristTax,
		adults,
		kids,
		babies,
		options,
		fmId,
	} = contract || {}
	const persons = adults + kids + babies

	let {
		buyRentalAvg,
		sellRentalAvg,
		sellRentalBaseWithPromo: sellRentalBaseWithPromoPrev,
		validRange: validRangePrev,
		guaranteePayNow: guaranteePayNowPrev,
		touristTaxPA,
	} = contract?.prices || ({} as ContractPrices)
	// We don't have this information in FM
	if (fmId) sellRentalBaseWithPromoPrev = 0

	const {
		adminFee = 0,
		buyAdjust = 0,
		depositRate = 0,
		insuranceRate = 0,
		optionsAdjust = 0,
		rentalDiscountRate: rentalDiscountRateOrg,
		sellAdjust = 0,
	} = contract?.rentalFees || {}

	const {guarantee, payThere, prices: housePrices} = house || {}

	if (!beginDate || !endDate)
		throw new Error(
			`calcPrices: "${beginDate} - ${endDate}" date range is invalid`
		)

	// NOTE: we want to update (already existing) avg/base prices only if date range has been changed
	const validRange = makeValidRange(beginDate, endDate)
	const doRecalc = validRangePrev && validRangePrev !== validRange

	const range = makePlainRange(beginDate, endDate)
	const stayDays = calcNumDays(beginDate, endDate)

	// rental prices
	let buyRentalBase
	if (!doRecalc && buyRentalAvg !== undefined) {
		buyRentalBase = roundMoney((buyRentalAvg * stayDays) / 7)
	} else {
		if (!housePrices?.length) housePricesError()

		buyRentalBase = calcStayPrice({range, house, isBuyPrice: !derived})
		buyRentalAvg = roundRate((buyRentalBase / stayDays) * 7)
	}

	let sellRentalBase
	if (!doRecalc && sellRentalAvg !== undefined) {
		sellRentalBase = roundMoney((sellRentalAvg * stayDays) / 7)
	} else {
		if (!housePrices?.length) housePricesError()

		sellRentalBase = calcStayPrice({range, house})
		sellRentalAvg = roundRate((sellRentalBase / stayDays) * 7)
	}

	let sellRentalBaseWithPromo
	if (
		// special case: calculate touristTaxPA if missing
		!(doRecalc || (hasTouristTax && !touristTaxPA)) &&
		sellRentalBaseWithPromoPrev !== undefined
	) {
		sellRentalBaseWithPromo = sellRentalBaseWithPromoPrev
	} else {
		if (!housePrices?.length) housePricesError()

		const calc = calcFullStayPrice({
			range,
			house,
			persons,
			platform,
			includePromotions: true,
			buyAdjust,
			rentalDiscountRate: rentalDiscountRateOrg,
		})
		sellRentalBaseWithPromo =
			doRecalc || sellRentalBaseWithPromoPrev === undefined
				? calc.price
				: sellRentalBaseWithPromoPrev

		touristTaxPA = roundRate(calc.taxPerAdult)
	}

	const buyRentalBaseWithAdjust = roundMoney(buyRentalBase + buyAdjust)
	const sellRentalBaseWithAdjust = roundMoney(sellRentalBase + sellAdjust)

	// calculation with 'buy prices' will give the same result
	const rentalPromoDiscountRate = roundRate(
		sellRentalBaseWithPromo ? 1 - sellRentalBaseWithPromo / sellRentalBase : 0
	)

	const rentalDiscountRate = roundRate(
		(rentalDiscountRateOrg === undefined && rentalPromoDiscountRate) ||
			rentalDiscountRateOrg ||
			0
	)
	const buyRentalDiscount = roundMoney(
		buyRentalBaseWithAdjust * rentalDiscountRate
	)

	const sellRentalDiscount = roundMoney(
		sellRentalBaseWithAdjust * rentalDiscountRate
	)

	// base price after adjusts + discounts
	const buyRental = roundMoney(buyRentalBaseWithAdjust - buyRentalDiscount)
	const sellRental = roundMoney(sellRentalBaseWithAdjust - sellRentalDiscount)

	// options prices
	const {optionsBase, optionsThere, optionsTotal} = optionsTotalPrices({
		options,
		stayDays,
		optionsAdjust,
	})

	// insurance
	const insurance = roundMoney(sellRental * insuranceRate || 0)
	const insuranceToPay = hasInsurance ? insurance : 0

	// house guarantee
	let guaranteePayNow
	if (!doRecalc && guaranteePayNowPrev !== undefined) {
		guaranteePayNow = guaranteePayNowPrev
	} else {
		if (!housePrices?.length) housePricesError()

		guaranteePayNow = (house && !payThere && guarantee) || 0
	}

	// deposit total
	const depositCost = roundMoney(sellRental * depositRate)
	const depositTotal = roundMoney(depositCost + adminFee + insuranceToPay)

	// tourist tax
	const touristTax =
		hasTouristTax && touristTaxPA ? roundMoney(touristTaxPA * adults) : 0

	// sell total without discounts (former baseTotal)
	const sellBaseTotal = roundMoney(
		sellRentalBase + optionsTotal + adminFee + insuranceToPay + touristTax
	)

	// sell total / buy total (former: houseAndExtrasPrice)
	const sellTotal = roundMoney(
		sellRental + optionsTotal + adminFee + insuranceToPay + touristTax
	)
	const buyTotal = roundMoney(buyRental + optionsTotal)

	// fields

	const buyFields: ContractPricesBuy = {
		buyRentalAvg: roundRate(buyRentalAvg || 0),
		buyRentalBase: roundMoney(buyRentalBase || 0),
		buyRentalBaseWithAdjust: roundMoney(buyRentalBaseWithAdjust || 0),
		buyRentalDiscount: roundRate(buyRentalDiscount || 0),
		buyRental: roundMoney(buyRental || 0),
		buyAdjust: roundRate(buyAdjust || 0),
		buyTotal: roundMoney(buyTotal || 0),
	}

	const sellFields: ContractPricesSell = {
		adminFee: roundMoney(adminFee || 0),
		sellRentalAvg: roundRate(sellRentalAvg || 0),
		sellRentalBase: roundMoney(sellRentalBase || 0),
		sellRentalBaseWithAdjust: roundMoney(sellRentalBaseWithAdjust || 0),
		sellRentalBaseWithPromo: roundMoney(sellRentalBaseWithPromo || 0),
		sellAdjust: roundRate(sellAdjust || 0),
		guaranteePayNow: roundMoney(guaranteePayNow || 0),
		insurance: roundMoney(insurance || 0),
		insuranceToPay: roundMoney(insuranceToPay || 0),
		insuranceRate: roundRate(insuranceRate || 0),
		sellRental: roundMoney(sellRental || 0),
		depositTotal: roundMoney(depositTotal || 0),
		depositCost: roundMoney(depositCost || 0),
		depositRate: roundRate(depositRate || 0),
		sellRentalDiscount: roundRate(sellRentalDiscount || 0),
		sellBaseTotal: roundMoney(sellBaseTotal || 0),
		sellTotal: roundMoney(sellTotal || 0),
		touristTaxPA: roundRate(touristTaxPA || 0),
		touristTax: roundMoney(touristTax || 0),
	}

	const commonFields: ContractPricesCommon = {
		optionsTotal: roundMoney(optionsTotal || 0),
		optionsAdjust: roundRate(optionsAdjust || 0),
		optionsBase: roundMoney(optionsBase || 0),
		optionsThere: roundMoney(optionsThere || 0),
		rentalPromoDiscountRate: roundRate(rentalPromoDiscountRate || 0),
		rentalDiscountRate: roundRate(rentalDiscountRate || 0),
		stayDays,
		validRange,
	}

	const prices = {
		...buyFields,
		...sellFields,
		...commonFields,
	}

	return prices
}

export const deriveOptions = ({
	options,
	prevContract,
	onlySelected = true,
}: {
	options: Extra[]
	prevContract?: Contract
	onlySelected?: boolean
}): Extra[] => {
	const mergedOptions = {}

	// removing duplicates
	if (prevContract?.options)
		for (const o of prevContract.options) {
			if (mergedOptions[o.optionId]) mergedOptions[o.optionId].count += o.count
			else mergedOptions[o.optionId] = {...o}
		}

	for (const o of options) {
		if (!onlySelected || o.count || o.required) {
			const prevOption = mergedOptions[o.optionId]
			if (prevOption) {
				// merging old options with new options
				// we only update count
				prevOption.count = o.count
			} else {
				mergedOptions[o.optionId] = {...o}
			}
		} else {
			// removing options with 0 count
			delete mergedOptions[o.optionId]
		}
	}

	return Object.values(mergedOptions)
}

export const calcMinMaxPrices = (
	house: Pick<House, 'prices' | 'blockedRanges'>,
	startDate?: string,
	endDate?: string
) => {
	if (!house?.prices?.length) {
		throw new Error('calcMinMaxPrices: No price data available for this house.')
	}

	const today = new Date()
	today.setHours(0, 0, 0, 0) // Normalize start of the day

	// Sort prices by date (ascending order)
	const sortedPrices = [...house.prices].sort(
		(a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
	)

	// Find the last known valid price before today
	let lastKnownPrice: HousePrice | null = null
	for (const price of sortedPrices) {
		const date = new Date(price.date)
		if (date < today) {
			lastKnownPrice = price // Update last known price
		} else {
			break // Stop once we reach future prices
		}
	}

	// Remove past prices (only keep today and future)
	let futurePrices = sortedPrices.filter(price => new Date(price.date) >= today)

	// If there are no future prices, or we have a past price, use the last known price as the ongoing price
	if (lastKnownPrice) {
		futurePrices.unshift({
			...lastKnownPrice,
			date: today.toISOString().split('T')[0] as HousePrice['date'], // Set today as its new date
		})
	}

	// Determine default start and end dates
	const defaultStart = futurePrices[0]?.date
	const defaultEnd = futurePrices[futurePrices.length - 1]?.date

	// Use provided dates or default to the first available future range
	const start = startDate ? new Date(startDate) : new Date(defaultStart)
	const end = endDate ? new Date(endDate) : new Date(defaultEnd)

	// Helper function to check if a date is in a blocked range
	const isBlocked = (date: Date) => {
		return house.blockedRanges?.some(
			blocked =>
				new Date(blocked.beginDate) <= date && date <= new Date(blocked.endDate)
		)
	}

	// Get available date slots with prices, filtering by date range and blocked dates
	const availablePrices = futurePrices
		.filter(price => {
			if (price.sell < 0) {
				throw new Error(
					`Negative price detected: ${price.sell} on ${price.date}`
				)
			}
			return price.sell > 0 // Exclude zero/negative prices
		})
		.filter(price => {
			const date = new Date(price.date)
			return date >= start && date <= end && !isBlocked(date)
		})
		.map(price => ({
			date: new Date(price.date),
			pricePerWeek: roundMoney((price.sell / 7) * 7), // Normalize per week
		}))

	// If no valid prices remain, return 0
	if (!availablePrices.length) {
		return {minPricePerWeek: 0, maxPricePerWeek: 0}
	}

	// Find min and max price per week, ignoring zero values for min price
	const filteredPrices = availablePrices
		.map(p => p.pricePerWeek)
		.filter(price => price > 0)

	const minPricePerWeek =
		filteredPrices.length > 0 ? Math.min(...filteredPrices) : 0
	const maxPricePerWeek = Math.max(...availablePrices.map(p => p.pricePerWeek))

	return {minPricePerWeek, maxPricePerWeek}
}
