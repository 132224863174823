import React from 'react'
import {HtmlContent} from 'plugins/rich-text'
import FadeBox from 'app/Components/Layout/Fade'
import {HeadBox} from 'app/Components/Layout/Display'
import {useI18n} from 'plugins/i18n'

const HouseDescription: React.FC<{
	readonly bottom?: React.ReactNode
	readonly description?: string | object
	readonly fontResize?: number
	readonly name?: string
	readonly domainName?: string
	readonly onClick?: () => void
	readonly regionId?: string
	readonly type?: string
	readonly top?: React.ReactNode
	readonly placeName?: string
}> = ({
	bottom,
	description,
	fontResize,
	name,
	domainName,
	onClick,
	type,
	regionId,
	top,
	placeName,
}) => {
	const {getText} = useI18n()
	return (
		<div
			className="flex w-full flex-col items-start justify-center"
			onClick={onClick}
		>
			{top}
			{name && (
				<HeadBox as="h2" lvl={3} resize={fontResize}>
					{domainName
						? `${domainName} - ${name}`
						: type
							? `${getText(type)} ${name}`
							: name}
				</HeadBox>
			)}
			{regionId && regionId !== 'r$unknown' && (
				<HeadBox as="h3" lvl={4}>
					{[placeName, getText(regionId)].filter(Boolean).join(', ')}
				</HeadBox>
			)}
			{description && (
				<FadeBox fadeColor="#FBFBFB">
					<HtmlContent value={description} />
				</FadeBox>
			)}
			{bottom}
		</div>
	)
}

export default HouseDescription
