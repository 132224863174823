import {eachDayOfInterval, isWithinInterval, subDays} from 'date-fns'
import addDays from 'date-fns/addDays'
import {isEmpty} from 'lodash-es'
import {ISODateToNumArr} from 'plugins/i18n/react/dateFormatter'
import {fixDate} from '.'
import {roundMoney, roundRate} from './helpers'

const dayPrice = (
	dayStr: DateISOString,
	housePriceRanges: HousePrice[],
	isBuyPrice?: boolean
): number => {
	// get the last price range that includes the day
	for (let i = housePriceRanges.length - 1; i >= 0; i--) {
		const priceRange = housePriceRanges[i]
		if (priceRange.date <= dayStr) {
			const prices = housePriceRanges[i]
			if (isBuyPrice) return prices.buy ? prices.buy / 7 : 0
			return prices.sell / 7
		}
	}
	return 0
}

// TODO ensure promotions are sorted by start date, and calculate like dayPrice
export const calcDayDiscountPercentage = (
	dayBefore: DateISOString,
	promotions: Promotion[]
): Promotion['discountPercentage'] => {
	let discountPercentage = 0

	for (const promotion of promotions) {
		// check if day is in promotion range

		const dayBeforeNight = new Date(...ISODateToNumArr(dayBefore))
		const dayAfterNight = addDays(new Date(...ISODateToNumArr(dayBefore)), 1)
		const startPromoDate = new Date(
			...ISODateToNumArr(promotion.promoStartDate)
		)
		const endPromoDate = new Date(...ISODateToNumArr(promotion.promoEndDate))

		const hasDiscount =
			isWithinInterval(dayBeforeNight, {
				start: startPromoDate,
				end: endPromoDate,
			}) &&
			isWithinInterval(dayAfterNight, {
				start: startPromoDate,
				end: endPromoDate,
			})
		if (hasDiscount) {
			// 'abs' to avoid user errors
			discountPercentage += Math.abs(promotion?.discountPercentage) || 0
		}
	}
	return discountPercentage
}

export const calcDaysInRange = ({start, end}) => {
	const rangeForInterval = {
		start: new Date(...ISODateToNumArr(start)),
		end: subDays(new Date(...ISODateToNumArr(end)), 1),
	}
	return eachDayOfInterval(rangeForInterval)
}

type CalcStayPriceArgs = {
	range: DateISOStringRange
	house: Pick<House, 'prices' | 'id' | 'taxCategory'> & {
		promotions?: House['promotions']
	}
	persons?: number
	adults?: number
	platform?: TouristTaxPlatform
	isBuyPrice?: boolean
	includePromotions?: boolean
	/** Used for tax calc */
	buyAdjust?: number
	/** Used for tax calc */
	rentalDiscountRate?: number
}
export const calcFullStayPrice = ({
	range,
	house,
	persons,
	platform,
	isBuyPrice,
	includePromotions,
	buyAdjust,
	rentalDiscountRate,
}: CalcStayPriceArgs): {price: number; taxPerAdult: number} => {
	if (!range?.start || !range.end || isEmpty(range))
		throw new Error(`calcStayPrice: some arguments are missing`)

	if (!house?.prices?.length)
		throw new Error(`calcStayPrice: house "${house?.id}" prices are undefined`)

	const {prices = [], promotions, taxCategory, id} = house
	const {start, end} = range
	const platformTaxes =
		!!persons && typeof taxCategory === 'number' && platform?.taxes

	let price = 0
	let tax = 0

	const firstAfterStartPriceRangeIdx = prices.findIndex(p => p.date > start)
	const firstAfterEndPriceRangeIdx = prices.findIndex(p => p.date > end)

	// if no ranges after start/end date, select last available
	const leftPriceRangeIdx =
		firstAfterStartPriceRangeIdx === 0
			? 0
			: firstAfterStartPriceRangeIdx < 0 // not found
				? prices.length - 1
				: firstAfterStartPriceRangeIdx - 1

	const rightPriceRangeIdx =
		firstAfterEndPriceRangeIdx === 0
			? 0
			: firstAfterEndPriceRangeIdx < 0 // not found
				? prices.length - 1
				: firstAfterEndPriceRangeIdx

	// optimization - use only the closets price ranges
	const pricesNarrowRange = prices.slice(
		leftPriceRangeIdx,
		rightPriceRangeIdx + 1
	)

	const daysInRange = calcDaysInRange({start, end})
	const stayNights = daysInRange.length
	const dayAdjust = buyAdjust && stayNights ? buyAdjust / stayNights : 0
	for (const day of daysInRange) {
		const dayStr = fixDate(day)
		const dayBasePrice = dayPrice(dayStr, pricesNarrowRange, isBuyPrice)
		const dayBuyPrice =
			((isBuyPrice ? dayBasePrice : dayPrice(dayStr, pricesNarrowRange, true)) +
				dayAdjust) *
			(rentalDiscountRate ? 1 - rentalDiscountRate : 1)

		let dayDiscountFromPromo = 0
		if (includePromotions && promotions) {
			dayDiscountFromPromo = calcDayDiscountPercentage(dayStr, promotions)
		}

		if (!dayBasePrice)
			throw new Error(
				`calcStayPrice: house "${id}" ${
					isBuyPrice ? 'buy' : 'sell'
				} price for day "${dayStr}" is undefined`
			)

		const dayBasePriceWithPromo =
			(dayBasePrice || 0) * (1 - Math.min(dayDiscountFromPromo, 100) / 100)

		price += dayBasePriceWithPromo

		if (platformTaxes) {
			let taxDef: TouristTaxes | undefined

			// find the last definition that is before the contract start date
			for (let i = platformTaxes.length - 1; i >= 0; i--) {
				if (platformTaxes[i]!.startDate <= dayStr) {
					taxDef = platformTaxes[i]
					break
				}
			}
			if (taxDef) {
				if (taxCategory === 0) {
					const pricePerPerson =
						// if we don't have access to the buy price, remove average markup
						// avg is 17% calculated from prices in the DB
						(dayBuyPrice || dayBasePriceWithPromo * 0.83) / persons!
					const dayTax =
						pricePerPerson * taxDef.byCategory[0]! * (1 + taxDef.additionalRate)
					tax += Math.min(dayTax, taxDef.maxTax)
				} else {
					tax += taxDef.byCategory[taxCategory]!
				}
			}
		}
	}

	return {price: roundMoney(price), taxPerAdult: roundRate(tax)}
}

export const calcStayPrice = (
	args: Omit<CalcStayPriceArgs, 'persons' | 'platform'>
) => calcFullStayPrice(args).price
