import LoadingComponent from 'app/Components/Loading'
import React, {useEffect, useState} from 'react'
import {createSelector} from 'reselect'
import {isServer} from 'stratokit/build'

const NullComponent = () => null

export const makeSplitPoint = <C extends React.ComponentType<any>>(
	fn: () => Promise<C | {default: C}>,
	options?: {ssr?: boolean} & {
		LoadingComponent?: React.ComponentType<any>
	}
): C => {
	const ssr = !options || options.ssr == null || options.ssr
	if (isServer) {
		if (!ssr) return NullComponent as any as C
		let Component = () => <div>not SSR component</div>
		fn()
			.then(m => {
				Component = (m as any).default || m
			})
			.catch(e => {
				// eslint-disable-next-line no-console
				console.error(e)
			})
		return (p => <Component {...p} />) as any as C
	}

	let RealCmp: C
	const Cmp = (props: any) => {
		const [loaded, setLoaded] = useState<boolean>(!!RealCmp)
		useEffect(() => {
			if (loaded) return
			fn()
				.then(m => {
					RealCmp = (m as any).default || m
					setLoaded(true)
				})
				.catch(e => {
					// eslint-disable-next-line no-console
					console.error(e)
				})
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		return loaded ? <RealCmp {...props} /> : <LoadingComponent />
	}
	return Cmp as C
}

// Workaround for https://github.com/reduxjs/reselect/issues/231#issuecomment-296538261
// createNonPureSelector should be used if the input can be shallowly equal but not equal
export const createNonPureSelector = <Result,>(
	...args: [...inputSelectors: any[], combiner: (...a: any[]) => Result]
) => {
	const selector = createSelector(...args)
	// pass {} for the state so it forces updating
	return () => (selector as any)({})
}

export {createSelector} from 'reselect'
