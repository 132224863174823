import React from 'react'
import {Icon} from 'plugins/icons'
import {useI18n} from 'plugins/i18n'

const logoSvgs = import.meta.glob('assets/icons/logo-*.icon.svg', {
	eager: true,
	import: 'default',
})
export const LogoIcon = props => {
	const {lang} = useI18n()
	const logo =
		logoSvgs[`/assets/icons/logo-${lang}.icon.svg`] ||
		logoSvgs['/assets/icons/logo-nl.icon.svg']
	return (
		<Icon
			className="text-white"
			{...props}
			glyph={logo}
			aria-label="Cap logo"
		/>
	)
}
