/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react'
import SlickSlider from 'app/Components/Carousels/SlickSlider'
import {ArrowLeftIconNew, ArrowRightIconNew} from 'app/Components/Icon'
import {useI18n} from 'plugins/i18n'
import {Image} from 'plugins/images'
import {isServer} from 'stratokit/build'
import CarouselLightbox from 'app/Sections/housedetailSlideshow/LightBoxCarousel'

const ImageWithAlt: React.FC<{
	readonly image: GQLImage
	readonly onClick?: () => void
}> = ({image, onClick, ...props}) => {
	const i18n = useI18n()
	const alt =
		image.alt ||
		(image.tags?.[0] && i18n.getText(image.tags[0])) ||
		`House image`
	return (
		<Image
			alt={alt}
			value={{...image.upload, ...image}}
			{...props}
			onClick={onClick}
		/>
	)
}

const PrevArrow = ({
	style = undefined,
	className = undefined,
	slideCount = undefined,
	currentSlide = undefined,
	...props
}) => {
	return (
		<div
			{...props}
			className="absolute top-1/2 left-0 z-10 flex h-full w-1/12 -translate-y-1/2 transform cursor-pointer items-center justify-center"
		>
			<div className="absolute inset-0 bg-gray-500 opacity-30" />
			<ArrowLeftIconNew height="1rem" className="z-20 text-white" />
		</div>
	)
}

const NextArrow = ({
	style = undefined,
	className = undefined,
	slideCount = undefined,
	currentSlide = undefined,
	...props
}) => {
	return (
		<div
			{...props}
			className="absolute top-1/2 right-0 z-10 flex h-full w-1/12 -translate-y-1/2 transform cursor-pointer items-center justify-center"
		>
			<div className="absolute inset-0 bg-gray-500 opacity-30" />
			<ArrowRightIconNew height="1rem" className="z-20 text-white" />
		</div>
	)
}

const HousedetailSlideshow: React.FC<{
	readonly images?: House['images']
}> = ({images = []}) => {
	const [currentImage, setCurrentImage] = useState(0)
	const [lightboxIsOpen, setLightboxIsOpen] = useState(false)

	const handleOpenLightbox = (index: number) => {
		setCurrentImage(index)
		setLightboxIsOpen(true)
	}

	const handleCloseLightbox = () => {
		setLightboxIsOpen(false)
	}

	const settings = {
		arrows: true,
		draggable: false,
		useCSS: 1,
		slidesToScroll: 1,
		autoplay: false,
		pauseOnHover: 1,
		lazyLoad: 0,
		infinite: 1,
		slidesToShow: 1,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	}

	return (
		<div>
			{images.length ? (
				isServer ? (
					<div>
						{images.slice(0, 3).map((image, i) => (
							<ImageWithAlt key={i} image={image} />
						))}
					</div>
				) : (
					<SlickSlider {...settings} className="h-56 w-full cursor-pointer">
						{images.map((image, i) => (
							<div key={i} className="h-56">
								<ImageWithAlt
									key={i}
									image={image}
									onClick={() => handleOpenLightbox(i)}
								/>
							</div>
						))}
					</SlickSlider>
				)
			) : (
				'NoImagesDefined'
			)}
			<CarouselLightbox
				images={images}
				isOpen={lightboxIsOpen}
				onClose={handleCloseLightbox}
				index={currentImage}
			/>
		</div>
	)
}
export default HousedetailSlideshow
