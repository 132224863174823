import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {LabelText} from 'app/Components/Inputs/Components/InputComponents'
import Box from 'app/Components/Layout/Box'
import {styled, getBg, getFg, useResponsive} from 'app/styles'
import Icon, {glyphs} from 'app/Components/Icon'

const LabelBox = styled(({noWrap, ...props}) => <Box wrap {...props} />)`
	${p =>
		p.noWrap &&
		`> * {
		white-space: nowrap;
	}`};
`
const ValueText = styled(({valueSize, ...rest}) => (
	<LabelText {...{...rest, textSize: valueSize}} />
))`
	${p => p.valueMinWidth && `min-width: ${p.valueMinWidth}`};
`

export const Wrapper = styled(({isHidden, ...props}) => <Box {...props} />)`
	display: ${p => (p.isHidden ? 'none' : 'block')};
	margin: 5px 0 15px 0;
`

export const LabelWrapper = styled(({isHidden, ...props}) => (
	<Box width="100%" {...props} />
)).attrs({colorSet: 'capGrey'})`
	border-top: 1px solid ${getFg};
	color: ${getBg};
	font-size: 0.6em;
	margin-left: 5px;
	padding: 15px 0;
	position: relative;
`

export const StyledIcon = styled(Icon)`
	position: absolute;
	right: 0;
	cursor: pointer;
`

export const LabeledBox = ({
	label,
	value,
	children,
	inline,
	nospace,
	bigLabel,
	mediumLabel,
	fullWidth,
	noWrap,
	valueMinWidth,
	labelSize,
	valueSize,
	textSize,
}) => (
	<LabelBox
		{...{
			left: nospace,
			spaceBetween: !nospace,
			column: !inline,
			noWrap,
		}}
	>
		<LabelText
			{...{fullWidth, bigLabel, mediumLabel, textSize: labelSize || textSize}}
		>
			{label}
		</LabelText>
		<ValueText
			{...{fullWidth, valueMinWidth, valueSize: valueSize || textSize}}
		>
			{value || children}
		</ValueText>
	</LabelBox>
)
LabeledBox.propTypes = {
	label: PropTypes.node,
	value: PropTypes.node,
	children: PropTypes.node,
	inline: PropTypes.bool,
	bigLabel: PropTypes.bool,
	mediumLabel: PropTypes.bool,
	nospace: PropTypes.bool,
	fullWidth: PropTypes.bool,
	noWrap: PropTypes.bool,
	valueMinWidth: PropTypes.string,
	labelSize: PropTypes.string,
	valueSize: PropTypes.string,
	textSize: PropTypes.string,
}

export const TogglingLabeledBox = ({label, children, ...rest}) => {
	const {isMobile} = useResponsive('isMobile')
	const [isClosed, setIsClosed] = useState(isMobile)

	return (
		<LabeledBox
			{...rest}
			label={
				<LabelWrapper
					left
					spaceBetween
					onClick={() => setIsClosed(prevState => !prevState)}
				>
					{label}
					<StyledIcon
						glyph={isClosed ? glyphs.plus : glyphs.minus}
						size="2em"
					/>
				</LabelWrapper>
			}
		>
			<Wrapper isHidden={isClosed}>
				{typeof children === 'function'
					? isClosed
						? false
						: children()
					: children}
			</Wrapper>
		</LabeledBox>
	)
}

TogglingLabeledBox.propTypes = {
	label: PropTypes.node.isRequired,
	children: PropTypes.any,
}
