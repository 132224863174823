import React from 'react'
import {useResponsive} from 'app/styles'
import {LangLink, useI18n} from 'plugins/i18n'
import {useLangUrls} from 'plugins/i18n/react/langUrlsContext'
import {useLocation} from 'plugins/react-router-deluxe'
import {useDispatch} from 'plugins/redux/hooks'
import Dropdown from 'app/Components/Inputs/Dropdown'
import {triggerSidebar} from './MobileHeader/MobileNav-duck'
import {cn} from 'app/lib'

const LanguagePicker = () => {
	const {isPhone} = useResponsive('isPhone')
	const dispatch = useDispatch()
	const {langUrls} = useLangUrls()
	const {lang, langs} = useI18n()
	const location = useLocation()
	const restPath = location.pathname.slice(3)

	return isPhone ? (
		<div className="flex space-x-3">
			{langs.map(l => (
				<LangLink
					className={cn('hover:text-[#445524]')}
					key={l}
					to={`/${l}/${langUrls?.[l] || restPath.replace(/^\/+/, '')}`}
					onClick={() => dispatch(triggerSidebar())}
				>
					{l.toUpperCase()}
				</LangLink>
			))}
		</div>
	) : (
		<Dropdown placeholder={lang.toUpperCase()} value={lang} type="text">
			<div className="flex flex-col">
				{langs.map(l => (
					<LangLink
						className={cn('hover:text-[#445524]', l === lang && 'text-white')}
						key={l}
						to={`/${l}/${langUrls?.[l] || restPath.replace(/^\/+/, '')}`}
						onClick={() => dispatch(triggerSidebar())}
					>
						{l.toUpperCase()}
					</LangLink>
				))}
			</div>
		</Dropdown>
	)
}
export default LanguagePicker
