import React from 'react'
import PropTypes from 'prop-types'
import {
	styled,
	capDarkGrey,
	mediumGreyColor,
	capGreenColor,
	cut,
} from 'app/styles'
import {Box} from 'app/Components/Layout'
import {connect} from 'react-redux'
import {_, useI18n, PlainText} from 'plugins/i18n'
import {FixDateValue} from 'app/lib/dateHelpersReact'

const Card = styled(Box)`
	${cut}
	background: #fff;
	padding: 1.5rem 2rem;
`
const Quote = styled.blockquote`
	color: ${capDarkGrey};
	text-align: left;
`
const Name = styled.span`
	display: inline-block;
	font-weight: bold;
	color: ${capDarkGrey};
`
const ReviewDate = styled(Box)`
	font-size: 0.9rem;
	color: ${mediumGreyColor};
`
const Country = styled(PlainText)`
	color: ${mediumGreyColor};
`
const Score = styled(Box)`
	color: ${capGreenColor};
	font-weight: bold;
`
const Anchor = styled.a`
	display: block;
	position: relative;
	top: -250px;
	visibility: hidden;
`

let Reviews = ({item, isPhone}) => {
	const {lang} = useI18n()
	return (
		<Box column gap="2rem" padding="2rem 0">
			{item.reviews
				.filter(r => r.textI18n?.[lang] && r.name)
				.map((review, i) => {
					return (
						<Box key={i} column>
							<Anchor id={`review-${i}`} />
							<Card left column gap="1rem" cut cutSize="2">
								<Box spaceBetween column={isPhone}>
									<Box left gap="1rem">
										<Name>{review.name}</Name>
										<Country trId={review.countryId} />
									</Box>
									<Score left={isPhone} order={isPhone ? 2 : 1}>
										{review.score}/10
									</Score>
									<ReviewDate
										left={isPhone}
										right={!isPhone}
										gap="0.5rem"
										order={isPhone ? 1 : 2}
									>
										{_`dateOfStay`}
										<FixDateValue value={review.startDate} />
										<span>-</span>
										<FixDateValue value={review.endDate} />
									</ReviewDate>
								</Box>
								<Quote>{review.text}</Quote>
							</Card>
						</Box>
					)
				})}
		</Box>
	)
}
Reviews = connect(({responsive: {isPhone}}) => ({isPhone}))(Reviews)
Reviews.propTypes = {
	item: PropTypes.shape({
		reviews: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string,
				name: PropTypes.string,
				date: PropTypes.string,
			})
		),
	}),
	isPhone: PropTypes.bool,
}

export default Reviews
