import React from 'react'
import PropTypes from 'prop-types'
import {LangLink} from 'plugins/i18n'
import {styled} from 'app/styles'

const paddingFactor = {
	compact: 0.4,
	long: 1.5,
}

// Combined props for LinkButton and ButtonBase + internal props
const propTypes = {
	// Pass through
	// to: PropTypes.string,
	// disabled: PropTypes.bool,
	border: PropTypes.bool,
	compact: PropTypes.bool,
	long: PropTypes.bool,
	fullWidth: PropTypes.bool,
	xsmall: PropTypes.bool,
	small: PropTypes.bool,
	medium: PropTypes.bool,
	large: PropTypes.bool,
	xlarge: PropTypes.bool,
	danger: PropTypes.bool,
	noWrap: PropTypes.bool,
	noPadding: PropTypes.bool,
	// colors
	colorSet: PropTypes.string,
	useSet: PropTypes.string,
	secondary: PropTypes.bool,
	accent: PropTypes.bool,
	hint: PropTypes.bool,
	fgColor: PropTypes.string,
	bgColor: PropTypes.string,
	// internal
	btnHeight: PropTypes.number,
	btnLength: PropTypes.number,
	// cap-only via buttonCss - TODO move to styles somehow
	light: PropTypes.bool,
	cut: PropTypes.bool,
	leftCut: PropTypes.bool,
	cutSize: PropTypes.number,
}

// // Get rid of all propTyped props
// const makeDomFilter = (Component, propTypes) => props => {
// 	const domProps = Object.entries(props)
// 		.filter(([prop]) => !propTypes[prop])
// 		.reduce((p, [prop, value]) => {
// 			p[prop] = value
// 			return p
// 		}, {})
// 	return <Component {...domProps} />
// }
// const button = makeDomFilter('button', propTypes)
const ButtonOrLink = props => {
	// Get rid of all propTyped props
	const domProps = Object.entries(props)
		.filter(([prop]) => !propTypes[prop])
		.reduce((p, [prop, value]) => {
			p[prop] = value
			return p
		}, {})
	const {to, disabled} = props // eslint-disable-line react/prop-types
	if (to && !disabled) {
		return <LangLink {...domProps} />
	}
	return <button type="button" {...domProps} />
}

// TODO rewrite like Icon, a single interpolated function returning a css object
const Button = styled(ButtonOrLink).attrs(p => {
	const {xsmall, small, medium, large, xlarge} = p
	const {compact, long} = p

	const btnHeight = xsmall
		? 1.2
		: small
			? 1.5
			: medium
				? 1.9
				: large
					? 2.5
					: xlarge
						? 3
						: 1.5
	const btnLength = compact ? 'compact' : long ? 'long' : undefined

	return {
		useSet:
			p.useSet ||
			(p.danger ? 'btnDanger' : p.accent ? 'btnAccent' : 'btnDefault'),
		btnHeight,
		btnLength,
		hint: p.disabled,
	}
})`
	white-space: ${p => p.noWrap && 'nowrap'};
	min-height: ${p => p.btnHeight}rem;
	padding: ${p =>
		!p.noPadding &&
		`0 ${
			paddingFactor[p.btnLength]
				? p.btnHeight * paddingFactor[p.btnLength]
				: p.btnHeight / 2
		}rem`};
	width: ${p => p.fullWidth && '100%'};
	// Never overflow containers
	max-width: 100%;
	font-size: 1rem;
	font-family: inherit;
	text-align: center;
	transition: 0.33s;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	cursor: ${p => (p.disabled ? 'default' : 'pointer')};
	// somewhat support disabled links (you can still use keyboard)
	a&[disabled] {
		pointer-events: none;
	}
	&:focus {
		${p => p.theme.focusCss};
	}
	& > *:only-child {
		margin: auto;
	}
	${p => p.theme.buttonCss};
`
Button.propTypes = propTypes

export default Button
