import {cn} from 'app/lib'
import React, {FC, ReactNode} from 'react'

const baseClasses = {
	small: 'text-sm leading-5',
	base: 'text-base leading-6',
	large: 'text-lg leading-7',
	heading: 'text-2xl leading-10 font-bold',
	heading2: 'text-xl leading-8 font-bold',
	heading3: 'text-lg leading-7 font-bold',
}

const Text: FC<{
	readonly variant?: keyof typeof baseClasses
	readonly children: ReactNode
	readonly className?: string
}> = ({variant = 'base', children, className, ...props}) => {
	return (
		<span className={cn(baseClasses[variant], className)} {...props}>
			{children}
		</span>
	)
}

export default Text
