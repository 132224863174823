import React, {Fragment} from 'react'
import {groupBy} from 'lodash-es'
import {_, useI18n} from 'plugins/i18n'
import styled from 'styled-components'
import {useResponsive} from 'app/styles'
import {Icon} from 'plugins/icons'
import {glyphs} from '../Icon'

export const EqList = styled.ul`
	list-style: outside none;
	font-weight: 100;
	padding-left: 1.5em;
	& li {
		position: relative;
		&:before {
			content: '';
			--size: 0.2rem;
			background: rgba(0, 0, 0, 0.85);
			width: var(--size);
			height: var(--size);
			border-radius: 50%;
			position: absolute;
			left: -0.75rem;
			top: 0.75rem;
			transform: translateY(-50%);
		}
	}
`

const RoomNameToGlyph = {
	'bld$room:slaapkamer': glyphs['bed'],
}

const RoomLine: React.FC<{
	readonly value: {
		type: string
		count: number
	}[]
	readonly roomName: string
}> = ({value, roomName}) => {
	const {getText} = useI18n()
	const glyph = RoomNameToGlyph[roomName]
	return (
		<li>
			{roomName !== 'none' && (
				<>
					{glyph && <Icon className="inline-flex" glyph={glyph} />}
					{getText(roomName)}
				</>
			)}
			{value.length > 0 && <> {_('with')} </>}
			{value.map((eq, i) => {
				const eqType = (
					<>
						{_(eq.type)}
						{eq.count > 1 ? ` (x${eq.count})` : ''}
					</>
				)

				let separator
				if (i === value.length - 2) {
					separator = <> {_('and')}</>
				} else if (i < value.length - 2) {
					separator = ','
				} else {
					separator = ''
				}

				return (
					<Fragment key={i}>
						{' '}
						{eqType}
						{separator}
					</Fragment>
				)
			})}
		</li>
	)
}

const FloorSection = ({
	value,
	floorName,
	showFloorName,
	isOutOrCommon,
}: {
	readonly value: {room: string; equipment: {type: string; count: number}[]}[]
	readonly floorName: string
	readonly showFloorName: boolean
	readonly isOutOrCommon: boolean
}) => {
	const {isPhone} = useResponsive('isPhone')
	const Ul = isPhone ? 'ul' : EqList
	return (
		<div className="my-2 flex w-full flex-col items-start justify-center gap-4">
			{showFloorName && <h4>{_(floorName)}</h4>}
			<Ul>
				{(floorName === 'none'
					? value.filter(room => room.equipment.length)
					: value
				).map((room, i) => {
					const {room: roomName, equipment} = room
					if (isOutOrCommon) {
						return equipment.map((eq, idx) => (
							<li key={idx}>
								{_(eq.type)}
								{eq.count > 1 && ` (x${eq.count})`}
							</li>
						))
					}
					return <RoomLine key={i} roomName={roomName} value={equipment} />
				})}
			</Ul>
		</div>
	)
}

export const BuildingSection = ({
	value,
	buildingName,
	showBuildingName,
	isOutOrCommon,
}: {
	readonly value: House['rooms'][]
	readonly buildingName: string
	readonly showBuildingName: boolean
	readonly isOutOrCommon: boolean
}) => {
	const byFloor = groupBy(value, 'floor')
	const floorKeyArr = Object.keys(byFloor)
	if (!floorKeyArr.length) return null

	const showFloorName = !(floorKeyArr.length === 1 || byFloor.none)

	return (
		<div className="flex w-full flex-col items-start justify-center gap-2">
			<h3>{_(showBuildingName ? buildingName : 'generalFloor')}</h3>
			{floorKeyArr.map((key, i) => {
				const floor = byFloor[key]
				const floorName = key
				return (
					<FloorSection
						key={i}
						floorName={floorName}
						showFloorName={showFloorName}
						value={floor}
						isOutOrCommon={isOutOrCommon}
					/>
				)
			})}
		</div>
	)
}

const Facilities = ({item}: {readonly item: House}) => {
	const {isPhone} = useResponsive('isPhone')
	const {getText} = useI18n()

	if (!item) return null

	const byBuilding = groupBy(item.rooms, 'building')
	const buildingKeyArr = Object.keys(byBuilding)

	const showBuildingName =
		buildingKeyArr.filter(key => !key.startsWith('bld$bld:z')).length > 1

	const wrapperClassName = isPhone
		? ''
		: 'grid w-full grid-cols-[repeat(auto-fill,minmax(20em,1fr))] items-start justify-start gap-x-8 gap-y-4 text-base'

	return (
		<div className={wrapperClassName}>
			{buildingKeyArr.map(key => {
				const isOutOrCommon =
					key.startsWith('bld$bld:z') &&
					Boolean(byBuilding[key]![0]?.equipment.length)
				return (
					<BuildingSection
						key={key}
						buildingName={key}
						showBuildingName={showBuildingName || isOutOrCommon}
						isOutOrCommon={isOutOrCommon}
						value={byBuilding[key]}
						getText={getText}
					/>
				)
			})}
		</div>
	)
}

export default Facilities
