// TODO share components with HousedetailTabs
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {gtag} from 'plugins/trackers'
import {Query} from 'plugins/apollo'
import Box from 'app/Components/Layout/Box'
import Icon, {glyphs} from 'app/Components/Icon'
import {Tabs, Pane} from 'app/Components/Layout/Tabs'
import {styled, getBg} from 'app/styles'
import {
	Facilities,
	Availabilities,
	Surroundings,
	Offer,
} from 'app/Components/Panels'
import {housesListQuery} from 'app/_server/queries/houses'
import {SloganBox, MapWrapper} from 'app/Components/House/HousedetailTabs'
import {_} from 'plugins/i18n'
import {useHouseDomainQuery} from 'app/_server/queries/houseDomains'
import Loading from 'app/Components/Loading'

const InfoWrap = styled(Box)`
	display: ${p => (p.isHidden ? 'none' : 'block')};
	padding: 25px 0 25px 0;
`

const Wrapper = styled.div`
	padding: 15px 0 15px 0;
	margin: 0 35px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	&:last-child {
		margin-bottom: 45px;
	}
`

const Title = styled(Box).attrs({relative: true})`
	font-size: 1.2rem;
	margin: 5px 0;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 5%;
		height: 1px;
		background: ${getBg};
		display: ${p => (p.isHidden ? 'none' : 'block')};
	}
`

const HouseWrap = ({showAll: initialShowAll, value, children}) => {
	const [showAll, setShowAll] = useState(initialShowAll)

	return (
		<Wrapper top useSet="pane">
			<div
				className="flex w-full items-start justify-center"
				onClick={() => setShowAll(prevShowAll => !prevShowAll)}
			>
				<Title isHidden={!showAll} left useSet="pane">
					{value}
				</Title>
				<Icon glyph={showAll ? glyphs.minus : glyphs.plus} size="2.7em" />
			</div>
			<InfoWrap isHidden={!showAll}>{children}</InfoWrap>
		</Wrapper>
	)
}

const setInfoGTag = action => gtag('event', action, {event_category: 'Info'})
const tabList = ['Faciliteiten', 'Prijzen', 'Beschikbaarheid', 'Streek']
const handleTabChange = value => tabList[value] && setInfoGTag(tabList[value])

const HousedetailTabs = ({value, templateData}) => {
	const {domainId, limit = 10} = value || {}
	const activeSlug = value?.activeSlug || templateData?.slug

	const {data: houseDomainData, loading: houseDomainLoading} =
		useHouseDomainQuery({id: domainId}, {skip: !domainId})

	if (houseDomainLoading) {
		return <Loading />
	}
	if (!houseDomainData?.houseDomain) {
		return (
			<>'No house domain found, should only happen while editing template'</>
		)
	}

	return (
		<Query
			query={housesListQuery}
			variables={{domainId, limit, idNotIn: [templateData.id]}}
		>
			{({data}) => {
				if (!data.houses) return <Loading />
				const houses = [...data.houses.items, templateData]

				return (
					// Keep in sync with tabList above
					<Tabs name="tabs" onChange={handleTabChange}>
						<Pane label={_`facilities`}>
							{houses.map((house, i) => (
								<HouseWrap
									key={i}
									value={house.name}
									showAll={house.slug === activeSlug}
								>
									<Facilities item={house} />
								</HouseWrap>
							))}
						</Pane>
						<Pane label={_`pricesPerWeek`}>
							{houses.map((house, i) => (
								<HouseWrap
									key={i}
									value={house.name}
									showAll={house.slug === activeSlug}
								>
									{house.slogan && <SloganBox value={house.slogan} />}
									<Offer {...{item: house}} />
								</HouseWrap>
							))}
						</Pane>
						<Pane label={_`availabilities`}>
							{houses.map((house, i) => (
								<HouseWrap
									key={i}
									value={house.name}
									showAll={house.slug === activeSlug}
								>
									<Availabilities item={house} />
								</HouseWrap>
							))}
						</Pane>
						<Pane column gap padding="2em" label={_`surroundings`}>
							<Surroundings {...{item: houseDomainData.houseDomain}} />
							<MapWrapper
								{...{
									item: houseDomainData.houseDomain,
									//  houses?.[0]
								}}
							/>
						</Pane>
					</Tabs>
				)
			}}
		</Query>
	)
}
HousedetailTabs.propTypes = {
	value: PropTypes.object.isRequired,
	templateData: PropTypes.shape({
		slug: PropTypes.string,
	}),
}

export default HousedetailTabs
