import React from 'react'
import {Template} from 'plugins/templates'
import SearchRentals from 'app/Components/SearchRentals'
import {Helmet} from 'plugins/react-helmet'
import {_, useI18n} from 'plugins/i18n'
import SearchRentalsMobile from 'app/Components/SearchRentalsMobile'
import {useResponsive} from 'app/styles'
import RegionMapSection from 'app/Sections/regionMap/RegionMapSection'
import ReviewSummary from './ReviewSummary'
import HighlightedHousesTabs from './HighlightedHousesTabs'
import PopularDestinations from './PopularDestinations'
import Specials from './Specials'
import RecentBlogposts from './RecentBlogposts'
import WhyCap from './WhyCap'
import ReviewsSection from './ReviewsSections'
import ViewVacationHousesButton from './ViewVacationHousesButton'
import Newsletter from './NewsletterSignup'
import HelpSection from './HelpSection'

const MobileHomepage = ({reviews}: {reviews: House['reviews']}) => {
	return (
		<>
			<Template id="homeTopMobile" />
			<SearchRentalsMobile
				className="my-8"
				hasTitle
				hasEndNote
				disableCompact
				getTotal
			/>
			<ReviewSummary />
			<HighlightedHousesTabs />
			<PopularDestinations />
			<Specials />
			<RecentBlogposts />
			<div className="mx-6">
				<WhyCap />
			</div>
			<ReviewsSection viaGoogle />
			<ViewVacationHousesButton />
			<RegionMapSection />
			<Newsletter />
			<HelpSection />
		</>
	)
}

const HomePage = () => {
	const {lang} = useI18n()
	const {isMobile} = useResponsive('isMobile')
	return (
		<>
			<Helmet>
				<link rel="canonical" href={`https://${globalThis.hostname}/${lang}`} />
			</Helmet>
			<div className="flex w-full flex-col items-center justify-center">
				{isMobile ? (
					<MobileHomepage />
				) : (
					<div className="relative w-full">
						<Template id="homeTop" />
						<div className="absolute bottom-0 left-0 z-10 w-full translate-y-[35%] transform px-10">
							<SearchRentals row noLabel={isMobile} />
						</div>
					</div>
				)}
				{!isMobile && <Template id="homeBottom" />}
			</div>
		</>
	)
}
export default HomePage
