import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {BeigeGreenTheme, lightBeigeColor} from 'app/styles'
import {Box} from 'app/Components/Layout'
import {Button} from 'app/Components/Buttons'
import {HeadBox, SpacesWrap} from 'app/Components/Layout/Display'
import {NewOrPromo} from 'app/Components/House'
import {connect} from 'react-redux'
import {graphql} from 'plugins/apollo'
import {newOrPromoQuery} from 'app/_server/queries/houses'
import {withRouter} from 'plugins/react-router-deluxe'
import {setSearch as setSearchAction} from 'state/ducks/searchRentals-duck'
import {_, LangLink, useI18n} from 'plugins/i18n'
import {searchBasePath} from 'app/lib'
import {compose} from 'redux'
import SmallThumbnailGrid from 'app/Components/Layout/SmallThumbnailGrid'

const StyledSpacesWrap = styled(SpacesWrap)`
	background: ${p => p.withBeigeBg && lightBeigeColor};
`

const NewOrPromoList = ({houses, isNewOrPromo, isPhone, onShowMore}) => {
	const isNew = isNewOrPromo === 'isNew'
	const isPromo = isNewOrPromo === 'isPromo'
	const i18n = useI18n()

	const isMobileNew = isPhone && isNew
	const isMobilePromo = isPhone && isPromo

	const title = (isNew && _`newHouses`) || (isPromo && _`promoHouses`) || false

	const showMorePath = `${searchBasePath(i18n)}?${isNew ? 'isNew' : 'isPromo'}`
	const showMoreLabel = isNew ? _`showAllNew` : _`showAllPromo`

	return (
		<div className="flex w-full flex-col items-start items-stretch justify-center gap-8">
			<div className="flex w-full items-center justify-between">
				{title && <HeadBox underline>{title}</HeadBox>}
				{!isPhone && (
					<LangLink onClick={() => onShowMore()} to={showMorePath}>
						{showMoreLabel}
					</LangLink>
				)}
			</div>
			<SmallThumbnailGrid itemNum={isMobileNew ? 1 : isMobilePromo ? 2 : 4}>
				{houses.map(house => (
					<NewOrPromo key={house.id} {...{house, isMobileNew, isMobilePromo}} />
				))}
			</SmallThumbnailGrid>
			{isPhone && (
				<BeigeGreenTheme>
					<div>
						<Button
							fullWidth
							large
							light
							onClick={() => onShowMore(showMorePath)}
						>
							{showMoreLabel}
						</Button>
					</div>
				</BeigeGreenTheme>
			)}
		</div>
	)
}
NewOrPromoList.propTypes = {
	houses: PropTypes.arrayOf(PropTypes.object),
	isNewOrPromo: PropTypes.string,
	isPhone: PropTypes.bool,
	onShowMore: PropTypes.func,
}

class NewOrPromoSection extends Component {
	static propTypes = {
		data: PropTypes.object,
		history: PropTypes.object.isRequired,
		isPhone: PropTypes.bool,
		value: PropTypes.object,
		templateData: PropTypes.object,
		setSearch: PropTypes.func,
	}

	handleShowMore = path => {
		const {history, templateData = {}, setSearch} = this.props
		if (templateData.regionId) setSearch({region: templateData.regionId})
		if (path) history.push(path)
	}

	render() {
		const {
			data: {loading, newOrPromo: houses},
			isPhone,
			value: {newOrPromo: isNewOrPromo},
		} = this.props
		if (loading || !houses || !houses.length) return false
		return (
			<StyledSpacesWrap withBeigeBg={isNewOrPromo === 'isNew'} volumes={[8, 2]}>
				<NewOrPromoList
					{...{
						houses,
						isNewOrPromo,
						isPhone,
						onShowMore: this.handleShowMore,
					}}
				/>
			</StyledSpacesWrap>
		)
	}
}

export default compose(
	graphql(newOrPromoQuery, {
		options: ({value = {}, templateData = {}}) => {
			const {newOrPromo, limit} = value
			const {regionId} = templateData
			const variables = {limit: limit || 4}
			if (newOrPromo === 'isNew') variables.isNew = true
			else if (newOrPromo === 'isPromo') variables.isPromo = true
			if (regionId) variables.regionId = regionId
			return {variables}
		},
	}),
	connect(({responsive: r}) => ({isPhone: r.isPhone}), {
		setSearch: setSearchAction,
	}),
	withRouter
)(NewOrPromoSection)
