import React from 'react'
import {useResponsive} from 'app/styles'
import IconList from 'app/Components/House/HousedetailDescription/IconList'

const Features: React.FC<{
	readonly houseFeatures: {icon: string; value: string}[]
	readonly row?: boolean
	readonly small?: boolean
	readonly noBorder?: boolean
}> = ({houseFeatures, row, small, noBorder}) => {
	const {isPhone} = useResponsive('isPhone')

	return (
		<div
			className={`${
				isPhone ? 'mt-6 ml-2' : ''
			} ${noBorder ? '' : 'border-l border-gray-300 pl-2'} ${
				!row && !isPhone && !small ? 'mx-4' : ''
			}`}
		>
			<IconList
				items={houseFeatures.map(hf => ({icon: hf.icon, value: hf.value}))}
				inline={row}
				noWrap
				iconProps={{
					size: small ? '1.2em' : '1.4em',
					colorSet: 'capGrey',
				}}
			/>
		</div>
	)
}

export default Features
