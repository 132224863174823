import React from 'react'
import {styled, getSecondary, useResponsive} from 'app/styles'
import {Box, ThemedBox} from 'app/Components/Layout'
import {HtmlContent} from 'plugins/rich-text'
import {PlainText} from 'plugins/i18n'
import {HeadBox, SpacesWrap} from 'app/Components/Layout/Display'
import {ImageFromId as OrigImageFromId} from 'plugins/images'

// TODO proper styling for Cap project, using p.theme.titleCss etc

const ImageFromId = styled(OrigImageFromId)`
	width: ${p => p.width};
`
const Wrapper = styled(ThemedBox)`
	width: 100%;
`
const Content = styled(Box)`
	width: ${p => p.width || '100%'};
	@media print {
		display: inline-block;
	}
`
const TitleText = styled.span`
	display: inline;
	&:first-child {
		&:after {
			content: ' ';
		}
	}
`

const Description = styled(HtmlContent)`
	color: ${getSecondary};
`

const TextSection: React.FC<{
	readonly value?: {
		headingI18n?: any
		greetingI18n?: any
		textI18n?: any
		align?: string
		headBoxLevel1?: boolean
		underlinedHeadBox?: boolean
		imagePositioning?: 'left' | 'right'
		imageId?: string
		imgSize?: number
	}
}> = ({value = {}}) => {
	const {isMobile} = useResponsive('isMobile')
	const {
		headingI18n,
		greetingI18n,
		textI18n,
		align,
		headBoxLevel1,
		underlinedHeadBox,
		imagePositioning,
		imageId,
		imgSize = 50,
	} = value

	const imageOnLeft = imageId && imagePositioning === 'left'
	const imageOnRight = imageId && imagePositioning === 'right'
	const textSize = imageId
		? isMobile
			? '100%'
			: `${100 - imgSize}%`
		: undefined

	return (
		<SpacesWrap volumes={isMobile ? [8, 1] : [14, 4]}>
			<Wrapper gap="2em" spaceBetween column={isMobile}>
				{isMobile && imageId && (
					<ImageFromId width={textSize} value={imageId} />
				)}
				{!isMobile && imageOnLeft && (
					<ImageFromId width={`${imgSize}%`} value={imageId} />
				)}
				<Content width={textSize} column left top align={align}>
					<HeadBox underline={underlinedHeadBox} lvl={headBoxLevel1 ? 1 : 2}>
						{greetingI18n && greetingI18n.en && (
							<PlainText Component={TitleText} i18n={greetingI18n} />
						)}
						<PlainText Component={TitleText} i18n={headingI18n} />
					</HeadBox>
					<PlainText Component={Description} i18n={textI18n} />
				</Content>
				{!isMobile && imageOnRight && (
					<ImageFromId width={`${imgSize}%`} value={imageId} />
				)}
			</Wrapper>
		</SpacesWrap>
	)
}

export default TextSection
