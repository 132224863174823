import React from 'react'
import {styled, css} from 'app/styles'
import slateToReact from './slateToReact'

export const contentCss = css`
	width: 100%;
	// No overflowing our parent
	max-width: 100%;
	line-height: 1.5;

	// Slate creates empty paragraphs, render like in editor with lineheight
	p:empty:before {
		content: ' ';
		white-space: pre;
	}
`

// This is an approximation of the rules for Slate
const htmlCss = css`
	p,
	h1,
	h2,
	h3,
	ul,
	ol {
		margin: 0 0 1rem;
	}
	em {
		font-style: italic;
	}
	strong {
		font-weight: bold;
	}
	ol,
	ul {
		padding-left: 20px;
		li {
			margin-left: 1em;
		}
	}
	ol {
		list-style-type: decimal;
		li {
			margin-bottom: 0.5em;
		}
	}
	ul {
		list-style-type: disc;
		li {
			margin-bottom: 0.5em;
		}
	}
	a {
		text-decoration: none;
	}
	h1 {
		font-size: 1.4em;
	}
	h2 {
		font-size: 1.3em;
	}
	h3 {
		font-size: 1.2em;
	}
	hr {
		margin-top: 1.5em;
		margin-bottom: 1.5em;
		border: 0;
	}
	blockquote {
		padding: 10px 20px;
		margin: 0 0 20px;
		font-size: 17.5px;
	}
	pre {
		display: block;
		padding: 0.7em;
		margin: 0 0 0.7em;
		font-size: 0.9em;
		line-height: 1.42857143;
		word-break: break-all;
		word-wrap: break-word;
		border-radius: 4px;
		code {
			padding: 0;
			font-size: inherit;
			color: inherit;
			white-space: pre-wrap;
			background-color: transparent;
			border-radius: 0;
		}
	}
	/* Avoid empty space at bottom of content box */
	> *:last-child {
		margin-bottom: 0;
	}
`

export const Content = styled.div`
	${contentCss};
`
export const PlainHtml = styled.div`
	${contentCss};
	${htmlCss};
`

const HtmlContent: React.FC<{
	readonly value?: string | object
	readonly children?: React.ReactNode
	[key: string]: any
}> = ({value, children, ...rest}) => {
	// Using children is not encouraged but possible
	const content = value || children
	if (!content) return <Content {...rest} />

	if (
		(typeof content === 'object' && (content as any).document) ||
		(content as any).object === 'document'
	) {
		return <Content {...rest}>{slateToReact(content)}</Content>
	}

	if (typeof content !== 'string') {
		// E.g. Editor
		return <span {...rest}>{content}</span>
	}

	let contentHtml = content
	// Sanitize the HTML before it can break our page
	if (globalThis.document && content) {
		const d = globalThis.document.createElement('span')
		d.innerHTML = content
		contentHtml = d.innerHTML
	}

	return <PlainHtml {...rest} dangerouslySetInnerHTML={{__html: contentHtml}} />
}

export default HtmlContent
