import * as React from 'react'
import {useHouseSearchOptions} from 'app/_server/queries/houses'
import {urlForSearch} from 'app/lib'
import {useI18n} from 'plugins/i18n'
import {Box} from 'app/Components/Layout'
import Loading from 'app/Components/Loading'
import {useSelector} from 'plugins/redux/hooks'

const UrlGroup = ({urls}) => {
	const className = 'text-xs text-capGold'
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{Array.isArray(urls) ? (
				urls.map(({url, urlText}, i) => (
					<a className={className} key={i} href={url}>
						{urlText}
					</a>
				))
			) : (
				<a className={className} href={urls.url}>
					{urls.urlText}
				</a>
			)}
		</>
	)
}

const makeUrl = ({i18n, urlText, region, houseCapacity, houseType}) => {
	return {
		urlText,
		url: `${globalThis.baseUrl}${urlForSearch({
			i18n,
			filters: {region: [region], houseCapacity, houseType},
		})}`,
	}
}

const CapacityUrlGroup = ({regionIds, houseCapacity, houseType}) => {
	const i18n = useI18n()
	const capacities = [2, 4, 6, 8, 10, 12].filter(s => s !== houseCapacity)

	return (
		<>
			{capacities.map((cap, i) => (
				<UrlGroup
					key={i}
					urls={makeUrl({
						i18n,
						urlText: `${i18n.getText(
							'vakantiehuisVilla_Plural'
						)} ${i18n.getText('for')} ${cap}+ ${i18n
							.getText('Guests')
							.toLowerCase()}`,
						region: regionIds?.[0],
						houseCapacity: cap,
						houseType,
					})}
				/>
			))}
		</>
	)
}

const LocationUrlGroup = ({regionIds, houseCapacity, houseType}) => {
	const i18n = useI18n()
	const {data, loading} = useHouseSearchOptions()
	if (loading || !data) return <Loading />

	if (regionIds) {
		const selectedLocations = data.searchOptions.locations.filter(
			o =>
				regionIds?.includes(o.countryId) ||
				o.regionIds.some(item => regionIds?.includes(item))
		)

		return selectedLocations.map((o, i) => {
			const locationUrls = o.regionIds
				.filter(item => !regionIds.includes(item))
				.map(item =>
					makeUrl({
						i18n,
						urlText: `${i18n.getText(
							'vakantiehuisVilla_Plural'
						)} ${i18n.getText('in')} ${i18n.getText(item)}`,
						region: item,
						houseCapacity,
						houseType,
					})
				)
			return <UrlGroup key={i} urls={locationUrls} />
		})
	}

	return data.searchOptions.locations.map((o, i) => (
		<UrlGroup
			key={i}
			urls={makeUrl({
				i18n,
				urlText: `${i18n.getText('vakantiehuisVilla_Plural')} ${i18n.getText(
					'in'
				)} ${i18n.getText(o.countryId)}`,
				region: o.countryId,
				houseCapacity,
				houseType,
			})}
		/>
	))
}

const RelatedInternalLinks = () => {
	const houseCapacity = useSelector(state => state.searchRentals.houseCapacity)
	const houseType = useSelector(state => state.searchRentals.houseType)
	const regionIds = useSelector(state => state.searchRentals.region)
	return (
		<Box gap wrap>
			<CapacityUrlGroup
				regionIds={regionIds}
				houseCapacity={houseCapacity}
				houseType={houseType}
			/>
			<LocationUrlGroup
				regionIds={regionIds}
				houseCapacity={houseCapacity}
				houseType={houseType}
			/>
		</Box>
	)
}

export default RelatedInternalLinks
