import PropTypes from 'prop-types'
import React from 'react'
import {styled, getFg, GreenDarkTheme} from 'app/styles'
import {Box} from 'app/Components/Layout'
import {_, PlainText} from 'plugins/i18n'
import {connect} from 'react-redux'
import {HtmlContent} from 'plugins/rich-text'
import {mapImages} from 'app/Pages/HouseOrDomain/MiniMap'

const Title = styled(Box)`
	font-size: 1.2em;
	margin-bottom: 2em;
	position: relative;
	&:after {
		position: absolute;
		content: '';
		top: 2em;
		width: 3em;
		height: 1px;
		background-color: ${getFg};
	}
`

const MinMapImg = styled.img``

const MiniMap = ({regionId}) => {
	if (!regionId || regionId === 'r$unknown') return false

	try {
		const miniMapPath = mapImages[`assets/images/maps/${regionId}.png`]
		return <MinMapImg src={miniMapPath} alt="regionMap" />
	} catch {
		return false
	}
}
MiniMap.propTypes = {
	regionId: PropTypes.string,
}

const RegionDescription = ({isMobile, value, className}) => {
	const padding = isMobile ? '1em' : '5em'
	const houseTypeDesc =
		value.houseType &&
		value.houseTypeDescs &&
		value.houseTypeDescs.find(
			houseTypeDesc => houseTypeDesc.houseType === value.houseType
		)
	return (
		<GreenDarkTheme>
			<Box
				columnReverse={isMobile}
				padding={padding}
				gap={padding}
				className={className}
			>
				<div className="flex w-full flex-col items-start justify-center">
					{houseTypeDesc ? (
						<>
							<Title left>{_`aboutRegion`}</Title>
							<HtmlContent value={houseTypeDesc.text} />
						</>
					) : (
						value.descriptionI18n && (
							<>
								<Title left>
									{value.countryTitleI18n ? (
										<PlainText asHtml i18n={value.countryTitleI18n} />
									) : (
										_`aboutRegion`
									)}
								</Title>
								<PlainText asHtml i18n={value.descriptionI18n} />
							</>
						)
					)}
				</div>
				<MiniMap regionId={value.regionId} />
			</Box>
		</GreenDarkTheme>
	)
}

RegionDescription.propTypes = {
	value: PropTypes.object,
	isMobile: PropTypes.bool,
	className: PropTypes.string,
}

export default connect(({responsive}, props) => ({
	isMobile: props.isMobile ?? responsive.isMobile,
}))(RegionDescription)
