import React from 'react'
import {useQuery} from 'plugins/apollo'
import {useSelector} from 'plugins/redux/hooks'
import {housesListQuery} from 'app/_server/queries/houses'
import Loading from 'app/Components/Loading'
import {useHistory} from 'plugins/react-router-deluxe'
import {HeadBox, SpacesWrap} from 'app/Components/Layout'
import {useResponsive} from 'app/styles'
import {_} from 'plugins/i18n'
import {Region as RegionHouse} from 'app/Components/House'
import {orderByIdsArray} from 'app/lib'
import SmallThumbnailGrid from 'app/Components/Layout/SmallThumbnailGrid'

export const getFavouriteHousesIds = (favoritesAndShare: {
	[key: string]: boolean
}): string[] =>
	Object.entries(favoritesAndShare)
		.filter(i => i[1])
		.map(i => i[0])

const FavouritesPage: React.FC = () => {
	const {isPhone} = useResponsive('isPhone')
	const history = useHistory()
	const favoritesAndShare = useSelector(state => state.favoritesAndShare)
	const favoriteHousesIds: string[] = getFavouriteHousesIds(favoritesAndShare)
	const visitedHousesIds: string[] = useSelector(state => state.visitedHouses)

	if (!favoriteHousesIds.length && !visitedHousesIds.length) {
		history.push('/xx/')
	}

	const {loading: loadingFavoriteHouses, data: favoriteHousesData} = useQuery(
		housesListQuery,
		{
			variables: {
				houseIds: favoriteHousesIds,
				limit: 12,
			},
		}
	)

	const {loading: loadingVisitedHouses, data: visitedHousesData} = useQuery(
		housesListQuery,
		{
			variables: {
				houseIds: visitedHousesIds,
				limit: 24,
			},
		}
	)

	if (loadingFavoriteHouses || !favoriteHousesData) return <Loading />
	if (loadingVisitedHouses || !visitedHousesData) return <Loading />

	return (
		<SpacesWrap fullWidth vertical volumes={[22, 2]}>
			<div className="flex w-full flex-col items-center justify-center gap-24">
				<div className="flex w-full flex-col items-center justify-center">
					<HeadBox underline>{_`favoriteHouses`}</HeadBox>
					<SmallThumbnailGrid itemNum={isPhone ? 2 : 4}>
						{favoriteHousesData.houses.items.map((house: FullHouse) => (
							<RegionHouse key={house.id} house={house} />
						))}
					</SmallThumbnailGrid>
				</div>
				<div className="flex w-full flex-col items-center justify-center">
					<HeadBox underline>{_`recentlyVisitedHouses`}</HeadBox>
					<SmallThumbnailGrid itemNum={isPhone ? 2 : 4}>
						{orderByIdsArray(
							visitedHousesIds,
							visitedHousesData.houses.items as FullHouse[]
						).map((house: FullHouse) => (
							<RegionHouse key={house.id} house={house} />
						))}
					</SmallThumbnailGrid>
				</div>
			</div>
		</SpacesWrap>
	)
}

export default FavouritesPage
