import React from 'react'
import {Img} from 'app/Components/Layout/Display'
import {fbTrack} from 'plugins/trackers'
import {FavIcon} from 'app/Components/Icon'

const RentalImage: React.FC<{
	readonly alt?: string
	readonly favorites?: {[key: string]: boolean}
	readonly id: string
	readonly image: {src: string; alt?: string}
	readonly noFav?: boolean
	readonly ratio?: string | number
	readonly tags: string[]
	readonly toggleFavorites: (id: string) => void
}> = ({alt, favorites, id, image, noFav, ratio, tags, toggleFavorites}) => {
	return (
		<div className="relative w-full items-center justify-center">
			<Img
				cut
				cutSize={2}
				ratio={ratio || 16 / 9}
				value={{...image, alt, title: tags.join(' ')}}
			/>
			{!noFav && (
				<div className="absolute top-4 right-4 h-8 w-8">
					<FavIcon
						height="2rem"
						width="2rem"
						active={favorites && Boolean(favorites[id])}
						onClick={e => {
							e.preventDefault()
							if (!favorites || !favorites[id]) {
								fbTrack('AddToWishlist')
							}
							toggleFavorites(id)
						}}
					/>
				</div>
			)}
		</div>
	)
}

export default RentalImage
