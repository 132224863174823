import React from 'react'
import {
	styled,
	getSecondary,
	capGreenColor,
	useResponsive,
	lightBeigeColor,
	beigeColor,
} from 'app/styles'
import {Box, ThemedBox} from 'app/Components/Layout'
import {HtmlContent} from 'plugins/rich-text'
import {PlainText} from 'plugins/i18n'
import {HeadBox, SpacesWrap} from 'app/Components/Layout/Display'
import {ImageFromId} from 'plugins/images'

const Wrapper = styled(ThemedBox)`
	width: 100%;
	justify-content: center;
`

const Content = styled.div`
	max-width: 70rem;
	text-align: center;

	display: grid;
	grid-template-columns: ${p => (p.isMobile ? '1fr' : 'repeat(3, 1fr)')};
	align-items: stretch;
	gap: 6rem;
`

const BenefitBoxWrap = styled(Box)`
	padding: 2.5rem;
	background-color: ${lightBeigeColor};
	position: relative;
	justify-content: center;

	&::after {
		position: absolute;
		content: '';
		right: 0;
		bottom: 0;
		height: 5rem;
		width: 5rem;
		background-color: white;
		transform: translate(3.5rem, 3.5rem) rotate(45deg);
	}
`

const BenefitBox = styled(Box)`
	height: 100%;

	flex-direction: column;
	justify-content: start;
	gap: 1rem;

	& > * {
		margin: 0;
	}
`

const IconContainer = styled(Box)`
	width: 7rem;
	height: 7rem;
	background-color: white;
	border-radius: 555rem;
	border: 2px solid ${beigeColor};
	padding: 2rem;

	img {
		filter: invert(26%) sepia(29%) saturate(878%) hue-rotate(39deg)
			brightness(97%) contrast(87%);
	}
`

const HeadingText = styled.span`
	display: inline;
	font-weight: 500;
	font-size: 1.3rem;
	color: ${capGreenColor};
	&:first-child {
		&:after {
			content: ' ';
		}
	}
`

const Description = styled(HtmlContent)`
	color: ${getSecondary};
`

const RentYourHouseBenefitsSection: React.FC<{
	readonly value: {
		benefits?: Array<{
			imageId: string
			headingI18n: any
			textI18n: any
		}>
	}
}> = ({value}) => {
	const {benefits} = value
	const {isMobile} = useResponsive('isMobile')

	return (
		<SpacesWrap volumes={isMobile ? [8, 1] : [14, 4]}>
			<Wrapper spaceBetween column={isMobile}>
				<Content column top {...{isMobile}}>
					{benefits?.length
						? benefits.map(({imageId, headingI18n, textI18n}) => (
								<BenefitBoxWrap key={imageId}>
									<BenefitBox gap>
										<IconContainer width="4rem" height="4rem">
											<ImageFromId ratio={1} value={imageId} />
										</IconContainer>
										<HeadBox lvl={3}>
											<PlainText Component={HeadingText} i18n={headingI18n} />
										</HeadBox>
										<PlainText Component={Description} i18n={textI18n} />
									</BenefitBox>
								</BenefitBoxWrap>
							))
						: null}
				</Content>
			</Wrapper>
		</SpacesWrap>
	)
}

export default RentYourHouseBenefitsSection
