import React from 'react'
import {Button} from 'app/Components/Buttons'
import {useLogout, useMe} from 'plugins/users/queries'
import {_} from 'plugins/i18n'
import {LogoutIcon} from 'app/Components/Icon'
import {useSelector} from 'plugins/redux/hooks'

const DefaultText: React.FC<{readonly user: {readonly fullName: string}}> = ({
	user,
}) => (
	<span className="flex flex-col items-center">
		<LogoutIcon size="1.3rem" />
		<br />
		{user.fullName}
	</span>
)

const LogoutButton: React.FC<{
	readonly LogoutText?: React.FC<{user: {readonly fullName: string}}>
	readonly children?: React.ReactNode
	readonly className?: string
}> = ({LogoutText = DefaultText, children, className}) => {
	const {data} = useMe()
	const {logout} = useLogout()
	const isServer = useSelector(state => state.responsive.isServer)

	if (isServer || !data?.user) return null

	return (
		<Button compact onClick={() => logout()} className={className}>
			{children ?? <LogoutText user={data.user} />}
		</Button>
	)
}

export default LogoutButton
