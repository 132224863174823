import PropTypes from 'prop-types'
import React from 'react'
import {CalendarInput} from 'app/Components/Inputs'
import {Align} from 'app/Components/Layout/Display'
import {moment} from 'app/lib'

export const Availabilities = ({item = {}}) => {
	const {blockedRanges} = item
	const dateStates =
		blockedRanges &&
		blockedRanges.map(({beginDate: start, endDate: end}) => {
			return {start, end, type: 'BLOCK'}
		})
	const minimumDate = moment()
	const maximumDate = moment(minimumDate).add(500, 'days')
	return (
		<Align position="center">
			<CalendarInput
				{...{
					dateStates,
					minimumDate,
					maximumDate,
					selectionType: 'single',
					selectedLabel: null,
					numberOfCalendars: 2,
					showLegend: ['BLOCK'],
				}}
				noHover
			/>
		</Align>
	)
}
Availabilities.propTypes = {
	item: PropTypes.object,
}
