import React from 'react'
import {useI18n} from 'plugins/i18n'
import {TextSpan} from '../Layout/Text'

/**
 * @param {{
 * 	value: number
 * 	currency?: string
 * }} args
 * @returns {JSX.Element}
 */
export const MoneyValue = ({value, currency = 'EUR', ...rest}) => {
	const {lang} = useI18n()

	const formatted = new Intl.NumberFormat(lang, {
		style: 'currency',
		currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(value || 0)

	return (
		<TextSpan noWrap {...rest}>
			{formatted}
		</TextSpan>
	)
}
