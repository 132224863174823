import {CalendarIcon, CapacityIcon, LocationIcon} from 'app/Components/Icon'
import {toStringRange} from 'app/lib'
import {_, useI18n} from 'plugins/i18n'
import React, {useMemo} from 'react'
import {useSelector} from 'plugins/redux/hooks'

const CompactSearchView: React.FC<{readonly onSetCompact: () => void}> = ({
	onSetCompact,
}) => {
	const {getText} = useI18n()
	const region = useSelector(state => state.searchRentals.region)
	const period = useSelector(state => state.searchRentals.period)
	const capacity = useSelector(state => state.searchRentals.houseCapacity)
	const regionText = useMemo(() => {
		return Array.isArray(region)
			? region.map(item => getText(item)).join(', ')
			: _(region)
	}, [region, _])

	return (
		<div className="mx-auto w-full cursor-pointer" onClick={onSetCompact}>
			<div className="flex items-center justify-between">
				<div className="flex items-end space-x-3">
					<LocationIcon className="mb-1" width="1rem" height="1.25rem" />
					<div>
						<p className="text-[10px] leading-3 text-gray-600">
							{_('destination')}
						</p>
						<p className="min-h-6">{regionText}</p>
					</div>
				</div>
				<button
					type="button"
					className="self-start text-[10px] text-gray-600 underline"
					onClick={onSetCompact}
				>
					{_('change')}
				</button>
			</div>
			<div className="my-2 border-t border-solid border-gray-300" />
			<div className="flex items-center justify-between">
				<div className="flex w-10/12 items-end space-x-3">
					<CalendarIcon className="mb-1 shrink-0" />
					<div>
						<p className="text-[10px] leading-3 text-gray-600">
							{_('period2')}
						</p>
						<p className="min-h-6">{toStringRange(period, 'DD MMM YY')}</p>
					</div>
				</div>
				<div className="mx-4 h-8 border-l border-solid border-gray-300" />
				<div className="flex items-end space-x-3">
					<CapacityIcon className="mb-1" width="1.5rem" />
					<div>
						<p className="text-[10px] leading-3 text-gray-600">
							{_('persons')}
						</p>
						<p className="min-h-6">{capacity}+</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CompactSearchView
