import gql from 'graphql-tag'
import {removeTypenameFromObj, useMutation} from 'plugins/apollo'
import {makeQueryHook} from './helpers'

export const houseDomainFields = `
	id
	address {
		suite
		unit
		street
		pcode
		city
		region
		countryId
		geo
	}
	agentId
	title
	description
	distances {
		km
		min
		name
		timeId
		typeId
	}
	slogan
	images {
		show
		h
		url
		w
		blurhash
		tags
		upload { id }
	}
	keyInfo {
		info
		phone
		address {
			suite
			unit
			street
			pcode
			city
			region
			countryId
		}
	}
	mainImage {
		h
		url
		w
		blurhash
		tags
		upload { id }
	}
	icons
	regionId
`

export const houseDomainInputFields = `
	id
	address {
		suite
		unit
		street
		pcode
		city
		region
		countryId
		geo
	}
	agentId
	titleI18n
	descriptionI18n
	distances {
		km
		min
		name
		timeId
		typeId
	}
	sloganI18n
	images {
		url w h blurhash
		upload {
			id
			ts
			filename
			meta
		}
		show isMain
		alt title note
		tags
	}
	icons
	keyInfo {
		info
		phone
		address {
			suite
			unit
			street
			pcode
			city
			region
			countryId
		}
	}
	regionId
`

// Queries
export const houseDomainQuery = gql`
  query houseDomain($id: ID!) {
  	houseDomain(id: $id) {
  		${houseDomainFields}
  	}
  }
`

export const houseDomainsQuery = gql`
  query houseDomains(
  	$limit: Int
  	$cursor: ShortString
  	$filter: ShortString
  	$sort: JSON
  ) {
  	houseDomains(
  		limit: $limit
  		cursor: $cursor
  		filter: $filter
  		sort: $sort
  	) {
  		items {
  			${houseDomainFields}
  		}
  		cursor
  		total
  	}
  }
`

export const fullHouseDomainQuery = gql`
  query houseDomain($id: ID!) {
  	houseDomain(id: $id) {
  		${houseDomainFields}
  		titleI18n
  		descriptionI18n
  		sloganI18n
  	}
  }
`

export const fullHouseDomainsQuery = gql`
  query houseDomains(
  	$limit: Int
  	$cursor: ShortString
  	$filter: ShortString
  	$sort: JSON
  ) {
  	houseDomains(
  		limit: $limit
  		cursor: $cursor
  		filter: $filter
  		sort: $sort
  	) {
  		items {
  			${houseDomainFields}
  			titleI18n
  			descriptionI18n
  			sloganI18n
  		}
  		cursor
  		total
  	}
  }
`

export const useHouseDomainQuery = makeQueryHook(houseDomainQuery)

const SAVE_HOUSE_DOMAIN_MUTATION = gql`
  mutation saveHouseDomain($houseDomain: HouseDomainInput!) {
    saveHouseDomain(houseDomain: $houseDomain) {
      ${houseDomainInputFields}
    }
  }
`

export const useSaveHouseDomainMutation = () => {
	const [saveHouseDomainMutation] = useMutation(SAVE_HOUSE_DOMAIN_MUTATION, {
		refetchQueries: ['houseDomains'],
	})

	const saveHouseDomain = (houseDomain: any) => {
		return saveHouseDomainMutation({
			variables: {
				houseDomain: removeTypenameFromObj(houseDomain, [
					'mainImage',
					'title',
					'description',
					'slogan',
					'blurhash',
				]),
			},
		})
	}

	return {
		saveHouseDomain,
	}
}

export const removeHouseDomainMutation = [
	gql`
		mutation removeHouseDomain($id: ID!) {
			removeHouseDomain(id: $id)
		}
	`,
	{
		props: ({mutate}) => ({
			removeHouseDomain: id =>
				mutate({
					variables: {id},
					refetchQueries: ['houseDomains'],
				}),
		}),
	},
]
