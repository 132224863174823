import React, {ReactNode} from 'react'
import {Box, HeadBox} from 'app/Components/Layout'
import {LangLink, _} from 'plugins/i18n'
import {Region as RegionHouse} from 'app/Components/House'
import {BeigeGreenTheme, styled, useResponsive} from 'app/styles'
import {Button} from 'app/Components/Buttons'
import SmallThumbnailGrid from '../Layout/SmallThumbnailGrid'

const skeletonHouses = [
	{
		id: 'skeleton-1',
		capacity: 6,
		bedroomCount: 4,
		bathroomCount: 4,
		name: 'Villa Pertuis',
		regionId: 'r$coteDazur',
		placeName: 'Pertuis',
		mainImage: {
			blurhash:
				'oRJuAV=as*xst7s*05I;adoJkDjDPE-pV@M|f9RiO^o3t6jYWBfhr{9ukCs:s,ofEgbct8j@RjoM',
		},
		urlI18n: {},
	},
	{
		id: 'skeleton-2',
		capacity: 9,
		bedroomCount: 7,
		bathroomCount: 7,
		name: 'Torre Canera',
		regionId: 'r$costaBrava',
		placeName: 'Pals',
		mainImage: {
			blurhash:
				'oZB#9ttlx^tSoys,GJRjRnbJRPV?rznOt8ofoIWB.AkqXTozWUoePqR*o#RjV?aeoOwvWFs-nNW=',
		},
		urlI18n: {},
	},
	{
		id: 'skeleton-3',
		capacity: 3,
		bedroomCount: 2,
		bathroomCount: 3,
		name: 'Podere Elvella (Cinghiale)',
		regionId: 'r$toscane',
		placeName: 'Trevinano',
		mainImage: {
			blurhash:
				'oKEfsCIX~pt8%Nxu?ubC-=xtxuxvN3xu%Mt7tQjY9F%NRjRkM|R*D$-;M{a#M|M{E1WCITWUs.M{',
		},
		urlI18n: {},
	},
	{
		id: 'skeleton-4',
		capacity: 9,
		bedroomCount: 7,
		bathroomCount: 7,
		name: 'Villa Oliveta',
		regionId: 'r$coteDazur',
		placeName: 'Pietrasanta',
		mainImage: {
			blurhash:
				'oKHod%au$_g4-=x^97rU%0WGtRNLpe9txZR-t8WEiaofR+RjWARj9YjGxaIVt6jY~qE3ozRjWBoy',
		},
		urlI18n: {},
	},
]

const SmallThumbnailGridWithBlur = styled(SmallThumbnailGrid)`
	filter: ${({loading}) => (loading ? 'blur(6px)' : 'none')};
`

export const HousesList = (args: {
	houses: FullHouse[]
	title: ReactNode
	showMorePath?: string
	showMoreLabel: ReactNode
	limit?: number
	loading?: boolean
}) => {
	const {isPhone} = useResponsive('isPhone')
	const {houses, title, showMorePath, showMoreLabel, loading, limit = 4} = args

	const housesToShow = [...(loading ? skeletonHouses : houses)].slice(0, limit)

	if (!housesToShow.length) return null

	return (
		<div className="flex w-full flex-col items-start items-stretch justify-center gap-8">
			<div className="flex w-full items-center justify-between">
				<HeadBox underline>{title}</HeadBox>
				{!isPhone && showMorePath && (
					<LangLink to={showMorePath}>{showMoreLabel}</LangLink>
				)}
			</div>
			<SmallThumbnailGridWithBlur loading={loading} itemNum={isPhone ? 2 : 4}>
				{housesToShow.map(house => (
					<RegionHouse key={house.id} house={house} />
				))}
			</SmallThumbnailGridWithBlur>
			{isPhone && showMorePath && (
				<BeigeGreenTheme>
					<div>
						<Button fullWidth large light to={showMorePath}>
							{showMoreLabel}
						</Button>
					</div>
				</BeigeGreenTheme>
			)}
		</div>
	)
}
