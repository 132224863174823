import {COMPANY_INFO} from 'app/constants'
import {useI18n} from 'plugins/i18n'
import React from 'react'
import teamImg from 'assets/images/team.jpg'

const HelpSection = () => {
	const {getText} = useI18n()
	return (
		<div className="flex w-full flex-col bg-[#BBB]/30 p-6">
			<h2 className="text-onBackground mb-1 text-2xl leading-8">
				{getText('helpTitle')}
			</h2>
			<h3 className="text-onTertiary mb-4 text-base leading-6">
				{getText('helpSubtitle')}
			</h3>
			<div className="mb-4 flex flex-row space-x-2">
				<button
					className="bg-primary text-onPrimary mt-3 flex-1 rounded-md p-2"
					type="button"
				>
					{COMPANY_INFO.phoneLabel}
				</button>
				<button
					className="bg-primary text-onPrimary mt-3 flex-1 rounded-md p-2"
					type="button"
				>
					{COMPANY_INFO.email}
				</button>
			</div>
			<img src={teamImg} />
		</div>
	)
}
export default HelpSection
