import React from 'react'
import OrigSlickSlider from 'app/Components/Carousels/SlickSlider'
import styled from 'styled-components'
import {BeigeGreenTheme, useResponsive} from 'app/styles'
import {Featured} from 'app/Components/House'
import {useFeaturedHousesQuery} from 'app/_server/queries/houses'
import Icon, {glyphs} from 'app/Components/Icon'
import {HeadBox, SpacesWrap} from 'app/Components/Layout/Display'
import {_} from 'plugins/i18n'

const SlickSlider = styled(OrigSlickSlider)`
	margin-right: -2.5em;
	.slick-slide:not(.slick-active) {
		transform: ${p => (p.isPhone ? 'translateX(-10%)' : 'translateX(-15%)')};
	}
`

// slideCount,currenSlide are from slick-slider
const Arrow = styled(({left, slideCount, currentSlide, ...p}) => (
	<Icon {...p} />
))`
	position: absolute;
	cursor: pointer;
	z-index: 1;
	height: 50px;
	width: 50px;
	top: calc(50% - 25px);
	${p => (p.left ? 'left: 0' : 'right: 0')};
`

const NextArrow = ({className, style, ...props}) => (
	<Arrow {...props} glyph={glyphs.arrowRight} />
)

const PrevArrow = ({className, style, ...props}) => (
	<Arrow {...props} left glyph={glyphs.arrowLeft} />
)

const settings = {
	dots: 0,
	slidesToShow: 1,
	slidesToScroll: 1,
	draggable: 1,
	lazyLoad: 0,
	infinite: 1,
	autoplay: 0,
	autoplaySpeed: 5000,
	pauseOnHover: 1,
	adaptiveHeight: false,
	nextArrow: <NextArrow />,
	prevArrow: <PrevArrow />,
}

const FeaturedHousesSection = () => {
	const {data, loading} = useFeaturedHousesQuery()
	const {isPhone} = useResponsive('isPhone')

	if (loading || !data?.featuredHouses || !data.featuredHouses.length) {
		return null
	}

	return (
		<div className="min-h-[44rem] w-full">
			<BeigeGreenTheme>
				<SpacesWrap volumes={[8, 2]}>
					<HeadBox underline>{_`InDeKijker`}</HeadBox>
					<SlickSlider {...{...settings, arrows: !isPhone}} isPhone={isPhone}>
						{data.featuredHouses.map(house => (
							<div className="w-[85%]!" key={house.id}>
								<Featured house={house} />
							</div>
						))}
					</SlickSlider>
				</SpacesWrap>
			</BeigeGreenTheme>
		</div>
	)
}

export default FeaturedHousesSection
