import PropTypes from 'prop-types'
import React, {FC, ChangeEvent} from 'react'
import InputLayout from './InputLayout'
import {Label, LabelText} from './Components/InputComponents'
import {RadioIcon} from 'app/Components/Icon'
import {styled, getSecondary} from 'app/styles'

const HiddenRBInput = styled.input.attrs({type: 'radio'})`
	opacity: 0;
	position: absolute;
`
const Radio = styled.div`
	display: flex;
	width: ${p => (p.theme.inputHeight / 5) * 3}rem;
	height: ${p => (p.theme.inputHeight / 5) * 3}rem;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border: thin solid ${getSecondary};
`

const RadioWrap = styled.div`
	display: inline-flex;
	padding-right: 0.5rem;
	height: ${p => p.theme.inputHeight}rem;
	align-items: center;
	position: relative; // Our real input likes to run away
	${HiddenRBInput}:focus + ${Radio} {
		// Add focus shadow to our checkbox
		${p => p.theme.focusCss}
	}
	// Hide the checkmark when input not selected
	${HiddenRBInput}:not(:checked) + ${Radio} > svg {
		fill: transparent;
	}
`

const RadioBtn = ({label, value, checked, onChange, name}) => (
	<Label inline together right>
		<RadioWrap>
			<HiddenRBInput
				name={name}
				checked={checked}
				value={value}
				onChange={onChange}
			/>
			<Radio>{checked ? <RadioIcon size="100%" primary /> : null}</Radio>
		</RadioWrap>
		<LabelText right together fullWidth>
			{label}
		</LabelText>
	</Label>
)
RadioBtn.propTypes = {
	checked: PropTypes.bool,
	value: PropTypes.number,
	onChange: PropTypes.func,
	label: PropTypes.node,
	name: PropTypes.string,
}

interface RadioInputProps {
	readonly error?: React.ReactNode
	readonly label?: React.ReactNode
	readonly value?: number | string | boolean
	readonly onChange?: (value: number | string | boolean) => void
	readonly options: {
		label: string
		value: number | string | boolean
	}[]
	readonly fullWidth?: boolean
	readonly labelOnTop?: boolean
	readonly noLabel?: boolean
	readonly name?: string
	readonly column?: boolean
}

const RadioInput: FC<RadioInputProps> = ({
	error,
	label,
	value,
	onChange,
	options,
	fullWidth,
	labelOnTop = false,
	noLabel = false,
	name = 'radio',
	column = false,
}) => {
	const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
		if (onChange) onChange(options[Number(ev.target.value)].value)
	}

	return (
		<InputLayout
			{...{label, labelOnTop, noLabel, error, fullWidth, htmlLabel: false}}
		>
			<div className={`flex w-full ${column ? 'flex-col' : ''}`}>
				{options.map((o, i) => (
					<RadioBtn
						key={i}
						name={name}
						label={o.label}
						value={i}
						checked={o.value === value}
						onChange={handleChange}
					/>
				))}
			</div>
		</InputLayout>
	)
}

export default RadioInput
