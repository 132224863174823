import * as React from 'react'
import SlickSlider from 'app/Components/Carousels/SlickSlider'
import {Image} from 'plugins/images'
import {LangLink, useI18n} from 'plugins/i18n'
import {urlForPost} from 'app/lib'

const settings = {
	dots: 0,
	slidesToShow: 1,
	slidesToScroll: 1,
	draggable: 1,
	lazyLoad: 0,
	infinite: 1,
	autoplay: 0,
	autoplaySpeed: 5000,
	pauseOnHover: 1,
	adaptiveHeight: false,
	arrows: false,
	variableWidth: true,
}

const BlogPostsSlider = ({blogposts}) => {
	const i18n = useI18n()
	return (
		<div className="ml-6 w-full">
			<SlickSlider {...{...settings}}>
				{blogposts.map(b => (
					<div
						className="mr-4 flex max-w-[301px] flex-col overflow-hidden rounded-lg bg-white"
						key={b.id}
					>
						<Image className="w-[301px]" value={b.mainImage} ratio={16 / 9} />
						<div className="mx-4 mt-4 block min-h-[84px] text-center text-xl leading-7 font-bold">
							{b.title}
						</div>
						<LangLink
							to={urlForPost(i18n, b, '')}
							className="mt-4 flex flex-col self-center pb-4"
						>
							<button
								type="button"
								className="bg-primary text-onPrimary w-5/6 self-center rounded-md p-2"
							>
								{i18n.getText('readMore')}
							</button>
						</LangLink>
					</div>
				))}
			</SlickSlider>
		</div>
	)
}

export default BlogPostsSlider
