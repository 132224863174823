import * as React from 'react'
import SlickSlider from 'app/Components/Carousels/SlickSlider'
import {Image} from 'plugins/images'
import {_, useI18n} from 'plugins/i18n'
import {useTranslationsQuery} from 'app/_server/queries/translations'
import Loading from '../Loading'
import {ImageProps} from 'plugins/images/react/Image'
import {Link} from 'plugins/react-router-deluxe'
import {urlForRegion} from 'app/lib'
import {region} from 'app/Sections'

const settings = {
	dots: 0,
	slidesToShow: 1,
	slidesToScroll: 1,
	draggable: 1,
	lazyLoad: 0,
	infinite: 1,
	autoplay: 0,
	autoplaySpeed: 5000,
	pauseOnHover: 1,
	adaptiveHeight: false,
	arrows: false,
	variableWidth: true,
}

const PopularDestinationsSlider = () => {
	const {lang} = useI18n()
	const {data: locationsData, loading} = useTranslationsQuery({
		section: 'REGION',
	})

	if (!locationsData && loading) return <Loading />

	const destinations: {
		id: I18nKey
		regionId: I18nKey
		countryId: I18nKey
		imageValue: ImageProps['value']
	}[] = locationsData.translations
		?.filter(t => t.meta?.isPopular)
		.map(({id, text, meta}) => ({
			id,
			regionId: id,
			countryId: meta?.countryId,
			text,
			imageValue: {
				id: meta?.imageId,
				alt: text,
			},
		}))
	return (
		<div className="w-full">
			<SlickSlider {...{...settings}}>
				{destinations.map(({regionId, countryId, imageValue}) => (
					<div
						className="mr-4 flex max-w-[301px] min-w-[301px] flex-col overflow-hidden rounded-lg bg-[#E7E7E7]"
						key={regionId}
					>
						<Image className="w-[301px]" value={imageValue} ratio={16 / 9} />
						<div className="text-onBackground mx-4 mt-4 text-xl leading-7 font-bold">
							{_(regionId)}
						</div>
						<div className="text-onTertiary mx-4 mt-1 text-sm leading-5">
							{_(countryId)}
						</div>
						<div className="mt-4 flex flex-col self-center pb-4">
							<Link
								to={urlForRegion({regionId, lang})}
								type="button"
								className="bg-primary text-onPrimary w-5/6 cursor-pointer self-center rounded-md p-2 text-center"
							>
								{_(`readMore`)}
							</Link>
						</div>
					</div>
				))}
			</SlickSlider>
		</div>
	)
}

export default PopularDestinationsSlider
