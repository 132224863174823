import React, {useEffect} from 'react'
import Loading from 'app/Components/Loading'
import {ThemeProvider} from 'styled-components'
import {HTTP404} from 'plugins/react-router-deluxe'
import {SpacesWrap, HeadBox} from 'app/Components/Layout/Display'
import {useI18n, _} from 'plugins/i18n'
import gql from 'graphql-tag'
import {useQuery} from 'plugins/apollo'
import {topLabelTheme, useResponsive} from 'app/styles'
import {Box} from 'app/Components/Layout'
import {Template} from 'plugins/templates'
import {makeUrl} from 'plugins/images'
import {useHistory} from 'react-router-dom'
import {urlForPost} from 'app/lib'
import {Helmet} from 'plugins/react-helmet'
import {useSaveBlogPostTemplate} from 'app/_server/queries/blogPosts'

const PostBase = ({blogPost}) => {
	const {loading, lang} = useI18n()
	const history = useHistory()
	const {isMobile} = useResponsive('isMobile')
	const {saveBlogPostTemplate} = useSaveBlogPostTemplate()
	const {title, template} = blogPost

	useEffect(() => {
		if (loading) return
		history.replace(urlForPost({loading, lang}, blogPost, ''))
	}, [lang, blogPost, loading, history])

	return (
		<>
			<Helmet>
				<link
					rel="canonical"
					href={`https://${globalThis.hostname}${urlForPost(
						{loading, lang},
						blogPost,
						''
					)}`}
				/>
			</Helmet>
			<SpacesWrap
				volumes={isMobile ? [14, 1] : [14, 4]}
				itemScope
				itemType="http://schema.org/Article"
			>
				<ThemeProvider theme={topLabelTheme}>
					<Box left column gap="1em">
						<HeadBox itemProp="name" underline lvl={1}>
							{title}
						</HeadBox>
						<Template
							onChange={val =>
								saveBlogPostTemplate({id: blogPost.id, template: val})
							}
							value={template}
						/>
					</Box>
				</ThemeProvider>
			</SpacesWrap>
		</>
	)
}

const BLOG_POST_QUERY = gql`
	query blogPostQuery($titleSlug: ShortString!) {
		blogPost(titleSlug: $titleSlug) {
			id
			titleI18n
			title(fallback: false)
			text
			summary
			isOnline
			regionIds
			mainImage {
				w
				h
				blurhash
				upload {
					id
				}
			}
			template
		}
	}
`

const Post = ({
	match: {
		params: {titleSlug},
	},
}: {
	readonly match: {
		params: {
			titleSlug: string
		}
	}
}) => {
	const {data, loading} = useQuery(BLOG_POST_QUERY, {
		variables: {titleSlug},
	})

	if (loading && !data?.blogPost) {
		return <Loading />
	}

	if (!data?.blogPost) {
		return (
			<>
				<h1>Missing post</h1>
				<HTTP404 />
			</>
		)
	}

	const blogPost = data.blogPost
	const {mainImage = {}, title: blogPostMetaTitle} = blogPost

	const blogPostMetaImagePath =
		mainImage &&
		mainImage.upload &&
		makeUrl(mainImage.upload, {
			mid: true,
			isServer: true,
		})

	const metaData = {
		title: blogPostMetaTitle,
		description:
			blogPost.summary?.length > 300
				? blogPost.summary.slice(0, 300) + '…'
				: blogPost.summary || 'No description',
		imageUrl: `https://${__CONFIG__.hostname}/${blogPostMetaImagePath}`,
	}

	const templateData = {
		metaData,
		mainImage: mainImage?.upload?.id,
		regionId: blogPost?.regionIds?.[0],
		regionIds: blogPost?.regionIds,
	}

	return (
		<>
			<Template id="postTop" {...{templateData}} />
			<PostBase blogPost={blogPost} />
			<Template id="postBottom" {...{templateData}} />
		</>
	)
}
export default Post
