import React from 'react'
import ReactSelect, {SelectMulti} from './ReactSelect'
import InputLayout, {LayoutProps} from './InputLayout'

const SelectInput = <T = string, M extends boolean = false>(
	props: LayoutProps<typeof ReactSelect<T, M>>
) => <InputLayout {...props} Input={ReactSelect<T, M>} />

export const MultiSelectInput = <T = string,>(
	props: LayoutProps<typeof SelectMulti<T>>
) => <InputLayout {...props} Input={SelectMulti<T>} />

export default SelectInput
