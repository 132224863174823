import React from 'react'
import {Helmet} from 'plugins/react-helmet'
import {LocationListener} from 'plugins/react-router-deluxe'
import {isBrowser, isDev} from 'stratokit/build'

const {gaId, gAdwordsId} = __CONFIG__

export const gtag = (...args) => globalThis.gtag && globalThis.gtag(...args)

const script = `window.dataLayer=window.dataLayer||[]
function gtag(){dataLayer.push(arguments)}gtag('js',new Date())
gtag('config','${gaId}')
${gAdwordsId ? `gtag('config','${gAdwordsId}')` : ''}`

const handlePageView = location =>
	gtag('config', gaId, {
		page_path: location.pathname + location.search,
	})

// Don't render the scripts on the client, they
// should be loaded on initial html only
// Also https://github.com/nfl/react-helmet/issues/357
const GoogleAnalytics = () =>
	isBrowser ? (
		<LocationListener onChange={handlePageView} />
	) : !isDev && gaId ? (
		<>
			<Helmet>
				{/* Putting in head because it also offers page speed tracking */}
				<script
					async
					src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
				/>
			</Helmet>
			{/* For some reason helmet ignores this script, so take it out */}
			{/* eslint-disable-next-line react/no-danger */}
			<script dangerouslySetInnerHTML={{__html: script}} />
		</>
	) : null

export default GoogleAnalytics
