import React, {ReactNode} from 'react'
import {FormInput, Label, LabelText} from './Components/InputComponents'
import {styled} from 'app/styles'
import {Box} from '../Layout'

export const DisabledOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.2);
	cursor: default;
`
const ChildrenWrap = styled.div<{
	fullWidth?: boolean
	short?: boolean | string
	error?: React.ReactNode
}>`
	position: relative;
	${p => (p.fullWidth ? 'width: 100%' : 'flex: 1 0 0')};
	min-width: ${p => !(p.fullWidth || p.short) && p.theme.labelWidth};
	max-width: ${p =>
		p.short ? (p.short === true ? '7em' : p.short) : undefined};
	box-shadow: ${p => p.error && '0 0 0 2px red'};
`

export type InputLayoutProps = {
	readonly label?: ReactNode
	readonly htmlLabel?: boolean
	readonly noLabel?: boolean
	readonly error?: boolean | string | ReactNode
	readonly right?: boolean
	readonly short?: boolean | string
	readonly inline?: boolean
	readonly inlineLabel?: boolean
	readonly flex?: string
	readonly together?: boolean
	readonly labelOnTop?: boolean
	readonly className?: string
	readonly column?: boolean
	readonly disabled?: boolean
	readonly switchLabel?: boolean
	readonly fullWidth?: boolean
	readonly labelGap?: boolean | string
	readonly prefix?: ReactNode
	readonly suffix?: ReactNode
}
export type LayoutProps<C extends React.ComponentType> = InputLayoutProps &
	React.ComponentPropsWithoutRef<C>

const InputLayout = <
	InputC extends React.ComponentType,
	Props = React.ComponentPropsWithoutRef<InputC>,
>({
	label,
	column,
	labelOnTop = column,
	switchLabel,
	fullWidth,
	error,
	children,
	right,
	inline,
	inlineLabel,
	short,
	together,
	flex,
	className,
	disabled,
	Input,
	inputProps,
	labelGap,
	htmlLabel = true,
	prefix,
	suffix,
	...rest
}: React.PropsWithChildren<
	LayoutProps<InputC> & {
		readonly Input?: InputC
		readonly inputProps?: Props
	}
>) => {
	const labelFirst = !!switchLabel !== !right || labelOnTop
	const {noLabel} = rest
	let childDiv =
		children || (Input && <Input {...({...rest, ...inputProps} as any)} />)
	if (prefix || suffix)
		childDiv = (
			<div className="flex w-full items-center justify-center gap-4">
				{!!prefix && <Box inline>{prefix}</Box>}
				<div className="flex w-full items-center justify-center">
					{childDiv}
				</div>
				{!!suffix && <Box inline>{suffix}</Box>}
			</div>
		)
	return (
		<FormInput
			{...{flex, left: !right, right, inline, fitChildren: short}}
			className={className}
		>
			<Label
				as={htmlLabel ? 'label' : 'div'}
				inline={inline}
				right={right}
				together={together}
				top={labelOnTop}
				gap={labelGap}
				fitChildren={!!short}
			>
				{!noLabel && labelFirst && (
					<LabelText
						as="span"
						together={together}
						top={labelOnTop}
						fullWidth={fullWidth}
						error={error}
						inline={inline || inlineLabel}
					>
						{label}
						{error && (
							<>
								{' '}
								<b>{error}</b>
							</>
						)}
					</LabelText>
				)}
				<ChildrenWrap
					fullWidth={(!label || labelOnTop) && !inline}
					short={short}
					error={error}
				>
					{childDiv}
					{disabled && <DisabledOverlay onClick={e => e.preventDefault()} />}
				</ChildrenWrap>
				{!noLabel && !labelFirst && (
					<LabelText
						as="span"
						together={together}
						right
						error={error}
						fullWidth={fullWidth}
						inline={inline || inlineLabel}
					>
						{label}
					</LabelText>
				)}
			</Label>
		</FormInput>
	)
}

const StyledInputLayout = styled(InputLayout).attrs(p => ({
	labelOnTop: p.inline
		? false
		: p.labelOnTop == null
			? p.theme.labelTop
			: p.labelOnTop,
}))`` as typeof InputLayout

export default StyledInputLayout
