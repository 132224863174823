import Value from './SimilarHousesSection'

export default {
	label: 'Similar Houses',
	group: 'content',
	Value,
	defaultValue: {
		house: undefined,
		houseId: undefined,
	},
}
