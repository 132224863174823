import React from 'react'
import {MainHeader} from '..'
import MobileNav from './MobileNav'
import Logo from '../Logo'
import MobileSearch from './MobileSearch'

const HorizontalDivider = () => (
	<hr className="mx-4 h-8 border-0 border-l border-neutral-500" />
)

const MobileHeader = ({routes}) => {
	return (
		<MainHeader className="px-4 py-0">
			<div className="flex min-h-10 w-full items-center justify-start">
				<MobileNav routes={routes} />
				<HorizontalDivider />
			</div>
			<div className="flex w-full items-center justify-center">
				<Logo />
			</div>
			<div className="flex min-h-10 w-full items-center justify-end">
				<HorizontalDivider />
				<MobileSearch />
			</div>
		</MainHeader>
	)
}

export default MobileHeader
