import React from 'react'
import PropTypes from 'prop-types'
import {StyledTextarea} from './Components/InputComponents'
import InputLayout, {LayoutProps} from './InputLayout'

const TextareaInput = (props: LayoutProps<typeof TextareaInput>) => (
	<InputLayout
		{...props}
		Input={StyledTextarea}
		inputProps={{value: props.value || ''}}
	/>
)
TextareaInput.propTypes = {
	value: PropTypes.string,
}

export default TextareaInput
