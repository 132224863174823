// inspired by the Webpack tapable library
import debug from 'debug'

const dbg = debug('tapable')
const isProd = process.env.NODE_ENV === 'production'

export type HookOptions = {after?: string; before?: string}
/** A `hook` that can be `tap`ped for easy extensibility. */
export class Hook<
	Args extends unknown[] = [],
	Returns = void,
	CallbackFn extends (...args: Args) => Returns = (...args: Args) => Returns,
	ArgNames extends string[] = {[n in keyof Args]: string},
> {
	_creator?: string
	_debugName: string
	_name: string
	_args: ArgNames
	_fName?: string
	_async: boolean = false

	taps: {name: string; fn: CallbackFn}[] = []

	/**
	 * Create a hook.
	 *
	 * @param [args] - The arguments that the taps will be called with.
	 * @param [name] - The name of this hook.
	 */
	constructor(args?: ArgNames, name?: string) {
		this._creator = new Error('tap').stack?.split('\n')[1]?.slice(7)
		if (args && !Array.isArray(args))
			throw new Error('pass the names of the arguments as an array')
		this._name = name || 'someHook'
		this._debugName = `${name}${this._creator ? ` (${this._creator})` : ''})`
		this._args = args || ([] as unknown as ArgNames)
		this.taps = []
		dbg('Hook %s created', this.name)
	}

	_error(...args) {
		// eslint-disable-next-line no-console
		console.error(`!!! Hook ${this._debugName}`, ...args)
	}

	/** A pretty name for the hook, for debugging */
	get name(): string {
		if (!this._fName)
			this._fName = `${this._async ? 'async ' : ''}${
				this._name
			}(${this._args.join(', ')})`
		return this._fName
	}

	/** Is there an async tap attached? */
	get isAsync() {
		return this._async
	}

	_handleError(name: string, error: Error): never {
		if (error.message) {
			error.message = `Hook ${this._debugName} tap ${name}: ${error.message}`
			throw error
		} else {
			throw new Error(
				`Hook ${this._debugName} tap ${name} errored: ${String(error)}`
			)
		}
	}

	_testArgs(args: Args, isSync?: boolean) {
		if (process.env.NODE_ENV === 'production') return
		if (this._async && isSync)
			throw new Error(
				`${this.name}: async hook cannot be called sync (maybe one of the taps is async?)`
			)
		if (args.length !== this._args.length)
			this._error(`called with ${args.length} instead of ${this._args.length}`)
	}

	/**
	 * Add a `tap`
	 *
	 * @param name - The name of the tap, should be unique.
	 * @param fn - The function that will be called.
	 * @param [options]
	 */
	tap(name: string, fn: CallbackFn, options?: HookOptions) {
		if (!isProd) {
			const prev = this.taps.find(t => t.name === name)
			if (prev) this._error(`tap: ${name} was already added!`)
		}
		const tap = {name, fn}
		dbg('%s: Adding tap %s %o', this.name, name, options)
		if (options?.before) {
			const index = this.taps.findIndex(t => t.name === options.before)
			if (index !== -1) {
				this.taps.splice(index, 0, tap)
				return
			}
		}
		if (options?.after) {
			const index = this.taps.findIndex(t => t.name === options.after)
			if (index !== -1) {
				this.taps.splice(index + 1, 0, tap)
				return
			}
		}
		this.taps.push(tap)
	}

	/**
	 * Add an async `tap` - marks the Hook async, otherwise identical to `.tap`
	 *
	 * @param name - The name of the tap, should be unique.
	 * @param fn - The function that will be awaited.
	 * @param [options]
	 */
	tapAsync(name: string, fn: CallbackFn, options?: HookOptions) {
		this._async = true
		this._fName = undefined
		this.tap(name, fn, options)
	}

	/**
	 * Remove a `tap`
	 *
	 * @param fn - The function that will be removed.
	 */
	untap(fn: CallbackFn) {
		const idx = this.taps.findIndex(t => t.fn === fn)
		if (idx >= 0) this.taps.splice(idx, 1)
	}

	/**
	 * Replace a `tap` - useful for hot reloading. Can be used for async taps as
	 * well.
	 *
	 * @param name - The name of the tap, should be unique.
	 * @param fn - The function that will be called.
	 */
	retap(name: string, fn: CallbackFn) {
		const tap = this.taps.find(t => t.name === name)
		if (tap) {
			tap.fn = fn
		} else {
			this._error(`retap: ${name} was not added yet!`)
			this.tap(name, fn)
		}
	}

	/**
	 * Call all taps in added order.
	 *
	 * @param args - The arguments each tap will be called with.
	 * @throws As soon as a tap throws.
	 */
	series(...args: Args) {
		this._testArgs(args, true)
		dbg('%sseries%o', this.name, args)
		for (const {name, fn} of this.taps) {
			try {
				fn(...args)
			} catch (error) {
				this._handleError(name, error)
			}
		}
	}

	/** @deprecated Use series instead. */
	call(...args: Args) {
		return this.series(...args)
	}

	/**
	 * Await all taps in added order.
	 *
	 * @param args - The arguments each tap will be called with.
	 * @throws As soon as a tap throws.
	 */
	async seriesAsync(...args: Args) {
		this._testArgs(args)
		dbg('%s.seriesAsync%o', this.name, args)
		for (const {name, fn} of this.taps) {
			try {
				await fn(...args)
			} catch (error) {
				this._handleError(name, error)
			}
		}
	}

	/** @deprecated Use seriesAsync instead. */
	callAsync(...args: Args) {
		return this.seriesAsync(...args)
	}

	/**
	 * Call all taps in added order and return their results.
	 *
	 * @param args - The arguments each tap will be called with.
	 * @throws As soon as a tap throws.
	 */
	seriesResult(...args: Args): Returns[] {
		this._testArgs(args, true)
		dbg('%s.seriesResult%o', this.name, args)
		const out: Returns[] = []
		for (const {name, fn} of this.taps) {
			try {
				out.push(fn(...args))
			} catch (error) {
				this._handleError(name, error)
			}
		}
		return out
	}

	/** @deprecated Use seriesResult instead. */
	map(...args: Args) {
		return this.seriesResult(...args)
	}

	/**
	 * Await all taps in parallel.
	 *
	 * @param args - The arguments each tap will be called with.
	 * @returns The Promise for the completed array.
	 * @throws As soon as a tap throws but doesn't wait for running calls.
	 */
	async parallelResult(...args: Args): Promise<Returns[]> {
		this._testArgs(args)
		dbg('%s.parallelResult%o', this.name, args)
		return Promise.all(
			this.taps.map(async ({name, fn}) => {
				try {
					return await fn(...args)
				} catch (error) {
					this._handleError(name, error)
				}
			})
		)
	}

	/** @deprecated Use parallelResult instead. */
	mapAsync(...args: Args) {
		return this.parallelResult(...args)
	}

	// we could also do callReverse, callUntil, reduce etc

	/** Clear all taps */
	reset() {
		this.taps = []
	}
}

export const addHook = <ArgNames extends string[]>(
	hooks: Hooks,
	name: keyof Hooks,
	args: ArgNames
) => {
	if (hooks[name]) throw new Error(`Hook ${name} is already defined`)
	hooks[name] = new Hook(args, name as string) as any
}

export const addAsyncHook = (hooks: Hooks, name: keyof Hooks, args) => {
	if (hooks[name]) throw new Error(`Hook ${name} is already defined`)
	hooks[name] = new AsyncHook(args, name) as any
}

/** Same as Hook but marks the Hook async from the start. */
export class AsyncHook<
	Args extends unknown[] = [],
	Returns = void,
	CallbackFn extends (...args: Args) => Returns = (...args: Args) => Returns,
	ArgNames extends string[] = {[n in keyof Args]: string},
> extends Hook<Args, Returns, CallbackFn, ArgNames> {
	/**
	 * Create an async hook.
	 *
	 * @param {ArgNames} [args] - The arguments that the taps will be called with.
	 * @param {string} [name] - The name of this hook.
	 */
	constructor(args: ArgNames, name) {
		super(args, name)
		this._async = true
		// Make sure we add async to the name
		this._fName = undefined
	}
}
