import {cn, toStringRange} from 'app/lib'
import * as React from 'react'
import {CalendarIcon} from '../Icon'
import Text from '../twComponents/Text'
import {_, useI18n} from 'plugins/i18n'
import {useSelector} from 'plugins/redux/hooks'
import ReservationCalendarInput from './ReservationCalendarInput'
import {ComponentProps, FC, useEffect, useState} from 'react'

const ReservationPeriodInputMobile: FC<
	ComponentProps<typeof ReservationCalendarInput>
> = ({blockedRanges, initialDate, switchDay}) => {
	const {getText} = useI18n()
	const selectedRange = useSelector(
		state => state.form?.reservationCard?.values?.dateRange
	)
	const value = toStringRange(selectedRange, 'DD MMM YY')
	const [prev, setPrev] = useState(selectedRange)
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (prev !== value) {
			setPrev(value)
			if (value !== '' && show) setShow(false)
		}
	}, [show, prev, value])

	return (
		<div className="gap flex w-full flex-col gap-2 rounded-md border border-solid border-gray-300 bg-transparent px-3 py-3 text-lg transition-colors">
			<div
				className="flex w-full gap-2"
				onClick={() => setShow(prevShow => !prevShow)}
			>
				<div className="self-end pb-1">
					<CalendarIcon className="shrink-0" />
				</div>
				<div className="flex w-full flex-col gap-1">
					<div className="flex justify-between">
						<Text className="text-[10px] leading-3 text-gray-400">{_`period2`}</Text>
						{value ? (
							<Text className="text-[10px] leading-3 text-gray-400">
								{' '}
								{_`change`}
							</Text>
						) : null}
					</div>
					<div className="flex max-w-11/12 items-center gap-2">
						<input
							name="period"
							type="text"
							className={cn(
								'placeholder:text-grey-300 flex w-full bg-transparent font-normal caret-transparent transition-colors focus-visible:outline-hidden'
							)}
							value={value}
							placeholder={getText('select')}
						/>
					</div>
				</div>
			</div>

			<div
				className={cn(
					'transition-all, h-full overflow-hidden duration-500 ease-in-out',
					show ? 'max-h-96' : 'pointer-events-none max-h-0'
				)}
			>
				<ReservationCalendarInput
					blockedRanges={blockedRanges}
					initialDate={initialDate}
					switchDay={switchDay}
				/>
			</div>
		</div>
	)
}

export default ReservationPeriodInputMobile
