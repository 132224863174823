import React, {useCallback} from 'react'
import {useId} from 'plugins/id-generator'
import Hamburger from './Hamburger'
import {triggerSidebar} from 'app/Components/Page/Header/MobileHeader/MobileNav-duck'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {LangLink} from 'plugins/i18n'
import styled, {ThemeProvider} from 'styled-components'
import {getBg, getFg, getHoverFg, greenDarkTheme} from 'app/styles'
import {Box} from 'app/Components/Layout'

// const NavSection = styled(Box)`
// 	font-weight: lighter;
// 	color: ${getFg};
// 	&:hover {
// 		color: ${getHoverFg};
// 		transition: 0.2s;
// 	}
// 	${p =>
// 		p.mobile &&
// 		`
// 		> * {
// 			width: 100%;
// 		}
// 	`};
// `

const NavWrapp = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	padding: 4em 1em 1em 1em;
	background: ${getBg};
	transition: transform 0.5s ease;
	transform: translateX(-100%);
`

const MobileWrapper = styled.div`
	> input:checked + ${NavWrapp} {
		transform: translateX(0);
	}
`

const MobileNav: React.FC<{
	readonly routes: {
		path?: string
		label: React.ReactNode
		onChange?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
	}[]
}> = ({routes, ...rest}) => {
	const id = useId()
	const open = useSelector(state => state.mobileNav.open)
	const dispatch = useDispatch()

	const toggleSidebar = useCallback(() => {
		dispatch(triggerSidebar())
	}, [dispatch])

	return (
		<MobileWrapper>
			<ThemeProvider theme={greenDarkTheme}>
				<input
					id={id}
					type="checkbox"
					style={{display: 'none'}}
					checked={open}
					onChange={() => {}}
				/>
				<NavWrapp>
					<div className="container flex h-full w-full flex-col flex-nowrap gap-2 overflow-x-hidden overflow-y-auto px-4 pt-12 pb-20 text-xl font-normal">
						{routes &&
							routes.map((route, i) =>
								route.path ? (
									<div key={i}>
										<LangLink
											className="whitespace-nowrap"
											onClick={e => {
												if (route.onChange) route.onChange(e)
												toggleSidebar()
											}}
											to={route.path}
										>
											{route.label}
										</LangLink>
									</div>
								) : (
									<div key={i}>{route.label}</div>
								)
							)}
					</div>
				</NavWrapp>
			</ThemeProvider>
			<label htmlFor={id}>
				<Hamburger active={open} onClick={toggleSidebar} />
			</label>
		</MobileWrapper>
	)
}

export default MobileNav
