// Very basic function to send the error via graphql. Has no dependencies so it can be embedded
// Keep this ES5 so it works across all browsers, it gets loaded in the server version
// full fn signature comes from window.onerror, it can also be (errorMessage, info)

function postError(errOrMsg, source, lineno, colno, error) {
	var errorMessage, info
	if (error) {
		errorMessage = String(error.stack || error.message)
		info = errOrMsg + ' ' + source + '@' + lineno + ':' + colno
	} else {
		errorMessage =
			typeof errOrMsg === 'string'
				? errOrMsg
				: errOrMsg.stack || errOrMsg.message || String(errOrMsg)
		info = source
	}
	// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
	if (/resizeobserver/i.test(errorMessage)) return
	if (errorMessage === globalThis.__lastErr) return
	globalThis.__lastErr = errorMessage
	var url = globalThis.location?.href
	var xhr = new XMLHttpRequest()
	xhr.open('POST', `${__CONFIG__.prefix}${__CONFIG__.graphqlPath}`)
	xhr.withCredentials = true // Make sure that session cookie is passed
	xhr.setRequestHeader('Content-Type', 'application/json')
	xhr.send(
		JSON.stringify({
			variables: {e: errorMessage, i: info, u: url},
			query:
				'mutation sendError($e: String!, $i: String, $u: String)' +
				'{sendError(error: $e, info: $i, url: $u)}',
		})
	)
	// We don't care about the result
}

export default postError
