import React, {useState} from 'react'
import styled from 'styled-components'
import {colorCss, hoverColorCss} from 'app/styles'
import {_, LangLink, useI18n} from 'plugins/i18n'
import {urlForRegion} from 'app/lib'

const List = styled.ul`
	& ul {
		margin-bottom: 0.5em;
		margin-left: 1em;
	}
`
const CountryList = styled(List)`
	${colorCss};
	a {
		${hoverColorCss};
	}
	width: 15em;
	padding: 0.5em 1em;
	height: 29em;
	overflow: auto;

	padding-bottom: 2.4em;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 3rem;
		background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.4) 100%
		);
		max-height: ${p => (p.scrollOverlay ? '3rem' : '0')};
		opacity: ${p => (p.scrollOverlay ? '1' : '0')};
		transition:
			opacity 0.5s,
			max-height 0.5s;
	}
`

const RegionLink = ({regionId, onChange}) => {
	const i18n = useI18n()
	const url = urlForRegion({regionId, lang: i18n.lang})
	return url ? (
		<LangLink
			className="block w-full cursor-pointer hover:underline"
			to={url}
			onClick={() => {
				onChange?.(regionId)
			}}
		>
			{_(regionId)}
		</LangLink>
	) : (
		<div className="block w-full cursor-pointer hover:underline">
			{_(regionId)}
		</div>
	)
}

const HouseCounRegionList: React.FC<{
	readonly optionsList?: any[]
	readonly onChange?: (regionId: string) => void
	readonly handleToggle: () => void
	readonly showOverlay?: boolean
}> = ({optionsList = [], showOverlay = true, onChange, handleToggle}) => {
	const [scrollOverlay, setScrollOverlay] = useState(!!showOverlay)

	return (
		<CountryList
			scrollOverlay={showOverlay && scrollOverlay}
			onScroll={e => {
				if (e.currentTarget.scrollTop > 0) {
					setScrollOverlay(false)
				} else {
					setScrollOverlay(true)
				}
			}}
			onClick={handleToggle}
		>
			{optionsList.map(({countryId, regionIds}, i) => (
				<li key={i}>
					<b>
						<RegionLink regionId={countryId} onChange={onChange} />
					</b>
					<ul>
						{regionIds.map((regionId, index) => (
							<li key={index}>
								<RegionLink regionId={regionId} onChange={onChange} />
							</li>
						))}
					</ul>
				</li>
			))}
		</CountryList>
	)
}
export {HouseCounRegionList}

const OptionsWrapper = styled.div`
	width: 260px;
	max-height: 268px;
	overflow-y: auto;
	padding: 1em;
	&:hover li {
		color: #7b7f5f;
		transition: 0.2s;
		&:hover {
			color: #252525;
		}
	}
`

const RentalContentList: React.FC<{
	readonly showContent?: string
	readonly optionsList?: any[]
}> = props => {
	const {showContent} = props

	let content
	switch (showContent) {
		case 'region': {
			content = <HouseCounRegionList {...props} />
			break
		}
		default: {
			content = <div>{_`noContent`}</div> // Assuming `_` is a translation function, replacing it with a div
		}
	}

	return <OptionsWrapper>{content}</OptionsWrapper>
}

export default RentalContentList
