import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist'
import * as reducers from 'state/reducers'
import {isBrowser, isDev} from 'stratokit/build'

export default function storeFactory({initial}: {initial: any}) {
	const combinedReducer = combineReducers(reducers)

	const middlewares: any[] = []

	const store = configureStore({
		reducer: combinedReducer,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [
						FLUSH,
						REHYDRATE,
						PAUSE,
						PERSIST,
						PURGE,
						REGISTER,
						'searchRentals/setRentalPeriod',
					],
					ignoredPaths: ['searchRentals.period'],
				},

				// eslint-disable-next-line unicorn/prefer-spread
			}).concat(middlewares),
		preloadedState: initial,
		devTools: isDev,
	})

	if (isBrowser) {
		persistStore(store, null)
	}

	if (import.meta.hot) {
		import.meta.hot.accept('state/reducers', newReducers => {
			const newReducer = combineReducers({...reducers, ...newReducers})
			store.replaceReducer(newReducer)
		})
	}
	return store
}

export type Store = ReturnType<typeof storeFactory>
export type RootState = ReturnType<Store['getState']>
export type AppDispatch = Store['dispatch']
