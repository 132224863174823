import React from 'react'
import {
	css,
	makeColors,
	getFg,
	getBg,
	getSecondary,
	ThemeProvider,
} from './utils'

export const capGreenLightColor = '#d6d8cd'
export const capGreenColor = '#435421'
export const capDarkGreenColor = 'rgba(67, 84, 33, 0.6)'
export const goldColor = '#FEBE12'
export const goldHoverColor = '#E0A000'
export const orangeColor = '#F5672F'
export const silverColor = '#E0E0E0'

// new WebSite
export const lightBeigeColor = '#F6F5EA'
export const beigeColor = '#e8e5d4'
export const darkBeigeColor = '#dfdcc2'
export const yellowGreenColor = '#B4AC82'
export const mediumGreyColor = '#666666'
export const lightGreen = '#e5fed0'
export const darkGreen = '#3c5823'
export const capGoldColor = '#79630C'
export const capDarkGrey = '#404040'

export const cut = css`
	clip-path: ${p =>
		p.cut &&
		(p.leftCut
			? `polygon(0% 0%, 100% 0%, 100% 100%, ${
					p.cutSize || 1
				}em 100%, 0% calc(100% - ${p.cutSize || 1}em))`
			: `polygon(0% 0%, 100% 0%, 100% calc(100% - ${
					p.cutSize || 1
				}em), calc(100% - ${p.cutSize || 1}em) 100%, 0% 100%)`)};
`

export const defaultTheme = {
	radius: 0 /* rem */,
	gap: '1rem',
	labelGap: '0.5rem',
	inputHeight: 1.8 /* rem */,
	inputLineHeight: 1.5 /* rem */,
	labelWidth: '11em',
	focusCss: css`
		outline: none;
		box-shadow: 0 0 5px 0 #66afe9;
		border: ${p => p.border && `thin solid #66afe9`};
	`,
	buttonCss: css`
		${cut};
		color: ${p => getFg(p, p.disabled ? 'btnDisabled' : null)};
		background-color: ${p => getBg(p, p.disabled ? 'btnDisabled' : null)};
		border: ${p => (p.border ? `thin solid ${getSecondary(p)}` : 'none')};
		border-radius: 2px;
		${q =>
			!q.disabled &&
			css`
				&:hover {
					transition: 0.8s;
					color: ${p => getFg(p, null, true)};
					background-color: ${p => getBg(p, null, true)};
					border: ${p =>
						p.border ? `thin solid ${getSecondary(p, null, true)}` : 'none'};
				}
			`};
	`,
	htmlInput: {
		pCss: css`
			line-height: 1.5;
		`,
		h1Css: css`
			color: ${getFg};
		`,
		imageCss: css`
			width: 60%;
			padding: 1em;
		`,
	},

	contentWidth: '1600px',

	inputCss: css`
		/* iOS fix  */
		border-radius: 0px;
	`,

	activeTabLabelCss: css`
		color: #435421;
		border-bottom: ${p => !p.isPhone && '1px solid #435421'};
	`,

	tabLabelCss: css`
		font-size: 0.9em;
		padding: 7px 15px;
		/* Let the body background shine through */
		&:not(:first-of-type) {
			margin-left: 1px;
		}
		margin-bottom: 1px;
		background-color: ${getBg};
	`,

	tabsCss: css`
		margin: 20px 50px 0 50px;
		width: 100%;
	`,

	uploadCss: css`
		border: 1px solid rgba(0, 0, 0, 0.22);
	`,

	colorSets: {
		default: makeColors('white'),
		defaultHover: makeColors('white', true),

		transparentWhite: makeColors({bg: 'transparent', whiteFg: true}),
		transparentBlack: makeColors({bg: 'transparent', whiteFg: false}),

		btn: makeColors({bg: '#E0E0E0', fg: '#484848'}),
		btnHover: makeColors({bg: ' #C3C3C3', fg: '#484848'}),

		btnSelecting: makeColors({bg: 'white', fg: '#696E71'}),
		btnSelectingHover: makeColors({bg: ' #4A90E2', fg: '#fff'}),

		pager: makeColors({bg: 'transparent', fg: '#BBBBBB'}),
		pagerCurrent: makeColors({bg: '#F6F4EA', fg: '#997E10'}),
		capGoldDarkGrey: makeColors(capGoldColor),

		pane: makeColors('#f6f3ea'),

		// Cap color sets
		capGreenLight: makeColors(capGreenLightColor),
		lightBeige: makeColors(lightBeigeColor),
		capGreen: makeColors(capGreenColor),
		capDarkGreen: makeColors(capDarkGreenColor),
		capBlack: makeColors({
			bg: 'black',
			fg: 'white',
		}),
		capBlackHover: makeColors(
			{
				bg: 'black',
				fg: 'white',
			},
			true
		),
		capBrown: makeColors({
			bg: 'transparent',
			fg: '#997E10',
		}),
		capBeigeBrown: makeColors({
			bg: darkBeigeColor,
			fg: '#997E10',
		}),
		capGreenWhite: makeColors({
			bg: '#445524',
			fg: 'white',
		}),
		capGreenDark: makeColors({
			bg: '#252525',
			fg: '#9EA18A',
		}),
		capGreenBlack: makeColors({
			bg: 'black',
			fg: '#9EA18A',
		}),
		capLightBrown: makeColors({
			bg: '#F6F5EA',
			fg: '#918044',
		}),

		goldStar: makeColors({fg: '#FEBE12'}),
		goldStarHover: makeColors({fg: '#FEBE12'}, true),
		greyStar: makeColors({fg: '#E0E0E0'}),
		greyStarHover: makeColors({fg: '#E0E0E0'}, true),

		// Basic buttons color sets

		danger: makeColors('rgb(213, 32, 29)'),
		dangerHover: makeColors('rgb(213, 32, 29)', true),
		warning: makeColors('rgba(255, 178, 38, 1)'),
		warningHover: makeColors('rgba(216, 149, 32, 1)'),
		success: makeColors('rgba(71,189,129,1)'),
		successHover: makeColors('rgba(61,150,104,1)'),

		// new WebSite
		capGrey: makeColors({bg: 'rgba(37, 37, 37, 0.9)', fg: '#BBBBBB'}),
		capDarkGrey: makeColors({fg: '#666666'}),
		capSocial: makeColors({bg: '#666666', fg: 'rgb(37, 37, 37)'}),
		capLightBeigeBlack: makeColors({bg: lightBeigeColor, fg: 'black'}),
		capBeige: makeColors({bg: beigeColor}),
		capBeigeBlack: makeColors({bg: beigeColor, fg: 'black'}),
		capDarkBeigeBlack: makeColors({bg: darkBeigeColor, fg: 'black'}),
		capBeigeGreen: makeColors({bg: beigeColor, fg: capGoldColor}),
		capLightGreenDarkGreen: makeColors({bg: lightGreen, fg: darkGreen}),
		capDarkWhite: makeColors({
			bg: 'rgba(37, 37, 37, 0.9)',
			fg: lightBeigeColor,
		}),
		capRedWhite: makeColors({bg: 'red', fg: 'rgba(255, 0, 0, 0.36)'}),
	},

	// DEFAULT SETS
	defaultSet: 'default',
	accentSet: 'capGreen',
	contrastSet: 'capGreenLight',
	accentHoverSet: 'capDarkGreen',
	btnDefaultSet: 'btn',
	btnDisabledSet: 'btn',
	btnDangerSet: 'danger',
	btnWarningSet: 'warning',
	btnAccentSet: 'capGreen',
	btnAccentHoverSet: 'capDarkGreen',
	btnSelectingSet: 'btnSelecting',
	btnSelectingHoverSet: 'btnSelectingHover',
	modalSet: 'default',
	tabsSet: 'default',
	paneSet: 'pane',

	starActiveSet: 'goldStar',
	starInactiveSet: 'greyStar',

	capButtonSet: 'capBlack',
	capButtonHoverSet: 'capBlack',
}

export const DefaultTheme = props => (
	<ThemeProvider {...props} theme={defaultTheme} />
)

export const newDefaultTheme = {
	...defaultTheme,
	boxShadowCss: css`
		box-shadow: 2px 2px 7px 0px #00000096;
	`,
	buttonCss: css`
		font-weight: ${p => p.light && 'lighter'};
		color: ${p => getFg(p, p.disabled ? 'btnDisabled' : null)};
		background-color: ${p => getBg(p, p.disabled ? 'btnDisabled' : null)};
		border: ${p => (p.border ? `thin solid ${getSecondary(p)}` : 'none')};
		border-radius: 2px;
		${q =>
			!q.disabled &&
			css`
				&:hover {
					transition: 0.8s;
					color: ${p => getFg(p, null, true)};
					background-color: ${p => getBg(p, null, true)};
					border: ${p =>
						p.border ? `thin solid ${getSecondary(p, null, true)}` : 'none'};
				}
			`};
	`,
	headCss: css`
		font-weight: lighter;
		color: ${capDarkGrey};
	`,
	lineCss: css`
		${p => `${p.vertical ? 'height' : 'width'}: ${p.length || '100%'}`};
		opacity: ${p => p.opacity};
		color: ${getFg};
	`,
	underlineCss: css`
		width: 100px;
		color: ${darkBeigeColor};
		margin-top: ${p => p.theme.gap};
	`,
	tabLabelCss: css`
		font-size: 1.2em;
		padding: ${p => (p.isPhone ? '18.5px 15px' : '18.5px 0')};
		color: #000;
		font-weight: lighter;
		margin: ${p => (p.isPhone ? '0px' : '0 35px')};
		border-top: ${p => (p.isPhone ? '1px solid #bfbfbf' : 'none')};
		border-bottom: ${p => (p.isPhone ? '1px solid #bfbfbf' : 'none')};
	`,

	accentSet: 'capGoldDarkGrey',

	activeTabLabelCss: css`
		color: #000;
		border-bottom: 5px solid #997e10;
	`,

	tabsCss: css`
		background-color: white;
		border-top: ${p => (p.isPhone ? 'none' : '1px solid #bfbfbf')};
		width: 100%;
		&:after {
			position: absolute;
			top: 72px;
			content: '';
			width: 100%;
			${p => !p.isPhone && 'height: 1px'};
			background: #bfbfbf;
		}
	`,

	uploadCss: css`
		border: 1px solid rgba(0, 0, 0, 0.22);
	`,

	moneyValueCss: css`
		color: #009d9f;
	`,
}
export const NewDefaultTheme = props => (
	<ThemeProvider {...props} theme={newDefaultTheme} />
)

export const lightBeigeTheme = {
	defaultSet: 'capLightBeigeBlack',
	defaultHoverSet: 'capLightBeigeBlack',
}

export const beigeGreenTheme = {
	defaultSet: 'capBeigeGreen',
	defaultHoverSet: 'capBeigeGreen',
	btnDefaultSet: 'capBeigeGreen',
	btnDefaultHoverSet: 'capBeigeGreen',
}
export const BeigeGreenTheme = props => (
	<ThemeProvider {...props} theme={beigeGreenTheme} />
)
export const beigeTheme2 = {
	defaultSet: 'capBeige',
	defaultHoverSet: 'capBeige',
	btnDefaultSet: 'capBeige',
	btnDefaultHoverSet: 'capBeige',
}
export const BeigeTheme2 = props => (
	<ThemeProvider {...props} theme={beigeTheme2} />
)

export const capDarkTheme = {
	defaultSet: 'capBlack',
	defaultHoverSet: 'capBlack',
	btnDefaultSet: 'capBlack',
	btnDefaultHoverSet: 'capBlack',
}
export const CapDarkTheme = props => (
	<ThemeProvider {...props} theme={capDarkTheme} />
)

export const greenBlackTheme = {
	defaultSet: 'capGreenBlack',
	defaultHoverSet: 'capGreenBlack',
	btnDefaultSet: 'capGreenWhite',
	btnSelectingHoverSet: 'capLightBeigeBlack',
}
export const GreenBlackTheme = props => (
	<ThemeProvider {...props} theme={greenBlackTheme} />
)

export const greenDarkTheme = {
	defaultSet: 'capGreenDark',
	defaultHoverSet: 'capGreenDark',
}
export const GreenDarkTheme = props => (
	<ThemeProvider {...props} theme={greenDarkTheme} />
)

export const RegionTheme = props => (
	<ThemeProvider
		{...props}
		themes={[
			greenDarkTheme,
			{
				headCss: css`
					font-weight: lighter;
					color: ${darkBeigeColor};
				`,
			},
		]}
	/>
)

export const greenWhiteTheme = {
	defaultSet: 'capGreenWhite',
	defaultHoverSet: 'capGreenWhite',
	btnDefaultSet: 'capGreenWhite',
	btnDefaultHoverSet: 'capGreenWhite',
}
export const GreenWhiteTheme = props => (
	<ThemeProvider {...props} theme={greenWhiteTheme} />
)

export const greenTheme = {
	defaultSet: 'capGreenLight',
	defaultHoverSet: 'capGreen',
	btnSelectingSet: 'capGreenLight',
	btnSelectingHoverSet: 'capGreen',
}
export const GreenTheme = props => (
	<ThemeProvider {...props} theme={greenTheme} />
)

export const whiteTheme = {
	defaultSet: 'default',
	defaultHoverSet: 'defaultHover',
}
export const WhiteTheme = props => (
	<ThemeProvider {...props} theme={whiteTheme} />
)

export const topLabelTheme = {
	labelTop: true,
}
export const TopLabelTheme = props => (
	<ThemeProvider {...props} theme={topLabelTheme} />
)

export const formTheme = {
	gap: '.6em',
}

export const beigeTheme = {
	...topLabelTheme,
	fontWeight: 'lighter',
	accentHoverSet: 'capBeigeBlack',
	btnDefaultSet: 'capGreenWhite',
	btnSelectingHoverSet: 'capLightBeigeBlack',
}

export const BeigeTheme = props => (
	<ThemeProvider {...props} theme={beigeTheme} />
)

const getContactFormTheme = isPhone => ({
	...beigeTheme,
	...lightBeigeTheme,
	accentSet: 'capDarkBeigeBlack',
	gap: isPhone ? '0.5em' : '2em',
	labelGap: isPhone ? '0.1em' : '0.2em',
	inputHeight: 2.2,
	inputLineHeight: 2,
	reactSelectOptionsCss: {
		fontWeight: 'lighter',
	},
	input: {
		textInputCss: {
			fontWeight: 'lighter',
		},
	},
})

export const ContactFormTheme: React.FC<{
	readonly isPhone?: boolean
	[key: string]: any
}> = ({isPhone = undefined, ...rest}) => (
	<ThemeProvider {...rest} theme={getContactFormTheme(isPhone)} />
)

export const ReservationBeigeTheme = ({isPhone, ...rest}) => (
	<ThemeProvider
		{...rest}
		themes={[
			getContactFormTheme(isPhone),
			{
				input: {
					checkboxCss: {
						background: 'white',
						height: isPhone ? '2rem' : '1em',
						width: isPhone ? '2rem' : '1em',
					},
					numericInputCss: {
						background: 'white',
						width: '8.75em',
						height: '2.5em',
					},
				},
			},
		]}
	/>
)

export const inlineFormTheme = {
	inlineForm: true,
}

const capLightTheme = {
	defaultSet: 'capBrown',
	defaultHoverSet: 'capBrown',
	btnDefaultSet: 'capBrown',
	btnDefaultHoverSet: 'capBrown',
}
export const CapLightTheme = props => (
	<ThemeProvider {...props} theme={capLightTheme} />
)

export const capBrownTheme = {
	defaultSet: 'capLightBrown',
	defaultHoverSet: 'capLightBrown',
}
export const CapBrownTheme = props => (
	<ThemeProvider {...props} theme={capBrownTheme} />
)

export const SubscribeTheme = props => (
	<ThemeProvider
		{...props}
		themes={[
			beigeTheme,
			lightBeigeTheme,
			{
				inputHeight: 2.2,
				inputLineHeight: 2,
				btnDefaultHoverSet: 'capGreenWhite',
			},
		]}
	/>
)

// The themes the templates can use
export const themes = {
	beigeGreenTheme,
	beigeTheme,
	lightBeigeTheme,
	capLightTheme,
	capDarkTheme,
	capBrownTheme,
	greenTheme,
	greenWhiteTheme,
	defaultTheme,
	transparentWhite: {defaultSet: 'transparentWhite'},
	transparentBlack: {defaultSet: 'transparentBlack'},
}
