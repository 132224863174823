import React, {useContext} from 'react'

const ConfigContext = /** @type {React.Context<ReactConfig>} */ (
	React.createContext({})
)

export const useConfig = () => useContext(ConfigContext)

const wrapWithConfig = context => {
	context.app = (
		<ConfigContext.Provider value={context.__CONFIG__}>
			{context.app}
		</ConfigContext.Provider>
	)
}

export default wrapWithConfig
