import {Helmet} from 'plugins/react-helmet'
import React from 'react'
import {Partytown} from '@builder.io/partytown/react'
import {isDev, isServer} from 'stratokit/build'

const {gtmId} = __CONFIG__

const googleConsentModeScript = `
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments)
}
gtag("consent", "default", {
  ad_personalization: "denied",
 ad_storage: "denied",
 ad_user_data: "denied",
 analytics_storage: "denied",
  functionality_storage: "denied",
  personalization_storage: "denied",
  security_storage: "granted",
  wait_for_update: 500
});
gtag("set", "ads_data_redaction", true);
gtag("set", "url_passthrough", true);
`

const googleTagManagerScript = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l !='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');
`

const GoogleTagManager = () =>
	isServer && !isDev && gtmId ? (
		<>
			<Partytown lib="/~partytown/" forward={['dataLayer.push']} />
			<Helmet>
				<script type="text/partytown" data-cookieconsent="ignore">
					{googleConsentModeScript}
				</script>
				<script
					type="text/javascript"
					data-cookieconsent="ignore"
					src={`https://www.googletagmanager.com/gtag/js?id=${gtmId}`}
				/>
				<script type="text/javascript" data-cookieconsent="ignore">
					{googleTagManagerScript}
				</script>
				{/* Cookiebot is a UI intensive script, not suited for partytown */}
				<script
					id="Cookiebot"
					src="https://consent.cookiebot.com/uc.js"
					data-cbid="720d3d71-325f-49ff-bdbd-1001e73e47dd"
					type="text/javascript"
					data-consentmode-defaults="disabled"
					async
				/>
			</Helmet>
			<noscript>
				<iframe
					src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
					height="0"
					width="0"
					style={{display: 'none', visibility: 'hidden'}}
					sandbox=""
				/>
			</noscript>
		</>
	) : null

export default GoogleTagManager
