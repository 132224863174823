import React from 'react'
import {isDev, isServer} from 'stratokit/build'

const hjId = __CONFIG__.hjId

const script = `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${hjId},hjsv:5};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');`

const Hotjar = () =>
	(!isDev && isServer && hjId && (
		// eslint-disable-next-line react/no-danger
		<script dangerouslySetInnerHTML={{__html: script}} />
	)) ||
	null

export default Hotjar
