import React from 'react'
import PropTypes from 'prop-types'
import {_, withI18n, LangLink} from 'plugins/i18n'
import {HeadBox} from 'app/Components/Layout/Display'
import {urlForPost} from 'app/lib'
import FadeBox from 'app/Components/Layout/Fade'
import {HtmlContent} from 'plugins/rich-text'

const PostDescription = ({
	fontResize,
	i18n,
	post,
	to = urlForPost(i18n, post, ''),
	top,
	bottom,
	withText,
	withLink = true,
	withTitle = true,
}) => (
	<div className="flex w-full flex-col items-start justify-center gap-4">
		{top}
		{withTitle && <HeadBox resize={fontResize}>{post.title}</HeadBox>}
		{withText && post.text && (
			<FadeBox fadeColor="#FBFBFB">
				<HtmlContent value={post.text} />
			</FadeBox>
		)}
		{withLink && (
			<LangLink className="text-capGold" to={to}>
				{_`readMore`}→
			</LangLink>
		)}
		{bottom}
	</div>
)

PostDescription.propTypes = {
	fontResize: PropTypes.number,
	post: PropTypes.object,
	to: PropTypes.string,
	i18n: PropTypes.object,
	top: PropTypes.any,
	bottom: PropTypes.any,
	withText: PropTypes.bool,
	withLink: PropTypes.bool,
	withTitle: PropTypes.bool,
}

export default withI18n(PostDescription)
