import React from 'react'
import {
	FacebookIcon,
	InstagramIcon,
	PinterestIcon,
	YoutubeIcon,
} from 'app/Components/Icon'

const links = [
	{
		name: 'facebook',
		Icon: FacebookIcon,
		link: 'https://www.facebook.com/cap.vakantiewoningen',
	},
	{
		name: 'instagram',
		Icon: InstagramIcon,
		link: 'https://www.instagram.com/cap.be/',
	},
	{
		name: 'youtube',
		Icon: YoutubeIcon,
		link: 'https://www.youtube.com/channel/UCcWWJpa_FqLaSJM894SqKbA',
	},
	{
		name: 'pinterest',
		Icon: PinterestIcon,
		link: 'https://in.pinterest.com/capholidayrentals/',
	},
]

const SocialIcons = () => (
	<div className="flex items-center justify-start gap-4">
		{links.map(({link, name, Icon}, i) => (
			<a
				key={i}
				href={link}
				target="_blank"
				rel="noopener noreferrer"
				aria-label={name}
			>
				<div key={i}>
					<Icon key={i} size="2rem" />
				</div>
			</a>
		))}
	</div>
)

export default SocialIcons
