import Value from './RegionDescriptionSection'
import Form from './RegionDescriptionForm'

export default {
	label: 'Region Description',
	group: 'content',
	Value,
	defaultValue: {descriptionI18n: null, regionId: null},
	Form,
}
