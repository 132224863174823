import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const emptySearch: SearchRentalsState = {
	features: {},
	filter: undefined,
	houseCapacity: undefined,
	houseName: '',
	houseType: undefined,
	isNew: undefined,
	isPromo: undefined,
	page: 1,
	period: undefined,
	priceRange: undefined,
	region: undefined,
	slug: undefined,
	sort: undefined,
	special: undefined,
	specials: {},
	types: {},
}

const initialState: SearchRentalsState = {
	...emptySearch,
	isSearchPanelActive: false,
}

export const searchRentalsSlice = createSlice({
	name: 'searchRentals',
	initialState,
	reducers: {
		setSearchPanel(
			state,
			action: PayloadAction<SearchRentalsState['isSearchPanelActive']>
		) {
			state.isSearchPanelActive = action.payload
		},
		setFeaturesFilter(
			state,
			action: PayloadAction<SearchRentalsState['features']>
		) {
			state.page = 1
			state.features = {...state.features, ...action.payload}
		},

		clearFeaturesFilter(state) {
			state.features = initialState.features
		},
		setTypesFilter(state, action: PayloadAction<SearchRentalsState['types']>) {
			state.page = 1
			state.types = {...state.types, ...action.payload}
		},

		setSpecialsFilter(
			state,
			action: PayloadAction<SearchRentalsState['specials']>
		) {
			state.page = 1
			state.specials = {...state.specials, ...action.payload}
		},

		clearSpecialsFilter(state) {
			state.page = 1
			state.specials = initialState.specials
		},

		setPriceRangesFilter(
			state,
			action: PayloadAction<SearchRentalsState['priceRange']>
		) {
			state.page = 1
			state.priceRange = action.payload
		},

		setRentalRegions(
			state,
			action: PayloadAction<{label: SearchRentalsState['region']}>
		) {
			state.page = 1
			state.region = action.payload?.label?.length
				? action.payload.label
				: undefined
		},

		setPromo(state, action: PayloadAction<SearchRentalsState['isPromo']>) {
			state.page = 1
			state.isPromo = !!action.payload || undefined
		},

		setHouseName(
			state,
			action: PayloadAction<SearchRentalsState['houseName']>
		) {
			state.page = 1
			state.houseName = action.payload
		},

		setNew(state, action: PayloadAction<SearchRentalsState['isNew']>) {
			state.page = 1
			state.isNew = action.payload ? true : undefined
		},

		setRentalType(
			state,
			action: PayloadAction<{label: SearchRentalsState['houseType']}>
		) {
			state.page = 1
			state.houseType = action.payload?.label
		},

		setRentalSize(
			state,
			action: PayloadAction<SearchRentalsState['houseCapacity']>
		) {
			state.page = 1
			state.houseCapacity = action.payload
		},

		setRentalPeriod(
			state,
			action: PayloadAction<{period: SearchRentalsState['period']}>
		) {
			state.page = 1
			state.period = action.payload?.period
		},

		// This still does a full reset
		clearSearch(state) {
			Object.assign(state, {
				...emptySearch,
				page: initialState.page,
			})
		},

		setRegionsDefault(state) {
			state.region = undefined
		},

		setTypeDefault(state) {
			state.houseType = undefined
		},

		setSizeDefault(state) {
			state.houseCapacity = undefined
		},

		setSort(state, action: PayloadAction<{sort: SearchRentalsState['sort']}>) {
			state.page = 1
			state.sort = action.payload?.sort
		},

		// The "setSearch" action used to nuke everything. Let’s keep that logic,
		// because it’s presumably for a full search update.
		setSearch(state, action: PayloadAction<Partial<SearchRentalsState>>) {
			const {features, ...rest} = action.payload
			const newSearch = {
				...state,
				...rest,
				features: {...initialState.features, ...features},
			}
			Object.assign(state, initialState, newSearch)
		},

		setPage(state, action: PayloadAction<SearchRentalsState['page']>) {
			state.page = action.payload
		},

		setPageDefault(state) {
			state.page = initialState.page
		},
	},
})

export const {
	clearFeaturesFilter,
	clearSearch,
	clearSpecialsFilter,
	setFeaturesFilter,
	setHouseName,
	setNew,
	setPage,
	setPageDefault,
	setPriceRangesFilter,
	setPromo,
	setRegionsDefault,
	setRentalPeriod,
	setRentalRegions,
	setRentalSize,
	setRentalType,
	setSearch,
	setSearchPanel,
	setSizeDefault,
	setSort,
	setSpecialsFilter,
	setTypeDefault,
	setTypesFilter,
} = searchRentalsSlice.actions

export default searchRentalsSlice.reducer
