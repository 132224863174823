import React from 'react'
import {LangLink} from 'plugins/i18n'

const Nav: React.FC<{readonly routes: {path: string; label: any}[]}> = ({
	routes,
}) => (
	<div className="grid w-full grid-cols-2 gap-4 md:flex">
		{routes
			? routes.map((route, i) => (
					<div
						className="align-center flex justify-start md:justify-center"
						key={i}
					>
						<LangLink className="text-capGreyFg text-xs" to={route.path}>
							{route.label}
						</LangLink>
					</div>
				))
			: null}
	</div>
)

export default Nav
