import {stringify} from 'jsurl2'
import {
	AGENT_CONTRACT_REPORT,
	BACKOFFICE,
	CONTRACT_PRINT,
	LANDING_PAGES,
	PAYMENT,
	PDF,
	REPORT,
	VOUCHER,
} from '../constants'
import {defaultFieldResolver, GraphQLFieldConfig} from 'graphql'
import {round, uniqBy} from 'lodash-es'
import {INFINITY, INFINITY_PAST} from 'app/_server/database/constants'
import {emptySearch} from 'state/ducks/searchRentals-duck'

import {searchFromValue} from 'plugins/react-router-deluxe/BoundRoute'
import {fixDate, makeSlug} from '.'
import {regionIdToUrlI18nMap} from 'plugins/region-urls'
import serializeNode from 'plugins/rich-text/rawText'
import clsx from 'clsx'
import {twMerge} from 'tailwind-merge'
import type {ClassValue} from 'clsx'

export {default as moment} from './moment-with-range'
export {default as isMostlyEqual} from './isMostlyEqual'

export const defaults = {
	...emptySearch,
	// Old keys we want to get rid of
	housingType: undefined,
	filters: undefined,
}

export const makeQuery = (filters: Partial<SearchRentalsState>) => {
	// remove pool from features
	const resultFilters: Record<string, any> = {...filters}
	if (filters.period) {
		// range to ISO form without time part
		resultFilters.period = null
		resultFilters.begin = fixDate(filters.period.start)
		resultFilters.end = fixDate(filters.period.end)
	}

	// delete resultFilters.lang
	// sort by keys
	return Object.fromEntries(
		Object.keys(resultFilters)
			.sort()
			.map(key => [key, resultFilters[key]])
	)
}

export const round0Dec = (num: number) => Math.round(num)
export const round2Dec = (num: number) => round(num, 2)
export const round3Dec = (num: number) => round(num, 3)
export const round4Dec = (num: number) => round(num, 4)

export const roundRate = (num: number) => round4Dec(num)
export const roundMoney = (num: number) => round2Dec(num)

export const isCapacityInSize = (sizeRange, capacity) => {
	const sizeArr = sizeRange.split('-')
	const from = Number(sizeArr[0])
	const to = Number(sizeArr[1])
	return from <= capacity && capacity <= to
}

export const isNotEmptyObj = obj => Boolean(Object.keys(obj).length)

// checks (deep) if the object is full empty or not
export const isDeepEmpty = val => {
	if (val && typeof val === 'object') {
		const valArr = Object.values(val)
		if (valArr.length) {
			for (val of valArr) {
				if (!isDeepEmpty(val)) {
					return false
				}
			}
			// if all checkDeep(obj) were false = no content found
			return true
		}
		// if the deepest object is empty
		return true
	}
	// if it's not an object
	return !val
}

// filters on condition(item, i) and then adds mapper(item, i) to array if not undefined
// use case: filterMap([...], v => v, (v, i) => ...)
export const filterMap = (data, condition, mapper) =>
	data
		? // eslint-disable-next-line unicorn/no-array-reduce
			data.reduce((acc, value, index) => {
				if (condition(value, index)) {
					const v = mapper(value, index)
					if (v !== undefined) acc.push(v)
				}
				return acc
			}, [])
		: []

/** Returns base path for search in a format: /xx/search -> xx is a lang */
export const searchBasePath = ({lang}: I18nContext | {lang: I18nLang}) =>
	`/${lang}/search`

export const urlForSearch = ({
	i18n,
	filters = {},
}: {
	i18n: I18nContext
	filters?: Partial<SearchRentalsState>
}) => {
	const query = makeQuery(filters)
	return `${searchBasePath(i18n)}${searchFromValue(query, defaults)}`
}

export const urlForHouse = ({
	house,
	lang,
}: {
	house: House
	lang: I18nLang | string
}) => `/${lang}/${house.urlI18n[lang]}`

export const urlForLandingPageBO = (id: number) =>
	`/${BACKOFFICE}/${LANDING_PAGES}/${id}`

export const urlForRegion = ({
	regionId,
	lang,
}:
	| {
			regionId: string | I18nKey
			lang: I18nContext['lang']
	  }
	| (I18nContext & {regionId: string | I18nKey})) => {
	const path = regionIdToUrlI18nMap?.[regionId]?.[lang]

	return path ? `/${lang}/${path}` : undefined
}

export const urlForPost = (
	{loading, lang}: {loading?: boolean; lang: string},
	post: BlogPost,
	prefix = ''
) => {
	if (!post) return
	if (!loading) {
		const {titleI18n} = post
		try {
			return `/${lang}${prefix}/blog/${
				makeSlug(titleI18n?.[lang], false) || makeSlug(titleI18n?.nl, false)
			}`
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(`url for post failed`, err, post)
		}
	}
	return `/${lang}${prefix}/blog`
}

export const calcSizeOpts = (maxCapacity: number) => {
	const sizeOpts: {value: number; label: string}[] = []
	if (maxCapacity >= 2) sizeOpts.push({value: 2, label: '2+'})
	if (maxCapacity >= 4) sizeOpts.push({value: 4, label: '4+'})
	if (maxCapacity >= 6) sizeOpts.push({value: 6, label: '6+'})
	if (maxCapacity >= 8) sizeOpts.push({value: 8, label: '8+'})
	if (maxCapacity >= 10) sizeOpts.push({value: 10, label: '10+'})
	if (maxCapacity >= 12) sizeOpts.push({value: 12, label: '12+'})
	return sizeOpts
}

export const withAgentRights = <Source, Target>(
	options: GraphQLFieldConfig<Source, Target>
) => {
	const origResolve = options.resolve || defaultFieldResolver
	const resolve: typeof origResolve = (root, args, ctx, info) => {
		// @ts-ignore -- TODO GqlContext interface definition
		if (ctx.isAgent) {
			return origResolve(root, args, ctx, info)
		}
		return null
	}
	return {...options, resolve}
}

/**
 * Note: This only works properly if the start/end range is not overlapping a
 * blocked range.
 */
export const firstDatesAroundRange = (
	{start, end}: DateISOStringRange,
	dates: DateISOString[]
): {left: DateISOString; right: DateISOString} => {
	const datesSorted = dates.sort()
	const slicedLeftDates = datesSorted.filter(d => d <= start)
	const slicedRightDates = datesSorted.filter(d => end <= d)

	return {
		left: slicedLeftDates.length
			? slicedLeftDates[slicedLeftDates.length - 1]
			: INFINITY_PAST,
		right: slicedRightDates.length ? slicedRightDates[0] : INFINITY,
	}
}

const pathWithSlug = ({
	list,
	id,
	domain,
	lang: langOrg,
	year,
}: {
	id: string | number
	list: string
	year?: string
	domain?: string
	lang?: string
}) => {
	if (!list || !id)
		throw new Error(
			`pathWithSlug: list: "${list}" or id: "${id}" argument is missing.`
		)

	const lang = langOrg || 'xx'
	const idSlug = stringify(id, {short: true, rich: true})

	return '/' + [lang, domain, list, idSlug, year].filter(Boolean).join('/')
}

export const agentPageBoPath = (id: string | number, lang?: string) =>
	pathWithSlug({lang, domain: BACKOFFICE, list: 'agents', id})

export const agentYearReportPath = (
	id: string | number,
	year: string,
	lang: string
) => pathWithSlug({lang, domain: PDF, list: REPORT, id, year})

export const agentYearReportRenderPdfPath = (
	id: string | number,
	year: string,
	lang: string
) => pathWithSlug({lang, domain: PDF, list: AGENT_CONTRACT_REPORT, id, year})

export const clientPageBoPath = (id: string | number, lang?: string) =>
	pathWithSlug({lang, domain: BACKOFFICE, list: 'customers', id})

export const contractPageBoPath = (id: string | number, lang?: string) =>
	pathWithSlug({lang, domain: BACKOFFICE, list: 'contracts', id})

export const housePageBoPath = (id: string | number, lang?: string) =>
	pathWithSlug({lang, domain: BACKOFFICE, list: 'houses', id})

export const housePagePath = (id: string | number, lang: string) =>
	pathWithSlug({lang, list: `house`, id})

export const contractPrintPath = (id: string | number, lang: string) =>
	pathWithSlug({lang, domain: PDF, list: CONTRACT_PRINT, id})

export const contractVoucherPath = (id: string | number, lang: string) =>
	pathWithSlug({lang, domain: PDF, list: VOUCHER, id})

export const contractPaymentPath = (id: string | number, lang: string) =>
	pathWithSlug({lang, list: PAYMENT, id})

/**
 * Sort (ascending) array of objects by keys (sensitive for numeric/string value
 * types) Returns: Sorted array of objects.
 */
export const sortByKeys = <T extends Record<string, any>>(
	arr: T[],
	sort: Record<string, number>,
	locales?: string,
	options?: Record<string, any>
): T[] => {
	if (!arr?.length) return []
	if (!sort) return arr.sort()

	const compareByKey = (a, b, key, order) => {
		const A = order === 1 ? a[key] : b[key]
		const B = order === 1 ? b[key] : a[key]

		if (!A && !B) return 0

		// if both values exists, but only one is 'string'
		// TODO: Replace it with better code + make tests for false, null and 0 key values as well. https://github.com/StratoKit/CAP/pull/1249#discussion_r741154756
		// @ts-ignore
		if (A && B && (typeof A === 'string') ^ (typeof B === 'string'))
			throw new Error(`Error: A: ${A} and B: ${B} have different types`)

		// if both are strings or one is undefined
		if (typeof A === 'string' || typeof B === 'string')
			return ((A ?? '') as string).localeCompare(B ?? '', locales, options)

		// in all other cases (e.g. two numbers)
		return A - B
	}

	return [...arr].sort((a, b) => {
		let result = 0
		// compare another pair of keys until find a difference in values
		for (const key in sort) {
			if (!key) continue

			const diff = compareByKey(a, b, key, sort[key])
			if (diff) {
				result = diff
				break
			}
		}
		return result
	})
}

/**
 * Make unique objects in array in regards to defined keys.
 *
 * Arr: Array of objects. keysArr: Array of keys of uniqueness. returns: Array
 * of unique objects (in regard to the keys)
 */
export const uniqueByKeys = (
	arr: {[s: string]: any}[],
	keysArr: string[]
): {[s: string]: any}[] => {
	if (!arr?.length || !keysArr?.length) return arr

	return uniqBy(arr, el => JSON.stringify(keysArr.map(key => el[key])))
}

export const stringifyNoFalsy = obj => {
	const json = JSON.stringify(obj, (key, value) => {
		// Remove falsy except '' and 0
		if (!value && value !== '' && value !== 0) return undefined
		return value
	})
	return json === '{}' ? null : json
}

export const randomInRange = (min: number = 1, max: number = 1_000_000) =>
	Math.floor(Math.random() * (max - min) + min)

export const removeRecurse = (obj, keys, values) => {
	if (!obj || typeof obj !== 'object') return
	if (Array.isArray(obj)) {
		for (const c of obj) removeRecurse(c, keys, values)
		return
	}
	for (const key of keys) if (key in obj) delete obj[key]
	for (const [key, value] of Object.entries(obj)) {
		if (values.includes(value)) delete obj[key]
		removeRecurse(value, keys, values)
	}
}

const stringifyJsonArray = arr => (arr?.length ? JSON.stringify(arr) : null)
const parseJsonArray = v => (v == null ? [] : JSON.parse(v))
/** Make an array column. */
export const jsonArrayColumn = (o?: Object) => ({
	...o,
	type: 'JSON',
	parse: parseJsonArray,
	stringify: stringifyJsonArray,
})

export const orderByIdsArray = <T extends {id: string}>(
	orderedIdsArr: string[],
	objectsArrToSort: T[]
): T[] => {
	const indexedVisitedHouses = {}
	for (const item of objectsArrToSort) {
		indexedVisitedHouses[item.id] = item
	}

	const restoredVisitedHousesOrder = orderedIdsArr.map(
		id => indexedVisitedHouses[id]
	)

	return restoredVisitedHousesOrder
}

export const removeSpecialChars = (str: string) =>
	str
		.split(' ')
		.map(word => makeSlug(word))
		.join(' ')

/**
 * Shortens a given description to a specified maximum number of characters. If
 * the description is a node, it will be serialized first.
 */
export const shortenDescription = (
	description: string | object,
	maxChars: number
) => {
	const serializedDesc =
		typeof description === 'object' ? serializeNode(description) : description

	let shortDescription = serializedDesc.slice(0, Math.max(0, maxChars))
	shortDescription =
		shortDescription.slice(
			0,
			Math.max(
				0,
				Math.min(shortDescription.length, shortDescription.lastIndexOf(' '))
			)
		) + '...'
	return shortDescription
}

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}
