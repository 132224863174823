import React from 'react'
import styled, {css} from 'styled-components'
import {makeSplitPoint} from 'plugins/react'
import {moment, ensureRange} from 'app/lib'

const DateRangePicker = makeSplitPoint(() => import('react-daterange-picker'))

const selectColor = 'rgb(68, 85, 36)'
const selectBorderColor = 'rgba(68, 85, 36, .6)'
const dayColor = '#D3D4CA'
const weekendColor = '#CFD1C8'
const arrowSize = 8
const selectionRadius = 10
const selectionMargin = 0

// To update, copy the react-calendar.css from the distro and fix the vars
const origCss = css`
	.DateRangePicker {
		display: inline-block;
		margin-bottom: 10px;
		padding: 0;
		position: relative;
		user-select: none;

		&__Legend {
			color: #52575e;
			font-size: 14px;
			line-height: 16px;
			list-style-type: none;
			margin: 20px 0;
			padding: 0;
		}

		&__LegendItem {
			display: inline-block;
			margin: 0 20px;
		}

		&__LegendItemColor {
			border-radius: 50%;
			display: inline-block;
			height: 14px;
			margin-right: 6px;
			vertical-align: text-bottom;
			width: 14px;
			border: thin solid rgba(#000, 0.25);

			&--selection {
				background-color: #ed5434;
			}
		}

		&__CalendarYear {
			text-align: center;
		}

		&__PaginationArrow {
			border: 0;
			cursor: pointer;
			display: block;
			height: 35px;
			outline: none;
			overflow: hidden;
			padding: 0;
			position: absolute;
			text-align: center;
			top: 0;
			white-space: nowrap;
			width: 35px;
			z-index: 1;

			&--previous {
				left: 20px;
			}

			&--next {
				right: 20px;
			}

			&:hover {
				background-color: #ccc;
			}
		}

		&__PaginationArrowIcon {
			border-bottom: ${arrowSize}px solid transparent;
			border-top: ${arrowSize}px solid transparent;
			height: 0;
			position: absolute;
			top: 10px;
			width: 0;

			&--is-disabled {
				opacity: 0.25;
			}

			&--previous {
				border-left: ${arrowSize}px solid transparent;
				border-right: ${arrowSize}px solid #aaa;
				right: ${15 - arrowSize / 2}px;
			}

			&--next {
				border-left: ${arrowSize}px solid #aaa;
				border-right: ${arrowSize}px solid transparent;
				left: ${15 - arrowSize / 2}px;
			}
		}

		&__Month {
			color: #333;
			display: inline-block;
			padding: 0 20px;
			position: relative;
			user-select: none;
			width: 275px;
		}

		&__MonthHeader {
			color: #000;
			font-size: 14px;
			font-weight: bold;
			height: 35px;
			line-height: 35px;
			position: relative;
			text-align: center;
		}

		&__MonthHeaderLabel {
			display: inline-block;
			position: relative;
		}

		&__MonthHeaderSelect {
			background: #e4e4e4;
			border: 0;
			cursor: pointer;
			display: inline-block;
			height: 100%;
			left: 0;
			margin: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 5;
		}

		&__MonthDates {
			border-bottom: thin solid #f4f5f6;
			border-collapse: separate;
			border-spacing: 0 thin;
			margin: 0;
			width: 100%;

			&--noHover {
				pointer-events: none;
			}
		}

		&__WeekdayHeading,
		&__Date {
			font-size: 12px;
			line-height: 1;
			padding: 10px 0;
			text-align: center;
			width: 14.285714285714286%;
		}

		&__WeekdayHeading {
			border-bottom: thin solid #f4f5f6;
			color: #000;
			font-weight: bold;

			abbr[title] {
				border-bottom-width: 0;
				color: #000;
				cursor: pointer;
				font-size: inherit;
				text-decoration: none;
			}
		}

		&__Date {
			border: 0 solid #f4f5f6;
			border-right-width: thin;
			cursor: pointer;
			overflow: hidden;
			position: relative;

			&:first-child {
				border-left-width: thin;
			}

			&--weekend {
				background-color: #f6f7f9;
			}

			&--otherMonth {
				opacity: 0.25;
			}

			&--is-disabled {
				color: #cdcdd1;
				cursor: default;
			}

			&--is-selected {
				color: #fff;
			}

			&--is-highlighted {
				color: #333;
			}
		}

		&__CalendarDatePeriod {
			bottom: 0;
			position: absolute;
			top: 0;

			&--am {
				left: 0;
				right: 50%;
			}

			&--pm {
				left: 50%;
				right: 0;
			}
		}

		&__CalendarSelection {
			background-color: #ed5434;
			border: thin solid darken(#ed5434, 5);
			bottom: ${selectionMargin}px;
			left: 0;
			position: absolute;
			right: 0;
			top: ${selectionMargin}px;

			&--inOtherMonth {
				opacity: 0.5;
			}

			&--start {
				border-bottom-left-radius: ${selectionRadius}px;
				border-right-width: 0;
				border-top-left-radius: ${selectionRadius}px;
				left: ${selectionMargin}px;
			}

			&--end {
				border-bottom-right-radius: ${selectionRadius}px;
				border-left-width: 0;
				border-top-right-radius: ${selectionRadius}px;
				right: ${selectionMargin}px;
			}

			&--segment {
				border-left-width: 0;
				border-right-width: 0;
			}

			&--single {
				border-radius: ${selectionRadius}px;
				left: ${selectionMargin}px;
				right: ${selectionMargin}px;
			}

			&--is-pending {
				background-color: rgba(#ed5434, 0.75);
				border-width: 0;
			}
		}

		&__CalendarHighlight {
			background-color: rgba(255, 255, 255, 0.25);
			border: thin solid rgba(0, 0, 0, 0.25);
			bottom: ${selectionMargin}px;
			left: 0;
			position: absolute;
			right: 0;
			top: ${selectionMargin}px;

			&--inOtherMonth {
				opacity: 0.5;
			}

			&--start {
				border-bottom-left-radius: ${selectionRadius}px;
				border-right-width: 0;
				border-top-left-radius: ${selectionRadius}px;
				left: ${selectionMargin}px;
			}

			&--end {
				border-bottom-right-radius: ${selectionRadius}px;
				border-left-width: 0;
				border-top-right-radius: ${selectionRadius}px;
				right: ${selectionMargin}px;
			}

			&--segment {
				border-left-width: 0;
				border-right-width: 0;
			}

			&--single {
				background-color: #fff;
				border: thin solid darken(#ed5434, 5);
				border-radius: ${selectionRadius}px;
				left: ${selectionMargin}px;
				right: ${selectionMargin}px;
			}
		}

		&__HalfDateStates {
			bottom: -50px;
			left: -50px;
			position: absolute;
			right: -50px;
			top: -50px;
			transform: rotate(30deg);
		}

		&__FullDateStates {
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		&__DateLabel {
			display: block;
			position: relative;
			text-align: center;
			width: 100%;
			z-index: 1;
		}
	}
`

const DatePickerDom = styled.div`
	width: 100%;
	margin: 7px auto;
	text-align: center;

	${origCss};
	.DateRangePicker {
		text-align: center;

		&__Legend {
			color: #000;
			/*			display: inline-block;
			width: 100%;
			text-align: center;*/
		}
		&__LegendItemColor {
			&--select {
				background-color: ${selectColor};
			}
		}
		&__CalendarSelection {
			background-color: ${selectColor};
			border: thin solid ${selectBorderColor};
			&--is-pending {
				background-color: ${selectBorderColor};
			}
		}
		&__CalendarHighlight {
			&--single {
				border: thin solid ${selectBorderColor};
			}
		}

		&__Date {
			background: ${dayColor};
			&--weekend {
				background-color: ${weekendColor};
			}
		}

		/*
		&__MonthHeader {
			text-align: left;
			height: auto;
			line-height: 1.6em;
			color: rgba(0,0,0,.87) !important;
			font-weight: 600;
		}

		&__WeekdayHeading {
			padding: .2em 0;
			font-weight: 400;
			abbr[title] {
				color: rgba(0,0,0,.54);
			}
		}
		&__WeekdayHeading,
		&__MonthDates {
			border: none;
		}
		&__MonthDates {
			border-spacing: 0 4px;
		}
		&__Date {
			color: rgba(0,0,0,.87);
			border: none;
			padding: .3em 0;
		}*/
	}
`

const StyledDatePicker = ({
	minimumDate,
	maximumDate,
	className,
	value: valueOrg,
	...props
}: Partial<DateRangePickerPropsType>) => {
	return (
		<DatePickerDom className={className}>
			<DateRangePicker
				locale={moment.locale()}
				{...props}
				value={valueOrg || undefined}
				minimumDate={minimumDate && new Date(minimumDate)}
				maximumDate={maximumDate && new Date(maximumDate)}
			/>
		</DatePickerDom>
	)
}

export default StyledDatePicker
