import React from 'react'
import {useSelector, useDispatch} from 'plugins/redux/hooks'
import {toggleFavorites} from 'state/ducks/favoritesAndShare-duck'
import {useI18n, LangLink} from 'plugins/i18n'
import {shortenDescription, urlForHouse} from 'app/lib'
import {
	CapacityIcon,
	ShowerIcon,
	BedIcon,
	FavIcon,
	StarIconNew,
} from 'app/Components/Icon'
import {Image} from 'plugins/images'
import {fbTrack} from 'plugins/trackers'
import Text from '../twComponents/Text'
import {MoneyValue} from '../Numbers'
import {calcMinMaxPrices} from 'app/lib/calcPrice'

const NewRentalImage = ({id, isNew, isPromo, score, ...props}) => {
	const favorites = useSelector(state => state.favoritesAndShare)
	const dispatch = useDispatch()
	const {getText} = useI18n()

	const isNewOrPromo = isPromo ? 'promo' : isNew ? 'new' : null
	return (
		<div className="relative">
			<Image ratio={16 / 9} {...props} />
			{score && score !== 'NaN' ? (
				<div className="group bg-secondary text-onSecondary absolute top-4 right-14 flex h-8 items-center justify-center gap-1 rounded-md px-2 text-base leading-6">
					<StarIconNew />
					{score}
				</div>
			) : null}
			{isNewOrPromo ? (
				<div className="group bg-onSecondary text-secondary absolute top-4 left-4 flex h-8 items-center justify-center rounded-md px-2 leading-6">
					{getText(isNewOrPromo)}
				</div>
			) : null}
			<div className="bg-secondary absolute top-4 right-4 flex h-8 w-8 items-center justify-center rounded-md">
				<FavIcon
					active={favorites && Boolean(favorites[id])}
					onClick={e => {
						e.preventDefault()
						if (!favorites[id]) {
							fbTrack('AddToWishlist')
						}
						dispatch(toggleFavorites(id))
					}}
				/>
			</div>
		</div>
	)
}

const NewHouseCard = ({house}) => {
	const {getText, lang} = useI18n()

	const {
		bathroomCount,
		bedroomCount,
		capacity,
		countryId,
		description,
		id,
		isNew,
		isPromo,
		mainImage,
		name,
		placeName,
		regionId,
		score,
	} = house

	const {minPricePerWeek, maxPricePerWeek} = calcMinMaxPrices(house)

	const tagsTranslated =
		(mainImage && mainImage.tags && mainImage.tags.map(t => getText(t))) || []
	const alt = [
		getText(countryId),
		getText(regionId),
		tagsTranslated.join(''),
		name,
	]
		.filter(Boolean)
		.join(' – ')

	const shortDescription = shortenDescription(description, 100)

	return (
		<div className="w-full">
			<LangLink to={urlForHouse({house, lang})} aria-label={alt}>
				<NewRentalImage
					{...{
						alt,
						id,
						isNew,
						isPromo,
						score,
						value: mainImage,
					}}
				/>
			</LangLink>
			<div className="text-onBackground flex flex-col p-4">
				<span className="flex flex-col gap-1 pb-4">
					<span className="text-lg font-bold">{house.name}</span>
					<span className="text-sm">
						{[placeName, getText(regionId)].filter(Boolean).join(', ')}
					</span>
					<Text variant="small">
						{minPricePerWeek === maxPricePerWeek ? (
							<MoneyValue value={minPricePerWeek} />
						) : (
							<>
								<MoneyValue value={minPricePerWeek} /> –{' '}
								<MoneyValue value={maxPricePerWeek} />
							</>
						)}
						{' / '}
						{getText('week')}
					</Text>
				</span>
				<p className="text-sm">{shortDescription}</p>
				<div className="my-4 flex w-full flex-row justify-around">
					<div className="flex w-full flex-col items-center justify-center">
						<CapacityIcon width="1.5rem" height="1rem" />
						<span>{capacity}</span>
						<span className="text-xs">{getText('persons')}</span>
					</div>
					<div className="flex w-full flex-col items-center justify-center">
						<BedIcon width="1.5rem" height="1.25rem" />
						<span>{bedroomCount}</span>
						<span className="text-xs">{getText('bedrooms')}</span>
					</div>
					<div className="flex w-full flex-col items-center justify-center">
						<ShowerIcon width="1.5rem" height="1.25rem" />
						<span>{bathroomCount}</span>
						<span className="text-xs">{getText('bathrooms')}</span>
					</div>
				</div>

				<LangLink
					to={urlForHouse({house, lang})}
					aria-label={alt}
					className="w-full"
				>
					<button
						type="button"
						className="bg-primary text-onPrimary w-full rounded-md p-2"
					>
						{getText('viewDetails')}
					</button>
				</LangLink>
			</div>
		</div>
	)
}

export default NewHouseCard
