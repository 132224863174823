/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import {css, ThemeProvider as ThemeProviderBase} from 'styled-components'
import {TinyColor} from '@ctrl/tinycolor'
import {shallowEqual} from 'react-redux'
import {useSelector} from 'plugins/redux/hooks'

export {
	default as styled,
	css,
	keyframes,
	createGlobalStyle,
	withTheme,
} from 'styled-components'

const differColor = c => (c.isLight ? c.darken() : c.lighten())

export const makeColors = (
	args: {
		bg?: string
		/** Ternary: true: use inverted (white) text color, false: not, null: auto */
		whiteFg?: boolean
		/** Force fg color */
		fg?: string
		/** Force secondary fg color */
		secondary?: string
		/** Force hint fg color */
		hint?: string
	},
	/** Make the colors more different */
	differ?: boolean
) => {
	if (typeof args === 'string') args = {bg: args}
	let {
		// Theme background colors
		bg = 'rgba(255, 255, 255, 0)',

		// Ternary: true: use inverted (white) text color, false: not, null: auto
		whiteFg,

		// Text colors, force
		fg,
		secondary,
		hint,
	} = args
	// Darker/lighter settings
	const mainC = new TinyColor(bg)
	if (differ) differColor(mainC)
	const fgC = fg && new TinyColor(fg)

	// if fg defined, use it, otherwise, use dark or white
	if (whiteFg == null) whiteFg = fgC ? fgC.isLight() : mainC.isDark()

	// Default colors text/textInverted
	const textC =
		fgC || new TinyColor(whiteFg ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,.87)')
	fg = textC.toRgbString()
	if (!secondary)
		secondary = textC
			.clone()
			// White text needs less opacity
			.setAlpha(whiteFg ? 0.7 : 0.45)
			.toRgbString()
	if (!hint)
		hint = textC
			.clone()
			.setAlpha(whiteFg ? 0.25 : 0.22)
			.toRgbString()

	return {
		bg: mainC.toRgbString(),
		fg,
		secondary,
		hint,
	}
}

export type ClrSet = ReturnType<typeof makeColors>
export type ClrSets = Record<string, ClrSet>
export type ColorProps = {
	colorSet?: string
	useSet?: ClrSets
	accent?: boolean
	secondary?: boolean
	hoverPrimary?: boolean
	hint?: boolean
	fgColor?: string
	bgColor?: string
	theme?: {colorSets: ClrSets}
}

// These helpers implement these attributes:
//  <SC
//    [colorSet="app color name"] [useSet="app color set"]
//    [accent] [secondary] [hoverPrimary] [hint]
//    [fgColor="..."] [bgColor="..."]
//  />
// Colors are defined in theme.colorSets
// Semantic set names point to colors in colorSets
// Hover is automatically added to get the hover color
export const getColors = (
	{useSet, colorSet: forceColorSet, theme, accent}: ColorProps,
	forceSet?: string,
	hover?: boolean
) => {
	const {colorSets} = theme!
	let colorName, setName
	if (forceColorSet) {
		colorName = forceColorSet
	} else {
		setName = forceSet || useSet || (accent ? 'accent' : 'default')
		if (!theme![setName + 'Set']) {
			// eslint-disable-next-line no-console
			console.error(new Error(`Unknown colorSet ${setName}Set`))
			setName = 'default'
		}
		colorName = theme![setName + 'Set']
	}
	let set: ClrSet | undefined
	if (hover) {
		const hoverName =
			(setName && theme![setName + 'HoverSet']) || colorName + 'Hover'
		set = colorSets[hoverName]
	}
	if (!set) {
		set = colorSets[colorName] || colorSets.default!
	}
	return set!
}
const fgSelector = (p: ColorProps, hover?: boolean) =>
	hover && p.hoverPrimary
		? 'fg'
		: p.secondary
			? 'secondary'
			: p.hint
				? 'hint'
				: 'fg'
export const getFg = (p: ColorProps, forceSet?: string, hover?: boolean) =>
	p.fgColor || getColors(p, forceSet, hover)[fgSelector(p, hover)]
export const getSecondary = (
	p: ColorProps,
	forceSet?: string,
	hover?: boolean
) => getColors(p, forceSet, hover).secondary
export const getHint = (p: ColorProps, forceSet?: string, hover?: boolean) =>
	getColors(p, forceSet, hover).hint
export const getBg = (p: ColorProps, forceSet?: string, hover?: boolean) =>
	p.bgColor || getColors(p, forceSet, hover).bg
export const getHoverFg = (p: ColorProps, forceSet?: string) =>
	getFg(p, forceSet, true)
export const getHoverBg = (p: ColorProps, forceSet?: string) =>
	getBg(p, forceSet, true)

export const colorCss = css`
	color: ${getFg};
	background-color: ${getBg};
`
export const hoverColorCss = css`
	&:hover {
		color: ${getHoverFg};
		background-color: ${getHoverBg};
	}
`

const merge = (old, cur) => {
	const res = {...old}
	for (const key of Object.keys(cur)) {
		const val = cur[key]
		res[key] =
			res[key] == null ||
			typeof val !== 'object' ||
			typeof res[key] !== 'object' ||
			Array.isArray(val)
				? val
				: merge(res[key], val)
	}
	return res
}

export const mergeThemes =
	(...themes) =>
	parent => {
		for (const theme of themes) {
			if (!theme) continue
			parent = merge(parent, theme)
		}
		return parent
	}

export const ThemeProvider: React.FC<{
	readonly themes?: object[]
	readonly theme?: object
	readonly children: React.ReactNode
}> = ({themes, theme, children}) => {
	const {isMobile} = useResponsive('isMobile')
	const mergedTheme =
		(themes ? mergeThemes(...themes, theme, {isMobile}) : theme) || {}

	return (
		<ThemeProviderBase theme={mergedTheme}>
			<>{children}</>
		</ThemeProviderBase>
	)
}

// https://www.w3.org/TR/CSS1/#url
// Parentheses, commas, whitespace characters, single quotes (') and double quotes (") appearing in a URL must be escaped with a backslash
export const makeCssUrl = href =>
	href && `url(${href.replace(/[\s"'(),]/g, c => `\\${c}`)})`

export const useResponsive = (...names) =>
	useSelector(
		state => Object.fromEntries(names.map(n => [n, state.responsive[n]])),
		shallowEqual
	)
