import React, {useEffect, ReactNode} from 'react'
import {useHistory, useLocation, Location} from 'react-router-dom'

const LocationListener: React.FC<{
	readonly children?: ReactNode
	readonly onChange?: (location: Location) => void
	readonly changeOnMount?: boolean
}> = ({children, onChange, changeOnMount}) => {
	const history = useHistory()
	const location = useLocation()

	useEffect(() => {
		const unlisten = history.listen(loc => {
			if (onChange) onChange(loc)
		})

		if (changeOnMount && onChange) {
			onChange(location)
		}

		return () => {
			unlisten()
		}
	}, [history, location, onChange, changeOnMount])

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children || null}</>
}

export default LocationListener
