import React from 'react'
import {ArrowLeftIconNew, ArrowRightIconNew} from 'app/Components/Icon'
import {Link} from 'plugins/react-router-deluxe'

const ArrowWrapper: React.FC<{
	readonly onClick?: () => void
	readonly disabled?: boolean
	readonly ariaLabel: string
	readonly to?: string
	readonly flip?: boolean
}> = ({onClick, disabled, ariaLabel, to, flip}) => {
	const baseClasses = `w-8 h-8 flex items-center justify-center border border-gray-400 rounded-lg`
	const disabledClasses = disabled ? 'cursor-not-allowed' : ''

	const Icon = flip ? <ArrowLeftIconNew /> : <ArrowRightIconNew />

	if (to && !disabled) {
		return (
			<Link
				to={to}
				className={`${baseClasses} ${disabledClasses}`}
				aria-label={ariaLabel}
			>
				{Icon}
			</Link>
		)
	}

	return (
		<button
			type="button"
			onClick={onClick}
			disabled={disabled}
			className={`${baseClasses} ${disabledClasses}`}
			aria-label={ariaLabel}
		>
			{Icon}
		</button>
	)
}

const PagerNumbers: React.FC<{
	readonly current: number
	readonly centerPageNr: number | false
	readonly isFirst: boolean
	readonly isLast: boolean
	readonly leftPageNr: number
	readonly onPageClick: (pageNr: number) => void
	readonly rightPageNr: number | false
	readonly total: number
}> = ({
	current,
	centerPageNr,
	isFirst,
	isLast,
	leftPageNr,
	onPageClick,
	rightPageNr,
	total,
}) => {
	const showLastPageNr = rightPageNr && rightPageNr < total
	return (
		<div className="flex max-w-72 items-center justify-center gap-6">
			<div
				className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg ${
					current === leftPageNr ? 'bg-onBackground text-white' : 'text-[#888]'
				}`}
				onClick={() => !isFirst && onPageClick(leftPageNr)}
			>
				{leftPageNr}
			</div>
			{centerPageNr ? (
				<div
					className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg ${
						current === centerPageNr
							? 'bg-onBackground text-white'
							: 'text-[#888]'
					}`}
					onClick={() => (isFirst || isLast) && onPageClick(centerPageNr)}
				>
					{centerPageNr}
				</div>
			) : null}
			{rightPageNr ? (
				<div
					className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg ${
						current === rightPageNr
							? 'bg-onBackground text-white'
							: 'text-[#888]'
					}`}
					onClick={() => !isLast && onPageClick(rightPageNr)}
				>
					{rightPageNr}
				</div>
			) : null}
			{showLastPageNr ? (
				<>
					<div>…</div>

					<div
						className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg text-black"
						onClick={() => onPageClick(total)}
					>
						{total}
					</div>
				</>
			) : null}
		</div>
	)
}

const ItemPager: React.FC<{
	readonly current: number
	readonly nextUrl?: string
	readonly onNext?: () => void
	readonly onPageClick: (pageNr: number) => void
	readonly onPrev?: () => void
	readonly prevUrl?: string
	readonly total: number
}> = ({current, nextUrl, onNext, onPageClick, onPrev, prevUrl, total}) => {
	const isFirst = current === 1
	const isLast = current === total
	const canGoNext = current < total
	const canGoPrev = current > 1
	const hasCenter = total > 1
	const hasRight = total > 2

	const leftPageNr = isFirst
		? current
		: isLast && hasRight
			? current - 2
			: current - 1
	const centerPageNr = hasCenter
		? isFirst
			? current + 1
			: isLast && hasRight
				? current - 1
				: current
		: false
	const rightPageNr = hasRight
		? isFirst
			? current + 2
			: isLast
				? current
				: current + 1
		: false

	const handlePrev = () => {
		if (onPrev) {
			onPrev()
		} else {
			onPageClick(current - 1)
		}
	}

	const handleNext = () => {
		if (onNext) {
			onNext()
		} else {
			onPageClick(current + 1)
		}
	}

	return (
		<div className="flex items-center justify-between gap-6">
			<ArrowWrapper
				onClick={canGoPrev ? handlePrev : undefined}
				to={prevUrl}
				disabled={!canGoPrev}
				ariaLabel="previous page"
				flip
			/>
			<PagerNumbers
				current={current}
				centerPageNr={centerPageNr}
				isFirst={isFirst}
				isLast={isLast}
				leftPageNr={leftPageNr}
				onPageClick={onPageClick}
				rightPageNr={rightPageNr}
				total={total}
			/>
			<ArrowWrapper
				onClick={canGoNext ? handleNext : undefined}
				to={nextUrl}
				disabled={!canGoNext}
				ariaLabel="next page"
			/>
		</div>
	)
}

export default ItemPager
