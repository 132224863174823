import wrapApp from './react/wrapApp'

const initClientHooks = hooks => {
	hooks.render.initRenderContext.tap('i18n', ctx => {
		// hacky side channel to tell the provider about our lang
		// TODO this can go in context, right?
		globalThis.ssrLang = ctx.state.lang
	})
	hooks.render.wrapApp.tap('i18n', wrapApp)
}

export default initClientHooks
