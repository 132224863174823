export const sortHousesByPriceAndCapacity = (args: {
	houses: FullHouse[]
	capacity?: number
	price?: number | false
}): FullHouse[] => {
	const {houses, capacity, price} = args

	if (!capacity && !price) return houses

	const sortedHouses = houses.sort((a, b) => {
		const aPrices = a.prices && a.prices.map(p => p.sell).filter(Boolean)
		const aPrice = aPrices.length > 0 && Math.min(...aPrices)

		const bPrices = b.prices && b.prices.map(p => p.sell).filter(Boolean)
		const bPrice = bPrices.length > 0 && Math.min(...bPrices)

		if (aPrice !== bPrice && aPrice && bPrice) {
			if (price) {
				// if the house > 20% cheaper than the provided price
				// it's less preferred (probably it's less luxurious)
				const priceThreshold = price * 0.2
				const isABelowTreshhold = aPrice < price - priceThreshold
				const isBBelowTreshhold = bPrice < price - priceThreshold
				if (isABelowTreshhold && isBBelowTreshhold) {
					return aPrice - bPrice
				}
				if (isABelowTreshhold) return 1
				if (isBBelowTreshhold) return -1
			}

			if (a.capacity === b.capacity) {
				return aPrice - bPrice
			}
			if (a.capacity !== b.capacity) {
				if (capacity) {
					// if the house has more capacity than capacity + 1
					// it's less preferred (probably it's less luxurious)
					const capacityThreshold = capacity + 1
					const isAAboveTreshhold = a.capacity > capacityThreshold
					const isBAboveTreshhold = b.capacity > capacityThreshold
					if (isAAboveTreshhold && isBAboveTreshhold) {
						return aPrice - bPrice
					}
					if (isAAboveTreshhold) return 1
					if (isBAboveTreshhold) return -1
				}

				return aPrice - bPrice
			}
		}

		// if price is the same, or no price provided
		// sort by capacity
		// prefer smaller houses first as probably
		// they are more luxurious for the same price
		return a.capacity - b.capacity
	})
	return sortedHouses
}
