import {validators} from 'app/Components/Form'
import {isEmpty, get, groupBy} from 'lodash-es'
import {calcDueDates, fixDate, isDeepEmpty} from 'app/lib'
import {
	calcFirstAvailableDate,
	calcOptionPrice,
	calcPrices,
	deriveOptions,
} from 'app/lib/calcPrice'
import {
	PER_PERSON,
	PER_PERSONWEEK,
	PER_PERSONWEEK_PRORATED,
} from 'app/_server/database/constants'

const capacityExceededMsg = 'capacityExceeded'
const fieldIsRequiredMsg = 'required'

export const validate = (
	values: {
		guests: GuestsFormType
		dateRange: Contract['dateRange']
		personalDetails
	},
	props: {
		capacity: House['capacity']
		hasInsurance: Contract['hasInsurance']
	}
) => {
	const {guests, dateRange, personalDetails} = values || {}
	const {capacity, hasInsurance} = props
	const errors = {
		guests: {},
		personalDetails: {
			address: {},
			insurance: {},
		},
		// TODO as ReservationFormType
	} as any

	const adults = Number(guests?.adults) || 0
	const kids = Number(guests?.kids) || 0
	const guestsTotal = adults + kids

	let maxStep = 0

	if (dateRange?.start && dateRange.end) {
		maxStep = 1
	} else {
		errors.dateRange = fieldIsRequiredMsg
	}

	if (guests) {
		if (adults <= 0) errors.guests.adults = 'leastOneAdultReq'
		if (kids < 0) errors.guests.kids = fieldIsRequiredMsg
		if (guestsTotal > capacity) {
			errors.guests.adults = capacityExceededMsg
			if (kids) errors.guests.kids = capacityExceededMsg
		}
		if (maxStep === 1 && isEmpty(errors.guests)) maxStep = 2
	} else {
		errors.guests = {adults: fieldIsRequiredMsg}
	}

	if (personalDetails) {
		const {
			personalDetails: {
				firstName,
				lastName,
				address = {},
				email,
				insurance = {},
			},
		} = values

		if (!firstName) errors.personalDetails.firstName = fieldIsRequiredMsg
		if (!lastName) errors.personalDetails.lastName = fieldIsRequiredMsg
		if (!address.street)
			errors.personalDetails.address.street = fieldIsRequiredMsg
		if (!address.pcode)
			errors.personalDetails.address.pcode = fieldIsRequiredMsg
		if (!address.city) errors.personalDetails.address.city = fieldIsRequiredMsg
		if (!address.countryId)
			errors.personalDetails.address.countryId = fieldIsRequiredMsg

		if (hasInsurance) {
			// The amount of guest fields is dynamic, based on guestsTotal.
			for (let i = 0; i < guestsTotal; i++) {
				const key = `guest${i + 1}`
				errors.personalDetails.insurance[key] = {}
				if (i > 0) {
					if (!get(insurance, `[${key}].firstName`))
						errors.personalDetails.insurance[key].firstName = fieldIsRequiredMsg
					if (!get(insurance, `[${key}].lastName`))
						errors.personalDetails.insurance[key].lastName = fieldIsRequiredMsg
				}
				if (!get(insurance, `[${key}].birthYear`))
					errors.personalDetails.insurance[key].birthYear = fieldIsRequiredMsg
			}
		}

		errors.personalDetails.email = email
			? validators.email(email)
			: validators.required(email)

		if (maxStep === 2 && isDeepEmpty(errors.personalDetails)) maxStep = 3
	}

	return {
		reservationForm: {_error: {maxStep}},
		...errors,
	}
}

const makeExtrasWithGuestAndPetsCount = (
	extras: Extra[],
	guestsToCharge: Contract['adults'],
	pets: Contract['pets']
): Extra[] => {
	if (!extras?.length) return []

	let newExtras: Extra[] = []
	for (const e of extras) {
		const newE = {...e}
		if (
			e.required &&
			e.per &&
			[PER_PERSON, PER_PERSONWEEK, PER_PERSONWEEK_PRORATED].includes(e.per)
		)
			newE.count = guestsToCharge

		if (e.pet || e.optionId === 'xt$huisdier' || e.optionId === 'xt$dieren')
			newE.count = pets

		newExtras.push(newE)
	}

	return newExtras
}

const calcExtrasPrices = (
	houseExtras: Extra[],
	stayDays: number
): ExtrasPricesByCondition => {
	let prices = {} as ExtrasPricesByCondition
	for (const extra of houseExtras) {
		if (!(extra.required || extra.count)) continue
		const price = calcOptionPrice(extra, stayDays, false)
		// creating an object of 'condition: prices sum of this condition'
		if (price)
			prices[extra.condition] = {
				...prices[extra.condition],
				[extra.optionId]: price,
				extraTypeSum: (prices[extra.condition]?.extraTypeSum || 0) + price,
			}
	}
	return prices
}

export const makeContractCalcProps = ({
	dateRange,
	chosenExtrasObj,
	guests,
	hasInsurance,
	house,
	taxPlatform,
	rentalFees,
	forcePrices,
}: {
	dateRange?: Contract['dateRange'] | DateISOStringRange
	chosenExtrasObj: ExtrasObj
	guests?: Partial<GuestsFormType>
	hasInsurance: Contract['hasInsurance']
	house: House
	taxPlatform?: TouristTaxPlatform
	rentalFees?: Contract['rentalFees']
	forcePrices?: ContractPricesSell & ContractPricesCommon
}): ExtraContractPropsType['contractCalc'] => {
	const {adults = 0, kids = 0, babies = 0, pets = 0} = guests || {}
	const guestsTotal = adults + kids + babies
	const guestsToCharge = adults + kids || 2

	const firstAvailableDate = house
		? calcFirstAvailableDate(house.prices)
		: undefined

	const blockedRanges = house?.blockedRanges?.map(res => ({
		...res,
		type: 'BLOCK',
	}))

	const houseExtrasObj = Object.fromEntries(
		(house?.extras || []).map(e => [e.optionId, e])
	)

	const housePlusChosenExtrasObj = {} as ExtrasObj
	for (const optionId of [
		...Object.keys(houseExtrasObj),
		...Object.keys(chosenExtrasObj || {}),
	]) {
		housePlusChosenExtrasObj[optionId] = {
			...houseExtrasObj[optionId],
			...chosenExtrasObj[optionId],
		}
	}

	const allExtras =
		makeExtrasWithGuestAndPetsCount(
			Object.values(housePlusChosenExtrasObj),
			guestsToCharge,
			pets
		) || []

	const groupedExtrasByCond = groupBy(
		allExtras,
		'condition'
	) as ExtrasGroupedByCondition

	const requiredExtras = allExtras.filter(extra => extra.required)
	const mergedExtras = allExtras.map(e =>
		e.required
			? e
			: chosenExtrasObj[e.optionId]
				? {...e, ...chosenExtrasObj[e.optionId]}
				: e
	)
	const selectedExtras = deriveOptions({options: mergedExtras})

	const optionalExtrasById = Object.fromEntries(
		mergedExtras.filter(e => !e.required && !e.pet).map(e => [e.optionId, e])
	)

	const hasDateRange = !!(dateRange?.start && dateRange.end)

	const prices =
		house &&
		hasDateRange &&
		(forcePrices ||
			calcPrices(
				house,
				{
					beginDate: fixDate(dateRange.start),
					endDate: fixDate(dateRange.end),
					options: selectedExtras,
					rentalFees,
					hasInsurance,
					derived: true,
					adults,
					kids,
					babies,
					hasTouristTax: true,
				},
				taxPlatform
			))

	const {stayDays = 0} = prices || {}

	const extrasPrices = hasDateRange && calcExtrasPrices(mergedExtras, stayDays)

	const {payThere, switchDay} = house || {}

	const dueDates =
		hasDateRange && prices
			? calcDueDates({
					contractDate: fixDate(new Date()),
					beginDate: fixDate(new Date(dateRange.start)),
					prices,
				})
			: []

	const deposit = dueDates?.find(d => d.isDeposit)
	const total = dueDates?.find(d => !d.isDeposit)
	const guarantee = dueDates?.find(d => d.isGuarantee)

	// payments prices & due dates
	const depositPayDate = deposit?.date
	const totalPayDate = total?.date
	// total + guarantee - deposit
	const sellTotalToPayBalance =
		((guarantee || total)?.amount || 0) - (deposit?.amount || 0)

	const fieldsFromContract = {
		prices: prices || {},
		hasInsurance,
		rentalFees,
	}

	const fieldsFromHouse = {
		payThere,
		switchDay,
	}

	return {
		...fieldsFromContract,
		...fieldsFromHouse,
		blockedRanges,
		depositPayDate,
		totalPayDate,
		sellTotalToPayBalance,
		extrasPrices,
		firstAvailableDate,
		groupedExtrasByCond,
		guests,
		guestsToCharge,
		guestsTotal,
		optionalExtrasById,
		requiredExtras,
		selectedExtras,
	}
}
