import React from 'react'
import {makeSplitPoint} from 'plugins/react'
import FavouritesPage from 'app/Pages/FavouritesPage'
import {Switch, Route} from 'plugins/react-router-deluxe'
import {Template} from 'plugins/templates'
import Page from 'app/Components/Page'
import Reservation from 'app/Pages/Reservation'
import HomePage from 'app/Pages/HomePage'

// Register template sections
import '../Sections'
import LandingPage from './LandingPage'
const PaymentPage = makeSplitPoint(() => import('app/Pages/Payment'), {
	ssr: false,
})

const Pages = () => (
	<Page>
		<Switch>
			<Route exact path="/:lang?" Component={HomePage} />
			<Route path="/:lang/payment/:contractId" Component={PaymentPage} />
			<Route path="/:lang/play" Component={Template} props={{id: 'play'}} />
			<Route path="/:lang/reservation/:slug/:tab?" Component={Reservation} />
			<Route path="/:lang/favourites" Component={FavouritesPage} />
			<Route
				path="/:lang/sitemap"
				Component={Template}
				props={{id: 'sitemap'}}
			/>

			<Route
				path="/:lang/:landingPageUrl*"
				Component={LandingPage}
				context={{displays: 'landingPage'}}
			/>
		</Switch>
	</Page>
)

export default Pages
