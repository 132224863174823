import React, {FunctionComponent} from 'react'
import styled from 'styled-components'
import {useI18n} from './I18nContext'
import {nl, fr, de, enGB as en} from 'date-fns/locale'
import {isValid} from 'date-fns'
import {dateFormatter} from './dateFormatter'

// This filters out props given by higher-up SCs
const DateDom = styled.span``

const locale = {en, nl, fr, de}

const DateValue: FunctionComponent<DateValueInput> = ({
	value,
	format,
	lang: forceLang,
	...rest
}) => {
	const {lang: currLang} = useI18n()

	const lang = forceLang || currLang

	if (!value) return null
	const date = new Date(value)

	if (!isValid(date)) {
		throw new Error(`DateValue: date "${value}" is invalid.`)
	}

	let dateFormatted

	dateFormatted = format
		? dateFormatter(date, format, {locale: locale[lang]})
		: (typeof value === 'string' && value > '9999' && '∞') ||
			date.toLocaleDateString(lang)

	return <DateDom {...rest}>{dateFormatted}</DateDom>
}

export default DateValue
