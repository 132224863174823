import PropTypes from 'prop-types'
import React from 'react'
import {ImageFromId} from 'plugins/images'
import {styled, getFg} from 'app/styles'
import {PlainText, useI18n} from 'plugins/i18n'
import {Box} from 'app/Components/Layout'
import {LogoIcon} from 'app/Components/LogoIcon'

const ImageWrapper = styled.div`
	width: 100%;
	position: relative;
`
const ImageWrap = styled(({height, mobileHeight, ...p}) => <div {...p} />)`
	height: ${p => (p.theme.isMobile ? p.mobileHeight : p.height)};
`

const Title = styled.div`
	font-size: ${p => (p.theme.isMobile ? '2em' : '3.5em')};
	color: ${getFg};
	font-weight: lighter;
	width: 100%;
	text-align: center;
`

const Wrapper = styled(Box)`
	position: absolute;
	top: 0;
	bottom: 0;
`

const Image = props => {
	const {value, ...rest} = props
	const {
		titleI18n,
		showLogo,
		height = '17em',
		mobileHeight = '9em',
	} = value || {}
	const {lang} = useI18n()

	const hasTitle = titleI18n
		? typeof titleI18n === 'string'
			? true
			: !!titleI18n[lang]
		: false

	let imageId = value?.imageId
	// Clean up wrong array
	if (imageId && Array.isArray(imageId)) imageId = imageId[0]

	return (
		<ImageWrapper {...rest}>
			<ImageWrap height={height} mobileHeight={mobileHeight}>
				<ImageFromId value={imageId} />
			</ImageWrap>
			<Wrapper>
				{!!showLogo && <LogoIcon width="350px" height="180px" />}
				{hasTitle && (
					<Title>
						- <PlainText i18n={titleI18n} /> -
					</Title>
				)}
			</Wrapper>
		</ImageWrapper>
	)
}
Image.propTypes = {
	value: PropTypes.shape({
		// The uploadzoneinput mistakenly puts this in an array
		imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		titleI18n: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		showLogo: PropTypes.bool,
	}),
}

export default Image
