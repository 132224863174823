import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
	RegionTheme,
	GreenDarkTheme,
	BeigeTheme,
	getBg,
	useResponsive,
} from 'app/styles'
import {Region as RegionHouse} from 'app/Components/House'
import {useQuery} from 'plugins/apollo'
import Icon, {glyphs} from 'app/Components/Icon'
import {HeadBox, SpacesWrap} from 'app/Components/Layout/Display'
import {Box} from 'app/Components/Layout'
import {_, useI18n} from 'plugins/i18n'
import {housesListQuery} from 'app/_server/queries/houses'
import {Button} from 'app/Components/Buttons'
import {urlForSearch} from 'app/lib'

const StyledSpacesWrap = styled(SpacesWrap)`
	background-color: ${getBg};
`

const HousesGrid = styled.div`
	align-items: start;
	display: -ms-grid;
	display: grid;
	grid-column-gap: 2em;
	grid-row-gap: 2em;
	-ms-grid-columns: ${p => '1fr '.repeat(p.itemNum)};
	& > div:nth-of-type(1) {
		-ms-grid-column: 1;
	}
	& > div:nth-of-type(2) {
		-ms-grid-column: 2;
	}
	& > div:nth-of-type(3) {
		-ms-grid-column: 3;
	}
	& > div:nth-of-type(4) {
		-ms-grid-column: 4;
	}
	grid-template-columns: repeat(${p => p.itemNum}, 1fr);
	justify-content: center;
	width: 100%;
`

const HousesList = ({houses, searchLink}) => {
	const {isPhone} = useResponsive('isPhone')
	return (
		<div className="flex w-full flex-col items-start items-stretch justify-center gap-8">
			<div className="flex w-full items-center justify-between">
				<HeadBox underline>{_`popularHouses`}</HeadBox>
				{!isPhone && (
					<GreenDarkTheme>
						<Button colorSet="capGreenDark" to={searchLink}>
							<div className="flex w-full items-center justify-center gap-4">
								{_`showAll`} <Icon size="1.5em" glyph={glyphs.arrowShowAll} />
							</div>
						</Button>
					</GreenDarkTheme>
				)}
			</div>
			<HousesGrid itemNum={isPhone ? 2 : 4}>
				{houses.map(house => (
					<RegionHouse key={house.id} house={house} />
				))}
			</HousesGrid>
			{isPhone && (
				<BeigeTheme>
					<div>
						<Button fullWidth large light to={searchLink}>
							{_`showAll`}
						</Button>
					</div>
				</BeigeTheme>
			)}
		</div>
	)
}

HousesList.propTypes = {
	houses: PropTypes.arrayOf(PropTypes.object),
	searchLink: PropTypes.string,
}

const PopularHouses: React.FC<{
	readonly value?: {
		regionId?: string
		specialId?: string
	}
}> = ({value}) => {
	const i18n = useI18n()
	const {regionId, specialId} = value || {}
	const {loading, error, data} = useQuery(housesListQuery, {
		variables: {regionId, specialId, limit: 4},
	})

	if (!(regionId || specialId) || regionId === 'r$unknown') return null

	if (loading || error) return null

	const houses = data?.houses?.items

	if (!houses || houses.length === 0) return null

	return (
		<RegionTheme>
			<StyledSpacesWrap volumes={[8, 2]}>
				<HousesList
					houses={houses}
					searchLink={urlForSearch({
						i18n,
						filters: {
							region: [regionId],
							specials: {[specialId]: true},
						},
					})}
				/>
			</StyledSpacesWrap>
		</RegionTheme>
	)
}

export default PopularHouses
