import React from 'react'
import {styled} from 'app/styles'

const START = 'flex-start'
const END = 'flex-end'
const MID = 'center'
const topBot = p => (p.top ? START : p.bottom ? END : MID)
const leftRight = p => (p.left ? START : p.right ? END : MID)

/**
 * @typedef {React.PropsWithChildren<
 * 	Partial<import('app/styles').ClrProps> & {
 * 		absolute?: boolean
 * 		bottom?: boolean
 * 		column?: boolean
 * 		columnReverse?: boolean
 * 		fitChildren?: boolean
 * 		flex?: string
 * 		gap?: boolean | string
 * 		height?: number | string
 * 		inline?: boolean
 * 		inverse?: boolean
 * 		left?: boolean
 * 		margin?: string | number
 * 		maxHeight?: string | number
 * 		maxWidth?: string | number
 * 		minHeight?: string | number
 * 		minWidth?: string | number
 * 		size?: string | number
 * 		padding?: boolean | string | number
 * 		posBottom?: number | string
 * 		posLeft?: number | string
 * 		posTop?: number | string
 * 		posRight?: number | string
 * 		order?: number
 * 		relative?: boolean
 * 		right?: boolean
 * 		spaceAround?: boolean
 * 		spaceBetween?: boolean
 * 		spaceEvenly?: boolean
 * 		stretch?: boolean
 * 		top?: boolean
 * 		width?: string | number
 * 		wrap?: boolean
 * 	}
 * >} BoxProps
 */

const Box =
	/**
	 * @type {import('styled-components').ThemedStyledFunction<
	 * 	'div',
	 * 	null,
	 * 	BoxProps
	 * >}
	 */
	(
		styled(
			/** @type {React.ComponentType<BoxProps & React.DOMAttributes>} */
			(
				React.forwardRef(
					(
						{
							absolute,
							active,
							bottom,
							colorSet,
							column,
							columnReverse,
							cut,
							cutSize,
							fitChildren,
							flex,
							gap,
							height,
							inline,
							left,
							leftCut,
							maxHeight,
							maxWidth,
							minHeight,
							minWidth,
							mobile,
							padding,
							posBottom,
							posLeft,
							posRight,
							posTop,
							order,
							relative,
							right,
							spaceAround,
							spaceBetween,
							spaceEvenly,
							stretch,
							top,
							width,
							wrap,
							...props
						},
						ref
					) => <div {...props} ref={ref} />
				)
			)
		)
	)`
		${p => {
			const gap = p.gap === true ? p.theme.gap : p.gap
			const styles = {
				position: p.relative ? 'relative' : p.absolute ? 'absolute' : undefined,
				top: p.posTop,
				left: p.posLeft,
				bottom: p.posBottom,
				right: p.posRight,

				display: p.inline ? 'inline-flex' : 'flex',

				// Size
				padding: p.padding === true ? gap : p.padding,
				margin: p.wrap && gap ? `-calc(${gap}/2)` : p.margin,
				width: p.width || p.size || (!(p.fitChildren || p.inline) && `100%`),
				height: p.height || p.size,
				minWidth: p.minWidth,
				maxWidth: p.maxWidth,
				minHeight: p.minHeight,
				maxHeight: p.maxHeight,

				// Children layout
				flexWrap: p.wrap ? 'wrap' : undefined,
				flexDirection:
					p.column && !p.inverse
						? 'column'
						: p.columnReverse || (p.column && p.inverse)
							? 'column-reverse'
							: p.inverse && !p.column
								? 'row-reverse'
								: undefined,
				justifyContent: p.spaceBetween
					? 'space-between'
					: p.spaceAround
						? 'space-around'
						: p.spaceEvenly
							? 'space-evenly'
							: p.column
								? topBot(p)
								: leftRight(p),
				alignItems: p.stretch ? 'stretch' : p.column ? leftRight(p) : topBot(p),
				flex: p.flex,
				order: p.order,

				// Gap between children
				// For flex we use negative + positive margin
				'& > *:not(:last-child)': !p.wrap &&
					gap && {
						[`margin-${
							p.column
								? p.inverse
									? 'top'
									: 'bottom'
								: p.inverse
									? 'left'
									: 'right'
						}`]: gap,
					},
				'& > *': p.wrap &&
					gap && {
						margin: `calc(${gap}/2)`,
					},
			}
			return styles
		}};
	`

export default Box
