import {TickIcon} from 'app/Components/Icon'
import {useI18n} from 'plugins/i18n'
import React from 'react'

const WhyCap = () => {
	const {getText} = useI18n()
	return (
		<div className="text-onBackground my-8">
			<h2 className="mb-4 text-2xl leading-8 font-bold">{getText('whyCap')}</h2>
			<div className="my-6 flex flex-row space-x-4">
				<TickIcon className="mt-1 flex-none" />
				<div className="text-base leading-6">{getText('reason1')}</div>
			</div>
			<div className="my-6 flex flex-row space-x-4">
				<TickIcon className="mt-1 flex-none" />
				<div className="text-base leading-6">{getText('reason2')}</div>
			</div>
			<div className="my-6 flex flex-row space-x-4">
				<TickIcon className="mt-1 flex-none" />
				<div className="text-base leading-6">{getText('reason3')}</div>
			</div>
		</div>
	)
}

export default WhyCap
