import React from 'react'
import PropTypes from 'prop-types'
import {styled, mediumGreyColor} from 'app/styles'
import Box from 'app/Components/Layout/Box'
import {_} from 'plugins/i18n'

const Wrapper = styled(Box)`
	color: ${mediumGreyColor};
`

const ReviewsScore = ({value: score}) => {
	if (!score || isNaN(score)) return null
	return (
		<Wrapper padding="2rem 0">
			<Box gap="1rem" fitChildren>
				{_`avgScore`}
				<span className="text-capGreen text-xl font-bold">{score}</span>
			</Box>
		</Wrapper>
	)
}
ReviewsScore.propTypes = {
	value: PropTypes.string,
}

export default ReviewsScore
