import React from 'react'
import {Route, Redirect} from 'react-router'
import {isBrowser} from 'stratokit/build'

const PermanentRedirect = isBrowser
	? Redirect
	: props => {
			return (
				<Route
					render={({staticContext}) => {
						// there is no `staticContext` on the client, so
						// we need to guard against that here
						if (staticContext) staticContext.permanent = true
						return <Redirect {...props} />
					}}
				/>
			)
		}

export default PermanentRedirect
