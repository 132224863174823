import React from 'react'
import {LangLink, useI18n} from 'plugins/i18n'
import {
	ArrowRightIconNew,
	FacebookIconNew,
	InstagramIconNew,
} from 'app/Components/Icon'

const LastMinuteBar = () => {
	const {getText} = useI18n()
	return (
		<div className="w-vw bg-tertiary text-onTertiary flex h-10 items-center justify-between px-4">
			<LangLink to="/xx/search?isPromo">
				<div className="flex items-center space-x-2">
					<span className="underline">{getText(`ourLastMinutes`)}</span>
					<div className="mt-1">
						<ArrowRightIconNew height="0.625em" width="0.353em" />
					</div>
				</div>
			</LangLink>
			<div className="flex space-x-2">
				<a
					href="https://www.instagram.com/cap.be/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<InstagramIconNew />
				</a>
				<a
					href="https://www.facebook.com/cap.vakantiewoningen"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FacebookIconNew />
				</a>
			</div>
		</div>
	)
}
export default LastMinuteBar
