import {parse, stringify} from 'jsurl2'
import {useHistory, useLocation} from 'react-router'

export const useRouterQuery = () => {
	const {replace} = useHistory()
	const {search, pathname} = useLocation()

	const setSearchParams = q => {
		let newSearchParams
		if (typeof q === 'object') newSearchParams = stringify(q, {short: true})
		if (typeof q === 'string') newSearchParams = q

		replace(`${pathname}${newSearchParams ? '?' + newSearchParams : ''}`)
	}

	const searchParams: {[key: string]: any} = parse(search.slice(1))

	return {searchParams, setSearchParams}
}
