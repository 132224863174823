import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface TableState {
	[key: string]: any
}

const initialState: TableState = {}

const tableSlice = createSlice({
	name: 'table',
	initialState,
	reducers: {
		setState(state, action: PayloadAction<Partial<TableState>>) {
			Object.assign(state, action.payload)
		},
	},
})

export const {setState} = tableSlice.actions

export default tableSlice.reducer
