import React, {Fragment, useRef} from 'react'
import {groupBy} from 'lodash-es'
import {_, useI18n} from 'plugins/i18n'
import {Icon} from 'plugins/icons'
import {glyphs} from 'app/Components/Icon'
import {Accordion} from './MobileHousePage'
import Text from 'app/Components/twComponents/Text'

const RoomNameToGlyph = {
	'bld$room:slaapkamer': glyphs['bed'],
}

const ListIcon = ({glyph}) => (
	<Icon className="mr-2 inline-flex" glyph={glyph} />
)

const RoomLine: React.FC<{
	readonly value: {
		type: string
		count: number
	}[]
	readonly roomName: string
}> = ({value, roomName}) => {
	const {getText} = useI18n()
	const glyph = RoomNameToGlyph[roomName] || glyphs['circledCheck']
	return (
		<li>
			<div className="flex">
				<div>{glyph && <ListIcon glyph={glyph} />}</div>
				<div>
					{roomName !== 'none' && (
						<span className="font-bold">{getText(roomName)}</span>
					)}
					{value.length > 0 && <> {_('with')} </>}
					{value.map((eq, i) => {
						const eqType = (
							<>
								{_(eq.type)}
								{eq.count > 1 ? ` (x${eq.count})` : ''}
							</>
						)

						let separator
						if (i === value.length - 2) {
							separator = <> {_('and')}</>
						} else if (i < value.length - 2) {
							separator = ','
						} else {
							separator = ''
						}

						return (
							<Fragment key={i}>
								{' '}
								{eqType}
								{separator}
							</Fragment>
						)
					})}
				</div>
			</div>
		</li>
	)
}

const FloorSection = ({
	value,
	floorName,
	showFloorName,
	isOutOrCommon,
}: {
	readonly value: {room: string; equipment: {type: string; count: number}[]}[]
	readonly floorName: string
	readonly showFloorName: boolean
	readonly isOutOrCommon: boolean
}) => {
	return (
		<>
			{showFloorName && <h4>{_(floorName)}</h4>}
			<ul className="space-y-2">
				{(floorName === 'none'
					? value.filter(room => room.equipment.length)
					: value
				).map((room, i) => {
					const {room: roomName, equipment} = room
					if (isOutOrCommon) {
						return equipment.map((eq, idx) => (
							<li key={idx}>
								<ListIcon glyph={glyphs['circledCheck']} />
								{_(eq.type)}
								{eq.count > 1 && ` (x${eq.count})`}
							</li>
						))
					}
					return <RoomLine key={i} roomName={roomName} value={equipment} />
				})}
			</ul>
		</>
	)
}

export const BuildingSection = ({
	value,
	buildingName,
	isOutOrCommon,
}: {
	readonly value: House['rooms'][]
	readonly buildingName: string
	readonly isOutOrCommon: boolean
}) => {
	const {getText} = useI18n()
	const byFloor = groupBy(value, 'floor')
	const floorKeyArr = Object.keys(byFloor)
	if (!floorKeyArr.length) return null

	const showFloorName = !(floorKeyArr.length === 1 || byFloor.none)

	return (
		<div className="space-y-2">
			<h3>
				<Text variant="heading3">{getText(buildingName)}</Text>
			</h3>
			{floorKeyArr.map((key, i) => {
				const floor = byFloor[key]
				const floorName = key
				return (
					<FloorSection
						key={i}
						floorName={floorName}
						showFloorName={showFloorName}
						value={floor}
						isOutOrCommon={isOutOrCommon}
					/>
				)
			})}
		</div>
	)
}

const Facilities = ({house}: {readonly house: House}) => {
	const {getText} = useI18n()

	const byBuilding = groupBy(house.rooms, 'building')
	const buildingKeyArr = Object.keys(byBuilding)

	const showBuildingName =
		buildingKeyArr.filter(key => !key.startsWith('bld$bld:z')).length > 1

	const sectionRefs = useRef(
		// eslint-disable-next-line unicorn/no-array-reduce
		buildingKeyArr.reduce(
			(acc, key) => {
				acc[key] = React.createRef<HTMLDivElement>()
				return acc
			},
			{} as Record<string, React.RefObject<HTMLDivElement>>
		)
	)

	if (!house) return null

	return (
		<Accordion title={getText('features')}>
			<div className="text-onBackground mb-4 flex flex-wrap gap-2">
				{buildingKeyArr.map(key => (
					<a
						key={key}
						href={`#${key}`}
						className="underline"
						onClick={e => {
							e.preventDefault()
							sectionRefs.current[key]?.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
								inline: 'start',
							})
						}}
					>
						<Text variant="small">{getText(key)}</Text>
					</a>
				))}
			</div>

			{buildingKeyArr.map((key, index) => {
				const isOutOrCommon =
					key.startsWith('bld$bld:z') &&
					Boolean(byBuilding[key]![0]?.equipment.length)

				return (
					<div ref={sectionRefs.current[key]} key={key} className="mb-6">
						<BuildingSection
							buildingName={key}
							showBuildingName={showBuildingName || isOutOrCommon}
							isOutOrCommon={isOutOrCommon}
							value={byBuilding[key]}
							getText={getText}
						/>
						{index < buildingKeyArr.length - 1 && (
							<hr className="my-4 border-t border-gray-300" />
						)}
					</div>
				)
			})}
		</Accordion>
	)
}

export default Facilities
