import {makeSplitPoint} from 'plugins/react'
import Value from './RentYourHouseIntroSection'

export default {
	label: 'RentYourHouse Intro',
	group: 'content',
	Value,
	defaultValue: {
		headingI18n: undefined,
		textI18n: undefined,
	},
	Form: makeSplitPoint(() => import('./RentYourHouseIntroForm'), {ssr: false}),
}
