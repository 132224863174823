import React, {FC} from 'react'
import styled from 'styled-components'
import {lightBeigeColor} from 'app/styles'
import {SpacesWrap} from 'app/Components/Layout/Display'
import {_} from 'plugins/i18n'
import {useSelector} from 'plugins/redux/hooks'
import {HousesList} from 'app/Components/Sections/HousesList'
import {housesListQuery} from 'app/_server/queries/houses'
import {useQuery} from 'plugins/apollo'
import {orderByIdsArray} from 'app/lib'

const StyledSpacesWrap = styled(SpacesWrap)<{withBeigeBg?: boolean}>`
	background: ${p => p.withBeigeBg && lightBeigeColor};
`

interface LikedAndVisitedHousesProps {
	readonly value: {
		house?: FullHouse
		houseId?: House['id']
	}
	readonly templateData: FullHouse & Record<string, any>
}

const RecentlyVisitedHouses: FC<LikedAndVisitedHousesProps> = ({
	templateData,
	value,
}) => {
	const houseId = value.house?.id || value?.houseId || templateData?.id

	const visitedHousesIds = useSelector(state => state.visitedHouses).filter(
		id => id !== houseId
	) // filter out the current house

	const {loading, data} = useQuery(housesListQuery, {
		variables: {
			houseIds: visitedHousesIds,
			limit: 24,
		},
		skip: !visitedHousesIds.length,
	})

	return (
		<StyledSpacesWrap volumes={[8, 2]}>
			<HousesList
				{...{
					houses: orderByIdsArray(
						visitedHousesIds,
						(data?.houses?.items || []) as FullHouse[]
					)
						.filter(Boolean)
						.slice(0, 4),
					title: _`recentlyVisitedHouses`,
					showMoreLabel: _`showMoreRecentlyVisitedHouses`,
					showMorePath: '/favourites',
					loading: loading && !data,
				}}
			/>
		</StyledSpacesWrap>
	)
}

export default RecentlyVisitedHouses
