import React from 'react'
import {useMe} from '../queries'
import {LoginModal} from './asyncComponents'

export const useLogin = (options?: Parameters<typeof LoginModal>[0]) => {
	const {data, loading: userLoading} = useMe()
	const user = data?.user
	const loginModal = user ? null : (
		<LoginModal {...options} loading={userLoading} />
	)

	return {
		user,
		userLoading,
		loginModal,
	}
}
