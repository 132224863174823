import React from 'react'
import {CapDarkTheme} from 'app/styles'
import {HeadBox} from 'app/Components/Layout/Display'
import {_} from 'plugins/i18n'
import Btn from 'app/Components/Buttons/Button'
import {COMPANY_INFO} from 'app/constants'

const {phone, phoneLabel, email} = COMPANY_INFO

const CtaSection: React.FC<{
	readonly value: {readonly ctaImages?: {readonly imageId: string}[]}
}> = () => {
	return (
		<div className="bg-lightBeige flex h-72 w-full items-center justify-center">
			<div className="flex w-4/5 flex-col items-start justify-center gap-8 p-8">
				<HeadBox lvl={3}>{_`ctaDescription`}</HeadBox>
				<div className="flex w-auto items-start justify-center space-x-4">
					<a className="w-full" href={`tel:${phone}`}>
						<CapDarkTheme>
							<Btn noWrap large>
								<span>{phoneLabel}</span>
							</Btn>
						</CapDarkTheme>
					</a>
					<a className="w-full" href={`mailto:${email}`}>
						<Btn accent noWrap large>
							<span>{email}</span>
						</Btn>
					</a>
				</div>
			</div>
		</div>
	)
}

export default CtaSection
