// Redirects to /404 on the server only, which makes the server return code 404 with the rendered HTML. On the client nothing happens.
import React from 'react'
import {Route} from 'react-router'
import {isServer} from 'stratokit/build'

const HTTP404 = () =>
	isServer ? (
		<Route
			render={({staticContext}) => {
				// there is no `staticContext` on the client, so
				// we need to guard against that here
				if (staticContext) staticContext.notFound = true
				return null
			}}
		/>
	) : null

export default HTTP404
