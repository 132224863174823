/* eslint-disable no-console */
let last
const logTimes = message => {
	const time = Date.now()
	if (console.timeStamp) console.timeStamp(message) // this is for the Performance tab in Chrome DevTools
	console.info(message, time, last && time - last)
	last = time
	// this here is for Google Analytics
	if (globalThis.ga)
		globalThis.ga('send', {
			hitType: 'timing',
			timingCategory: 'Performance',
			timingVar: message,
			timingValue: time,
		})
}
logTimes('scripts parsed')

export default logTimes
