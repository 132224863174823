import PropTypes from 'prop-types'
import React from 'react'
import {HeadBox} from 'app/Components/Layout/Display'
import {_, useI18n} from 'plugins/i18n'

export const HouseTitle = ({
	name,
	placeName,
	regionId,
	province,
	fontResize,
	type,
	capacity,
}) => {
	const hBoxLvls = {
		name: 1,
		region: 3,
	}
	const {getText} = useI18n()
	return (
		<>
			{/* a wrapper needed because of noMargin which removes margin set up by the parent */}
			<div>
				<HeadBox lvl={hBoxLvls.name} resize={fontResize} noMargin>
					{!!type && `${getText(type)} `}
					{name}
					{!!capacity && ` ${getText('for')} ${capacity} ${getText('persons')}`}
				</HeadBox>
			</div>

			<div>
				<HeadBox lvl={hBoxLvls.region} resize={fontResize} noMargin>
					{placeName}, {province ? `${province},` : ''}{' '}
					{regionId !== 'r$unknown' && _(regionId)}
				</HeadBox>
			</div>
		</>
	)
}
HouseTitle.propTypes = {
	name: PropTypes.string,
	type: PropTypes.string,
	placeName: PropTypes.string,
	regionId: PropTypes.string,
	province: PropTypes.string,
	fontResize: PropTypes.number,
	capacity: PropTypes.number,
}
