import React, {FC} from 'react'
import {_} from 'plugins/i18n'
import {fixDate, makePlainRange, roundRate} from 'app/lib'
import {MoneyValue} from '../Numbers'
import {groupBy} from 'lodash-es'
import {makeContractCalcProps} from 'app/Pages/Reservation/helpers'
import {useTaxPlatform} from 'app/_server/queries/taxPlatforms'
import {COND_PAYINADVANCE} from 'app/_server/database/constants'
import {calcStayPrice} from 'app/lib/calcStayPrice'

const rentalFeesInit: Partial<RentalFees> = {
	adminFee: __CONFIG__.rentalFees.adminFee,
	insuranceRate: __CONFIG__.rentalFees.insuranceRate,
	depositRate: __CONFIG__.rentalFees.depositRate,
}

const ReservationPriceMobile: FC<{
	readonly range?: DateISOStringRange
	readonly house: House
}> = ({range: selectedRange, house}) => {
	const {extras, taxPlatformId} = house || {}
	const {data, error, loading} = useTaxPlatform(
		{id: taxPlatformId!},
		{skip: !taxPlatformId}
	)

	let range
	// We saw some runtime errors where prices was not loaded yet
	let currentPrice, regularPrice
	if (house.prices.length > 0) {
		range =
			selectedRange &&
			makePlainRange(fixDate(selectedRange.start), fixDate(selectedRange.end))

		if (range) {
			currentPrice = calcStayPrice({
				range,
				house,
				includePromotions: true,
			})
			regularPrice = calcStayPrice({range, house})
		} else {
			const sellPrices = house.prices.map(p => p.sell).filter(Boolean)
			if (sellPrices.length > 0) {
				currentPrice = Math.min(...sellPrices)
			}
		}
	}

	const startFromPrice =
		currentPrice < regularPrice ? regularPrice : currentPrice

	const contractCalc = makeContractCalcProps({
		rentalFees: rentalFeesInit,
		dateRange: range,
		chosenExtrasObj: groupBy(extras, 'optionId'),
		guests: {adults: 1},
		hasInsurance: false,
		house,
		taxPlatform: data?.taxPlatform,
	})

	const {prices, groupedExtrasByCond, extrasPrices} = contractCalc || {}

	const {
		stayDays,
		rentalDiscountRate,
		sellRentalDiscount,
		sellRental,
		sellRentalBaseWithAdjust,
		sellTotal,
		adminFee,
		touristTax,
	} = prices || {}

	const condition = COND_PAYINADVANCE

	const extrasToPayInAdvanceList = groupedExtrasByCond[condition]
		?.map(({optionId}, i) => {
			if (!(extrasPrices[condition] && extrasPrices[condition][optionId])) {
				return null
			}

			return (
				<span key={i} className="flex">
					<span className="flex-1">{_(optionId)}</span>
					<MoneyValue value={extrasPrices[condition]?.[optionId]} />
				</span>
			)
		})
		.filter(Boolean)

	return range ? (
		<div className="flex flex-col gap-2 text-sm text-gray-500">
			<div className="flex items-center gap-2 font-normal">
				{/* PER NIGHT */}
				<span className="text-base font-bold text-black">
					<MoneyValue value={sellRental / stayDays} />
				</span>
				{_`perNight`}
				{rentalDiscountRate > 0 && (
					<>
						<span className="line-through">
							<MoneyValue value={sellRentalBaseWithAdjust / stayDays} />
						</span>
						<span className="font-bold text-black">
							{roundRate(rentalDiscountRate * 100)}% {_`discount`}
						</span>
					</>
				)}
			</div>
			{/* DISCOUNT AMOUNT */}
			{rentalDiscountRate > 0 && (
				<div className="flex justify-between">
					<span>{_`Discount`}</span>
					<span>
						-<MoneyValue value={sellRentalDiscount} />
					</span>
				</div>
			)}
			{/*  NIGHT */}
			<div className="flex justify-between">
				<span>
					<MoneyValue value={sellRental / stayDays} /> x {stayDays}{' '}
					{stayDays === 1 ? _`night` : _`nights`}
				</span>
				<span>
					<MoneyValue value={sellRental} />
				</span>
			</div>
			{adminFee ? (
				<span className="flex">
					<span className="flex-1">{_('admFee')}</span>{' '}
					<MoneyValue value={adminFee} />
				</span>
			) : (
				false
			)}
			{touristTax ? (
				<span className="flex">
					<span className="flex-1">{_('TouristTaxEstimate')}</span>{' '}
					<MoneyValue value={touristTax} />
				</span>
			) : (
				false
			)}
			{/* EXTRAS */}
			{extrasToPayInAdvanceList}

			<div className="border border-solid border-gray-300" />
			{/* TOTAL SELL PRICE */}
			<div className="flex justify-between text-base font-bold text-black">
				<span>{_`total`}</span>
				<span>
					<MoneyValue value={sellTotal} />
				</span>
			</div>
		</div>
	) : (
		<div className="flex gap-2">
			{_`startsFrom`}
			<span className="text-base font-bold text-black">
				<MoneyValue value={startFromPrice} />{' '}
				{!!selectedRange ? false : _`perWeek`}
			</span>
		</div>
	)
}

export default ReservationPriceMobile
