import PropTypes from 'prop-types'
import React from 'react'
import SlickSlider from './SlickSlider'

const settings = {
	dots: 0,
	arrows: 0,
	slidesToShow: 1,
	slidesToScroll: 1,
	draggable: 0,
	lazyLoad: 0,
	infinite: 1,
	autoplay: 1,
	autoplaySpeed: 5000,
	pauseOnHover: 1,
}

const TextCarousel = ({content, ...rest}) => (
	<SlickSlider {...rest} {...settings}>
		{content.map((text, i) => (
			<div key={i}>{text}</div>
		))}
	</SlickSlider>
)
TextCarousel.propTypes = {
	content: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default TextCarousel
