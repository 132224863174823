import React from 'react'
import {StyledInput} from './Components/InputComponents'
import InputLayout, {LayoutProps} from './InputLayout'

const TextInput = (props: LayoutProps<typeof StyledInput>) => (
	<InputLayout
		{...props}
		Input={StyledInput}
		inputProps={{value: props.value || ''}}
	/>
)

export default TextInput
