import React from 'react'
import PropTypes from 'prop-types'
import {
	Preview,
	Description,
	Features,
	ThumbnailLabel,
} from 'app/Components/House'
import {connect} from 'react-redux'
import {Box} from 'app/Components/Layout'

const ResultThumb = ({house, preventRedirect}) => {
	const {
		name,
		domainName,
		type,
		regionId,
		capacity,
		bedroomCount,
		bathroomCount,
		isNew,
		isPromo,
		placeName,
	} = house
	const isNewOrPromo = isNew || isPromo

	return (
		<Preview
			{...{house, maxWidth: '19em', column: true, ratio: 1.33, preventRedirect}}
		>
			<Description
				{...{
					name,
					domainName,
					type,
					regionId,
					bottom: (
						<Features
							row
							noBorder
							houseFeatures={[
								{value: capacity, icon: 'peopleNew'},
								{value: bedroomCount, icon: 'bedNew'},
								{value: bathroomCount, icon: 'bathNew'},
							]}
						/>
					),
					placeName,
				}}
			/>
			{isNewOrPromo && <ThumbnailLabel isNew={isNew} />}
		</Preview>
	)
}
ResultThumb.propTypes = {
	house: PropTypes.object,
	placeName: PropTypes.string,
	preventRedirect: PropTypes.bool,
}

const List = React.forwardRef(
	({houses, isPhone, itemsPerRow, ...rest}, ref) => {
		// This would be so much nicer with grid but we have to support IE11
		return (
			<Box ref={ref} wrap top gap="2em">
				{houses?.length &&
					houses.map(house => (
						<ResultThumb key={house.id} {...{...rest, house}} />
					))}
			</Box>
		)
	}
)
List.propTypes = {
	houses: PropTypes.arrayOf(PropTypes.object),
	isPhone: PropTypes.bool,
	itemsPerRow: PropTypes.number,
	_ref: PropTypes.object,
}

export default connect(
	({responsive: r}) => ({isPhone: r.isPhone}),
	null,
	null,
	{forwardRef: true}
)(List)
