import React from 'react'
import PropTypes from 'prop-types'
import {styled, goldColor, orangeColor} from 'app/styles'
import {SpacesBox} from 'app/Components/Layout/Display'
import {_} from 'plugins/i18n'

const Label = styled(({isNew, ...props}) => <SpacesBox {...props} />)`
	position: absolute;
	top: 1em;
	left: -1em;
	color: white;
	background: ${p => (p.isNew ? goldColor : orangeColor)};
`

const ThumbnailLabel = ({isNew, ...rest}) => {
	return (
		<Label {...rest} isNew={isNew}>
			{isNew ? _`isNew` : _`isPromo`}
		</Label>
	)
}
ThumbnailLabel.propTypes = {
	isNew: PropTypes.bool,
}

export default ThumbnailLabel
