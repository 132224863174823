import Form from './ColumnsFormAsync'
import Value from './ColumnsSection'
import thumbnail from './columnsPreview.png'

export default {
	label: 'Two columns',
	group: 'layout',
	Value,
	Form,
	thumbnail,
}
