import logTimes from 'stratokit/client/logTimes'
import {hydrate, render} from 'react-dom'
import renderApp from './renderApp'
import {addHook} from 'stratokit/tapable'

const initReactHooks = hooks => {
	const renderHooks = {}
	// Keep synchronized with prerender/middleware.js
	addHook(renderHooks, 'initRenderContext', ['context'])
	addHook(renderHooks, 'wrapApp', ['context'])
	addHook(renderHooks, 'afterRender', ['context'])
	hooks.render = renderHooks

	const initReact = () => {
		const context = {}
		hooks.render.initRenderContext.call(context)

		const doRender = (hydrateOrRender, app) => {
			logTimes('app will render')
			context.app = app([], context)
			hooks.render.wrapApp.call(context)
			hydrateOrRender(
				context.app,
				globalThis.document.querySelector('#root'),
				() => {
					hooks.render.afterRender.call(context)
					logTimes('app interactive')
				}
			)
		}

		if (import.meta.hot) {
			import.meta.hot.accept('./renderApp', newApp => {
				// eslint-disable-next-line no-console
				console.log('=== hot reload app')
				doRender(render, newApp.default)
			})
		}

		doRender(hydrate, renderApp)
	}

	hooks.start.tap('react', initReact)
}

export default initReactHooks
