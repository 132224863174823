import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import {ThemeProvider, defaultTheme} from 'app/styles'
import {useLogin} from 'plugins/users/react/hooks'

export const EditableContext = React.createContext<{
	isEditing: boolean
	toggleEdit: () => void
}>({
	isEditing: false,
	toggleEdit: () => {},
})

const EditableContent: React.FC<{
	readonly children: React.ReactNode
}> = ({children}) => {
	const [isEditing, setIsEditing] = useState(false)
	const {loginModal} = useLogin()

	const toggleEdit = useCallback(() => {
		setIsEditing(prevIsEditing => !prevIsEditing)
	}, [])

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.ctrlKey && !event.shiftKey && event.key === 'E') {
				toggleEdit()
			}
		},
		[toggleEdit]
	)

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [handleKeyDown])

	const contextValue = useMemo(
		() => ({isEditing, toggleEdit}),
		[isEditing, toggleEdit]
	)

	return (
		<EditableContext.Provider value={contextValue}>
			<>
				{children}
				{isEditing && loginModal ? (
					<ThemeProvider theme={defaultTheme}>{loginModal}</ThemeProvider>
				) : null}
			</>
		</EditableContext.Provider>
	)
}
export {EditableContent}

export const useEditing = () => useContext(EditableContext)
