import React from 'react'
import BgImage from './BgImage'
import {makeSrcSet} from '../makeUrl'

interface ImageValue {
	id?: string
	url?: string
	src?: string
	alt?: string
	upload?: {
		id?: string
	}
	[key: string]: any
}

export interface ImageProps {
	value?: ImageValue
	orig?: boolean
	square?: boolean
	big?: boolean
	mid?: boolean
	small?: boolean
	thumb?: boolean
	alt?: string
	[key: string]: any
}

const Image: React.FC<ImageProps> = ({
	value,
	alt,
	big,
	thumb,
	small,
	...rest
}) => {
	if (!value) return <BgImage {...rest} />

	let src: string | undefined = value.url || value.src
	let forceSmall: boolean | undefined
	let srcSet: string | undefined

	const imgId = value?.id || value?.upload?.id

	if (!src && imgId) {
		;({src, srcSet} = makeSrcSet({...value, id: imgId}))
	}

	const {id, ...bgImageProps} = value

	return (
		<BgImage
			{...bgImageProps}
			{...rest}
			blur={forceSmall ? (big ? 8 : 2) : 0}
			src={src}
			srcSet={srcSet}
			alt={alt || value.alt}
		/>
	)
}

export default Image
