import React, {Component} from 'react'
import {isEqual} from 'lodash-es'
import {makeSplitPoint, createSelector} from 'plugins/react'
import {styled, getColors, getHint, getFg, getBg} from 'app/styles'

import {PlainText} from 'plugins/i18n'

const getSelect = () =>
	Promise.all([
		// @ts-ignore
		import('react-select/dist/react-select.css'),
		import('react-select'),
	]).then(([, RS]) => (RS as any).default)
const Select = makeSplitPoint(getSelect)
const SelectCreatable = makeSplitPoint(() => getSelect().then(S => S.Creatable))

const i18nText = id => <PlainText trId={id} />

const makeOptions = (options, optionValues, getLabel, useI18n) => {
	const toLabelFn =
		(useI18n && i18nText) || getLabel || (optionValues && (i => i))
	if (optionValues) {
		return optionValues.map(value => ({value, label: toLabelFn(value)}))
	}
	if (toLabelFn) {
		return (options || []).map(option => ({
			...option,
			label: toLabelFn(option.value),
		}))
	}
	return options
}

type MaybeArray<T, M extends boolean | undefined> = M extends true ? T[] : T

export type ReactSelectProps<T, M extends boolean | undefined> = {
	readonly multi?: M
	readonly value?: MaybeArray<T, M>
	readonly onChange?: (newValues: MaybeArray<T, M>) => void
	readonly maxSelect?: M extends true ? number : never
	readonly creatable?: boolean
	readonly Label?: React.ComponentType<{
		option?: T
		value?: T
		children: React.ReactNode
	}>
	readonly options?: {value: T; label: React.ReactNode}[]
	readonly optionValues?: T[]
	readonly useI18n?: boolean
	readonly className?: string
	readonly placeholder: React.ReactNode
}

class ReactSelect<T, M extends boolean> extends Component<
	ReactSelectProps<T, M>
> {
	handleMultiChange = options => {
		const {onChange} = this.props
		if (!onChange) {
			return
		}
		return onChange(options.map(o => o.value))
	}

	renderOption = option => {
		const Label = this.props.Label!
		return (
			<Label option={option} value={option.value}>
				{option.label}
			</Label>
		)
	}

	getOptions = createSelector(
		[
			({options}) => options,
			({optionValues}) => optionValues,
			({getLabel}) => getLabel,
			({useI18n}) => useI18n,
		],
		makeOptions
	)

	getMultiValue(value, options) {
		if (!Array.isArray(value)) value = [value]
		return value.map(
			v =>
				(options && options.find(o => isEqual(o.value, v))) || {
					value: v,
					label: v,
				}
		)
	}

	render() {
		const {
			creatable,
			optionValues,
			multi,
			onChange,
			Label,
			maxSelect,
			value,
			...rest
		} = this.props
		let options = this.getOptions(this.props)
		if (multi && maxSelect && value && (value as T[]).length >= maxSelect)
			options = options.filter(o => (value as T[]).includes(o.value))
		const props = {
			...rest,
			value: multi ? this.getMultiValue(value || [], options) : value,
			multi,
			options,
			onChange: multi ? this.handleMultiChange : onChange,
			simpleValue: !multi,
			optionRenderer: Label && this.renderOption,
		}
		if (optionValues) {
			;(props as any).matchProp = 'label'
		}
		const RSelect = creatable ? SelectCreatable : Select
		return <RSelect {...props} />
	}
}

const StyledTagSelect = styled(ReactSelect)<{disabled?: boolean}>`
	// Specificity
	&.Select {
		width: 100%;

		&.is-open > .Select-control {
			border-color: ${p => getBg(p, 'accent')};
			background-color: ${getBg};
		}
		&.is-focused:not(.is-open) > .Select-control {
			${p => p.theme.focusCss};
			background-color: ${getBg};
		}

		/* Prevent value overflowing under clear/dropdown icon */
		&.has-value.is-clearable.Select--single > .Select-control .Select-value {
			padding-right: 48px;
			overflow: hidden;
		}

		.Select-control {
			/* prevent placeholder from overflowing control */
			position: relative;
			overflow: hidden;

			border-radius: ${p => p.theme.radius}rem;
			border: ${p => p.theme.input?.border || `thin solid ${getHint}`};
			box-shadow: none;
			line-height: 1.5rem;
			padding: 0.4rem;
			min-width: 5rem;
			width: 100%;
			min-height: ${p => p.theme.inputHeight}rem;
			${p => p.theme.input && p.theme.input.textInputCss};

			.Select-placeholder,
			.Select-value {
				line-height: 1.5rem;
				display: inline-flex;
				align-items: center;
				${p => p.theme.input && p.theme.input.textInputCss};
				border: none;
			}

			.Select-input {
				height: auto;

				input {
					padding: 0;
					line-height: 1.5rem;
				}
			}
			.Select-placeholder {
				color: ${p => getHint(p)};
				white-space: nowrap;
				overflow: hidden;
				position: absolute;
				padding-right: 30px;
				& > * {
					overflow: hidden;
				}
				${p => p.theme.input && p.theme.input.placeholder};
			}
			.Select-value > .Select-value-label {
				// R-S has annoyingly specific CSS
				color: ${p => getColors(p)[p.disabled ? 'hint' : 'fg']} !important;
				background-color: ${getBg};
			}
		}
		// Stretch option dropdown
		.Select-menu-outer {
			min-width: 100%;
			width: auto;
			max-width: 200%;
			max-height: 50vh;
			height: auto;
			z-index: 10000;

			${p => p.theme.reactSelectOptionsCss};
		}
		.Select-menu {
			max-height: calc(50vh - 2px);
		}
		.Select-option {
			color: ${p => getFg(p, 'btnSelecting')};
			background-color: ${p => getBg(p, 'btnSelecting')};
			&.is-selected {
				color: ${p => getFg(p, 'accent')};
				background-color: ${p => getBg(p, 'accent')};
			}
			&:hover,
			&.is-focused {
				color: ${p => getFg(p, 'btnSelectingHover')};
				background: ${p => getBg(p, 'btnSelectingHover')};
				&.is-selected {
					color: ${p => getFg(p, 'accent', true)};
					background-color: ${p => getBg(p, 'accent', true)};
				}
			}
		}
		.Select-control {
			background-color: ${getBg};
			${({theme}) => theme.noBorder && 'border: none;'};
		}
		&.Select--multi {
			vertical-align: bottom;
			display: inline-block;
			.Select-value,
			.Select-value > .Select-value-label {
				color: ${p =>
					getColors(p, 'accent')[p.disabled ? 'hint' : 'fg']} !important;
				background-color: ${p => getBg(p, 'accent')} !important;
				border: none !important;
				font-size: 1em !important;
				margin: 0;
				padding: 0 4px;
			}
			.Select-value-icon {
				border: none;
				padding: 1px 3px;
				&:hover {
					background-color: transparent !important;
					color: inherit !important;
				}
			}
			.Select-value-label {
				padding: 0 !important;
			}
			.Select-multi-value-wrapper {
				display: flex;
				flex-wrap: wrap;
				margin: -0.2em;
				& > * {
					margin: 0.2em;
				}
			}
		}
		&.Select--single .Select-value-label {
			overflow: hidden;
		}
	}
` as unknown as typeof ReactSelect

export const SelectSingle = <T extends unknown>(
	props: React.ComponentProps<typeof StyledTagSelect<T, false>>
) => <StyledTagSelect<T, false> {...props} />
export const SelectMulti = <T extends unknown>(
	props: React.ComponentProps<typeof StyledTagSelect<T, true>>
) => <StyledTagSelect<T, true> multi {...props} />

export default StyledTagSelect
