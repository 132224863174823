import React from 'react'
import {useDispatch} from 'plugins/redux/hooks'
import {closeModal} from 'state/ducks/modal'
import Footer from './Footer'
import Header from './Header'

const Page = ({children}) => {
	const dispatch = useDispatch()
	return (
		<div
			className="grid min-h-screen w-full grid-cols-[1fr_minmax(0,1600px)_1fr] grid-rows-[1fr_auto] overflow-hidden"
			onClick={() => dispatch(closeModal())}
		>
			<div className="col-start-2 row-start-1">
				<div className="relative flex h-full flex-col items-start bg-white">
					<Header />
					<div className="min-h-full w-full">{children}</div>
				</div>
			</div>

			<div className="col-start-2 row-start-2">
				<Footer />
			</div>
		</div>
	)
}

export default Page
