import React from 'react'
import {LogoSmallIcon} from 'app/Components/Icon'
import {_} from 'plugins/i18n'
import {useResponsive} from 'app/styles'
import SocialIcons from '../SocialIcons'
import SloganCarousel from './SloganCarousel'
import {Line} from 'app/Components/Layout/Display'
import Nav from './Nav'

const routes = [
	{path: '/xx/conditions', label: _`conditionsLink`},
	{path: '/xx/insurance', label: _`insuranceLink`},
	{path: '/xx/sitemap', label: _`sitemapLink`},
	{path: '/xx/privacy', label: _`privacyLink`},
]

const Footer = () => {
	const {isPhone} = useResponsive('isPhone')
	const contacts = [_`merendree`, !isPhone && _`gent`, _`phone`]

	return (
		<div className="text-tertiary relative mx-auto h-96 w-full max-w-[1600px]">
			<div className="flex h-full w-full flex-col items-center justify-around bg-[#252525e6] p-4 md:justify-between md:p-12">
				<div className="m-3 flex w-full items-center justify-start">
					<LogoSmallIcon fgColor="white" width="9rem" height="5rem" />
				</div>
				<div className="flex w-full items-center justify-between gap-4">
					{contacts.map((contact, i) => (
						<div key={i}>{contact}</div>
					))}
					{isPhone ? null : <SloganCarousel />}
				</div>
				<Line colorSet="capDarkGrey" />
				{isPhone ? <Nav routes={routes} /> : null}
				<Line colorSet="capDarkGrey" />
				<div className="flex w-full flex-col justify-between gap-4 md:flex-row md:gap-8">
					<div className="align-center flex w-full justify-center gap-4">
						<div className="text-left text-[0.8em] whitespace-nowrap">
							{_('copyrightNotice')}
						</div>
						{isPhone ? null : <Nav routes={routes} />}
					</div>
					<SocialIcons />
				</div>
			</div>
		</div>
	)
}

export default Footer
