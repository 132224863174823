import React from 'react'
import rules from './rules'

const serializeString = obj => {
	for (const rule of rules) {
		if (!rule.serialize) continue
		const ret = rule.serialize(obj, obj.text)
		if (ret) return ret
	}
	// eslint-disable-next-line unicorn/no-array-reduce
	return obj?.text?.split('\n').reduce((array, text, i) => {
		if (i !== 0) array.push(<br key={i} />)
		array.push(text)
		return array
	}, [])
}

let leafKey = 0
const addKey = element => React.cloneElement(element, {key: leafKey++})
const serializeLeaf = leaf => {
	const string = {object: 'string', text: leaf.text}
	const text = serializeString(string)

	// eslint-disable-next-line unicorn/no-array-reduce
	return leaf.marks.reduce((children, mark) => {
		for (const rule of rules) {
			if (!rule.serialize) continue
			const ret = rule.serialize(mark, children)
			if (ret === null) return undefined
			if (ret) return addKey(ret)
		}

		// eslint-disable-next-line no-console
		console.error(
			new Error(`No serializer defined for mark of type "${mark.type}".`)
		)
		return children
	}, text)
}

const serializeNode = node => {
	if (node.object === 'text') {
		const {leaves} = node
		return leaves.map(element => serializeLeaf(element))
	}

	const prevKey = leafKey
	leafKey = 0
	const children = node.nodes.map(element => serializeNode(element))
	leafKey = prevKey

	for (const rule of rules) {
		if (!rule.serialize) continue
		const ret = rule.serialize(node, children)
		if (ret === null) return
		if (ret) return addKey(ret)
	}

	// eslint-disable-next-line no-console
	console.error(
		new Error(`No serializer defined for node of type "${node.type}".`)
	)
	return children
}

const toReact = value => {
	const nodes = value.document ? value.document.nodes : value.nodes
	const elements = nodes.map(element => serializeNode(element)).filter(Boolean)

	return elements
}

export default toReact
