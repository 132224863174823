import {getBg, styled} from 'app/styles'
import {useHouseSearchOptions} from 'app/_server/queries/houses'
import {_} from 'plugins/i18n'
import React, {useState} from 'react'
import Icon, {glyphs} from 'app/Components/Icon'
import Loading from '../Loading'
import SelectInput from './SelectInput'

const StyledSelect = styled(SelectInput)`
	width: 100%;
	background-color: ${getBg};
	flex: ${p => p.flex};
`

// const CountryLabel = styled.span`
// 	white-space: nowrap;
// 	.Select-menu-outer & {
// 		font-weight: bold;
// 	}
// `
const RegionLabel = styled.span`
	white-space: nowrap;
	.Select-menu-outer & {
		margin-left: 0.5em;
	}
`

const Placeholder = (
	<div className="flex w-full items-center justify-start gap-4">
		<div className="inline-flex w-full items-center justify-start">
			<Icon glyph={glyphs.pin} />
		</div>
		{_('rentalRegion')}
	</div>
)

const makeOptions = (locations: SearchRentalsOptions['locations']) => {
	const countriesAndRegions: any[] = []
	if (locations)
		for (const {countryId, regionIds} of locations) {
			// countriesAndRegions.push({
			// 	value: countryId,
			// 	label: <CountryLabel>{_`${countryId}`}</CountryLabel>,
			// })
			if (regionIds)
				for (const regionId of regionIds) {
					countriesAndRegions.push({
						value: regionId,
						label: (
							<RegionLabel>
								{_`${countryId}`} / {_`${regionId}`}
							</RegionLabel>
						),
					})
				}
		}
	return countriesAndRegions
}

const RegionsSelectInput = ({
	onChange,
	regionIds = [],
	...props
}: {
	readonly onChange: (v: I18nKey[]) => any
	readonly regionIds?: I18nKey[]
}): JSX.Element | null => {
	const {data, loading} = useHouseSearchOptions()
	const [selected, setSelected] = useState(regionIds)

	if (loading && !data) return <Loading />
	if (!data) return null

	const {locations}: SearchRentalsOptions = (
		data as {searchOptions: SearchRentalsOptions}
	).searchOptions
	const countriesAndRegions: any[] = makeOptions(locations)

	const handleChange = (v: I18nKey[]) => {
		if (v !== selected) {
			setSelected(v)
			onChange?.(v)
		}
	}

	return (
		<StyledSelect
			{...{
				name: 'regionIds',
				placeholder: Placeholder,
				noLabel: true,
				...props,
				multi: true,
				options: countriesAndRegions,
				value: selected,
				onChange: handleChange,
			}}
		/>
	)
}

export default RegionsSelectInput
