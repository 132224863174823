import React from 'react'
import {makeHouseTemplateData} from './helpers'
import {useI18n} from 'plugins/i18n'
import {useResponsive} from 'app/styles'
import HousedetailDescription from 'app/Components/House/HousedetailDescription'
import {Template} from 'plugins/templates'
import HousedetailTabs from 'app/Components/House/HousedetailTabs'
import {SpacesWrap} from 'app/Components/Layout/Display'
import {ReservationCard} from 'app/Components/Reservation'
import ReviewsScore from 'app/Components/House/ReviewsScore'
import ReviewsCarousel from 'app/Components/House/ReviewsCarousel'
import MiniMap from './MiniMap'
import {useSelector} from 'plugins/redux/hooks'
import MobileHousePage from './MobileHousePage'

const HousePage: React.FC<{readonly house: House}> = ({house}) => {
	const i18n = useI18n()
	const {isPhone} = useResponsive('isPhone')
	const searchedPeriod = useSelector(state => state.searchRentals.period)

	const templateData = makeHouseTemplateData(i18n, house)

	return isPhone ? (
		<MobileHousePage house={house} />
	) : (
		<>
			<Template id="houseInfoTop" templateData={templateData} />
			<SpacesWrap volumes={[8, 2]}>
				<div className="flex w-full items-start justify-center gap-8">
					<HousedetailDescription house={house} />
					<div className="flex w-[35%] flex-col items-end justify-center">
						<ReservationCard {...{...house, searchedPeriod}} />
						<ReviewsScore value={house.score} />
						<ReviewsCarousel value={house.reviews} />
						<MiniMap regionId={house.regionId} />
					</div>
				</div>
			</SpacesWrap>
			<HousedetailTabs house={house} />
			<Template id="houseInfoBottom" templateData={templateData} />
		</>
	)
}

export default HousePage
