import {addDays, subWeeks} from 'date-fns'
import {calcNumDays, fixDate} from './dateHelpers'
import {roundMoney} from './helpers'

/**
 * @param {Pick<
 * 	Contract,
 * 	'contractDate' | 'beginDate' | 'acceptLowerDeposit' | 'stripeCId'
 * > & {
 * 	prices: Pick<
 * 		Contract['prices'],
 * 		'depositTotal' | 'sellTotal' | 'guaranteePayNow'
 * 	>
 * }} args
 * @returns {DueDate[]}
 */
export const calcDueDates = ({
	contractDate,
	beginDate,
	prices,
	acceptLowerDeposit,
	stripeCId,
}) => {
	if (!contractDate || !beginDate || !prices)
		throw new Error(`calcDueDates: some arguments are missing`)

	const contractD = new Date(contractDate)
	const beginD = new Date(beginDate)

	/** @type {DueDate[]} */
	const dueDates = []

	const depositPayDate = fixDate(addDays(contractD, 3))
	// totalDate is just to check if we should create deposit dueDate.
	const totalDate = fixDate(subWeeks(beginD, 6))

	const totalDueDate =
		contractDate < beginDate && calcNumDays(contractDate, beginDate) > 6 * 7 // six weeks
			? fixDate(subWeeks(beginD, 6))
			: fixDate(addDays(contractD, 3))

	if (!acceptLowerDeposit && depositPayDate < totalDate)
		dueDates.push({
			amount: prices.depositTotal,
			date: depositPayDate,
			isDeposit: true,
		})
	dueDates.push({
		amount: prices.sellTotal,
		date: totalDueDate,
	})
	if (prices.guaranteePayNow)
		dueDates.push({
			amount: roundMoney(prices.sellTotal + prices.guaranteePayNow),
			date: fixDate(addDays(beginD, stripeCId ? -1 : -14)),
			isGuarantee: true,
		})
	return dueDates
}

/**
 * @param {{
 * 	dueDates: Contract['dueDates']
 * 	paid?: Contract['paid']
 * }} args
 */
export function getUnpaidDueDates({dueDates = [], paid = 0}) {
	const unpaidDueDates = (dueDates || []).filter(o => o.amount > paid)
	if (!unpaidDueDates.length) return null
	return unpaidDueDates
}

/**
 * @param {{
 * 	dueDates?: DueDate[]
 * 	date?: string
 * 	paid?: number
 * }} args
 * @returns {number}
 */
export function getOverdueAmount(args) {
	const {
		dueDates,
		paid = 0,
		date: currentDate = fixDate(new Date()),
	} = args || {}

	const required = dueDates?.find(d => d.date <= currentDate)?.amount || 0
	return Math.max(0, required - paid)
}

/**
 * @param {{
 * 	dueDates: DueDate[] | undefined
 * 	currentDate?: string
 * 	paid?: number
 * }} args
 * @returns {number | null}
 */
export function getCurrentDueDateAmount({
	dueDates,
	paid = 0,
	currentDate = fixDate(new Date()),
}) {
	const notPaidDueDates = getUnpaidDueDates({dueDates, paid})
	if (!notPaidDueDates) return null

	const futureDueDates =
		notPaidDueDates &&
		notPaidDueDates.filter(dueDate => dueDate.date >= currentDate)

	let currentAmount
	if (futureDueDates.length) {
		// if has future unpaid due dates, get amount from first
		const amountsToPay = futureDueDates && futureDueDates.map(i => i.amount)
		currentAmount = Math.min(...amountsToPay)
	} else {
		// if all due dates has passed get the final due date amount
		currentAmount = Math.max(...notPaidDueDates.map(d => d.amount))
	}

	return currentAmount
}

/**
 * @param {{
 * 	dueDates: DueDate[] | undefined
 * 	currentDate: string
 * 	paid?: number
 * }} args
 */
export function getCurrentAmountToPay({dueDates, paid, currentDate}) {
	const currentDueDateAmount = getCurrentDueDateAmount({
		dueDates,
		paid,
		currentDate,
	})
	if (!currentDueDateAmount) return 0
	return paid ? currentDueDateAmount - paid : currentDueDateAmount
}
