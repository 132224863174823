import * as React from 'react'
import SlickSlider from 'app/Components/Carousels/SlickSlider'
import {GoogleIcon, StarIconNew} from 'app/Components/Icon'
import {useI18n} from 'plugins/i18n'
import {useTranslationQuery} from 'app/_server/queries/translations'

const settings = {
	dots: 0,
	slidesToShow: 1,
	slidesToScroll: 1,
	draggable: 1,
	lazyLoad: 0,
	infinite: 1,
	autoplay: 0,
	autoplaySpeed: 5000,
	pauseOnHover: 1,
	adaptiveHeight: false,
	arrows: false,
	variableWidth: true,
}

const ReviewsSlider: React.FC<{
	readonly reviews: House['reviews']
	readonly viaGoogle?: boolean
}> = ({reviews = [], viaGoogle}) => {
	const {lang} = useI18n()

	const randomizedOrder = [...reviews].sort(() => 0.5 - Math.random()) || []

	return (
		<div className="ml-5 w-full">
			<SlickSlider {...{...settings}}>
				{randomizedOrder.map(
					({countryId, textI18n, startDate, score: scoreOrg, name}) => {
						const countryKey = countryId?.split('$')[1]
						const {data} = useTranslationQuery(
							{key: countryKey, section: 'COUNTRY'},
							{skip: !countryId}
						)

						const score = viaGoogle ? `${Math.floor(scoreOrg / 2)}/5` : scoreOrg
						const countryName = data?.translation?.text
						const text =
							textI18n[lang] || Object.values(textI18n).find(t => !!t)

						return (
							<div
								className="mr-2 flex max-w-[301px] flex-col space-y-3 overflow-hidden rounded-lg bg-slate-100 bg-white p-4"
								key={startDate}
							>
								<div className="bg-secondary text-onSecondary flex max-w-20 flex-row items-center justify-evenly rounded-lg px-2 py-1">
									<StarIconNew className="fill-onSecondary" />
									{score}
								</div>
								<div className="flex flex-row space-x-2">
									<span className="text-onTertiary text-sm leading-none font-bold">
										{name}
									</span>
									<span className="text-sm leading-none font-bold text-[#888]">
										{countryName}
									</span>
								</div>
								<div className="text-[#5D5D5D]">{text}</div>
								{viaGoogle && (
									<div className="flex flex-row items-center space-x-1">
										<span className="text-sm leading-none text-[#888]">
											Review via
										</span>
										<GoogleIcon
											width="3em"
											height="1em"
											className="fill-onSecondary mt-1"
										/>
									</div>
								)}
							</div>
						)
					}
				)}
			</SlickSlider>
		</div>
	)
}

export default ReviewsSlider
