import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {styled} from 'app/styles'

const {mapKey} = __CONFIG__

const MapWrapper = styled.div`
	position: relative;
	overflow: hidden;
	box-shadow: ${p => p.allowScroll && `0px 0 50px rgba(0,0,0,0.2)`};
	min-height: 300px;
	iframe {
		pointer-events: ${p => !p.allowScroll && `none`};
		vertical-align: top;
	}
`
const MapOverlay = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
	box-shadow: inset 0px 0 50px rgba(0, 0, 0, 0.2);
	cursor: pointer;
`
const Img = styled.img`
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 300px;
`

class GmapEmbed extends Component {
	static propTypes = {
		isPhone: PropTypes.bool,
		googlePlaceId: PropTypes.string,
		lat: PropTypes.number,
		lng: PropTypes.number,
		q: PropTypes.string,
	}

	state = {
		scroll: false,
	}

	render() {
		const {isPhone, googlePlaceId, lat, lng, q, ...rest} = this.props
		const {scroll} = this.state
		let search
		if (googlePlaceId) {
			search = `place_id:${googlePlaceId}`
		} else if (lat || lng) {
			search = `${lat},${lng}`
		} else {
			search = q
		}
		if (!search) {
			return <div />
		}
		search = encodeURIComponent(search)
		return (
			<MapWrapper
				{...rest}
				allowScroll={scroll}
				onMouseOut={() => this.setState({scroll: false})}
			>
				{isPhone ? (
					<a
						href={`https://www.google.com/maps/place?q=${search}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Img
							src={`https://maps.googleapis.com/maps/api/staticmap?center=${search}&zoom=14&size=600x300&key=${mapKey}${
								lat && lng
									? `&markers=size:large%7Ccolor:0xff0000%7Clabel:%7C${lat},+${lng}`
									: ''
							}`}
						/>
					</a>
				) : (
					<iframe
						src={`https://www.google.com/maps/embed/v1/place?q=${search}&zoom=15&key=${mapKey}`}
						width="100%"
						height="400px"
					/>
				)}
				{!scroll && (
					<MapOverlay onClick={() => this.setState({scroll: true})} />
				)}
			</MapWrapper>
		)
	}
}

export default connect(({responsive}) => ({
	isPhone: responsive.isPhone,
}))(GmapEmbed)
