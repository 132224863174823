import {gql} from 'graphql-tag'
import {makeMutationHook, makeQueryHook} from './helpers'

export const taxPlatformsQuery = gql`
	query taxPlatformsQuery {
		taxPlatforms {
			id
			name
			url
			comms {
				type
				data
				note
			}
		}
	}
`
export const useTaxPlatforms = makeQueryHook<
	{taxPlatforms: Pick<TouristTaxPlatform, 'id' | 'name'>[]},
	never
>(taxPlatformsQuery)

const taxPlatformFields = `
	id
	name
	url
	comms {
		type
		data
		note
	}
	bank {
		IBAN
		BIC
		SWIFT
		name
		number
		address {
			suite
			unit
			street
			pcode
			city
			region
			countryId
		}
	}
	taxes {
		startDate
		additionalRate
		maxTax
		byCategory
	}
`

export const taxPlatformQuery = gql`
	query taxPlatformQuery($id: Int!) {
		taxPlatform(id: $id) {
			${taxPlatformFields}
		}
	}
`
export const useTaxPlatform = makeQueryHook<
	{taxPlatform: TouristTaxPlatform},
	{id: number}
>(taxPlatformQuery, undefined, v => ({skip: !v?.id}))

export const saveTaxPlatformMutation = gql`
	mutation saveTaxPlatform($taxPlatform: TaxPlatformInput!) {
		saveTaxPlatform(taxPlatform: $taxPlatform) {
			${taxPlatformFields}
		}
	}
`

export const useSaveTaxPlatform = makeMutationHook<
	TouristTaxPlatform,
	{taxPlatform: TouristTaxPlatform}
>(saveTaxPlatformMutation, {refetchQueries: ['taxPlatformsQuery']})
