import React from 'react'
import PropTypes from 'prop-types'
import {styled} from 'app/styles'
import {CloseIcon} from 'app/Components/Icon'
import {ThemedBox} from 'app/Components/Layout'

const PillWrapper = styled(p => <ThemedBox {...p} />)`
	border-radius: 1rem;
	text-align: center;
	padding: 0 0.3rem;
`

export const Pill = ({children, onClose}) => {
	return (
		<PillWrapper inline gap="0.5em">
			<span>{children}</span>
			<CloseIcon className="cursor-pointer" onClick={onClose} size="0.6em" />
		</PillWrapper>
	)
}
Pill.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func,
}
