import wrapApp from './wrapApp'

const initClientHooks = (/** @type {Hooks} */ hooks) => {
	hooks.render.initRenderContext.tap('config', context => {
		// grab our data from SSR
		context.__CONFIG__ = context.state.__CONFIG__
	})
	hooks.render.wrapApp.tap('config', wrapApp)
}

export default initClientHooks
