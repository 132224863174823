import {useI18n} from 'plugins/i18n'
import React from 'react'

const ViewVacationHousesButton = () => {
	const {getText} = useI18n()
	return (
		<button
			type="button"
			className="border-primary bg-onPrimary text-primary m-4 w-5/6 self-center rounded-md border border-solid p-2"
		>
			{getText('discoverOurVacationHouses')}
		</button>
	)
}
export default ViewVacationHousesButton
