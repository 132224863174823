import {makeSplitPoint} from 'plugins/react'
import Value from './TestimonialSection'

export default {
	label: 'testimonial',
	group: 'content',
	Value,
	defaultValue: {
		author: undefined,
		textI18n: undefined,
		imageId: undefined,
	},
	Form: makeSplitPoint(() => import('./TestimonialForm'), {ssr: false}),
}
