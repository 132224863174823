// Keep initError last so it wraps everything
import initReactClient from 'plugins/react/initClient'
import initReactSSRClient from 'plugins/react-ssr/initClient'
import initReactConfigClient from 'plugins/react-config/initClient'
import initIdGeneratorClient from 'plugins/id-generator/initClient'
import initTemplatesClient from 'plugins/templates/initClient'
import initI18nClient from 'plugins/i18n/initClient'
import initRegionUrlsClient from 'plugins/region-urls/initClient'
import initIconsClient from 'plugins/icons/initClient'
import initApolloClient from 'plugins/apollo/initClient'
import initReactRouterDeluxeClient from 'plugins/react-router-deluxe/initClient'
import initReduxClient from 'plugins/redux/initClient'

import initLocalClient from './initClient'

import initReactHelmetClient from 'plugins/react-helmet/initClient'
import initErrorReporterClient from 'plugins/error-reporter/initClient'

const plugins = [
	initReactClient,
	initReactSSRClient,
	initReactConfigClient,
	initIdGeneratorClient,
	initTemplatesClient,
	initI18nClient,
	initRegionUrlsClient,
	initIconsClient,
	initApolloClient,
	initReactRouterDeluxeClient,
	initReduxClient,

	initLocalClient,

	initReactHelmetClient,
	initErrorReporterClient,
]

export default plugins
