import React from 'react'
import PropTypes from 'prop-types'
import {Box} from 'app/Components/Layout'
import Description from './Description'
import Features from './Features'
import {Button as OrigButton} from 'app/Components/Buttons'
import {Preview} from '.'
import {_, useI18n} from 'plugins/i18n'
import {useDispatch} from 'plugins/redux/hooks'
import {setHouseName} from 'state/ducks/searchRentals-duck'
import {styled, lightBeigeColor, useResponsive} from 'app/styles'
import {useHistory} from 'plugins/react-router-deluxe'
import {urlForHouse} from 'app/lib'

const Button = styled(OrigButton)`
	background: ${lightBeigeColor};
	white-space: nowrap;
`

export const FeaturedBase = ({isPhone, house, onReadMore}) => {
	const {
		bathroomCount,
		bedroomCount,
		capacity,
		description,
		name,
		type,
		regionId,
		placeName,
	} = house

	const houseFeatures = [
		{value: capacity, icon: 'peopleNew'},
		{value: bedroomCount, icon: 'bedNew'},
		{value: bathroomCount, icon: 'bathNew'},
	]

	const features = (
		<Features
			{...{houseFeatures, colorSet: 'capGrey', row: !isPhone, isPhone}}
		/>
	)

	return (
		<Preview {...{house, column: isPhone, ratio: 1.33}}>
			<Box column={!isPhone} padding={isPhone ? '0' : '0 4em 0 2em'}>
				<Description
					{...{
						description,
						fontResize: isPhone ? 1 : 1.3,
						name,
						onClick: () => isPhone && onReadMore(),
						type,
						regionId,
						placeName,
						bottom: !isPhone && (
							<div className="flex w-full items-center justify-start gap-4">
								<Button className="text-capGold" large onClick={onReadMore}>
									...{_`readMore`}
								</Button>
								{features}
							</div>
						),
					}}
				/>
				{isPhone && features}
			</Box>
		</Preview>
	)
}
FeaturedBase.propTypes = {
	isPhone: PropTypes.bool,
	house: PropTypes.object,
	setHouseName: PropTypes.func,
	onReadMore: PropTypes.func,
}
const Featured = ({house}) => {
	const i18n = useI18n()
	const history = useHistory()
	const dispatch = useDispatch()
	const {isPhone} = useResponsive('isPhone')

	const handleClearNameSearch = () => {
		dispatch(setHouseName(''))
	}

	const handleReadMore = () => {
		history.push(urlForHouse({house, lang: i18n.lang}))
		handleClearNameSearch()
	}

	return (
		<FeaturedBase {...{i18n, house, isPhone, onReadMore: handleReadMore}} />
	)
}

Featured.propTypes = {
	house: PropTypes.object,
}

export default Featured
