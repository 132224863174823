import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {createSelector} from 'plugins/react'

const initialState: string[] = []

const visitedHousesSlice = createSlice({
	name: 'visitedHouses',
	initialState,
	reducers: {
		addVisited(state, action: PayloadAction<string>) {
			const houseId = action.payload
			const prevVisitedWithoutCurrentHouseId = state.filter(
				id => id !== houseId
			)
			const visited = [houseId, ...prevVisitedWithoutCurrentHouseId].slice(
				0,
				24
			)
			return visited
		},
	},
})

export const {addVisited} = visitedHousesSlice.actions

export default visitedHousesSlice.reducer

const getVisitedArr = (state: {visitedHouses: string[]}) => state.visitedHouses

export const selectors = {
	getVisitedArr,
	getVisitedLength: createSelector(
		getVisitedArr,
		visitedArr => visitedArr.length
	),
}
