import PropTypes from 'prop-types'
import React from 'react'
import Icon, {glyphs} from 'app/Components/Icon'
import Dropdown from 'app/Components/Inputs/Dropdown'
import * as rs from 'react-share'
import {Align} from 'app/Components/Layout/Display'
import {Box} from 'app/Components/Layout'
import styled from 'styled-components'
import {_} from 'plugins/i18n'
import {CapLightTheme} from 'app/styles'
import {useUpload} from 'plugins/images/react/FileFromId'
import Loading from 'app/Components/Loading'
const {
	FacebookShareButton,
	TwitterShareButton,
	PinterestShareButton,
	WhatsappShareButton,
	EmailShareButton,
	FacebookIcon,
	TwitterIcon,
	PinterestIcon,
	WhatsappIcon,
	EmailIcon,
} = rs.default || rs

const StyledIcon = styled(Icon).attrs({primary: true})`
	margin-right: 0.5em;
`
const MediaBtnsWrap = styled(Box).attrs({
	gap: '1em',
	spaceBetween: true,
	fitChildren: true,
})`
	text-align: center;
	> * {
		cursor: pointer;
	}
`

export const SocialMediaList = ({shareUrl, title, description, imageId}) => {
	const {upload, loading} = useUpload(imageId)
	if (loading) return <Loading />

	const mergedText =
		title && description
			? `${title} ${description}`
			: title
				? title
				: description
					? description
					: ''

	const imageUrl =
		globalThis.location &&
		upload?.filename &&
		`${location.protocol}//${location.host}/upload/${upload.filename}`

	return (
		<MediaBtnsWrap>
			<FacebookShareButton url={shareUrl} quote={description || title}>
				<FacebookIcon size={40} round />
			</FacebookShareButton>
			<TwitterShareButton
				url={shareUrl}
				title={
					mergedText.length > 280 ? `${mergedText.slice(0, 279)}…` : mergedText
				}
			>
				<TwitterIcon size={40} round />
			</TwitterShareButton>
			{imageUrl && (
				<PinterestShareButton
					url={shareUrl}
					description={mergedText}
					media={imageUrl}
				>
					<PinterestIcon size={40} round />
				</PinterestShareButton>
			)}
			<WhatsappShareButton url={shareUrl} title={title}>
				<WhatsappIcon size={40} round />
			</WhatsappShareButton>
			<EmailShareButton url={shareUrl} title={title} body={description}>
				<EmailIcon size={40} round />
			</EmailShareButton>
		</MediaBtnsWrap>
	)
}
SocialMediaList.propTypes = {
	shareUrl: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	imageUrl: PropTypes.string,
}

const Text = styled(Box)`
	font-size: 12px;
`
const ShareButton = ({imageId, expanded, title, description}) => {
	// A bit of a hack. Maybe use Redux?
	const shareUrl = globalThis.window && globalThis.window.location.href
	if (!title) title = globalThis.document && globalThis.document.title
	if (!description) {
		const descriptionTag =
			globalThis.document &&
			globalThis.document.querySelector('meta[name="description"]')
		description = descriptionTag ? descriptionTag.content : ''
	}
	const list = (
		<SocialMediaList
			shareUrl={shareUrl}
			imageId={imageId}
			title={title}
			description={description}
		/>
	)
	if (expanded) return list
	return (
		<CapLightTheme>
			<Dropdown
				placeholder={
					<Align position="left" vAlign="middle" inline>
						<StyledIcon glyph={glyphs.share} size="1.5rem" />
						<Text>{_('share')}</Text>
					</Align>
				}
				type="button"
				noWrap
			>
				{list}
			</Dropdown>
		</CapLightTheme>
	)
}

ShareButton.propTypes = {
	imageId: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	expanded: PropTypes.bool,
}

export default ShareButton
