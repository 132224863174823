import {makeSplitPoint} from 'plugins/react'
import Value from './Filler'

export default {
	label: 'Filler',
	group: 'content',
	Value,
	defaultValue: {height: '1.5em'},
	InlineForm: makeSplitPoint(() => import('./FillerForm'), {ssr: false}),
}
