import {
	EMAIL_FAILED,
	IS_AGENT_PAYMENT,
	IS_DEPOSIT_PAYMENT,
	IS_GUARANTEE_PAYMENT,
	IS_SALDO_PAYMENT,
	SEND_CONTRACT_CONFIRMATION_TO_CLIENT,
	SEND_CONTRACT_CONFIRMATION_TO_AGENT,
	IS_NOT_SALDO_PAYMENT,
	IS_GUARANTEE_BLOCK,
	IS_GUARANTEE_REFUND,
	SEND_CONTRACT_CONFIRMATION_TO_CAP,
} from '../constants'

const isProd = process.env.NODE_ENV === 'production'

/*
 * Interpolates values in the string: `{{key}}` => `value`
 */
export const _interpolateString = (
	string: string,
	values: Record<string, string>
) => {
	if (!string) return ''
	return string.replace(
		/{{([^}]+)}}/g,
		(_, p1) => values[p1.toUpperCase()] || ''
	)
}

const interpolateRecurse = (text, values) => {
	if (typeof text === 'string') {
		return _interpolateString(text, values)
	}
	if (text !== null && typeof text === 'object') {
		const out = Array.isArray(text) ? [] : {}
		for (const key of Object.keys(text)) {
			out[key] = interpolateRecurse(text[key], values)
		}
		return out
	}
	return text
}

export const interpolate = (text, values = {}) => {
	const upperValues = {}
	for (const key of Object.keys(values)) {
		upperValues[key.toUpperCase()] = values[key]
	}
	return interpolateRecurse(text, upperValues)
}

export const throwAndDispatchEmailFailedError = async ({
	id,
	rejectReason,
	err,
	extraData,
	models,
	noThrow,
}: {
	id: string | number
	rejectReason: string
	err?: any
	models: Models
	extraData?: any
	noThrow?: boolean
}) => {
	await models.dispatch(EMAIL_FAILED, {
		...extraData,
		err,
		id,
		rejectReason,
	})

	if (noThrow) return

	const error = err || new Error(`Email failed. Reject reason: ${rejectReason}`)
	throw error
}

export const selectPaymentType = ({
	agentId,
	amount,
	contract,
	isBlocked,
	isGuarantee,
}: {
	agentId?: Agent['id']
	amount: Payment['amount']
	contract?: Contract
	isBlocked?: boolean
	isGuarantee?: boolean
}) => {
	if (!contract?.id && !agentId)
		throw new Error(
			`selectPaymentType: both contractId and agentId are undefined`
		)

	if (agentId) return IS_AGENT_PAYMENT

	if (contract?.id) {
		/** @type {Contract} */

		// NOTE: this is deriver, so paid contains current amount already
		const {isMailingToClientDisabled, paid: paidWithAmount, prices} = contract
		if (isMailingToClientDisabled) return

		if (isGuarantee && isBlocked) return IS_GUARANTEE_BLOCK

		if (isGuarantee && amount <= 0) return IS_GUARANTEE_REFUND

		const {sellTotal, depositTotal, guaranteePayNow} = prices

		// incomplete deposit
		if (paidWithAmount < depositTotal) return IS_NOT_SALDO_PAYMENT
		// deposit paid, but still not saldo
		if (depositTotal <= paidWithAmount && paidWithAmount < sellTotal)
			return IS_DEPOSIT_PAYMENT
		// saldo paid, but still not guarantee (if not payThere)
		if (
			sellTotal <= paidWithAmount &&
			(!guaranteePayNow || paidWithAmount < sellTotal + guaranteePayNow)
		)
			return IS_SALDO_PAYMENT
		// saldo and guarantee (if not payThere) fully paid
		if (guaranteePayNow && sellTotal + guaranteePayNow <= paidWithAmount)
			return IS_GUARANTEE_PAYMENT
	}

	const errMsg = `selectPaymentType: it was impossible to define payment type (agentId: ${agentId}, contractId: ${contract?.id})`

	if (isProd) {
		// eslint-disable-next-line no-console
		console.error(new Error(errMsg))
	} else {
		throw new Error(errMsg)
	}
}

export const dispatchSendConfirmationEmails = async ({
	dbContracts,
	contractId,
	sendToClient,
	subjectForClient,
	messageForClient,
	subjectForAgent,
	messageForAgent,
	sendToAgent,
}: {
	dbContracts: Contracts
	contractId: Contract['id']
	sendToClient: boolean
	sendToAgent: boolean
	subjectForClient?: string
	subjectForAgent?: string
	messageForClient?: string
	messageForAgent?: string
}) => {
	const contract: Contract = await dbContracts.get(contractId)

	await dbContracts.dispatch(SEND_CONTRACT_CONFIRMATION_TO_CAP, {
		id: contractId,
		subject: subjectForClient,
		message: messageForClient,
	})

	if (sendToClient && !contract?.isMailingToClientDisabled)
		await dbContracts.dispatch(SEND_CONTRACT_CONFIRMATION_TO_CLIENT, {
			id: contractId,
			subject: subjectForClient,
			message: messageForClient,
		})

	if (sendToAgent)
		await dbContracts.dispatch(SEND_CONTRACT_CONFIRMATION_TO_AGENT, {
			id: contractId,
			subject: subjectForAgent,
			message: messageForAgent,
		})
}
