import React from 'react'
import styled from 'styled-components'
import {lightBeigeColor} from 'app/styles'
import {SpacesWrap} from 'app/Components/Layout/Display'
import {useQuery} from 'plugins/apollo'
import {housesListQuery} from 'app/_server/queries/houses'
import {_, useI18n} from 'plugins/i18n'
import {urlForSearch} from 'app/lib'
import {HOUSE_GENERAL_KEY} from 'app/_server/database/constants'
import {HousesList} from 'app/Components/Sections/HousesList'

const StyledSpacesWrap = styled(SpacesWrap)<{withBeigeBg?: boolean}>`
	background: ${p => p.withBeigeBg && lightBeigeColor};
`

const joinWords = (words: string[], andWord = 'and'): string =>
	words.length <= 2
		? words.join(` ${andWord} `)
		: words.slice(0, -1).join(', ') + ` ${andWord} ` + words.slice(-1)

const calcRegionsString = (regionIds: string[], i18n: any): string =>
	joinWords(
		regionIds.map(id => i18n.getText(id)),
		i18n.getText('a$and')
	)

interface BlogPostRegionHousesSectionProps {
	readonly templateData?: {
		regionIds: I18nKey[]
	} & Record<string, any>
}

const BlogPostRegionHousesSection: React.FC<
	BlogPostRegionHousesSectionProps
> = ({templateData}) => {
	const {regionIds} = templateData || {}
	const i18n = useI18n()
	const {loading, data} = useQuery(housesListQuery, {
		variables: {
			regionIds,
			limit: 4,
		},
	})

	const houses: FullHouse[] = data?.houses?.items || []

	if (!regionIds?.length) return null

	const title = `${i18n.getText('housesInRegion')} ${
		calcRegionsString(regionIds, i18n) || ''
	}`

	const showMorePath = urlForSearch({
		i18n,
		filters: {houseType: HOUSE_GENERAL_KEY, region: regionIds},
	})

	return (
		<StyledSpacesWrap volumes={[8, 2]}>
			<HousesList
				{...{
					houses,
					title,
					showMoreLabel: _`showAllRegionHouses`,
					showMorePath,
					loading: loading && !data,
				}}
			/>
		</StyledSpacesWrap>
	)
}

export default BlogPostRegionHousesSection
