import React from 'react'
import {styled, getSecondary, capGreenColor, useResponsive} from 'app/styles'
import {Box, ThemedBox} from 'app/Components/Layout'
import {HtmlContent} from 'plugins/rich-text'
import {PlainText} from 'plugins/i18n'
import {HeadBox, SpacesWrap} from 'app/Components/Layout/Display'

const Wrapper = styled(ThemedBox)`
	width: 100%;
	justify-content: center;
`

const Content = styled(Box)`
	max-width: 55rem;
	text-align: center;
`

const TitleText = styled.span`
	display: inline;
	color: ${capGreenColor};
	&:first-child {
		&:after {
			content: ' ';
		}
	}
`

const Description = styled(HtmlContent)`
	color: ${getSecondary};
`

export const RentYourHouseIntroSection: React.FC<{
	readonly value: {
		headingI18n: any
		textI18n: any
	}
}> = ({value}) => {
	const {headingI18n, textI18n} = value
	const {isMobile} = useResponsive('isMobile')

	return (
		<SpacesWrap volumes={isMobile ? [8, 1] : [14, 4]}>
			<Wrapper spaceBetween column={isMobile}>
				<Content column top>
					<HeadBox lvl={2}>
						<PlainText Component={TitleText} i18n={headingI18n} />
					</HeadBox>
					<PlainText Component={Description} i18n={textI18n} />
				</Content>
			</Wrapper>
		</SpacesWrap>
	)
}

export default RentYourHouseIntroSection
