import Value from './CtaSection'
import {makeSplitPoint} from 'plugins/react'

export default {
	label: 'Call to action',
	group: 'content',
	Value,
	defaultValue: {image1Id: [], image2Id: [], image3Id: []},
	Form: makeSplitPoint(() => import('./CtaForm'), {ssr: false}),
}
