import Value from './PopHouses'
import {makeSplitPoint} from 'plugins/react'

export default {
	label: 'Popular Houses',
	group: 'content',
	Value,
	Form: makeSplitPoint(() => import('./PopHousesForm'), {ssr: false}),
	defaultValue: {regionId: null, specialId: null},
}
