import React from 'react'
import {gql} from 'graphql-tag'
import {styled, getFg} from 'app/styles'
import Image from './Image'
import NoImageIcon from './NoImageIcon'
import Box from 'app/Components/Layout/Box'
import {Icon} from 'app/Components/Icon'
import pdfGlyph from './file-pdf.icon.svg'
import fileGlyph from './file-generic.icon.svg'
import {graphql, useQuery} from 'plugins/apollo'

export const imageMimeTypes = [
	'image/jpeg',
	'image/gif',
	'image/png',
	'image/svg',
]

const uploadQuery = gql`
	query upload($value: ID!) {
		upload(id: $value) {
			id
			filename
			mime
			size
			ts
			meta
		}
	}
`

export const uploadQueryWithOpts = [uploadQuery, {skip: ({value}) => !value}]

export const useUpload = uploadId => {
	const {loading, data, error} = useQuery(uploadQuery, {
		variables: {value: uploadId},
		skip: !uploadId,
	})
	const upload = loading || data?.upload?.id !== uploadId ? null : data?.upload
	return {upload, loading, error}
}

const Desc = styled(Box)`
	margin: 0.5em auto 0;
	color: ${getFg};
`

const FileFromId: React.FC<{
	readonly data?: {
		upload?: {
			id: string
			path: string
			meta: Record<string, any>
			mime?: string
			filename?: string
		}
		loading?: boolean
	}
	readonly noPlaceholder?: boolean
	readonly imageProps?: React.ImgHTMLAttributes<HTMLImageElement>
}> = ({data, noPlaceholder, imageProps}) => {
	if (!data || data.loading || !data.upload) {
		if (noPlaceholder) {
			return <div />
		}
		return (
			<div className="flex min-h-full w-full items-center justify-center">
				<NoImageIcon />
			</div>
		)
	}

	if (data && !data.loading && data.upload) {
		if (imageMimeTypes.includes(data.upload.mime)) {
			const {meta, ...rest} = data.upload
			return <Image contain {...imageProps} value={{...meta, ...rest}} />
		}

		let fileIcon
		switch (data.upload.mime) {
			case 'application/pdf': {
				fileIcon = <Icon glyph={pdfGlyph} width="4em" height="4em" />
				break
			}
			default: {
				fileIcon = <Icon glyph={fileGlyph} width="4em" height="4em" />
			}
		}

		return (
			<a href={`/upload/${data.upload.id}`}>
				<div className="flex min-h-full w-full flex-col items-center justify-center">
					{fileIcon}
					<Desc title={data.upload.mime}>{data.upload.filename}</Desc>
				</div>
			</a>
		)
	}

	return null
}

export default graphql(...uploadQueryWithOpts)(FileFromId)
