import React from 'react'
import {styled, getFg, GreenDarkTheme, useResponsive} from 'app/styles'
import {Box} from 'app/Components/Layout'
import {_, useI18n} from 'plugins/i18n'
import {HtmlContent} from 'plugins/rich-text'

const Title = styled(Box)`
	font-size: 2.2em;
	margin-bottom: 1em;
	position: relative;
	&:after {
		position: absolute;
		content: '';
		top: 1.6em;
		width: 3em;
		height: 1px;
		background-color: ${getFg};
	}
`

const SpecialDescription: React.FC<{
	readonly value: {
		titleI18n: string
		textI18n: {[key: string]: string}
	}
}> = ({value}) => {
	const {lang} = useI18n()
	const {isMobile} = useResponsive('isMobile')

	return (
		<GreenDarkTheme>
			<Box column padding={isMobile ? '2em' : '5em'}>
				<Title left>
					{_`about`}&nbsp;{value.titleI18n}
				</Title>
				<HtmlContent value={value.textI18n[lang] || value.textI18n} />
			</Box>
		</GreenDarkTheme>
	)
}

export default SpecialDescription
