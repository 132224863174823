import React from 'react'
import {makeSplitPoint} from 'plugins/react'
import {useResponsive} from 'app/styles'

const getSlider = () =>
	import('slick-carousel/slick/slick.css')
		.then(() => import('slick-carousel/slick/slick-theme.css'))
		.then(() => import('react-slick'))
const Slider = makeSplitPoint(getSlider)

const SlickSlider = ({children, ...props}) => {
	const {isServer} = useResponsive('isServer')
	return !isServer && children && children.length ? (
		<Slider {...props}>{children}</Slider>
	) : null
}

export default SlickSlider
