import React from 'react'
import {TextInput} from 'app/Components/Inputs'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {setRentalPeriod} from 'app/redux/ducks/searchRentals-duck'
import {_} from 'plugins/i18n'
import {toStringRange} from 'app/lib'
import {openModal} from 'app/redux/ducks/modal'
import {modalId} from '.'
import {CalendarIcon} from 'app/Components/Icon'
import {ThemeProvider} from 'app/styles'

const PeriodInputIcon = () => (
	<div className="pr-4 pl-2">
		<CalendarIcon />
	</div>
)

const PeriodInput: React.FC<{}> = () => {
	const dispatch = useDispatch()
	const value = useSelector(state => state.searchRentals.period)

	return (
		<ThemeProvider theme={{radius: '0.5'}}>
			<TextInput
				name="period"
				value={toStringRange(value, 'DD MMM YY')}
				// change here means clicking X to clear the field
				onChange={() => dispatch(setRentalPeriod({period: undefined}))}
				onClick={() => {
					dispatch(openModal(modalId))
				}}
				placeholder={_('rentalPeriod')}
				Icon={value ? null : PeriodInputIcon}
				labelOnTop
			/>
		</ThemeProvider>
	)
}
export default PeriodInput
