import React from 'react'
import {useDispatch} from 'plugins/redux/hooks'
import {useLocation} from 'react-router-dom'
import {useI18n} from 'plugins/i18n'
import {
	setSearch as setSearchFn,
	clearSearch as clearSearchFn,
} from 'state/ducks/searchRentals-duck'
import {searchBasePath} from 'app/lib'
import BreadcrumbsLayout from './BreadcrumbsLayout'

const SearchBreadcrumbs: React.FC<{
	readonly templateData: {
		regionId: string
		countryId: string
		type: string
		name: string
	}
}> = ({templateData}) => {
	const location = useLocation()
	const dispatch = useDispatch()
	const i18n = useI18n()
	const {getText, getTrSlug} = i18n

	const {pathname} = location
	const {regionId, countryId, type, name} = templateData || {}

	const houseTypeParam = `/${getTrSlug(type)}`
	const countryParam = `/${getTrSlug(countryId)}`
	const regionParam = `/${getTrSlug(regionId)}`

	const clearSearch = () => dispatch(clearSearchFn())
	const setSearch = (search: Record<string, string>) =>
		dispatch(setSearchFn(search))

	const handleClick = (search: Record<string, string>) => {
		clearSearch()
		setSearch(search)
	}

	const breadcrumbs = [
		{
			label: i18n.getText('searchPage'),
			link: searchBasePath(i18n),
			onClick: clearSearch,
		},
		{
			link: `/xx${houseTypeParam}`,
			label: getText(type),
			onClick: () => handleClick({type}),
		},
		{
			link: `/xx${houseTypeParam}${countryParam}`,
			label: getText(countryId),
			onClick: () => handleClick({type, region: countryId}),
		},
		{
			link: `/xx${houseTypeParam}${countryParam}${regionParam}`,
			label: getText(regionId),
			onClick: () => handleClick({type, region: regionId}),
		},
		{
			link: pathname,
			label: name,
		},
	]

	return <BreadcrumbsLayout breadcrumbs={breadcrumbs} narrowRight />
}

export default SearchBreadcrumbs
