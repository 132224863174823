import React from 'react'
import PlainText from './PlainText'

// _('someTr') => <PlainText trId="someTr"/>
// _`someTr${num}` => <PlainText trId="someTr34"/>
const _ = (...args) => {
	let trId
	let asHtml
	const str = args[0]
	if (Array.isArray(str)) {
		trId = str[0]
		for (let i = 1; i < args.length; i++) {
			trId += args[i]
			trId += str[i]
		}
	} else {
		trId = str
		asHtml = args[1]
	}
	return <PlainText trId={trId} asHtml={asHtml} />
}

export default _
