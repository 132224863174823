import React from 'react'
import {HtmlContent} from 'plugins/rich-text'
import IconList from '../HousedetailDescription/IconList'
import {Box} from 'app/Components/Layout'
import {DomainTitle} from '.'
import {
	styled,
	getFg,
	BeigeGreenTheme,
	BeigeTheme,
	useResponsive,
} from 'app/styles'
import {_} from 'plugins/i18n'
import {SloganBox} from 'app/Components/House/HousedetailDescription/SloganBox'
import ShareButtonsList from 'app/Components/House/HousedetailDescription/ShareButtonsList'
import {useSelector} from 'plugins/redux/hooks'
import Btn from 'app/Components/Buttons/Button'
import {formValueSelector} from 'app/Components/Form'
import {Description} from '../HousedetailDescription'
import {FavIcon} from 'app/Components/Icon'

export const TextBox: React.FC<{
	readonly html?: string
	readonly children?: React.ReactNode
}> = ({html, children}) => {
	return (
		<div className="relative text-base">
			{children}
			{html && <HtmlContent value={html} />}
		</div>
	)
}

const HouseDomainDescriptionWrapper = styled(Box).attrs({
	colorSet: 'capDarkGrey',
	column: true,
	gap: '1em',
	left: true,
})`
	color: ${getFg};
`

const Buttons = styled(
	({isFavorite, isPhone, onAddFavorite, reservationPath, slug, ...rest}) => (
		<Box column gap spaceBetween {...rest}>
			<Btn to={reservationPath} disabled={!slug} fullWidth large>
				{_('resRequest')}
			</Btn>
			<BeigeGreenTheme>
				<Btn fullWidth={isPhone} large long={isPhone} onClick={onAddFavorite}>
					<FavIcon />
				</Btn>
			</BeigeGreenTheme>
		</Box>
	)
)`
	margin-top: 1em;
`

const selector = formValueSelector('reservationCard')

const HouseDomainDescription: React.FC<{
	readonly domainData?: Record<string, any>
}> = ({domainData}) => {
	const {isPhone} = useResponsive('isPhone')
	const domainHouse =
		useSelector((state: any) => selector(state, 'domainHouse')) || {}

	if (!domainData) {
		return null
	}

	const {title, description, slogan, regionId, icons} = domainData

	return (
		<HouseDomainDescriptionWrapper>
			<DomainTitle
				title={title}
				regionId={regionId}
				fontResize={isPhone ? 0.8 : 1.5}
			/>
			{slogan && (
				<div>
					<SloganBox slogan={slogan} />
				</div>
			)}
			<Description description={description} isPhone={isPhone} />
			{isPhone && (
				<BeigeTheme>
					<Buttons
						isPhone
						reservationPath={`/xx/reservation/${domainHouse.slug}`}
						slug={domainHouse.slug}
					/>
				</BeigeTheme>
			)}
			<IconList
				inline
				size="2em"
				items={icons && icons.map(icon => ({icon}))}
			/>
			<ShareButtonsList isPhone={isPhone} />
		</HouseDomainDescriptionWrapper>
	)
}
export default HouseDomainDescription
