import PropTypes from 'prop-types'
import React from 'react'
import InfoTip from 'app/Components/Tooltip'
import {styled, mediumGreyColor} from 'app/styles'
import Icon, {glyphs} from 'app/Components/Icon'
import {_} from 'plugins/i18n'
import {Box} from 'app/Components/Layout'

const Item = styled(({noWrap, ...props}) => <Box {...props} />)`
	${p => p.noWrap && 'white-space: nowrap;'};
	margin-bottom: 5px;
`
const ItemBox = styled.div`
	${p => p.inline && 'display: inline-block;'} margin-right: 0.9em;
`
const IconListWrap = styled.div`
	display: inline-block;
`
const Value = styled.span`
	margin-left: 0.25em;
	color: ${mediumGreyColor};
	font-weight: 300;
`
const IconList = ({items, inline, noWrap, size, iconProps}) => (
	<IconListWrap>
		{items &&
			items.map((item, i) => (
				<ItemBox {...{inline}} key={i}>
					<InfoTip key={i} tooltipText={_`a$icon:${item.icon}`}>
						<Item {...{noWrap}} inline>
							<Icon {...{...iconProps, size}} glyph={glyphs[item.icon]} />
							<Value>{item.value}</Value>
						</Item>
					</InfoTip>
				</ItemBox>
			))}
	</IconListWrap>
)
IconList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	inline: PropTypes.bool,
	noWrap: PropTypes.bool,
	size: PropTypes.string,
	iconProps: PropTypes.object,
}
export default IconList
