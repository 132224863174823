const isImage = (id: string): boolean => {
	const lastDot = id.lastIndexOf('.')
	const ext = lastDot > 0 ? id.slice(lastDot + 1) : ''
	return !ext || /^(jpe?g|hei[cf]|png|webp|avif)$/.test(ext)
}

interface ImageOptions {
	big?: boolean
	mid?: boolean
	small?: boolean
	thumb?: boolean
	square?: boolean
	isServer?: boolean
}

const makeUrl = (
	{id}: {id: string},
	options: ImageOptions = {},
	forceSmall?: boolean
): string | undefined => {
	if (!id) return undefined
	if (!isImage(id)) return `/upload/${id}`

	let size: 'big' | 'mid' | 'small' | 'thumb'
	if (options.thumb) size = 'thumb'
	else if (options.small || forceSmall) size = 'small'
	else if (options.mid) size = 'mid'
	else if (options.big) size = 'big'
	else size = 'mid'

	return `/_img/${id}@${size}${options.square ? '-square' : ''}`
}

interface SrcSetOptions {
	id: string
	w?: number
	h?: number
	square?: boolean
}

export const makeSrcSet = ({
	id,
	w,
	h,
	square,
}: SrcSetOptions): {src: string; srcSet?: string} => {
	if (!isImage(id)) return {src: `/upload/${id}`}

	const s = w && h ? Math.max(w, h) : w
	const sq = square ? '-square' : ''
	const base = `/_img/${id}@`
	let src: string, srcSet: string | undefined

	if (s) {
		if (s >= 1280) {
			src = `${base}small${sq}`
			srcSet = `${base}big${sq} 2000w,${base}mid${sq} 1280w,${src} 500w`
		} else if (s >= 800) {
			src = `${base}small${sq}`
			srcSet = `${base}big${sq} 2000w,${src} 500w`
		} else {
			src = `${base}big${sq}`
		}
	} else {
		src = `${base}big${sq}`
	}

	return {src, srcSet}
}

export default makeUrl
