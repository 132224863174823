import React from 'react'
import {
	styled,
	getFg,
	getBg,
	GreenDarkTheme,
	GreenWhiteTheme,
	useResponsive,
} from 'app/styles'
import {Box} from 'app/Components/Layout'
import {_, LangLink} from 'plugins/i18n'

const Title = styled(Box)`
	font-size: 1.2em;
	margin-bottom: 2em;
	position: relative;
	&:after {
		position: absolute;
		content: '';
		top: 2em;
		width: 3em;
		height: 1px;
		background-color: ${getFg};
	}
`

const StyledButton = styled(LangLink)`
	text-align: center;
	margin: 25px 0;
	padding: 8px 55px;
	font-size: 1.3em;
	background-color: ${getBg};
	color: ${getFg};
`

const RegionDescription = () => {
	const {isMobile} = useResponsive('isMobile')
	return (
		<GreenDarkTheme>
			<Box top column margin={isMobile ? '2em 0' : '5em 0'}>
				<Title>{_`areYouLost`}</Title>
				<div className="flex w-full items-center justify-center">
					{_`pageNotFound`}
				</div>
				<GreenWhiteTheme>
					<StyledButton
						to={{
							pathname: `/xx/`,
						}}
					>
						{_`home`}
					</StyledButton>
				</GreenWhiteTheme>
			</Box>
		</GreenDarkTheme>
	)
}

export default RegionDescription
