import {deburr} from 'lodash-es'
import {sections, langs} from './lib'
import {Section} from 'app/gql/graphql'
export const makeSlug = (str, slice = true) => {
	const slug = encodeURIComponent(deburr(str).trim())
		.replace(/(%..|[ '(),_~-])+/g, '-')
		.replace(/(^-|-$)/g, '')
		.toLowerCase()
	return slice ? slug.slice(0, 30) : slug
}

export type I18nContext = {
	dict: {[key: I18nKey]: string | object}
	isHtml: {[key: I18nKey]: boolean}
	sections: {[key in Section]: I18nKey[]}
	loading?: boolean
	lang: I18nLang
	langs: I18nLang[]
	isEditing?: boolean
	edit:
		| {section: I18nSectionKey; trKey: string}
		| {section?: never; trKey?: never}
	setLang?: (lang: I18nLang) => void
	chooseTr?: (arg: I18nContext['edit']) => void
	toggleEdit?: () => void
	getText: (id: string | I18nKey, prefix?: string) => string
	getId: (id: string | I18nKey, prefix?: string, section?: string) => I18nKey
	getTrSlug: (id: string | I18nKey, prefix?: string) => string
}

export const calcI18n = (
	props: Partial<I18nContext> & {
		allTranslations?: any
		addMissing?: (id: I18nKey) => void
	} = {}
) => {
	const {
		allTranslations,
		loading,
		lang = langs[0],
		isEditing,
		edit = {},
	} = props
	const {setLang, toggleEdit, chooseTr, addMissing} = props
	const dict = {}
	const isHtml = {}
	const secKeys = {} as I18nContext['sections']
	const order = {}
	for (const k of Object.keys(sections)) {
		secKeys[sections[k]] = []
	}
	if (!loading && allTranslations) {
		for (const tr of allTranslations) {
			dict[tr.id] = tr.text
			order[tr.id] = tr.order
			if (tr.html) isHtml[tr.id] = true
			const section = tr.id.slice(0, tr.id.indexOf('$'))
			const sk = sections[section]
			if (sk) secKeys[sk].push(tr.id)
		}
		for (const k of Object.keys(sections)) {
			secKeys[sections[k]].sort((idA, idB) => {
				const a = order[idA] ?? Number.MAX_SAFE_INTEGER
				const b = order[idB] ?? Number.MAX_SAFE_INTEGER
				return b - a
			})
		}
	}
	const getId = (id, prefix = '', section = 'a') => {
		if (!id) return
		if (/\$/.test(id)) {
			return id
		}
		const t = `${section}$${prefix}${id}`
		if (dict && dict[t]) return t
		return `${section}$${id}`
	}
	const getText = (id, prefix) => {
		let tr, missing
		if (loading || !id) {
			tr = '…'
		} else if (/\$/.test(id)) {
			tr = dict[id]
			if (!tr) {
				missing = !(id in dict)
				tr = `[${id}]`
			}
		} else {
			let key
			if (prefix) {
				key = `a$${prefix}:${id}`
				tr = dict[key]
				if (!tr) missing = !(key in dict)
			}
			if (!tr && (missing || !prefix)) {
				key = `a$${id}`
				tr = dict[key]
				if (!tr) missing = !(key in dict)
			}
			if (!tr) tr = `[${key}]`
		}
		if (missing) {
			const properId = getId(id, prefix)
			dict[properId] = tr
			if (addMissing) addMissing(properId)
		}
		return tr
	}
	const getTrSlug = (id: string, prefix?: string): string =>
		makeSlug(getText(id, prefix))
	const out: I18nContext = {
		dict,
		isHtml,
		sections: secKeys,
		loading,
		lang: lang as I18nLang,
		langs: langs as I18nLang[],
		isEditing,
		edit,
		setLang,
		toggleEdit,
		chooseTr,
		getText,
		getId,
		getTrSlug,
	}
	// Webpack magic continued
	// const locale = localeReq(`./${lang === 'en' ? 'en-US' : lang}/index.js`)
	// out.locale = locale
	return out
}
