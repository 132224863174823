import React from 'react'
import {ensureRange, fixDate} from 'app/lib'
import {reservationsToRanges} from 'app/lib'
import DateRangePicker from './DateRangePicker'

const defaultLegend = {
	available: {
		color: '#ffffff',
		label: 'Available',
	},
	1: {
		color: '#ffffff',
		label: 'Available',
	},
	SWITCHRANGE: {
		color: 'rgb(68, 85, 36)',
		fontColor: '#fff',
		hoverFontColor: '#000',
		label: 'Switch range',
	},
	BLOCK: {
		selectable: false,
		color: '#f8d0c7',
		hover: '#ee836e',
		label: 'Blocked',
	},
	OPTION: {
		selectable: false,
		color: '#ffeec0',
		hover: '#f8a969',
		label: 'Option',
	},
	CONTRACT: {
		selectable: false,
		color: '#00eec0',
		hover: '#00a969',
		label: 'Contract',
	},
	3: {
		selectable: false,
		color: '#f8d0c7',
		hover: '#ee836e',
		label: 'Blocked',
	},
	0: {
		selectable: false,
		color: '#ffeec0',
		hover: '#f8a969',
		label: 'Price',
	},
	2: {
		selectable: false,
		color: '#00eec0',
		hover: '#00a969',
		label: 'Contract',
	},
}
const CalendarInput: import('react').FunctionComponent<
	CalendarInputPropsType & {onChange: (v: DateISOStringRange) => void}
> = ({
	dateStates = [],
	onChange,
	showLegend,
	value: valueOrg,
	valueState = 'available',
	customLegend,
	...rest
}) => {
	const legend = customLegend || defaultLegend
	let stateDefinitions = {}
	if (Array.isArray(showLegend)) {
		for (const type of showLegend) {
			stateDefinitions[type] = legend[type]
		}
		stateDefinitions.available = legend.available
		stateDefinitions.selected = {hide: true}
		showLegend = true
	} else {
		stateDefinitions = legend
	}
	const handleChange = ({start, end}) => {
		const newDateRange = {
			start: fixDate(start),
			end: fixDate(end),
		}
		onChange?.(newDateRange)
	}

	const value = ensureRange(valueOrg)

	const settings: Partial<DateRangePickerPropsType> = {
		firstOfWeek: 1,
		...rest,
		value,
		dateStates: reservationsToRanges({reservations: dateStates}),
		defaultState: valueState,
		onSelect: handleChange,
		stateDefinitions,
		showLegend,
	}

	return <DateRangePicker {...settings} />
}

export default CalendarInput
