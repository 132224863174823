import React from 'react'
import PropTypes from 'prop-types'
import {styled, css, cut, getFg} from 'app/styles'
import {Image} from 'plugins/images'
import {TextSpan} from './Text'

const spaces = css`
	padding: ${({volume = 1}) => `${volume * 0.25}em ${volume * 1.25}rem`};
`

const spacesH = css`
	${({volume, indent}) => {
		const padding = (volume || 1) * 1.25
		return `
			padding-left: ${padding}rem;
			${!indent && `padding-right: ${padding}rem;`}
		`
	}};
`
const spacesV = css`
	${({volume}) => {
		const padding = (volume || 1) * 0.25
		return `
			padding-top: ${padding}rem;
			padding-bottom: ${padding}rem;
		`
	}};
`

const stretch = css`
	width: 100%;
	height: 100%;
`

const inlineContent = css`
	> * {
		display: inline-block;
	}
`

export const Line = props => <LineBase {...props} />

const LineBase = styled.div`
	border-${p => (p.vertical ? 'right' : 'bottom')}: thin ${p =>
		p.dotted ? 'dotted' : 'solid'} ${getFg};
	margin: ${p => p.margin && '0.25em 0'};
	${p => p.theme.lineCss};
`

export const FullWidthBox = styled.div`
	width: 100%;
`

export const SpacesWrap = ({children, volumes, ...rest}) => (
	<SpacesBox fullWidth vertical self volume={volumes[0]} {...rest}>
		<SpacesBox horizontal self volume={volumes[1]}>
			{children}
		</SpacesBox>
	</SpacesBox>
)
SpacesWrap.propTypes = {
	children: PropTypes.any,
	volumes: PropTypes.array,
}

/**
 * @typedef {Object} SpacesBoxProps
 * @property {boolean} [horizontal] - Apply horizontal spacing.
 * @property {boolean} [vertical] - Apply vertical spacing.
 * @property {boolean} [self] - Apply spacing only to the container itself, not
 *   its children.
 * @property {boolean} [between] - Apply additional spacing between child
 *   elements.
 * @property {boolean} [indent] - Apply indentation by removing right padding
 *   from the last child.
 * @property {boolean} [disabled] - If true, the spacing styles will be
 *   disabled.
 * @property {boolean} [inline] - Apply styles for inline content.
 * @property {boolean} [stretch]
 * @property {boolean} [fullWidth] - Set width to 100%.
 * @property {number} [volume]
 */

export const SpacesBox = /**
 * @type {import('styled-components').ThemedStyledFunction<
 * 	'div',
 * 	SpacesBoxProps
 * >}
 */ (styled.div)`
	${({horizontal, vertical, self, between, indent, disabled}) => {
		if (disabled) return false
		if (horizontal) {
			const styles = self
				? spacesH
				: css`
						> * {
							${spacesH};
						}
						${between &&
						`
					> :first-child {
						padding-left: 0;
					}
					> :last-child {
						padding-right: 0;
					}
				`};
					`
			return styles
		}
		if (vertical) {
			return self
				? spacesV
				: css`
						> * {
							${spacesV};
						}
						${between &&
						`
					> :first-child {
						padding-top: 0;
					}
					> :last-child {
						padding-bottom: 0;
					}
				`};
					`
		}
		return self
			? spaces
			: css`
					> * {
						${spaces};
						${indent && 'padding-right: 0'};
					}
					${between &&
					`
				> :first-child {
					padding-left: 0;
				}
				> :last-child {
					padding-right: 0;
				}
			`};
				`
	}};
	${p => p.inline && inlineContent};
	${p => p.stretch && stretch};
	width: ${p => p.fullWidth && '100%'};
`

export const InSpacesBox = styled(SpacesBox)`
	> * {
		padding: 0.25em 0 0.25em 1em;
	}
`

const ChildWrapContainer = styled.span`
	${p => p.center && 'text-align: center;'};
	${p =>
		p.gap &&
		`
		& > *:not(:first-child) {
			margin-left: ${p.gap === true ? p.theme.gap : p.gap}
		},
	`}
	}
`
const ChildWrap = styled.div`
	display: inline-block;
	${p => p.width && `width: ${p.width};`} ${p =>
		p.vAlign && `vertical-align: ${p.vAlign};`};
`
export const InlineBox = ({widths, vAlign, center, gap, children}) => {
	if (widths && widths.length !== children.length) {
		return false
	}
	return (
		<ChildWrapContainer {...{center, gap}}>
			{children.map((child, i) => (
				<ChildWrap key={i} {...{vAlign}} width={widths && widths[i]}>
					{child}
				</ChildWrap>
			))}
		</ChildWrapContainer>
	)
}
InlineBox.propTypes = {
	widths: PropTypes.arrayOf(PropTypes.string),
	vAlign: PropTypes.string,
	center: PropTypes.bool,
	gap: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	children: PropTypes.any,
}

const CutImage = styled(p => <Image {...p} />)`
	${cut};
`
export const Img = p => {
	let value
	// All the various ways we can point at an image
	value =
		!p || p.id || p.src || !p.value
			? p
			: {id: p.uploadId, ...p.value, ...p.value.upload}
	return <CutImage {...p} value={value} />
}

export const Align = styled(FullWidthBox)`
	> * {
		display: ${({inline}) => (inline ? 'inline-block' : 'block')};
		${({vAlign}) => vAlign && `vertical-align: ${vAlign}`};
	}
	text-align: ${({position}) => position || 'left'};
	${p => p.spaces && spaces};
`

const Underline = styled(Line)`
	${p => p.theme.underlineCss};
`

const headCss = css`
	max-width: 100%;
	width: 100%;
	display: inline-block;
	${p => p.size && `font-size: ${p.size}`};
	margin-bottom: 0.7em;
	${p => p.noMargin && 'margin: 0 !important;'};
	line-height: 1.2em;
	${p => p.theme.headCss};
`
const Head1 = styled(TextSpan)`
	font-size: ${p => (p.resize ? `${p.resize * 2}em` : '2em')};
	${headCss};
`
const Head2 = styled(TextSpan)`
	font-size: ${p => (p.resize ? `${p.resize * 1.5}em` : '2em')};
	${headCss};
`
const Head3 = styled(TextSpan)`
	font-size: ${p => (p.resize ? `${p.resize}em` : '1.5em')};
	${headCss};
`
const Head4 = styled(TextSpan)`
	font-size: ${p => (p.resize ? `${p.resize}em` : '1em')};
	${headCss};
`
const StyledHeads = [Head1, Head2, Head3, Head4]

/**
 * Props for the HeadBox component.
 *
 * @typedef {Object} HeadBoxProps
 * @property {number} [lvl] - The level of the heading (1-4)
 * @property {boolean} [underline] - Whether to show an underline below the
 *   heading.
 * @property {React.ReactNode} children - The content of the heading.
 * @property {string} [as] - The HTML tag to use for the heading (defaults to
 *   `h${lvl}`)
 * @property {number} [resize] - The amount to resize the heading by (multiplied
 *   by the default size)
 * @property {string} [size] - The font size of the heading.
 */

/**
 * HeadBox component.
 *
 * @param {HeadBoxProps} props - The props for the component.
 */
export const HeadBox = ({lvl = 2, underline, children, as, ...rest}) => {
	const StyledHead = lvl ? StyledHeads[lvl - 1] : Head2
	return (
		<StyledHead as={as || `h${lvl}`} {...rest}>
			{children}
			{underline && <Underline />}
		</StyledHead>
	)
}

HeadBox.propTypes = {
	children: PropTypes.node,
	lvl: PropTypes.number,
	resize: PropTypes.number,
	size: PropTypes.string,
	underline: PropTypes.bool,
}
