// https://github.com/akashanand708/react-drag-element/blob/17f45068268a9f6683bbf74fd00f07e8cd01067a/src/index.js
import React, {Component} from 'react'
import PropTypes from 'prop-types'

class DragElement extends Component {
	static propTypes = {
		horizontal: PropTypes.bool,
		vertical: PropTypes.bool,
		dragId: PropTypes.string.isRequired,
		dragItemId: PropTypes.string.isRequired,
		children: PropTypes.any,
	}

	x = 0

	y = 0

	componentDidMount() {
		const {dragId, dragItemId} = this.props
		if (
			dragId !== undefined &&
			dragId !== '' &&
			dragId !== null &&
			dragItemId !== undefined &&
			dragItemId !== '' &&
			dragItemId !== null
		) {
			const element = document.querySelector(`#${dragItemId}`)
			const dragIdElement = document.querySelector(`#${dragId}`)
			if (element && dragIdElement) {
				/* if present, the header is where you move the DIV from: */
				dragIdElement.addEventListener('mousedown', e =>
					this.dragMouseDown(e, element)
				)
			}
		}
	}

	dragMouseDown = (e, element) => {
		e = e || window.event
		e.preventDefault()
		// get the mouse cursor position at startup:
		const {x, y} = this
		this.startX = e.clientX - x
		this.startY = e.clientY - y
		document.addEventListener('mouseup', this.closeDragElement)
		// call a function whenever the cursor moves:
		if (this._mouseMove)
			document.removeEventListener('mousemove', this._mouseMove)
		this._mouseMove = e => this.elementDrag(e, element)
		document.addEventListener('mousemove', this._mouseMove)
	}

	elementDrag = (e, element) => {
		const {startX, startY} = this
		e = e || window.event
		e.preventDefault()

		this.x = e.clientX - startX
		this.y = e.clientY - startY
		const x = this.props.vertical ? 0 : this.x
		const y = this.props.horizontal ? 0 : this.y
		element.style.transform = `translate(${x}px, ${y}px)`
	}

	closeDragElement = () => {
		/* stop moving when mouse button is released: */
		document.removeEventListener('mouseup', this.closeDragElement)
		document.removeEventListener('mousemove', this._mouseMove)
		document.removeEventListener('mousedown', this._mouseDown)
		this._mouseMove = null
	}

	render() {
		return <>{this.props.children}</>
	}
}

export default DragElement
