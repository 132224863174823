import PropTypes from 'prop-types'
import React from 'react'
import {makeSplitPoint} from 'plugins/react'
import {sections} from './lib'
import {HtmlContent} from 'plugins/rich-text'
import styled from 'styled-components'
import {useI18n} from './I18nContext'

const Editable = makeSplitPoint(() => import('./EditableTr'), {ssr: false})

const isSlate = v =>
	(Array.isArray(v) && v[0] === 'slt1') || (v.document && v.object === 'value')

// This filters non-DOM props
const Span = styled.span``

// TODO when a user is logged in, create missing translations on the server
const PlainText = props => {
	const {
		trId,
		value,
		section,
		prefix,
		Component = Span,
		asHtml,
		i18n: miniDict,
		...rest
	} = props
	const i18n = useI18n()

	let text, isHtml

	const id = trId || value
	let fullId

	if (miniDict == null) {
		if (!i18n) {
			text = `[${id}]`
		} else if (id) {
			fullId = i18n.getId(id, prefix, section)
			text = i18n.getText(fullId)
			isHtml = i18n.isHtml[fullId]
		} else {
			text = ''
		}
	} else if (typeof miniDict === 'string') {
		text = miniDict
	} else if (isSlate(miniDict)) {
		isHtml = true
		text = miniDict
	} else {
		text = (miniDict && (miniDict[i18n.lang] || miniDict[i18n.langs[0]])) || ''
		isHtml = isSlate(text)
	}

	if (fullId && i18n.isEditing) {
		let key
		let section = 'APP'
		const m = /^(.*)\$(.*)$/.exec(fullId)
		if (m) {
			section = sections[m[1]]
			key = m[2]
		}
		return (
			<Editable section={section} trKey={key} text={text} isHtml={isHtml} />
		)
	}

	return asHtml || isHtml ? (
		<HtmlContent {...rest} value={text} />
	) : (
		<Component {...rest}>{text}</Component>
	)
}
PlainText.propTypes = {
	trId: PropTypes.string,
	value: PropTypes.string,
	section: PropTypes.string,
	prefix: PropTypes.string,
	// This is a user-provided i18n object with translations
	i18n: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	asHtml: PropTypes.bool,
	Component: PropTypes.any,
}

export default PlainText
