import {FiveStarRatingIcon} from 'app/Components/Icon'
import {useI18n} from 'plugins/i18n'
import React from 'react'
import Text from 'app/Components/twComponents/Text'

const ReviewSummary = () => {
	const {getText} = useI18n()
	return (
		<div className="flex flex-col items-center">
			<FiveStarRatingIcon
				className="fill-primary mb-3"
				width="7.3em"
				height="1em"
			/>
			<p className="mb-1 leading-6">{getText(`customersGiveCapAverage`)}</p>
			<p className="mb-2.5 text-sm leading-5 text-gray-600">
				Google reviews • Trustpilot
			</p>
			<button
				type="button"
				className="border-primary rounded-md border border-solid p-2"
				onClick={() => {
					const section = document.querySelector('#reviews-section')
					if (section) {
						const offset = -100
						const sectionPosition =
							section.getBoundingClientRect().top + window.scrollY + offset

						window.scrollTo({
							top: sectionPosition,
							behavior: 'smooth',
						})
					}
				}}
			>
				<Text variant="small" className="text-primary">
					{getText(`readReviews`)}{' '}
				</Text>
			</button>
		</div>
	)
}
export default ReviewSummary
