import {setRentalSize} from 'app/redux/ducks/searchRentals-duck'
import React, {useMemo} from 'react'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {cn} from 'app/lib'

const makeSizeOptions = (maxCapacity: number): number[] => {
	const sizeOpts: number[] = []
	for (let i = 2; i <= maxCapacity && i <= 12; i = i + 2) {
		sizeOpts.push(i)
	}
	return sizeOpts
}

const SizeInput = ({maxCapacity}: {readonly maxCapacity: number}) => {
	const dispatch = useDispatch()
	const val = useSelector(state => state.searchRentals.houseCapacity)

	const options = useMemo(() => makeSizeOptions(maxCapacity), [maxCapacity])

	return (
		<>
			<input
				type="range"
				min={options[0]}
				max={options[options.length - 1]}
				value={val || 0}
				className="range range-xs"
				step="2"
				onChange={e => {
					const newVal = Number(e.target.value)
					if (options.includes(newVal)) dispatch(setRentalSize(newVal))
				}}
			/>
			<div className="flex w-full justify-between px-2 text-xs">
				{options.map(v => (
					<span
						key={v}
						className={cn(
							'text-base leading-6',
							v === val ? 'text-black' : 'text-gray-300'
						)}
					>
						{v}+
					</span>
				))}
			</div>
		</>
	)
}

export default SizeInput
