import React, {useState} from 'react'
import {HtmlContent} from 'plugins/rich-text'
import IconList from './IconList'
import {Box, ThemedBox} from 'app/Components/Layout'
import {HouseTitle} from './HouseTitle'
import {
	styled,
	getFg,
	BeigeGreenTheme,
	BeigeTheme,
	ThemeProvider,
	useResponsive,
} from 'app/styles'
import {_, LangLink} from 'plugins/i18n'
import {SloganBox} from './SloganBox'
import ShareButton from 'app/Components/Buttons/ShareButton'
import Btn from 'app/Components/Buttons/Button'
import {Features} from 'app/Components/House'
import serializeNode from 'plugins/rich-text/rawText'
import {FavIcon} from 'app/Components/Icon'
import {useFavoriteToggle} from 'app/hooks'

const FeaturesListWrap = styled(({colorSet, ...props}) => (
	<div {...props} />
)).attrs({
	colorSet: 'capGrey',
})`
	padding-left: 20px;
	margin-left: 20px;
	border-left: 2px solid ${getFg};
`

const TextBox: React.FC<{
	readonly html?: Record<string, unknown>
	readonly children?: React.ReactNode
}> = ({html, children}) => {
	return (
		<div className="relative w-full font-sans text-base">
			{children}
			{html && <HtmlContent value={html} />}
		</div>
	)
}

export const FeaturesList: React.FC<{
	readonly houseFeatures: Record<string, any>[]
}> = ({houseFeatures, ...rest}) => (
	<FeaturesListWrap {...rest}>
		<IconList
			noWrap
			size="2em"
			iconProps={{colorSet: 'capGrey'}}
			items={houseFeatures.map(hf => ({icon: hf.icon, value: hf.value}))}
		/>
	</FeaturesListWrap>
)

const HousedetailDescriptionWrapper = styled(Box).attrs({
	colorSet: 'capDarkGrey',
	column: true,
	gap: '1em',
	left: true,
})`
	color: ${getFg};
	width: ${p => !p.isPhone && '65%'};
`

const ReservationButton: React.FC<{readonly reservationPath: string}> = ({
	reservationPath,
}) => (
	<BeigeTheme>
		<LangLink className="w-full" to={reservationPath}>
			<Btn fullWidth large>
				{_('resRequest')}
			</Btn>
		</LangLink>
	</BeigeTheme>
)

const FavouriteButton: React.FC<{
	readonly houseId: string
}> = ({houseId}) => {
	const {isPhone} = useResponsive('isPhone')
	const {isFavorite, onToggleFavorite} = useFavoriteToggle(houseId)
	return (
		<BeigeGreenTheme>
			<Btn
				fullWidth={isPhone}
				large
				long={isPhone}
				onClick={() => onToggleFavorite()}
			>
				<Box gap="0.5rem">
					<FavIcon active={isFavorite} />
					{_`addToFavourites`}
				</Box>
			</Btn>
		</BeigeGreenTheme>
	)
}

export const Description = ({isPhone, description}) => {
	const [expanded, setExpanded] = useState(false)

	const serializedDesc =
		typeof description === 'object' ? serializeNode(description) : description

	const shortDescLength = 200

	const descriptionLength = serializedDesc.length
	let shortDescription = serializedDesc.slice(0, Math.max(0, shortDescLength))
	shortDescription =
		shortDescription.slice(
			0,
			Math.max(
				0,
				Math.min(shortDescription.length, shortDescription.lastIndexOf(' '))
			)
		) + '...'

	return (
		<>
			<TextBox>
				<b>{_`description`}</b>
			</TextBox>
			{isPhone && descriptionLength > shortDescLength ? (
				expanded ? (
					<>
						<TextBox html={description} />
						<span
							className="text-capGold flex cursor-pointer items-center justify-center"
							onClick={() => setExpanded(false)}
						>{_`readLessDesc`}</span>
					</>
				) : (
					<>
						<TextBox html={shortDescription} />
						<span
							className="text-capGold flex cursor-pointer items-center justify-center"
							onClick={() => setExpanded(true)}
						>{_`readMoreDesc`}</span>
					</>
				)
			) : (
				<TextBox html={description} />
			)}
		</>
	)
}

const HousedetailDescription: React.FC<{
	readonly house?: House
}> = ({house}) => {
	const {isPhone} = useResponsive('isPhone')

	if (!house) {
		return null
	}

	const {
		metaDescription,
		description,
		capacity,
		bedroomCount,
		bathroomCount,
		icons,
		name,
		type,
		id,
		placeName,
		regionId,
		province,
		slogan,
		promotions,
		isFree,
		slug,
		mainImage,
	} = house

	const houseFeatures = [
		{value: capacity, icon: 'peopleNew'},
		{value: bedroomCount, icon: 'bedNew'},
		{value: bathroomCount, icon: 'bathNew'},
	]

	return (
		<HousedetailDescriptionWrapper isPhone={isPhone}>
			<div className="flex w-full items-center justify-center">
				<Box column left gap="1em">
					{isPhone && (
						<ReservationButton reservationPath={`/xx/reservation/${slug}`} />
					)}
					<HouseTitle
						capacity={capacity}
						name={name}
						type={type}
						id={id}
						placeName={placeName}
						regionId={regionId}
						province={province}
						main
						fontResize={isPhone ? 0.8 : 1.5}
					/>
					{isPhone && (
						<Features row noWrap size="1.5em" houseFeatures={houseFeatures} />
					)}
					{(slogan || promotions) && (
						<SloganBox slogan={slogan} promotions={promotions} />
					)}
				</Box>
				{!isPhone && (
					<FeaturesList
						leftBorder
						noWrap
						size="1.5em"
						houseFeatures={houseFeatures}
					/>
				)}
			</div>
			{!isFree && <ThemedBox useSet="btnDanger">{_('houseNotFree')}</ThemedBox>}
			<Description isPhone={isPhone} description={description} />
			<ThemeProvider theme={{defaultSet: 'capGreenWhite'}}>
				<IconList
					inline
					iconProps={{round: true, primary: true, bg: true}}
					size="2em"
					items={icons.map(icon => ({icon}))}
				/>
			</ThemeProvider>
			{isPhone && (
				<div className="flex w-full flex-col items-center justify-center gap-4">
					<FavouriteButton houseId={id} />
				</div>
			)}
			<ShareButton
				expanded
				title={name}
				description={metaDescription}
				imageId={mainImage.upload.id}
			/>
		</HousedetailDescriptionWrapper>
	)
}

export default HousedetailDescription
