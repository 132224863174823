// Checkbox input; uses hidden <input> to make keyboard and label focus work as they should
import React, {FC, ChangeEvent} from 'react'
import {styled, getSecondary} from 'app/styles'
import InputLayout from './InputLayout'
import {CheckIcon} from 'app/Components/Icon'

// Doesn't show in browser or affect layout, but still gets keyboard focus
const HiddenCBInput = styled.input.attrs({type: 'checkbox'})`
	opacity: 0;
	position: absolute;
`
const Checkbox = styled.div<{disabled?: boolean}>`
	display: flex;
	width: ${p => (p.theme.inputHeight / 5) * 3}rem;
	height: ${p => (p.theme.inputHeight / 5) * 3}rem;
	padding: 1px;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border: thin solid ${p => getSecondary(p)};
	${p => p.theme.input && p.theme.input.checkboxCss};
	background-color: ${p => p.disabled && 'rgba(0,0,0,0.2)'};
`

const CheckboxWrap = styled.div<{right?: boolean}>`
	display: inline-flex;
	${p => (p.right ? 'margin-left: 0.5em' : 'margin-right: 0.5em')};
	align-items: center;
	position: relative; // Our real input likes to run away
	${HiddenCBInput}:focus + ${Checkbox} {
		// Add focus shadow to our checkbox
		${p => p.theme.focusCss}
	}
	// Hide the checkmark when input not selected
	${HiddenCBInput}:not(:checked) + ${Checkbox} > svg {
		fill: transparent;
	}
`

const CheckboxInput: FC<{
	readonly value?: boolean
	readonly disabled?: boolean
	readonly label?: React.ReactNode
	readonly error?: React.ReactNode
	readonly onChange?: (v: boolean) => void
	readonly right?: boolean
	readonly className?: string
	readonly name?: string
}> = ({
	value = false,
	disabled = false,
	label,
	error,
	onChange,
	right = false,
	className,
	name,
}) => {
	const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
		if (onChange) onChange(ev.target.checked)
	}

	return (
		<InputLayout
			inline
			short
			together
			fullWidth
			switchLabel
			labelOnTop={false}
			right={right}
			label={label}
			error={error}
			className={className}
		>
			<CheckboxWrap right={right} onClick={e => e.stopPropagation()}>
				<HiddenCBInput
					name={name}
					checked={Boolean(value)}
					onChange={handleChange}
					disabled={disabled}
				/>
				<Checkbox disabled={disabled}>
					{value ? (
						<CheckIcon size="100%" primary={!disabled} secondary={disabled} />
					) : null}
				</Checkbox>
			</CheckboxWrap>
		</InputLayout>
	)
}

export default CheckboxInput
