import {LangLink, _} from 'plugins/i18n'
import {ImageFromId} from 'plugins/images'
import React from 'react'
import styled from 'styled-components'

const ImageWrap = styled(({height, mobileHeight, ...p}) => <div {...p} />)`
	height: ${p => (p.theme.isMobile ? p.mobileHeight : p.height)};
	width: 100%;
`

export const HeroLabel = () => {
	return (
		<div className="bg-secondary/80 text-onSecondary absolute mx-6 mt-10 mb-4 px-4 py-4">
			<h1 className="mb-2 text-[30px] leading-[38px] font-bold">
				{_(`heroHeader`)}
			</h1>
			<p className="leading-6">{_(`heroDescription`)}</p>
			<div className="mt-6 flex flex-row items-center space-x-6">
				<LangLink to="/xx/search">
					<button
						type="button"
						className="bg-primary text-onPrimary rounded-md px-3 py-2 shadow-xs"
					>
						{_(`ResultsForLandingPage`)}
					</button>
				</LangLink>
				<LangLink to="/xx/about">
					<div className="text-primary underline">{_(`aboutUs`)}</div>
				</LangLink>
			</div>
		</div>
	)
}

const HeroSection = ({value}) => {
	const {height, mobileHeight, imageId} = value || {}
	return (
		<div className="relative flex flex-col justify-end">
			<ImageWrap height={height} mobileHeight={mobileHeight}>
				<ImageFromId value={imageId} className="w-fill" />
			</ImageWrap>
			<HeroLabel />
		</div>
	)
}
export default HeroSection
