import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import {_} from 'plugins/i18n'

const CancelButton = props => (
	<Button secondary {...props}>
		{props.children || _`cancel`}
	</Button>
)
CancelButton.propTyps = {children: PropTypes.any}

export default CancelButton
