import React, {useEffect} from 'react'
import {landingPageByUrlUserQuery} from 'app/_server/queries/landingPages'
import Loading from 'app/Components/Loading'
import {Template} from 'plugins/templates'
import {
	PermanentRedirect,
	Route,
	Switch,
	useParams,
} from 'plugins/react-router-deluxe'
import HouseOrDomainPage from '../HouseOrDomain'
import {makeUrl} from 'plugins/images'
import {useQuery} from 'plugins/apollo'
import {useLangUrls} from 'plugins/i18n/react/langUrlsContext'
import {Blog, BlogPost} from '../Blog'
import {_} from 'plugins/i18n'
import {useIsAdmin} from 'plugins/users'
import {Button} from 'app/Components/Buttons'
import SearchUrlRoute from 'app/Components/ShowRentals/SearchUrlRoute'
import styled from 'styled-components'
import {urlForLandingPageBO} from 'app/lib'
import {Helmet} from 'plugins/react-helmet'

interface ButtonWrapperProps extends React.HTMLProps<HTMLDivElement> {
	houseId?: string | number | null
}
const ButtonWrapper: React.FC<ButtonWrapperProps> = styled.div`
	padding: ${(p: ButtonWrapperProps) =>
		p.houseId ? '5rem 0 5rem 3rem' : '5rem 0 1rem 3rem'};
	transform: translateY(-1.5rem);
`

const LandingPage = () => {
	const isAdmin = useIsAdmin()
	const {landingPageUrl, lang} = useParams()
	const {setLangUrls} = useLangUrls()

	const {data, previousData, loading} = useQuery(landingPageByUrlUserQuery, {
		variables: {
			url: landingPageUrl,
			lang,
		},
		onCompleted: d => {
			if (!d?.landingPageByUrl) return
			const lp = d.landingPageByUrl
			setLangUrls(lp.urlI18n)
		},
	})

	useEffect(() => {
		return () => setLangUrls(null)
	}, [setLangUrls])

	if (!data?.landingPageByUrl) {
		if (loading) return <Loading />
		return (
			<Switch>
				<PermanentRedirect from="/:lang/home.html" to="/:lang" />
				<Route exact path="/:lang/blog" Component={Blog} />
				<Route path="/:lang/blog/:postDate/:titleSlug" Component={BlogPost} />
				<Route path="/:lang/blog/:titleSlug" Component={BlogPost} />

				<SearchUrlRoute />

				<Template id="404" />
			</Switch>
		)
	}

	const landingPage = data?.landingPageByUrl || previousData?.landingPageByUrl
	const landingPageMetaImagePath =
		landingPage.imageId &&
		makeUrl(
			{id: landingPage.imageId},
			{
				mid: true,
				isServer: true,
			}
		)
	if (landingPage.redirectUrl)
		return <PermanentRedirect to={landingPage.redirectUrl} />

	const showBottomTemplate = !landingPage.houseId

	return (
		<>
			<Helmet>
				<link
					rel="canonical"
					href={`https://${globalThis.hostname}/${lang}/${landingPage.url}`}
				/>
			</Helmet>
			<div>
				{landingPage.metaTitle && (
					<meta itemProp="name" content={landingPage.metaTitle} />
				)}
				{landingPageMetaImagePath && (
					<meta itemProp="image" content={landingPageMetaImagePath} />
				)}
				{isAdmin && (
					<ButtonWrapper houseId={landingPage.houseId}>
						<Button to={urlForLandingPageBO(landingPage.id)}>
							{_(`GoToEditing`)}
						</Button>
					</ButtonWrapper>
				)}
				{!!landingPage.houseId && (
					<HouseOrDomainPage houseId={landingPage?.houseId} />
				)}
				{!!Object.keys(landingPage.template).length && (
					<Template
						value={landingPage.template}
						templateData={landingPage}
						allowEmpty
					/>
				)}

				{showBottomTemplate && (
					<Template
						id="landingPageBottom"
						allowEmpty
						templateData={landingPage}
					/>
				)}
			</div>
		</>
	)
}

export default LandingPage
