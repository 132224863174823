import React from 'react'
import {Helmet} from 'plugins/react-helmet'

const addresses = [
	'https://www.google.pl',
	'https://in.hotjar.com',
	'https://vars.hotjar.com',
	'https://script.hotjar.com',
	'https://fonts.googleapis.com',
	'https://stats.g.doubleclick.net',
	'https://www.googleadservices.com',
	'https://www.google-analytics.com',
	'https://www.googletagmanager.com',
	'https://googleads.g.doubleclick.net',
]

const Preconnect: React.FC = () => {
	return (
		<Helmet>
			{addresses.map(address => (
				<link
					key={address}
					rel="preconnect dns-prefetch"
					href={address}
					crossOrigin="anonymous"
				/>
			))}
		</Helmet>
	)
}

export default Preconnect
