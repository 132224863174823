// Adapted from slate-plain-serializer

/**
 * Serialize a `node` to plain text.
 *
 * @param {Node} node
 * @returns {string}
 */

function serializeNode(node, options = {}) {
	if (!node) return ''
	if (node.object === 'value') return serializeNode(node.document, options)

	if (
		node.object === 'document' ||
		(node.object === 'block' && node.nodes.every(n => n.object === 'block'))
	) {
		const {delimiter = '\n'} = options
		return node.nodes.map(element => serializeNode(element)).join(delimiter)
	}
	if (node.object === 'block')
		return node.nodes.map(element => serializeNode(element)).join('')
	if (node.leaves) return node.leaves.map(l => l.text).join('')
}

/**
 * Export.
 *
 * @type {Object}
 */

export default serializeNode
