import React from 'react'
import {ApolloProvider} from '@apollo/client/react/context'

const wrapWithApollo = context => {
	context.app = (
		<ApolloProvider client={context.apollo}>{context.app}</ApolloProvider>
	)
}

export default wrapWithApollo
