import {makeMutationHook} from 'app/_server/queries/helpers'
import gql from 'graphql-tag'
import {pick} from 'lodash-es'
import {useMutation, useQuery} from 'plugins/apollo'

const regularFieldsA = ['id', 'sections', 'tag', 'description']
const toTemplateInput = template => pick(template, regularFieldsA)
const templateFields = `
	${regularFieldsA.join(' ')}
	meta {
		user { id fullName }
		updatedAt createdAt
	}
`

// Queries
export const templateQuery = gql`
  query template($id: ID) {
  	template(id: $id) {
  		${templateFields}
  	}
  }
`

export const useTemplate = (id, options) =>
	useQuery(
		gql`
			query template($id: ID) {
				template(id: $id) {
					id
					sections
				}
			}
		`,
		{...options, variables: {id}}
	)

export const allTemplatesQuery = gql`
	query allTemplates {
		allTemplates
	}
`

export const templatesQuery = gql`
  query templates($tag: ID) {
  	templates(tag: $tag) {
  		items {
  			${templateFields}
  		}
  	}
  }
`

// Mutations

export const SAVE_TEMPLATE_MUTATION = gql`
		mutation saveTemplate($template: TemplateInput!) {
			saveTemplate(template: $template) {
				${templateFields}
			}
		}
	`
export const saveTemplateMutation = [
	SAVE_TEMPLATE_MUTATION,
	{
		props: ({mutate}) => ({
			saveTemplate: template =>
				mutate({
					variables: {template: toTemplateInput(template)},
					refetchQueries: ['allTemplates', 'templates'],
				}),
		}),
	},
]

export const useSaveTemplateMutation = makeMutationHook(
	SAVE_TEMPLATE_MUTATION,
	{
		refetchQueries: ['allTemplates', 'templates'],
	},
	template => {
		return {
			variables: {
				template: toTemplateInput(template),
			},
		}
	}
)

const REMOVE_TEMPLATE_MUTATION = gql`
	mutation removeTemplate($id: ID!) {
		removeTemplate(id: $id)
	}
`

export const useRemoveTemplateMutation = () => {
	const [removeTemplateMutation] = useMutation(REMOVE_TEMPLATE_MUTATION, {
		refetchQueries: ['allTemplates', 'templates'],
	})

	const removeTemplate = (template: {id: string}) => {
		if (template && template.id) {
			return removeTemplateMutation({
				variables: {id: template.id},
			})
		}
	}

	return {
		removeTemplate,
	}
}
