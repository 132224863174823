import {CheckboxInput, RadioInput} from 'app/Components/Inputs'
import {Line, ThemedBox} from 'app/Components/Layout'
import {TogglingLabeledBox} from 'app/Components/Layout/Labels'
import {capBrownTheme, ThemeProvider, topLabelTheme} from 'app/styles'
import {useI18n, _} from 'plugins/i18n'
import React from 'react'
import {useSpecialsQuery} from 'app/_server/queries/specials'
import Loading from 'app/Components/Loading'
import {
	type setFeaturesFilter as setFeaturesFilterFn,
	type setSpecialsFilter as setSpecialsFilterFn,
	type setPriceRangesFilter as setPriceRangesFilterFn,
	setSpecialsFilter,
	setFeaturesFilter,
	setPriceRangesFilter,
} from 'app/redux/ducks/searchRentals-duck'
import {useDispatch} from 'plugins/redux/hooks'
import {Feature, useGetFeatures} from 'plugins/i18n/graphql/queries'
import Text from '../twComponents/Text'

// Filter flemish satellite tv
// if (lang !== DEFAULT_CAP_LANG && f === 'ft$tv') continue

const isFeatureGroup = (f: Feature) => f.id.startsWith('ftg$')

export const makeFeatureList = ({
	features,
	// i18n,
}: {
	features: Feature[]
	i18n: I18nContext
}) => {
	const grouped: {
		[key: string]: {order?: Feature['order']; children?: Feature[]}
	} = {other: {children: []}}

	for (const f of features) {
		if (isFeatureGroup(f)) {
			grouped[f.id] = {order: f.order, ...grouped[f.id]}
		} else if (f.featureGroup) {
			if (!grouped[f.featureGroup]?.children) {
				grouped[f.featureGroup] = {children: [], ...grouped[f.featureGroup]}
			}
			grouped[f.featureGroup]!.children!.push(f)
		} else {
			grouped.other.children.push(f)
		}
	}
	const groupedArray = Object.entries(grouped).map(([key, value]) => ({
		id: key,
		...value,
	}))

	const combinedArray = [...groupedArray]

	combinedArray.sort((a, b) => {
		const orderA = a.order ?? Number.POSITIVE_INFINITY
		const orderB = b.order ?? Number.POSITIVE_INFINITY
		return orderA - orderB
	})
	return combinedArray
}

export const Features = ({
	value: _value,
	counters,
	onChange,
}: {
	readonly counters: HouseFilterCountersType
	readonly value: SearchRentalsState['features']
	readonly onChange:
		| typeof setFeaturesFilterFn
		| ((status: SearchRentalsState['features']) => void)
}) => {
	const value = _value ?? {}
	const i18n = useI18n()
	const {data, loading} = useGetFeatures()
	if (!data?.features && loading) return <Loading />
	const options = makeFeatureList({features: data!.features, i18n})

	const handleChange = (id: I18nKey, v: boolean) => {
		return onChange({[id]: v})
	}

	return (
		<div className="flex w-full flex-col items-start justify-center gap-2">
			<Text variant="heading">{_('features')}</Text>
			{options?.map(item => (
				<div className="w-full" key={item.id}>
					<Text className="block font-bold">{_(item.id)}</Text>
					{/* <CheckboxInput
						key={item.id}
						label={_(item.id)}
						value={value[item.id]}
						onChange={v => handleChange(item.id, v)}
					/> */}
					{'children' in item ? (
						<div className="flex w-full flex-col items-start justify-center gap-1">
							{item.children!.map(p => (
								<div className="flex w-full justify-between">
									<CheckboxInput
										key={p.id}
										label={<Text variant="small">{_(p.id)}</Text>}
										value={value[p.id]}
										onChange={v => handleChange(p.id, v)}
									/>
									<Text variant="small">
										{counters?.features?.[p.id] !== undefined &&
											counters.features[p.id]}
									</Text>
								</div>
							))}
						</div>
					) : null}
				</div>
			))}
		</div>
	)
}

export const priceBelowBreakpoints = [1000, 2500, 4000, 7000] as const

export const PriceRanges = ({
	value,
	counters,
	onChange,
}: {
	readonly value?: number
	readonly counters: HouseFilterCountersType
	readonly onChange: typeof setPriceRangesFilterFn | ((e: any) => void)
}) => {
	const priceOptions = [
		...priceBelowBreakpoints.map(p => ({
			label: (
				<div className="flex w-full justify-between">
					<Text variant="small">{`< ${p}€`}</Text>
					<Text variant="small">
						{' '}
						{counters?.priceBelow?.[p] !== undefined && counters.priceBelow[p]}
					</Text>
				</div>
			),
			value: p,
		})),
		{
			label: <Text variant="small">{_('noLimit')}</Text>,
			value: 0,
		},
	]

	return (
		<div className="flex w-full flex-col items-start justify-start">
			<Text className="my-2" variant="heading">
				{_('pricePerWeek')}
			</Text>
			<RadioInput
				options={priceOptions}
				name="prices"
				value={value}
				onChange={onChange}
				noLabel
				fullWidth
				column
			/>
		</div>
	)
}

export const Specials = ({
	value: _value,
	counters,
	onChange,
}: {
	readonly value: SearchRentalsState['specials']
	readonly counters: HouseFilterCountersType
	readonly onChange:
		| typeof setSpecialsFilterFn
		| ((special: SearchRentalsState['specials']) => void)
}) => {
	const value = _value ?? {}
	const {data, loading} = useSpecialsQuery()

	if (!data && loading) return <Loading />
	if (!data) return null

	return (
		<div className="flex w-full flex-col items-start justify-center gap-1">
			<Text className="my-2" variant="heading">
				{_('specials')}
			</Text>
			{data.specials?.items.map((item, i) => (
				<div className="flex w-full justify-between">
					<CheckboxInput
						key={i}
						label={<Text variant="small">{item.title}</Text>}
						value={value[item.id]}
						onChange={e => onChange({[item.id]: e})}
					/>
					<Text variant="small">
						{counters?.specials?.[item.id] !== undefined &&
							counters.specials[item.id]}
					</Text>
				</div>
			))}
		</div>
	)
}

const SearchRentalsMoreFilters = (p: {
	features: SearchRentalsState['features']
	priceRange?: number
	specials: SearchRentalsState['specials']
	counters: HouseFilterCountersType
}) => {
	const {features, priceRange, specials, counters} = p
	const dispatch = useDispatch()
	return (
		<TogglingLabeledBox fullWidth bigLabel nospace label={_('moreFilters')}>
			<ThemeProvider themes={[topLabelTheme, capBrownTheme]}>
				<ThemedBox column top left gap padding>
					<Features
						value={features}
						counters={counters}
						onChange={filter => dispatch(setFeaturesFilter(filter))}
					/>
					<Line />
					<PriceRanges
						value={priceRange}
						counters={counters}
						onChange={filter => dispatch(setPriceRangesFilter(filter))}
					/>
					<Line />
					<Specials
						value={specials}
						counters={counters}
						onChange={filter => dispatch(setSpecialsFilter(filter))}
					/>
				</ThemedBox>
			</ThemeProvider>
		</TogglingLabeledBox>
	)
}

export default SearchRentalsMoreFilters
