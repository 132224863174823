import Value from './HousesTabs'
import {makeSplitPoint} from 'plugins/react'

export default {
	label: 'Grouped Houses Overview',
	group: 'tabs',
	Value,
	Form: makeSplitPoint(() => import('./HousesTabsForm'), {ssr: false}),
	defaultValue: {domainId: null, activeSlug: null},
}
