import {makeSplitPoint} from 'plugins/react'

const TemplateEditorAsync = makeSplitPoint(() => import('./TemplateEditor'), {
	ssr: false,
})

export const TemplateEditor = makeSplitPoint(
	() => import('./TemplateEditor').then(m => m.TemplateEditor),
	{
		ssr: false,
	}
)
export const TemplateField = makeSplitPoint(
	() => import('./TemplateEditor').then(m => m.TemplateField),
	{
		ssr: false,
	}
)

export default TemplateEditorAsync
