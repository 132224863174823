import React from 'react'
import {useI18n} from 'plugins/i18n'
import {LogoutButton, useIsAdmin} from 'plugins/users'
import {styled, colorCss, hoverColorCss} from 'app/styles'
import {Button} from 'app/Components/Buttons'
import {
	EditIcon,
	TranslationIcon,
	SettingsIcon,
	LogoutIcon,
} from 'app/Components/Icon'
import DragElement from './DragElement'
import {useEditing} from 'plugins/templates/react/Editable'

const StyledLogoutButton = styled(LogoutButton)`
	${colorCss};
	${hoverColorCss};

	position: relative;
	width: 3rem;
	height: 3rem;

	${p => p.theme.boxShadow1};
	border-radius: 50px;
`

const StyledButton = styled(Button)`
	${colorCss};
	${hoverColorCss};
	position: relative;
	width: 3rem;
	height: 3rem;

	${p => p.theme.boxShadowCss};
	border-radius: 50px;
`

const Wrapper = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	top: 0;
	bottom: 0;
	right: 0;

	/* over Content */
	z-index: 1000;

	margin-right: 1em;
	> * + * {
		margin-top: 1em;
	}

	/* Don't steal clicks from items below */
	pointer-events: none;
	> * {
		pointer-events: all;
	}
`

const AdminButtons: React.FC<{readonly adminUrl: string}> = ({adminUrl}) => {
	const {toggleEdit: toggleEditI18n, isEditing: isI18nEditing} = useI18n()
	const isAdmin = useIsAdmin()
	const {isEditing, toggleEdit} = useEditing()

	if (!isAdmin) {
		return null
	}

	return (
		<DragElement dragItemId="adminButtons" dragId="adminHandle">
			<Wrapper id="adminButtons">
				<div
					className="h-3 w-full cursor-move border-y-4 border-r-0 border-l-0 border-solid border-gray-300"
					id="adminHandle"
				/>
				<StyledButton accent onClick={toggleEditI18n} aria-label="translations">
					<TranslationIcon outline={isI18nEditing} size="2rem" />
				</StyledButton>
				<StyledButton accent onClick={toggleEdit} aria-label="template editor">
					<EditIcon outline={isEditing} size="2rem" />
				</StyledButton>
				{!!adminUrl && (
					<StyledButton to={adminUrl} aria-label="settings">
						<SettingsIcon size="2rem" />
					</StyledButton>
				)}
				<StyledLogoutButton Button={StyledButton} aria-label="Logout">
					<LogoutIcon size="1.9rem" />
				</StyledLogoutButton>
			</Wrapper>
		</DragElement>
	)
}

export default AdminButtons
