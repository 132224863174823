import React, {useEffect, useState} from 'react'
import {makeSplitPoint} from 'plugins/react'
import {makeSrcSet} from 'plugins/images'

const Lightbox = makeSplitPoint(() => import('react-images'), {ssr: false})

const CarouselLightbox: React.FC<{
	readonly images: GQLImage[]
	readonly isOpen: boolean
	readonly index: number
	readonly onClose: (index: number) => void
}> = ({images, isOpen, index, onClose}) => {
	const [currentIndex, setCurrentIndex] = useState<number>(index ?? 0)

	useEffect(() => {
		if (isOpen) {
			setCurrentIndex(index ?? 0)
		}
	}, [isOpen, index])

	const handlePrevious = () => {
		setCurrentIndex(prevIndex => prevIndex - 1)
	}

	const handleNext = () => {
		setCurrentIndex(prevIndex => prevIndex + 1)
	}

	if (!images || images.length === 0) return null

	const lightboxImages = images.map(image => {
		if (image.upload) {
			const set = makeSrcSet({id: image.upload.id, w: image.w})
			return {
				src: set.src,
				srcSet: set.srcSet?.split(','),
				thumbnail: set.src,
			}
		}
		return {src: image.url}
	})

	return (
		<Lightbox
			images={lightboxImages}
			isOpen={isOpen}
			onClose={() => onClose(currentIndex)}
			currentImage={currentIndex}
			onClickPrev={handlePrevious}
			onClickNext={handleNext}
		/>
	)
}

export default CarouselLightbox
