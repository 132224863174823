import {_} from 'plugins/i18n'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {Features, PriceRanges, Specials} from './SearchRentalsMoreFilters'
import {FilterIcon, MinusIcon, PlusIcon} from 'app/Components/Icon'
import {
	useHouseSearchOptions,
	useHousesFilterCountersQuery,
} from 'app/_server/queries/houses'
import {
	setFeaturesFilter,
	setPriceRangesFilter,
	setRentalType,
	setSpecialsFilter,
} from 'app/redux/ducks/searchRentals-duck'
import NewHouseCard from '../../Components/House/NewHouseCard'
import SearchRentalsMobile from '../../Components/SearchRentalsMobile'
import ActiveFeatureFilters from './ActiveFeatureFilters'
import ItemPager from '../../Components/Pager'
import SortInput from '../SearchRentalsMobile/SortInput'
import PopularDestinations from 'app/Pages/HomePage/PopularDestinations'
import Newsletter from 'app/Pages/HomePage/NewsletterSignup'
import HelpSection from 'app/Pages/HomePage/HelpSection'
import RelatedInternalLinks from './RelatedInternalLinks'
import RegionMapSection from 'app/Sections/regionMap/RegionMapSection'
import {filterToGqlVars} from './SearchRentalsPage'
import Loading from '../Loading'

const FiltersMobile: React.FC<{counters: HouseFilterCountersType}> = ({
	counters,
}) => {
	const dispatch = useDispatch()
	const [isOpen, setIsOpen] = useState(false)

	const features = useSelector(state => state.searchRentals.features)
	const priceRange = useSelector(state => state.searchRentals.priceRange)
	const specials = useSelector(state => state.searchRentals.specials)

	const toggleFilters = () => {
		setIsOpen(!isOpen)
	}

	return (
		<div>
			<div className="flex items-center justify-between border-t border-b border-solid border-gray-300">
				<div className="flex items-center space-x-3">
					<FilterIcon height="1rem" width="1.25rem" />
					<span>{_('filters')}</span>
				</div>
				<button type="button" onClick={toggleFilters}>
					{isOpen ? (
						<MinusIcon width="2rem" height="2rem" />
					) : (
						<PlusIcon width="2rem" height="2rem" />
					)}
				</button>
			</div>
			{isOpen ? (
				<div className="my-2 flex flex-col space-y-4">
					<Features
						value={features}
						counters={counters}
						onChange={filter => dispatch(setFeaturesFilter(filter))}
					/>
					<PriceRanges
						value={priceRange}
						counters={counters}
						onChange={filter => dispatch(setPriceRangesFilter(filter))}
					/>
					<Specials
						value={specials}
						counters={counters}
						onChange={filter => dispatch(setSpecialsFilter(filter))}
					/>
					<ActiveFeatureFilters />
				</div>
			) : null}
		</div>
	)
}

const hTypePrioList = new Set(['ht$vakantiehuisVilla'])

const HouseTypePills: React.FC = () => {
	const dispatch = useDispatch()
	const {data, previousData, loading} = useHouseSearchOptions()

	const searchOptions = data.searchOptions || previousData.searchOptions || {}
	const {types: houseTypesOptions, countByType: houseTypesOptionsCounter} =
		searchOptions

	const searchRentals = useSelector(state => state.searchRentals)
	const selectedType = searchRentals.houseType

	const variables = filterToGqlVars({
		searchRentals,
	})?.variables
	const {
		data: housesCountersData,
		previousData: housesCountersPreviousData,
		loading: housesCountersLoading,
	} = useHousesFilterCountersQuery(variables)

	const housesFilterCounters = housesCountersData || housesCountersPreviousData
	const houseTypeCurrCounters = housesFilterCounters?.housesFilterCounters.types

	if (
		(!data && loading) ||
		(!housesCountersData &&
			!housesCountersPreviousData &&
			housesCountersLoading)
	)
		return <Loading />

	const houseTypesOptionsSorted = Object.entries(houseTypeCurrCounters || {})
		.sort(([keyA], [keyB]) => {
			if (hTypePrioList.has(keyA)) return -1 // Move prioritized type to the top
			if (hTypePrioList.has(keyB)) return 1
			return 0
		})
		.reduce(
			(acc, [key, value]) => {
				acc[key] = value
				return acc
			},
			{} as Record<House['type'], number>
		)

	return (
		<div role="tablist" className="flex flex-wrap gap-2">
			<button
				type="button"
				role="tab"
				className={`border-primary rounded-full border border-solid px-3 py-2 text-base leading-6 whitespace-nowrap ${selectedType ? 'bg-onPrimary text-primary' : 'bg-primary text-onPrimary'}`}
				onClick={() => dispatch(setRentalType({label: undefined}))}
			>
				{_('all')}
			</button>
			{Object.entries(houseTypesOptionsSorted).map(([type, counter]) => {
				return (
					<button
						key={type}
						type="button"
						role="tab"
						className={`rounded-full border border-solid border-black px-3 py-2 text-base leading-6 whitespace-nowrap ${selectedType === type ? 'bg-primary text-onPrimary' : 'bg-onPrimary text-primary'}`}
						onClick={() => dispatch(setRentalType({label: type}))}
					>
						{_(type)} {type && '(' + counter + ')'}
					</button>
				)
			})}
		</div>
	)
}

const ResultsListMobile: React.FC<{readonly houses: any[]}> = ({houses}) => {
	return (
		<div className="flex flex-col space-y-8">
			{houses.map(h => (
				<div key={h.id} className="bg-[#BBB]/30">
					<NewHouseCard house={h} />
				</div>
			))}
		</div>
	)
}

const SearchRentalsPageMobile = ({
	total,
	items,
	handleSetPage,
	pageSize,
	counters,
}) => {
	const currPage = useSelector(state => state.searchRentals.page)
	const index = currPage - 1
	const houses = items.slice(index * pageSize, index * pageSize + pageSize)

	return (
		<>
			<div className="mx-6 mt-11 mb-4 flex flex-col gap-4">
				<div className="flex flex-col gap-2">
					<SearchRentalsMobile total={total} className="mx-0" />
					<FiltersMobile {...{counters}} />
				</div>
				<div>
					<span className="font-bold">{total} </span>
					<span>{_('vacationHomesFound')}</span>
				</div>
				<HouseTypePills />
				<SortInput />
				<ResultsListMobile houses={houses} />
				<ItemPager
					current={currPage}
					total={Math.ceil(total / pageSize)}
					onPageClick={handleSetPage}
				/>
			</div>
			<PopularDestinations />
			<RelatedInternalLinks />
			<RegionMapSection />
			<Newsletter />
			<HelpSection />
		</>
	)
}
export default SearchRentalsPageMobile
