import React from 'react'
import PropTypes from 'prop-types'
import {PostPreview, PostDescription} from 'app/Components/Blog'
import {styled, useResponsive} from 'app/styles'

const ListGrid = styled.div`
	align-items: start;
	display: grid;
	grid-column-gap: 2em;
	grid-row-gap: 2.5em;
	grid-template-columns: repeat(${p => p.itemNum}, 1fr);
	justify-content: center;
	width: 100%;
`

const List = ({posts}) => {
	const {isPhone} = useResponsive('isPhone')
	return (
		<ListGrid itemNum={isPhone ? 1 : 3}>
			{posts.map(post => (
				<PostPreview key={post.id} column={!isPhone} {...{post}}>
					<PostDescription
						fontResize={0.65}
						withText
						withTitle={!isPhone}
						{...{post}}
					/>
				</PostPreview>
			))}
		</ListGrid>
	)
}

List.propTypes = {
	posts: PropTypes.arrayOf(PropTypes.object),
}

export default List
