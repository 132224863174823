import {landingPageByUrlUserQuery} from 'app/_server/queries/landingPages'
import {useSpecialsQuery} from 'app/_server/queries/specials'
import {useQuery} from 'plugins/apollo'
import {LangLink, useI18n} from 'plugins/i18n'
import {Image} from 'plugins/images'
import React from 'react'

const SpecialItem = ({special}) => {
	const {getText, lang} = useI18n()
	const {data, previousData} = useQuery(landingPageByUrlUserQuery, {
		variables: {url: `specials/${special.id.slice(3)}`, lang},
	})
	const landing = data?.landingPageByUrl || previousData?.landingPageByUrl
	if (!landing) return null
	return (
		<LangLink to={landing.redirectUrl || landing.urlI18n[lang]}>
			<div
				key={special.id}
				className="flex flex-col items-center justify-center overflow-hidden rounded-lg bg-[#E7E7E7]"
			>
				<Image ratio={169 / 120} value={special.image.upload} />
				<div className="mt-4 mb-5 min-h-10 text-center text-base leading-5 font-bold">
					{special.title}
				</div>
				<button
					type="button"
					className="bg-primary text-onPrimary mb-4 w-5/6 rounded-md p-2"
				>
					{getText('offers')}
				</button>
			</div>
		</LangLink>
	)
}

const Specials = () => {
	const {getText} = useI18n()
	const {data, loading} = useSpecialsQuery()

	if (loading) return null
	return (
		<div className="my-8 flex w-full flex-col items-start justify-center">
			<h2 className="ml-6 py-2 text-2xl leading-8 font-bold">
				{getText('yourPreferences')}
			</h2>
			<div className="mx-auto grid w-full grid-cols-2 gap-4 px-4">
				{data.specials.items
					.filter(o => o.showOnHomepage)
					.map(special => (
						<SpecialItem key={special.id} special={special} />
					))}
			</div>
		</div>
	)
}
export default Specials
