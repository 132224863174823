import PropTypes from 'prop-types'
import React from 'react'
import {ThemedBox} from 'app/Components/Layout'
import {PlainText} from 'plugins/i18n'

const TextSection = props => (
	<ThemedBox {...props} value={undefined}>
		<PlainText i18n={props.value?.textI18n} />
	</ThemedBox>
)
TextSection.propTypes = {
	value: PropTypes.object,
}

export default TextSection
