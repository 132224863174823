import React from 'react'
import {useQuery} from 'plugins/apollo'
import {specialsQuery} from 'app/_server/queries/specials'
import Box from 'app/Components/Layout/Box'
import {Button} from 'app/Components/Buttons'
import {ThemeProvider, greenWhiteTheme} from 'app/styles'
import {useSelector, useDispatch} from 'plugins/redux/hooks'
import {
	setFeaturesFilter,
	clearFeaturesFilter,
	setPriceRangesFilter,
	setSpecialsFilter,
	clearSpecialsFilter,
} from 'state/ducks/searchRentals-duck'
import {_} from 'plugins/i18n'
import {Pill} from './Components'

const ActiveFeatureFilters: React.FC = () => {
	const dispatch = useDispatch()

	const features = useSelector(state => state.searchRentals.features)
	const priceRange = useSelector(state => state.searchRentals.priceRange)
	const specials = useSelector(state => state.searchRentals.specials)

	const {loading, data} = useQuery(specialsQuery, {name: 'sp'})
	if (loading || !data) return null

	const {
		specials: {items: allSpecials},
	} = data
	const featuresEntries = Object.entries(features).filter(([_key, v]) => v)
	const specialsEntries = Object.entries(specials).filter(([_key, v]) => v)

	if (!featuresEntries.length && !priceRange && !specialsEntries.length)
		return null

	const handleClearPriceRangeFilter = () => dispatch(setPriceRangesFilter())
	const handleClearFilters = () => {
		handleClearPriceRangeFilter()
		dispatch(clearFeaturesFilter())
		dispatch(clearSpecialsFilter())
	}

	return (
		<ThemeProvider theme={greenWhiteTheme}>
			<Box gap="1rem" left>
				<span>{_`filters`}:</span>
				<Box left wrap gap="0.5rem">
					{featuresEntries.map(([t, v]) =>
						v ? (
							<Pill
								key={t}
								onClose={() => dispatch(setFeaturesFilter({[t]: false}))}
							>
								{_(t)}
							</Pill>
						) : null
					)}
					{priceRange && (
						<Pill onClose={handleClearPriceRangeFilter}>
							<span>&lt; {priceRange}€</span>
						</Pill>
					)}
					{specialsEntries &&
						allSpecials &&
						specialsEntries.map(([t, v]) =>
							v ? (
								<Pill
									key={t}
									onClose={() => dispatch(setSpecialsFilter({[t]: false}))}
								>
									{(allSpecials.find(s => s.id === t) || {}).title}
								</Pill>
							) : null
						)}
				</Box>
				<Button onClick={handleClearFilters}>{_`clearAllFilters`}</Button>
			</Box>
		</ThemeProvider>
	)
}

export default ActiveFeatureFilters
