import {useApolloClient, useMutation} from 'plugins/apollo'
import {makeQueryHook} from 'app/_server/queries/helpers'
import gql from 'graphql-tag'
import {pick} from 'lodash-es'

const regularFieldsA = [
	'id',
	'email',
	'lang',
	'firstName',
	'lastName',
	'role',
	'disabled',
]

const toUserInput = user => pick(user, regularFieldsA, 'password')

export const meQuery = gql`
	query user {
		user {
			id
			fullName
			role
			email
			lang
			agentIds
		}
	}
`
export const useMe = makeQueryHook<
	{
		user: Pick<User, 'id' | 'fullName' | 'role' | 'email' | 'lang' | 'agentIds'>
	},
	never
>(meQuery)

// Returns null for more flexible use in jsx
export const useIsAdmin = (): true | null => {
	const {data} = useMe()
	return data?.user?.role === 'ADMIN' || null
}

const LOGIN_MUTATION = gql`
	mutation login($email: Email!, $password: ShortString!) {
		login(email: $email, password: $password)
	}
`

export const loginMutation = [
	LOGIN_MUTATION,
	{
		props: ({mutate}) => ({
			login: variables => {
				return mutate({variables, refetchQueries: ['user']})
			},
		}),
	},
]

export const useLoginMutation = () => {
	const [mutate] = useMutation(LOGIN_MUTATION, {
		refetchQueries: ['user'],
	})

	const login = async (variables: {email: string; password: string}) => {
		return await mutate({variables})
	}

	return {login}
}

const GOOGLE_LOGIN_MUTATION = gql`
	mutation login($token: String!) {
		googleLogin(token: $token)
	}
`

export const googleLoginMutation = [
	GOOGLE_LOGIN_MUTATION,
	{
		props: ({mutate}) => ({
			googleLogin: token => {
				return mutate({variables: {token}, refetchQueries: ['user']})
			},
		}),
	},
]

export const useGoogleLoginMutation = () => {
	const [mutate] = useMutation(GOOGLE_LOGIN_MUTATION, {
		refetchQueries: ['user'],
	})

	const googleLogin = async (token: string) => {
		return await mutate({variables: {token}})
	}

	return {googleLogin}
}

const LOGOUT_MUTATION = gql`
	mutation logout {
		logout
	}
`

export const useLogout = () => {
	const client = useApolloClient()
	const [logoutMutation] = useMutation(LOGOUT_MUTATION)

	const logout = async () => {
		await logoutMutation()
		await client.resetStore()
	}

	return {logout}
}

export const userFields = `
	${regularFieldsA.join(' ')}
`

export const userQuery = gql`
  query user($id: ID, $email: Email) {
  	user(id: $id, email: $email) {
  		${userFields}
  	}
  }
`

export const usersQuery = gql`
  query users($email: Email, $limit: Int, $cursor: ShortString) {
  	users(email: $email, limit: $limit, cursor: $cursor) {
  		items {
  			${userFields}
  		}
  		cursor
  		total
  	}
  }
`

// Mutations

export const SAVE_USER_MUTATION = gql`
  mutation saveUser($user: UserInput!) {
    saveUser(user: $user) {
      ${userFields}
    }
  }
`
export const saveUserMutation = [
	SAVE_USER_MUTATION,
	{
		props: ({mutate}) => ({
			saveUser: user =>
				mutate({
					variables: {user: toUserInput(user)},
					refetchQueries: ['users'],
				}),
		}),
	},
]

export const useSaveUser = () => {
	const [mutate, {data, loading, error}] = useMutation(SAVE_USER_MUTATION, {
		refetchQueries: ['users'],
	})

	const saveUser = async (user: any) => {
		return await mutate({
			variables: {user: toUserInput(user)},
		})
	}

	return {saveUser, data, loading, error}
}

const PASSWORD_RESET_MUTATION = gql`
	mutation reset($email: Email!) {
		passwordReset(email: $email)
	}
`

export const passwordResetMutation = [
	PASSWORD_RESET_MUTATION,
	{
		props: ({mutate}) => ({
			passwordReset: variables => mutate({variables, refetchQueries: ['user']}),
		}),
	},
]

export const usePasswordResetMutation = () => {
	const [mutate] = useMutation(PASSWORD_RESET_MUTATION, {
		refetchQueries: ['user'],
	})

	const passwordReset = async (variables: {email: string}) => {
		return await mutate({variables})
	}

	return {passwordReset}
}
