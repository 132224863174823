import React from 'react'
import {_, useI18n} from 'plugins/i18n'
import TextCarousel from 'app/Components/Carousels/TextCarousel'

const SloganCarousel = () => {
	const i18n = useI18n()
	const texts = i18n.sections.APP.filter(id => id.startsWith('a$slogan:')).map(
		id => _(id)
	)
	return (
		<div className="text-capGreyFg mb-4 ml-20 max-w-[36%] overflow-hidden text-[1.5em] italic">
			<TextCarousel content={texts} />
		</div>
	)
}
export default SloganCarousel
