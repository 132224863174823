import React from 'react'
import {Provider as ReduxProvider} from 'react-redux'

const wrapWithRedux = context => {
	context.app = (
		<ReduxProvider store={context.store}>{context.app}</ReduxProvider>
	)
}

export default wrapWithRedux
