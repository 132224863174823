import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import {Query} from 'plugins/apollo'
import {InputField, SelectInput} from 'app/Components/Inputs'

const domainHousesQuery = gql`
	query HousesListQuery($domainId: ID) {
		houses(domainId: $domainId) {
			items {
				id
				name
				prices {
					date
					sell
				}
				blockedRanges
				switchDay
				slug
			}
		}
	}
`

const Label = ({value}) => <span>{value.name}</span>
Label.propTypes = {
	value: PropTypes.object,
}

const DomainHouseSelect = ({domainId}) => (
	<Query query={domainHousesQuery} variables={{domainId}}>
		{({data}) => {
			if (!data.houses) return false
			const {
				houses: {items},
			} = data
			return (
				<InputField
					Input={SelectInput}
					optionValues={items}
					Label={Label}
					valueComponent={Label}
					name="domainHouse"
					labelOnTop
					required
				/>
			)
		}}
	</Query>
)
DomainHouseSelect.propTypes = {
	domainId: PropTypes.string,
}

export default DomainHouseSelect
