import React, {useEffect, useState} from 'react'
import rtg from 'react-transition-group'
import {createGlobalStyle} from 'styled-components'
import {CalendarInput} from 'app/Components/Inputs'
import {ensureRange} from 'app/lib'
import {setRentalPeriod} from 'app/redux/ducks/searchRentals-duck'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {closeModal} from 'app/redux/ducks/modal'
import {modalId} from '.'
const {CSSTransitionGroup} = rtg

const transitionSpeed = '0.3s'
const fadingSpeed = '0.1s'
const Transitions = createGlobalStyle`
// CSS for ReactCSSTransition
.slideOptionsList-enter {
	left: 20px;
	opacity: 0.01;
}

.slideOptionsList-enter-active {
	left: 260px;
	opacity: 1;
	transition: all ${transitionSpeed} ease-out;
}

.slideOptionsList-leave {
	opacity: 1;
}

.slideOptionsList-leave-active {
	opacity: 0.01;
	transition: all 0.3s ease-out;
}


.fadeOptionsList-enter {
	opacity: 0.01;
}

.fadeOptionsList-enter-active {
	opacity: 1;
	transition: all ${transitionSpeed} ease-out;
	transition-delay: ${fadingSpeed};
}

.fadeOptionsList-leave {
	opacity: 1;
}

.fadeOptionsList-leave-active {
	opacity: 0.01;
	transition: all ${fadingSpeed} ease-out;
}
`
const transitionOptions1 = {
	transitionName: 'slideOptionsList',
	transitionEnterTimeout: 300,
	transitionLeaveTimeout: 300,
}

const transitionOptions2 = {
	transitionName: 'fadeOptionsList',
	transitionEnterTimeout: 300,
	transitionLeaveTimeout: 100,
}

const CalendarModal: React.FC = () => {
	const dispatch = useDispatch()
	const period = useSelector(state => state.searchRentals.period)
	const showModal = useSelector(state => state.modal.id === modalId)

	const [transitionOptions, setTransitionOptions] = useState(transitionOptions1)

	useEffect(() => {
		setTransitionOptions(showModal ? transitionOptions2 : transitionOptions1)
		return () => {
			if (showModal) dispatch(closeModal())
		}
	}, [showModal, dispatch])

	return (
		<>
			<Transitions />
			<CSSTransitionGroup {...transitionOptions}>
				{showModal && (
					<div className="fixed inset-0 z-20 flex h-full w-full items-center justify-center bg-black/50 transition-opacity duration-300 ease-out">
						<div
							className="absolute top-28 left-0 z-20 m-4 ml-2 inline-block bg-white align-top shadow-md"
							onClick={e => e.stopPropagation()}
						>
							<CalendarInput
								name="dateRange"
								numberOfCalendars={2}
								selectionType="range"
								showLegend={false}
								switchDateStep="month"
								minimumDate={new Date()}
								value={ensureRange(period)}
								onChange={newPeriod => {
									dispatch(setRentalPeriod({period: newPeriod}))
									dispatch(closeModal())
								}}
							/>
						</div>
					</div>
				)}
			</CSSTransitionGroup>
		</>
	)
}

export default CalendarModal
