import React from 'react'
import {_} from 'plugins/i18n'
import PopularDestinationsSlider from 'app/Components/Carousels/PopularDestinationsSlider'

const PopularDestinations = () => {
	return (
		<div className="ml-6 flex w-full flex-col">
			<h2 className="mb-4 text-2xl leading-8 font-bold">
				{_('popularDestinations')}
			</h2>
			<PopularDestinationsSlider />
		</div>
	)
}
export default PopularDestinations
