import React from 'react'
import {LocationListener} from 'plugins/react-router-deluxe'
import {isBrowser, isDev} from 'stratokit/build'

const fbPixelId = __CONFIG__.fbPixelId

export const fbTrack = (eventId, ...rest) =>
	globalThis.fbq && globalThis.fbq('track', eventId, ...rest)

const handlePageView = () => fbTrack('ViewContent')

const script = `!function(f,b,e,v,n,t,s){if(f.fbq)return
n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)}
if(!f._fbq)f._fbq=n
n.push=n
n.loaded=!0
n.version='2.0'
n.queue=[]
t=b.createElement(e)
t.async=!0
t.src=v
s=b.getElementsByTagName(e)[0]
s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js')
fbq('init','${fbPixelId}')
fbq('track','PageView')`

// Don't render the scripts on the client, they
// should be loaded on initial html only
// Also https://github.com/nfl/react-helmet/issues/357
const FacebookPixel = () =>
	isBrowser ? (
		<LocationListener onChange={handlePageView} />
	) : !isDev && fbPixelId ? (
		<>
			{/* eslint-disable-next-line react/no-danger */}
			<script dangerouslySetInnerHTML={{__html: script}} />

			<noscript>
				<img
					height="1"
					width="1"
					style={{display: 'none'}}
					src={`https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1`}
				/>
			</noscript>
		</>
	) : (
		false
	)

export default FacebookPixel
