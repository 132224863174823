import React, {useMemo} from 'react'
import {BoundRoute} from 'plugins/react-router-deluxe'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {
	setSearch as setSearchOrg,
	emptySearch,
} from 'state/ducks/searchRentals-duck'
import {defaults, makeQuery, moment} from 'app/lib'
import {useHouseSearchOptions} from 'app/_server/queries/houses'
import {useI18n} from 'plugins/i18n'
import Loading from 'app/Components/Loading'
import SearchRentals from './SearchRentalsPage'
import {I18nContext} from 'plugins/i18n/react/calcI18n'
import {allowedSortKeys} from '../SearchRentalsMobile/SortInput'

const oldFiltersMap = {
	'["animalsAllowed"]': 'ft$pets',
	'["beach"]': 'ft$closeToSea',
}

const country2Id = {
	france: 'c$fr',
	frankrijk: 'c$fr',
	spanje: 'c$es',
	marokko: 'c$ma',
}
const region2Id = {
	provence: 'r$provence',
}

const filterEntriesByDict = <T extends Record<string, any>>(obj: T, dict): T =>
	(obj &&
		(Object.fromEntries(
			Object.entries(obj).filter?.(([k, v]) => v && !!dict[k])
		) as T)) ||
	{}

export const SearchUrlRoute = ({
	i18n,
	search,
	searchOptions,
	setSearch,
}: {
	readonly i18n: I18nContext
	readonly search: SearchRentalsState
	readonly searchOptions: SearchRentalsOptions
	readonly setSearch: Function
}) => {
	const {dict, lang} = i18n

	const parseQuery = (obj: SearchRentalsState & Record<string, any>) => {
		// Translate from old site
		if (obj.page && typeof obj.page !== 'number') obj.page = 1
		if (typeof obj.region === 'string' && !/^[cr]\$/.test(obj.region))
			obj.region = [region2Id[String(obj.region).toLowerCase()]]
		if (obj.region && !Array.isArray(obj.region)) obj.region = [obj.region]
		if (typeof obj.country === 'string' && !obj.region)
			obj.region = country2Id[String(obj.country).toLowerCase()]
		if (obj.capacityClass) delete obj.capacityClass
		if (obj.housingType) {
			if (obj.housingType === '7472616e736c6174696f6e3131313235')
				obj.houseType = 'ht$vakantiehuisVilla'
			if (obj.housingType === '7472616e736c6174696f6e3131313134')
				obj.houseType = 'ht$appartement'
			delete obj.housingType
		}
		if (obj.filters) {
			const key = oldFiltersMap[obj.filters]
			if (key) obj.features = {[key]: true}
			delete obj.filters
		}
		if (obj.special && !obj.special.startsWith('sp-')) {
			switch (obj.special) {
				case 'promotions': {
					obj.isPromo = true
					delete obj.special
					break
				}
				case 'golf': {
					obj.special = 'sp-golf'
					break
				}
				case 'kids': {
					obj.special = 'sp-kids'
					break
				}
				default: {
					delete obj.special
				}
			}
		}
		try {
			if (obj.period)
				obj.period =
					typeof obj.period === 'string'
						? moment.range(obj.period)
						: moment.range(obj.period.start, obj.period.end)
			if (obj.begin) {
				const begin = new Date(obj.begin)
				const end = new Date(obj.end)
				if (!Number.isNaN(begin.getTime()) && !Number.isNaN(end.getTime()))
					obj.period = moment.range(begin, end)
				delete obj.begin
				delete obj.end
			}
		} catch {
			obj.period = undefined
		}

		// TODO move specials to i18n with metadata
		// specials = filterEntriesByDict(specials, {'sp-'})
		obj.features = filterEntriesByDict(obj.features, dict)
		obj.houseType =
			obj.houseType && dict[obj.houseType] ? obj.houseType : undefined
		obj.region = obj.region?.filter(r =>
			searchOptions.locations.some(
				l => l.countryId === r || l.regionIds.includes(r)
			)
		)

		// sanitize sort obj
		for (const key of Object.keys(obj.sort || {})) {
			if (!allowedSortKeys.includes(key)) delete obj.sort![key]
		}
		if (!Object.keys(obj.sort || {}).length) obj.sort = undefined

		return obj
	}

	return (
		<>
			<BoundRoute
				path="/:lang/search"
				value={search}
				onChange={setSearch}
				defaults={defaults}
				parseQuery={parseQuery}
				makeQuery={makeQuery}
				// We do have to handle the language in the URL
				// But it is not a real part of the filter
				// 1. Just keep any language that is running right now
				makeParams={() => ({lang})}
				// 2. And don't put the language value into the filter
				parseParams={() => ({})}
			/>
			<SearchRentals />
		</>
	)
}

const searchKeys = Object.keys(emptySearch)

const UrlWithData: React.FC = props => {
	const i18n = useI18n()
	const dispatch = useDispatch()

	const searchRentals = useSelector(state => state.searchRentals)

	const search = useMemo(() => {
		const result = {}
		for (const k of searchKeys) {
			result[k] = searchRentals[k]
		}
		return result
	}, [searchRentals])

	const {data, loading: searchOptionsLoading} = useHouseSearchOptions()

	const loading = i18n.loading || searchOptionsLoading || !data?.searchOptions

	if (loading) return <Loading />

	return (
		<SearchUrlRoute
			{...props}
			search={search}
			setSearch={o => dispatch(setSearchOrg(o))}
			loading={loading}
			searchOptions={data.searchOptions}
			i18n={i18n}
		/>
	)
}

export default UrlWithData
