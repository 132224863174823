import React from 'react'
import postError from './postError'
import dovesImg from 'assets/images/doves.jpg'
import {isDev} from 'stratokit/build'

type ErrorBoundaryState = {
	errMsg: string | null
	info?: {
		componentStack: string
	} | null
}

type ErrorBoundaryProps = {
	readonly children: React.ReactNode
}

class ErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	state: ErrorBoundaryState = {errMsg: null}

	constructor(props: ErrorBoundaryProps) {
		super(props)
		if (import.meta.hot) {
			import.meta.hot.on('vite:error', (hmrStatus: any) => {
				// eslint-disable-next-line no-console
				console.log('========= error', hmrStatus.err)
				this.setState({errMsg: hmrStatus.err.message})
			})
			import.meta.hot.on('vite:afterUpdate', () => {
				// eslint-disable-next-line no-console
				console.log('========= updated')
				this.setState({errMsg: null})
			})
		}
	}

	componentDidCatch(error: Error, info: {componentStack: string}) {
		postError(error?.stack, info?.componentStack)
		this.setState({
			errMsg: error?.message || null,
			info: isDev ? info : null,
		})
	}

	render() {
		const {errMsg, info} = this.state

		if (errMsg) {
			return (
				<div className="fixed inset-0 z-50 flex flex-col items-center justify-between overflow-auto bg-white p-[30vh_10vw] text-black">
					<img width="150" src={dovesImg} />
					<div>
						<h1>
							Oops, something went wrong! Please try to reload the page, or go
							to the <a href="/">homepage</a>.
						</h1>
						<pre className="w-[60vw] font-mono text-[0.8em] whitespace-pre-wrap text-black/80 italic">
							{errMsg}
						</pre>
					</div>
					<div>
						<button type="button" onClick={() => this.setState({errMsg: null})}>
							Ignore
						</button>
						<button
							type="button"
							autoFocus
							onClick={() => window.location.reload()}
						>
							Reload page
						</button>
					</div>
					{info && (
						<pre className="w-[60vw] font-mono text-[0.8em] whitespace-pre-wrap text-black/80 italic">
							{info.componentStack}
						</pre>
					)}
				</div>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary
