import React from 'react'
import {Box} from 'app/Components/Layout'
import {
	FacebookSecondIcon,
	TwitterIcon,
	WhatsappIcon,
	MessengerIcon,
	MailIcon,
} from 'app/Components/Icon'
import {styled} from 'app/styles'
import {_} from 'plugins/i18n'

const links = [
	{
		name: 'facebook',
		Icon: FacebookSecondIcon,
		link: 'https://www.facebook.com/',
	},
	{
		name: 'twitter',
		Icon: TwitterIcon,
		link: 'https://www.twitter.com/',
	},
	{
		name: 'whatsapp',
		Icon: WhatsappIcon,
		link: 'https://whatsapp.com/',
	},
	{
		name: 'messenger',
		Icon: MessengerIcon,
		link: 'https://www.twitter.com/',
	},
	{
		name: 'email',
		Icon: MailIcon,
		link: 'https://www.twitter.com/',
	},
]

const IconWrapper = styled(Box)`
	margin: ${p => (p.isPhone ? '0.2em' : '0.35em')};
`

const ShareText = styled.p`
	font-size: 0.9em;
`

const ShareButtonsList = ({isPhone}) => (
	<Box flex="1" left>
		<ShareText>{_`shareButton`}</ShareText>
		{links.map((item, i) => (
			<a key={i} href={item.link} target="_blank" rel="noopener noreferrer">
				<IconWrapper key={i} isPhone={isPhone}>
					<item.Icon key={i} size="2rem" />
				</IconWrapper>
			</a>
		))}
	</Box>
)

export default ShareButtonsList
