/* eslint-disable no-shadow */
import React, {useEffect, useState} from 'react'
import rtg from 'react-transition-group'
import {connect} from 'react-redux'
import Icon, {glyphs} from 'app/Components/Icon'
import {openModal, closeModal} from 'state/ducks/modal'
import {ensureRange, searchBasePath, toStringRange} from 'app/lib'
import {useHouseSearchOptions} from 'app/_server/queries/houses'
import {_, LangLink, useI18n} from 'plugins/i18n'
import {
	clearSearch,
	setRentalPeriod,
	setRentalRegions,
	setRentalSize,
	setRentalType,
	setHouseName,
} from 'state/ducks/searchRentals-duck'
import {CalendarInput, TextInput, SelectInput} from 'app/Components/Inputs'
import {
	styled,
	getBg,
	getFg,
	GreenWhiteTheme,
	createGlobalStyle,
	ThemeProvider,
	css,
} from 'app/styles'
import Box from 'app/Components/Layout/Box'
import {compose} from 'redux'
const {CSSTransitionGroup} = rtg

const modalId = 'rentalSearch'

const searchInputsTheme = {
	input: {
		placeholder: css`
			color: #737373;
		`,
	},
}

const Wrapper = styled(({...p}) => <Box {...p} />)`
	padding: 1.5rem 1rem;
	background-color: ${getBg};
	box-shadow: 0 0 7px rgba(50, 50, 50, 0.32);
`

const StyledInput = styled(TextInput)`
	width: 100%;
	background-color: ${getBg};
	flex: ${p => p.flex};
`
const StyledSelect = styled(SelectInput)`
	width: 100%;
	background-color: ${getBg};
	flex: ${p => p.flex};
`

const StyledButton = styled(p => <LangLink {...p} />)`
	position: relative;
	text-align: center;
	padding: 0.35em 1.5em;
	line-height: 1.6em;
	font-size: 1.1em;
	background-color: ${getBg};
	color: ${getFg};
	width: 100%;
	flex: ${p => p.flex};
`

const CalendarWrapper = styled.div`
	box-shadow: 0 0 7px rgba(50, 50, 50, 0.32);
	display: inline-block;
	background-color: ${getBg};
	position: absolute;
	z-index: 2;
	margin-left: 10px;
	vertical-align: top;
	left: 22.5%;
	top: 100px;
	${p =>
		p.isMobile &&
		`
			top: 4rem;
			left: 0;
			margin: 1em;
	`};
	${p =>
		p.showRentals &&
		!p.isMobile &&
		`
			top: 250px;
			left: 0;
	`};
`

const CountryLabel = styled.span`
	white-space: nowrap;
	.Select-menu-outer & {
		font-weight: bold;
	}
`
const RegionLabel = styled.span`
	white-space: nowrap;
	.Select-menu-outer & {
		margin-left: 0.5em;
	}
`

const transitionSpeed = '0.3s'
const fadingSpeed = '0.1s'
const Transitions = createGlobalStyle`
// CSS for ReactCSSTransition
.slideOptionsList-enter {
	left: 20px;
	opacity: 0.01;
}

.slideOptionsList-enter-active {
	left: 260px;
	opacity: 1;
	transition: all ${transitionSpeed} ease-out;
}

.slideOptionsList-leave {
	opacity: 1;
}

.slideOptionsList-leave-active {
	opacity: 0.01;
	transition: all 0.3s ease-out;
}


.fadeOptionsList-enter {
	opacity: 0.01;
}

.fadeOptionsList-enter-active {
	opacity: 1;
	transition: all ${transitionSpeed} ease-out;
	transition-delay: ${fadingSpeed};
}

.fadeOptionsList-leave {
	opacity: 1;
}

.fadeOptionsList-leave-active {
	opacity: 0.01;
	transition: all ${fadingSpeed} ease-out;
}
`
const transitionOptions1 = {
	transitionName: 'slideOptionsList',
	transitionEnterTimeout: 300,
	transitionLeaveTimeout: 300,
}

const transitionOptions2 = {
	transitionName: 'fadeOptionsList',
	transitionEnterTimeout: 300,
	transitionLeaveTimeout: 100,
}

export const makeCountriesAndRegionsOptions = searchLocations => {
	const countriesAndRegions = []

	if (searchLocations) {
		for (const country of searchLocations) {
			countriesAndRegions.push({
				value: country.countryId,
				label: <CountryLabel>{_`${country.countryId}`}</CountryLabel>,
			})
			if (country.regionIds)
				for (const regionId of country.regionIds) {
					countriesAndRegions.push({
						value: regionId,
						label: <RegionLabel>{_`${regionId}`}</RegionLabel>,
					})
				}
		}
	}

	return countriesAndRegions
}

export const makeHouseTypesOptions = houseTypes =>
	houseTypes
		? houseTypes.map((value, i) => ({
				value,
				label: <span key={i}>{_(value)}</span>,
			}))
		: undefined

export const makeSizeOptions = maxCapacity => {
	const sizeOpts = []
	if (maxCapacity >= 2) sizeOpts.push({value: 2, label: '2+'})
	if (maxCapacity >= 4) sizeOpts.push({value: 4, label: '4+'})
	if (maxCapacity >= 6) sizeOpts.push({value: 6, label: '6+'})
	if (maxCapacity >= 8) sizeOpts.push({value: 8, label: '8+'})
	if (maxCapacity >= 10) sizeOpts.push({value: 10, label: '10+'})
	if (maxCapacity >= 12) sizeOpts.push({value: 12, label: '12+'})
	return sizeOpts
}

/**
 * @typedef {Object} RentalInputProps
 * @property {boolean} [flex]
 * @property {string | boolean | undefined} value
 * @property {boolean} noLabel
 * @property {Array} options
 * @property {(newValues: any) => void} [onChange]
 */

/**
 * @param {RentalInputProps} props
 * @returns {JSX.Element}
 */
export const RentalRegionInput = ({
	flex,
	value,
	noLabel,
	options,
	onChange,
}) => {
	return (
		<StyledSelect
			flex={typeof flex === 'string' ? flex : flex ? '1 0 0' : ''}
			value={value}
			noLabel={noLabel}
			label={!noLabel && _('rentalRegion')}
			options={options}
			onChange={onChange}
			placeholder={
				<div className="flex w-full items-center justify-start gap-4">
					<Icon glyph={glyphs.pin} />
					{_('rentalRegion')}
				</div>
			}
			labelOnTop
			multi
		/>
	)
}

/**
 * @param {RentalInputProps} props
 * @returns {JSX.Element}
 */
export const RentalTypeInput = ({flex, value, options, onChange, noLabel}) => {
	return (
		<StyledSelect
			flex={typeof flex === 'string' ? flex : flex ? '1 0 0' : ''}
			value={value}
			noLabel={noLabel}
			label={!noLabel && _('rentalType')}
			options={options}
			onChange={onChange}
			placeholder={
				<div className="flex w-full items-center justify-start gap-4">
					<Icon glyph={glyphs.home} />
					{_('rentalType')}
				</div>
			}
			labelOnTop
		/>
	)
}

/**
 * @param {RentalInputProps} props
 * @returns {JSX.Element}
 */
export const RentalSizeInput = ({flex, value, noLabel, options, onChange}) => {
	return (
		<StyledSelect
			flex={typeof flex === 'string' ? flex : flex ? '1 0 0' : ''}
			value={value}
			noLabel={noLabel}
			label={!noLabel && _('rentalSize')}
			options={options}
			onChange={onChange}
			placeholder={
				<div className="flex w-full items-center justify-start gap-4">
					<Icon glyph={glyphs.people} />
					{_(`rentalSize`)}
				</div>
			}
			labelOnTop
		/>
	)
}

const SearchRentals = ({
	closeModal,
	column,
	houseCapacity,
	houseName,
	houseType,
	light: lightProp,
	noLabel,
	openModal,
	period,
	region,
	setRentalPeriod,
	setRentalRegions,
	setRentalSize,
	setRentalType,
	setHouseName,
	showModal,
	showRentals,
	row = false,
}) => {
	const i18n = useI18n()
	const [showCalendar, setShowCalendar] = useState(false)
	const [transitionOptions, setTransitionOptions] = useState(transitionOptions1)
	const {data, loading} = useHouseSearchOptions()

	useEffect(() => {
		setTransitionOptions(showModal ? transitionOptions2 : transitionOptions1)
	}, [showModal])

	useEffect(() => {
		return () => {
			if (showModal) closeModal()
		}
	}, [showModal, closeModal])

	const closeRentalContentDiv = () => {
		closeModal()
		setShowCalendar(false)
	}

	const handleRange = period => {
		setRentalPeriod({period})
		closeRentalContentDiv()
	}

	if (loading) {
		return null
	}

	const {locations, types: houseTypes, maxCapacity} = data.searchOptions || {}
	const sizeOpts = makeSizeOptions(maxCapacity)
	const countriesAndRegions = makeCountriesAndRegionsOptions(locations)

	const light = lightProp

	const calendar = (
		<CalendarWrapper
			onClick={e => e.stopPropagation()}
			showRentals={showRentals}
		>
			{showCalendar ? (
				<CalendarInput
					name="dateRange"
					numberOfCalendars={2}
					selectionType="range"
					showLegend={false}
					switchDateStep="month"
					minimumDate={new Date()}
					value={ensureRange(period)}
					onChange={handleRange}
				/>
			) : null}
		</CalendarWrapper>
	)

	const rentalPeriodInput = (
		<StyledInput
			flex={row && '2'}
			name="period"
			noLabel={noLabel}
			value={toStringRange(period)}
			onChange={() => setRentalPeriod({period: undefined})}
			onClick={() => {
				openModal(modalId)
				setShowCalendar(true)
			}}
			label={!noLabel && _('rentalPeriod')}
			placeholder={
				period ? (
					<div className="inline-flex w-full items-center justify-center">
						<span className="text-black">{toStringRange(period)}</span>
					</div>
				) : (
					<div className="flex w-full items-center justify-start gap-4">
						<Icon glyph={glyphs.calendar} />
						{_('rentalPeriod')}
					</div>
				)
			}
			labelOnTop
		/>
	)

	const rentalNameInput = (
		<StyledInput
			flex={row && '1'}
			name="houseName"
			value={houseName}
			noLabel={noLabel}
			label={!noLabel && _('rentalName')}
			onChange={v => setHouseName(v)}
			placeholder={
				<div className="flex w-full items-center justify-start gap-4">
					<Icon glyph={glyphs.search} />
					{_('rentalName')}
				</div>
			}
			labelOnTop
		/>
	)

	return (
		<>
			<Transitions />
			<Wrapper>
				<Box
					width="100%"
					gap="1em"
					bottom
					column={column}
					onClick={e => {
						e.stopPropagation()
					}}
				>
					<div className="flex w-full flex-col items-center justify-center gap-4">
						<ThemeProvider theme={searchInputsTheme}>
							<Box gap="1em" column={column}>
								<RentalRegionInput
									flex={row && '1'}
									value={region}
									noLabel={noLabel}
									options={countriesAndRegions}
									onChange={v => setRentalRegions({label: v})}
								/>
								<RentalTypeInput
									flex={row && '1'}
									value={houseType}
									noLabel={noLabel}
									options={makeHouseTypesOptions(houseTypes)}
									onChange={v => setRentalType({label: v})}
								/>
								<Box flex={row && '2'} column={!light && !row} gap="1em">
									<RentalSizeInput
										flex={row && '1'}
										value={houseCapacity}
										noLabel={noLabel}
										options={sizeOpts}
										onChange={setRentalSize}
									/>
									{rentalPeriodInput}
								</Box>
							</Box>
							<div className="flex w-full items-center justify-center">
								{rentalNameInput}
							</div>
						</ThemeProvider>
					</div>
					{!light && (
						<GreenWhiteTheme>
							<StyledButton
								flex={row && '0.5'}
								to={{
									pathname: searchBasePath(i18n),
								}}
							>
								{_`search`}
							</StyledButton>
						</GreenWhiteTheme>
					)}
				</Box>
			</Wrapper>
			<CSSTransitionGroup {...transitionOptions}>
				{showModal && calendar}
			</CSSTransitionGroup>
		</>
	)
}

export default compose(
	connect(
		({searchRentals, modal}) => ({
			filter: searchRentals.filter,
			region: searchRentals.region,
			houseType: searchRentals.houseType,
			houseName: searchRentals.houseName,
			houseCapacity: searchRentals.houseCapacity,
			period: searchRentals.period,
			showModal: modal.id === modalId,
		}),
		{
			clearSearch,
			openModal,
			closeModal,
			setRentalPeriod,
			setRentalRegions,
			setRentalSize,
			setRentalType,
			setHouseName,
		}
	)
)(SearchRentals)
