import gql from 'graphql-tag'
import {makeQueryHook} from './helpers'

export const regionQuery = gql`
	query regionQuery($id: ID!) {
		region(id: $id) {
			id
			imageId
			titleI18n
			regionDesc
			countryTitle
			countryDesc
			houseIds {
				id
				name
				placeName
				type
				regionId
				mainImage {
					w
					h
					blurhash
					upload {
						id
					}
				}
			}
			suggestions {
				imageId
				title
				desc
				link
			}
			houseTypeDescs {
				houseType
				text
				textI18n
			}
		}
	}
`

export const regionsQuery = gql`
	query regionsQuery($ids: [ID]!) {
		regions(ids: $ids) {
			id
			imageId
			titleI18n
			regionDesc
			countryTitle
			countryDesc
			houseIds {
				id
				name
				placeName
				type
				regionId
				mainImage {
					w
					h
					blurhash
					upload {
						id
					}
				}
			}
			suggestions {
				imageId
				title
				desc
				link
			}
			houseTypeDescs {
				houseType
				text
				textI18n
			}
		}
	}
`

export const useRegionsQuery = makeQueryHook(regionsQuery)
