import {makeSplitPoint} from 'plugins/react'
import Value from './RentYourHouseBenefitsSection'

export default {
	label: 'RentYourHouse Benefits',
	group: 'content',
	Value,
	defaultValue: {
		headingI18n: undefined,
		textI18n: undefined,
		imageId: undefined,
	},
	Form: makeSplitPoint(() => import('./RentYourHouseBenefitsForm'), {
		ssr: false,
	}),
}
