import {makeSplitPoint} from 'plugins/react'

export default {
	label: 'House Images Carousel',
	group: 'slider',
	Value: makeSplitPoint(() => import('./HousedetailSlideshowSection')),
	defaultValue: {images: []},
	Form: makeSplitPoint(() => import('./HousedetailSlideshowForm'), {
		ssr: false,
	}),
}
