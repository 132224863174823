import React from 'react'
import {
	styled,
	getSecondary,
	useResponsive,
	capGreenColor,
	silverColor,
} from 'app/styles'
import {Box, ThemedBox} from 'app/Components/Layout'
import {HtmlContent} from 'plugins/rich-text'
import {PlainText} from 'plugins/i18n'
import {ImageFromId} from 'plugins/images'

const Wrapper = styled(ThemedBox)`
	width: 100%;
	justify-content: center;
	background-color: ${capGreenColor};
	margin: ${p => (p.isMobile ? '5rem 0 3rem' : '2rem 0 0')};
`

const Content = styled(Box)`
	text-align: center;
	max-width: 50rem;
	padding-bottom: 2rem;
	color: ${silverColor};
`

const Description = styled(HtmlContent)`
	color: ${getSecondary};
`

const AuthorImage = styled.div`
	width: 8rem;
	height: 8rem;
	border-radius: 50rem;
	border: 10px solid white;
	overflow: hidden;
	margin-top: -4rem;
`

export const TestimonialSection: React.FC<{
	readonly value: {
		author?: string
		textI18n: any
		imageId: string
	}
}> = ({value}) => {
	const {author, textI18n, imageId} = value
	const {isMobile} = useResponsive('isMobile')

	return (
		<Wrapper column {...{isMobile}}>
			<Content column top gap>
				<AuthorImage>
					<ImageFromId value={imageId} />
				</AuthorImage>
				<PlainText Component={Description} i18n={textI18n} />
				{author && <span className="text-capGreenLight italic">{author}</span>}
			</Content>
		</Wrapper>
	)
}

export default TestimonialSection
