import React from 'react'
import {Switch, Route} from 'plugins/react-router-deluxe'
import {makeSplitPoint} from 'plugins/react'

const Document = makeSplitPoint(() => import('./Document'))
const Voucher = makeSplitPoint(() => import('./Voucher'))
const AgentContractReport = makeSplitPoint(
	() => import('../backoffice/Agent/YearlyReport')
)
const ContractSummary = makeSplitPoint(
	() => import('app/_server/database/outbox/ContractSummary')
)

const Pdf = () => (
	<Document>
		<Switch>
			<Route
				path="/:lang/pdf/voucher/:contractId"
				render={props => <Voucher {...props} allPages />}
			/>
			<Route
				path="/:lang/pdf/contract-print/:contractId"
				Component={ContractSummary}
			/>
			<Route
				path="/:lang/pdf/agent-contract-report/:agentId/:year"
				Component={AgentContractReport}
			/>
		</Switch>
	</Document>
)

export default Pdf
