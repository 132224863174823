import storeFactory from './storeFactory'
import {mediaChanged, mediaQueryTracker} from 'redux-mediaquery'
import wrapApp from './wrapApp'

export type ResponsiveState = {
	isNarrow: boolean
	isMobile: boolean
	isPhone: boolean
	isTablet: boolean
	isLaptop: boolean
	isWide: boolean
	isRetina: boolean
	innerWidth: boolean
	innerHeight: boolean
	isServer: boolean
}

let unlisten
const initMediaQuery = store => {
	if (unlisten) unlisten()
	unlisten = mediaQueryTracker(
		{
			isNarrow: 'screen and (max-width: 460px)',
			isMobile: 'screen and (max-width: 767px)',
			isPhone: 'screen and (max-width: 767px)',
			isTablet: 'screen and (max-width: 1024px)',
			isLaptop: 'screen and (min-width: 1366px)',
			isWide: 'screen and (min-width: 1600px)',
			isRetina:
				'screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
			innerWidth: true,
			innerHeight: true,
		},
		store.dispatch
	)
	if (store.getState().responsive.isServer) {
		store.dispatch(mediaChanged({isServer: false}))
	}
}

const init = context => {
	const store = storeFactory({initial: context.state.redux})
	context.store = store
}

const initClientHooks = (/** @type {Hooks} */ hooks) => {
	hooks.render.initRenderContext.tap('redux', init)
	hooks.render.wrapApp.tap('redux', wrapApp)
	hooks.render.afterRender.tap('redux', context => {
		initMediaQuery(context.store)
	})
}

export default initClientHooks
