import {LogoSmallIcon} from 'app/Components/Icon'
import {LogoIcon} from 'app/Components/LogoIcon'
import {clearSearch} from 'app/redux/ducks/searchRentals-duck'
import {useResponsive} from 'app/styles'
import {LangLink} from 'plugins/i18n'
import {useDispatch} from 'plugins/redux/hooks'
import React from 'react'

const Logo = () => {
	const {isPhone} = useResponsive('isPhone')
	const dispatch = useDispatch()

	return (
		<LangLink
			className="z-1 h-full"
			onClick={() => dispatch(clearSearch())}
			to="/xx/"
		>
			{isPhone ? (
				<LogoIcon height="6em" width="6em" />
			) : (
				<LogoSmallIcon width="4em" height="2.4em" aria-label="Cap logo" />
			)}
		</LangLink>
	)
}
export default Logo
