import React from 'react'
import {PostImage} from '.'
import {Box} from 'app/Components/Layout'
import {urlForPost} from 'app/lib'
import {LangLink, useI18n} from 'plugins/i18n'

const Preview = ({
	children,
	column,
	post,
	ratio,
	to,
	...rest
}: {
	readonly children: React.ReactNode
	readonly column?: boolean
	readonly post: BlogPost
	readonly ratio?: string | number
	readonly to?: string
}) => {
	const i18n = useI18n()
	return (
		<Box gap="1em" column={column} left relative {...rest}>
			<LangLink
				className="w-full"
				to={to || urlForPost(i18n, post, '')}
				aria-label={post.title}
			>
				<PostImage post={post} ratio={ratio} />
			</LangLink>
			<div className="flex w-full flex-col items-stretch gap-4">{children}</div>
		</Box>
	)
}
export default Preview
