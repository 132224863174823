import React from 'react'
import {HelmetProvider} from 'react-helmet-async'

const wrapWithHelmet = context => {
	context.app = (
		<HelmetProvider context={context.helmet}>{context.app}</HelmetProvider>
	)
}

export default wrapWithHelmet
