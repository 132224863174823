import gql from 'graphql-tag'
import {useQuery, removeTypenameFromObj, useMutation} from 'plugins/apollo'
import {makeMutationHook, makeQueryHook} from './helpers'
import {HouseDataQuery} from './houses'

export const contractFields = `
	id
	acceptLowerDeposit
	adults
	agentId
	agentNote
	agentSaldo
	archiveDate
	babies
	babies
	beginDate
	capId
	capNote
	clientId
	clientNote
	discountNote
	contractDate
	createdThroughWebsite
	dueDates
	endDate
	externalSeller
	isExternalSeller
	hasInsurance
	hasTouristTax
	houseId
	id
	isOverdue
	kids
	guaranteePaidThere
	needsGuaranteeReturn
	needsGuaranteeToBePaid
	needsVoucher
	optionExpiryDate
	optionNote
	paid
	pets
	petsNote
	prices
	isMailingToClientDisabled
	rentalFees
	saldo
	status
	voucherSent
	client {
		fullName
		firstEmail
		firstPhone
		lang
	}
	insuranceGuests {
		firstName
		lastName
		birthYear
	}
	house {
		name
	}
	options {
		condition
		count
		optionId
		per
		price
		required
		note
	}
`

// Queries
export const contractQuery = gql`
  query contract($id: ID!) {
  	contract(id: $id) {
  		${contractFields}
  	}
  }
`

export const useContractQuery = makeQueryHook<
	{contract: Contract},
	Pick<Contract, 'id'>
>(contractQuery)

export const basicContractQuery = gql`
	query basicContract($id: ID!) {
		contract(id: $id) {
			id
			status
		}
	}
`

export const contractsSummaryQuery = gql`
	query contractsSummary($year: ShortString) {
		contractsSummary(year: $year)
	}
`

export const externalSellerOptionsQuery = gql`
	query externalSellerOptions {
		externalSellerOptions
	}
`

// Only the fields used in lists
export const contractsQuery = gql`
  query contracts(
  	$limit: Int
  	$cursor: ID
  	$status: ContractStatus
  	$statuses: [ContractStatus]
  	$isSoon: Boolean
  	$isExternalSeller: Boolean
  	$isOneWeek: Boolean
  	$isFinished: Boolean
  	$isValid: Boolean
  	$hasAction: Boolean
  	$isCurrent: Boolean
  	$hasInsurance: Boolean
  	$hasTouristTax: Boolean
  	$hasOptions: Boolean
  	$sort: JSON
  	$houseId: ID
  	$clientId: ID
  	$agentId: ID
  	$year: ShortString
  	$capId: ShortString
  	$createdThroughWebsite: Boolean
  	$needsVoucher: Boolean
  	$needsGuaranteeReturn: Boolean
  	$needsGuaranteeToBePaid: Boolean
  	$isOverdue: Boolean
  ) {
  	contracts(
  		limit: $limit
  		cursor: $cursor
  		status: $status
  		statuses: $statuses
  		isSoon: $isSoon
  		isExternalSeller: $isExternalSeller
  		isOneWeek: $isOneWeek
  		isFinished: $isFinished
  		isValid: $isValid
  		hasAction: $hasAction
  		isCurrent: $isCurrent
  		hasInsurance: $hasInsurance
  		hasTouristTax: $hasTouristTax
  		hasOptions: $hasOptions
  		sort: $sort
  		houseId: $houseId
  		clientId: $clientId
  		agentId: $agentId
  		year: $year
  		capId: $capId
  		createdThroughWebsite: $createdThroughWebsite
  		needsVoucher: $needsVoucher
  		needsGuaranteeReturn: $needsGuaranteeReturn
  		needsGuaranteeToBePaid: $needsGuaranteeToBePaid
  		isOverdue: $isOverdue
  	) {
  		items {
  			${contractFields}
  		}
  		cursor
  		total
  	}
  }
`

// Only the fields used in lists
export const taxQuery = gql`
  query contracts(
  	$hasTouristTax: Boolean
  	$year: ShortString
	$statuses: [ContractStatus]
  ) {
  	contracts(
  		hasTouristTax: $hasTouristTax
  		year: $year
		statuses: $statuses
  	) {
  		items {
  			${contractFields}
				house {
					address {
						street
						pcode
						city
					}
					name
					owner {
						name
					}
					agent {
						name
					}
					taxRegistration
					taxCategory
					taxPlatformId
					taxPlatform {name}
				}
				client {
					fullName
				}
  		}
  	}
  }
`
export const taxSumQuery = gql`
	query touristTaxSum($year: ShortString) {
		touristTaxSum(year: $year)
	}
`

export const contractsYears = gql`
	query contractsYears($houseId: ID, $clientId: ID, $agentId: ID) {
		contractsYears(houseId: $houseId, clientId: $clientId, agentId: $agentId)
	}
`

export const paymentInfoQuery = gql`
	query paymentInfo($contractId: ID!) {
		paymentInfo(contractId: $contractId) {
			id
			acceptLowerDeposit
			capId
			houseId
			clientId
			agentId
			contractDate
			archiveDate
			beginDate
			endDate
			status
			name
			clientName
			houseName
			adults
			kids
			babies
			pets
			petsNote
			options {
				optionId
				price
				condition
				per
				required
				count
				note
			}
			paid
			saldo
			dueDates
			rentalFees
			optionsPrice
			guaranteePayNow
			depositTotal
			sellTotal
			payments {
				id
				amount
				pendingAmount
				date
				note
			}
			needsVoucher
			needsGuaranteeReturn
			needsGuaranteeToBePaid
			isOverdue
		}
	}
`

// Mutations

const REGISTER_CONTRACT_MUTATION = gql`
mutation registerContract($contract: RegisterInput!) {
	registerContract(contract: $contract) {
		${contractFields}
	}
}
`

export const useRegisterContract = () => {
	const [origRegisterContract, {loading, error, data}] = useMutation(
		REGISTER_CONTRACT_MUTATION
	)

	const registerContract = (contract: Partial<Contract>) => {
		return origRegisterContract({
			variables: {
				contract: removeTypenameFromObj(contract, [
					'agentId',
					'agentSaldo',
					'archiveDate',
					'contractDate',
					'paid',
					'prices',
					'rentalFees',
					'saldo',
					'voucherSent',
					'client',
				]),
			},
			refetchQueries: [
				'contracts',
				'externalSellerOptions',
				{query: HouseDataQuery, variables: {houseId: contract.houseId}},
			],
			awaitRefetchQueries: true,
		})
	}

	return {registerContract, loading, error, data}
}

export const registerContractMutation = [
	REGISTER_CONTRACT_MUTATION,
	{
		name: 'registerContract',
		props: ({registerContract}) => ({
			registerContract: contract => {
				return registerContract({
					variables: {
						contract: removeTypenameFromObj(contract, [
							'agentId',
							'agentSaldo',
							'archiveDate',
							'contractDate',
							'paid',
							'prices',
							'rentalFees',
							'saldo',
							'voucherSent',
							'client',
						]),
					},
					refetchQueries: [
						'contracts',
						'externalSellerOptions',
						{query: HouseDataQuery, variables: {houseId: contract.houseId}},
					],
					awaitRefetchQueries: true,
				})
			},
		}),
	},
] as const

export const sendContractConfirmationMutation = [
	gql`
		mutation sendContractConfirmation(
			$id: ID!
			$messageForAgent: JSON
			$messageForClient: JSON
			$sendToAgent: Boolean
			$sendToClient: Boolean
			$subjectForAgent: String
			$subjectForClient: String
		) {
			sendContractConfirmation(
				id: $id
				messageForAgent: $messageForAgent
				messageForClient: $messageForClient
				sendToAgent: $sendToAgent
				sendToClient: $sendToClient
				subjectForAgent: $subjectForAgent
				subjectForClient: $subjectForClient
			) {
				${contractFields}
			}
		}
	`,
	{
		props: ({mutate}) => ({
			sendConfirmation: variables =>
				mutate({variables, refetchQueries: ['outboxEmails']}),
		}),
	},
] as const

const SAVE_CONTRACT_MUTATION = gql`
mutation saveContract($contract: ContractInput!) {
	saveContract(contract: $contract) {
		${contractFields}
	}
}
`

export const saveContractMutation = [
	SAVE_CONTRACT_MUTATION,
	{
		name: 'saveContract',
		props: ({saveContract}) => ({
			saveContract: contract => {
				return saveContract({
					variables: {
						contract: removeTypenameFromObj(contract, [
							'house',
							'agent',
							'client',
							'saldo',
							'agentSaldo',
							'paid',
							'prices',
							'petsMax',
							'isOverdue',
							'isExternalSeller',
							'guaranteePaidThere',
						]),
					},
					refetchQueries: [
						'contracts',
						{query: HouseDataQuery, variables: {houseId: contract.houseId}},
						'paymentInfo',
						'externalSellerOptions',
					],
				})
			},
		}),
	},
] as const

export const useSaveContract = () => {
	const [origSaveContract, {loading, error, data}] = useMutation(
		SAVE_CONTRACT_MUTATION
	)

	const saveContract = (contract: Partial<Contract>) => {
		return origSaveContract({
			variables: {
				contract: removeTypenameFromObj(contract, [
					'house',
					'agent',
					'client',
					'saldo',
					'agentSaldo',
					'paid',
					'prices',
					'petsMax',
					'isOverdue',
					'isExternalSeller',
					'guaranteePaidThere',
				]),
			},
			refetchQueries: [
				'contracts',
				{query: HouseDataQuery, variables: {houseId: contract.houseId}},
				'paymentInfo',
				'externalSellerOptions',
			],
		})
	}

	return {saveContract, loading, error, data}
}

const SEND_VOUCHER_MUTATION = gql`
  mutation sendVoucher($id: ID!, $subject: String, $message: JSON) {
    sendVoucher(id: $id, subject: $subject, message: $message) {
      ${contractFields}
    }
  }
`

export const useSendVoucherMutation = () => {
	const [sendVoucherMutation] = useMutation(SEND_VOUCHER_MUTATION, {
		refetchQueries: ['outboxEmails'],
	})

	const sendVoucher = (variables: {
		id: string
		subject?: string
		message?: any
	}) => {
		return sendVoucherMutation({
			variables,
		})
	}

	return {
		sendVoucher,
	}
}

export const confirmContractMutation = [
	gql`
		mutation confirmContract($id: ID!) {
			confirmContract(id: $id) {
				${contractFields}
			}
		}
	`,
	{
		props: ({mutate}) => ({
			confirmContract: variables =>
				mutate({variables, refetchQueries: ['contracts', 'outboxEmails']}),
		}),
	},
] as const

export const cancelContractMutation = [
	gql`
		mutation cancelContract($id: ID!) {
			cancelContract(id: $id) {
				${contractFields}
			}
		}
	`,
	{
		props: ({mutate}) => ({
			cancelContract: id =>
				mutate({
					variables: {id},
					refetchQueries: ['contracts', 'outboxEmails'],
				}),
		}),
	},
] as const

const CLOSE_CONTRACT_MUTATION = gql`
  mutation closeContract($id: ID!) {
    closeContract(id: $id) {
      ${contractFields}
    }
  }
`

export const useCloseContractMutation = () => {
	const [closeContract, {data, loading, error}] = useMutation(
		CLOSE_CONTRACT_MUTATION,
		{
			refetchQueries: ['contracts', 'outboxEmails'],
		}
	)

	return {
		closeContract,
		data,
		loading,
		error,
	}
}

// export const useContractQuery2 = contractId => {
// 	const {loading, data, error} = useQuery<{contract: Contract}>(contractQuery, {
// 		variables: {id: contractId},
// 		skip: !contractId,
// 	})
// 	const contract =
// 		loading || data?.contract?.id !== contractId ? null : data?.contract
// 	return {contract, loading, error}
// }

export const useContractsQuery = makeQueryHook(contractsQuery)
export const useExternalSellerOptionsQuery = makeQueryHook(
	externalSellerOptionsQuery
)

export const usePaymentInfoQuery = contractId => {
	const {loading, data, error} = useQuery(paymentInfoQuery, {
		variables: {contractId},
		skip: !contractId,
	})
	const paymentInfo =
		loading || data?.paymentInfo?.id !== contractId ? null : data?.paymentInfo
	return {paymentInfo, loading, error}
}

export const useTaxSumQuery = year => {
	const {loading, data, error} = useQuery(taxSumQuery, {
		variables: {year},
		skip: !year,
	})
	const touristTaxSum = loading || data?.touristTaxSum
	return {touristTaxSum, loading, error}
}

export const useSendContractConfirmationMutation = makeMutationHook(
	sendContractConfirmationMutation[0],
	{refetchQueries: ['outboxEmails']}
)
export const useCancelContractMutation = makeMutationHook(
	cancelContractMutation[0],
	{refetchQueries: ['contracts', 'outboxEmails']}
)
export const useConfirmContractMutation = makeMutationHook(
	confirmContractMutation[0],
	{refetchQueries: ['contracts', 'outboxEmails']}
)
