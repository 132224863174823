import {HOUSE_GENERAL_KEY} from 'app/_server/database/constants'

const capitalizeFirstLetter = (str: string) =>
	str.slice(0, 1).toUpperCase() + str.slice(1)

const lowerCaseFirstLetter = (str: string) =>
	str && str.slice(0, 1).toLowerCase() + str.slice(1)

export const makeSearchPageTitle = (
	{
		i18n,
		houseType,
		featureIds,
		hasForRentLabel = true,
		capacity,
		regionIds,
		suffixText = `- CAP`,
	}: {
		capacity?: number
		featureIds?: string[]
		hasForRentLabel?: boolean
		houseType?: string
		i18n: any
		regionIds?: string[]
		suffixText?: string
	},
	widthLimit?: number
) => {
	const {getText: origGetText} = i18n
	const getText = str => lowerCaseFirstLetter(origGetText(str))

	const textParts: Record<string, any> = {suffixText}

	textParts.houseTypeText = houseType
		? `${getText(houseType)}`
		: `${getText(HOUSE_GENERAL_KEY)}`

	textParts.featuresText =
		featureIds?.length &&
		`${getText('with')} ${featureIds
			.map(ft => lowerCaseFirstLetter(getText(ft)))
			.join(', ')}`

	textParts.forRentText =
		hasForRentLabel && `${getText('forRent_Single_SearchTitle')}`

	textParts.capacityText =
		capacity && `${getText('for')} ${capacity} ${getText('people')}`

	textParts.regionsText =
		regionIds?.length &&
		`${getText('in')} ${regionIds
			.map(
				(regionId, i) =>
					`${capitalizeFirstLetter(getText(regionId))}${
						regionIds.length === 1
							? ''
							: i === regionIds.length - 2
								? ` ${getText('and')} `
								: i === regionIds.length - 1
									? ''
									: ', '
					}`
			)
			.join('')}`

	const partsOrder = [
		'houseTypeText',
		'featuresText',
		'forRentText',
		'regionsText',
		'capacityText',
		'suffixText',
	]

	// we need this for proper text length limitation
	let partsPriority = [
		'suffixText',
		'houseTypeText',
		'regionsText',
		'featuresText',
		'capacityText',
		'forRentText',
	]

	let text = partsOrder
		.map(key => partsPriority.includes(key) && textParts[key])
		.filter(Boolean)
		.join(' ')

	// Keep removing text parts until the text meets the limit
	if (widthLimit && widthLimit < text.length) {
		while (text.length > widthLimit) {
			partsPriority = partsPriority.slice(0, -1)
			text = partsOrder
				.map(key => partsPriority.includes(key) && textParts[key])
				.filter(Boolean)
				.join(' ')
		}
	}

	return capitalizeFirstLetter(text)
}

export const makeSearchPageDescription = (
	{
		i18n,
		houseType,
		featureIds,
		regionIds,
		capacity,
	}: {
		capacity?: number
		featureIds?: string[]
		houseType?: string
		i18n: any
		regionIds?: string[]
	},
	widthLimit?: number
) => {
	const {getText: origGetText} = i18n
	const getText = str => lowerCaseFirstLetter(origGetText(str))
	const prefix = `${getText('CAPhasWideRangeOfHolidayHomes')}`
	const suffixText = getText('atCAPnow')
	const ctaBook = `${capitalizeFirstLetter(getText('BookTheMostUnique'))}`

	const houseTypeText = houseType
		? `${getText(houseType)}`
		: `${getText('houses')}`

	const houseTypePluralText = houseType
		? `${getText(
				`${
					houseType.startsWith('ht$') ? `a$${houseType.slice(3)}` : houseType
				}_Plural`
			)}`
		: `${getText('houses')}`

	const featuresText =
		featureIds?.length &&
		`${getText('with')} ${featureIds
			.map(ft => lowerCaseFirstLetter(getText(ft)))
			.join(', ')}`

	const regionsText =
		regionIds?.length &&
		`${getText('in')} ${regionIds
			.map(
				(regionId, i) =>
					`${capitalizeFirstLetter(getText(regionId))}${
						regionIds.length === 1
							? ''
							: i === regionIds.length - 2
								? ` ${getText('and')} `
								: i === regionIds.length - 1
									? ''
									: ', '
					}`
			)
			.join('')}`

	const capacityText = capacity
		? `${getText('for')} ${capacity} ${getText('people')}`
		: ''

	const visitedText = featuresText
		? ''
		: `${getText('personallyVisitedForYou')}`

	const textPart1 = [
		prefix,
		houseTypePluralText,
		featuresText,
		regionsText,
		visitedText,
	]
		.filter(Boolean)
		.join(' ')

	const textPart2 = [
		ctaBook,
		houseTypeText,
		featuresText,
		regionsText,
		capacityText,
		suffixText,
	]
		.filter(Boolean)
		.join(' ')

	const fullText = `${capitalizeFirstLetter(
		textPart1
	)}. ${capitalizeFirstLetter(textPart2)}!`

	return widthLimit && fullText.length >= widthLimit
		? fullText.slice(0, widthLimit) + '…'
		: fullText
}
