import React, {Component} from 'react'
import PropTypes from 'prop-types'
import OrigSlickSlider from 'app/Components/Carousels/SlickSlider'
import styled from 'styled-components'
import {HeadBox, SpacesBox} from 'app/Components/Layout/Display'
import {PostPreview, PostDescription} from 'app/Components/Blog'
import {_, LangLink} from 'plugins/i18n'
import {blogPostsQuery} from 'app/_server/queries/blogPosts'
import {connect} from 'react-redux'
import {graphql} from 'plugins/apollo'
import {BeigeGreenTheme, getBg, getFg} from 'app/styles'
import {compose} from 'redux'

const SlickSlider = styled(OrigSlickSlider)`
	.slick-slide:not(.slick-active) {
		transform: translateX(-6%);
	}
`
const PostWrap = styled.div`
	padding: 2em;
`
const SliderWrap = styled.div`
	width: 100%;
`
const HeadLinkWrap = styled.div`
	width: 100%;
	text-align: right;
	color: ${getFg};
`

const RegionPostsList = ({posts, isPhone}) => (
	<BeigeGreenTheme>
		<div className="flex w-full flex-col items-start items-stretch justify-center gap-8">
			<div className="flex w-full items-center justify-between">
				<HeadBox resize={0.9} underline>
					{_`regionBlogpost`}
				</HeadBox>
				{!isPhone && (
					<HeadLinkWrap>
						<LangLink to="/xx/blog">{_`showAllPosts`}</LangLink>
					</HeadLinkWrap>
				)}
			</div>
			{isPhone ? (
				<RegionPostsSlider {...{posts, isPhone}} />
			) : (
				<div className="flex w-full items-start justify-center gap-8">
					{posts.map(post => (
						<PostPreview key={post.id} {...{post, column: true}}>
							<PostDescription {...{post, isPhone, fontResize: 0.5}} />
						</PostPreview>
					))}
				</div>
			)}
		</div>
	</BeigeGreenTheme>
)
RegionPostsList.propTypes = {
	posts: PropTypes.arrayOf(PropTypes.object),
	isPhone: PropTypes.bool,
}

const settings = {
	adaptiveHeight: false,
	arrows: 0,
	autoplay: 0,
	autoplaySpeed: 5000,
	dots: 0,
	draggable: 0,
	infinite: 1,
	lazyLoad: 0,
	pauseOnHover: 1,
	slidesToScroll: 1,
	slidesToShow: 1,
}

const RegionPostsSlider = ({posts, isPhone}) => (
	<SliderWrap>
		<SlickSlider {...settings}>
			{posts.map(post => (
				<PostWrap key={post.id}>
					<PostPreview key={post.id} {...{post, column: true}}>
						<PostDescription {...{post, isPhone}} />
					</PostPreview>
				</PostWrap>
			))}
		</SlickSlider>
	</SliderWrap>
)
RegionPostsSlider.propTypes = {
	posts: PropTypes.arrayOf(PropTypes.object),
	isPhone: PropTypes.bool,
}

const Wrapper = styled(SpacesBox).attrs({
	colorSet: 'capBeigeBrown',
})`
	background-color: ${getBg};
	padding: 2rem;
	width: 100%;
`

class RegionBlogPostsSection extends Component {
	static propTypes = {
		data: PropTypes.object,
		isPhone: PropTypes.bool,
	}

	render() {
		const {
			data: {loading, blogPosts},
			isPhone,
		} = this.props
		if (loading && !blogPosts) {
			return false
		}
		const {items: posts} = blogPosts
		return (
			<Wrapper self>
				<RegionPostsList {...{posts, isPhone}} />
			</Wrapper>
		)
	}
}

export default compose(
	graphql(blogPostsQuery, {
		options: ({value}) => ({
			variables: {
				limit: 4,
				onlyOnline: true,
				regionIds: value.regionIds,
			},
		}),
	}),
	connect(({responsive: r}) => ({isPhone: r.isPhone}))
)(RegionBlogPostsSection)
