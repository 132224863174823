import * as React from 'react'
import SlickSlider from 'app/Components/Carousels/SlickSlider'
import NewHouseCard from '../House/NewHouseCard'
import {
	useFeaturedHousesQuery,
	useNewOrPromoQuery,
} from 'app/_server/queries/houses'

const settings = {
	dots: 0,
	slidesToShow: 1,
	slidesToScroll: 1,
	draggable: 1,
	lazyLoad: 0,
	infinite: 1,
	autoplay: 0,
	autoplaySpeed: 5000,
	pauseOnHover: 1,
	adaptiveHeight: false,
	arrows: false,
	variableWidth: true,
}

const HouseSlider = ({houses}) => {
	return (
		<div className="ml-6 w-full">
			<SlickSlider {...{...settings}}>
				{houses.map(house => (
					<div
						key={house.id}
						className="mr-4 max-w-[301px] overflow-hidden rounded-lg bg-white"
					>
						<NewHouseCard house={house} />
					</div>
				))}
			</SlickSlider>
		</div>
	)
}

export const FeaturedHousesSlider = () => {
	const {data, loading} = useFeaturedHousesQuery()

	if (loading || !data?.featuredHouses || !data.featuredHouses.length) {
		return null
	}
	return <HouseSlider houses={data.featuredHouses} />
}

export const NewHousesSlider = () => {
	const {data, loading} = useNewOrPromoQuery({isNew: true, limit: 4})

	if (loading || !data?.newOrPromo || !data.newOrPromo.length) {
		return null
	}
	return <HouseSlider houses={data.newOrPromo} />
}
export const PromoHousesSlider = () => {
	const {data, loading} = useNewOrPromoQuery({isPromo: true, limit: 4})

	if (loading || !data?.newOrPromo || !data.newOrPromo.length) {
		return null
	}
	return <HouseSlider houses={data.newOrPromo} />
}

export default HouseSlider
