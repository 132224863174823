import React from 'react'
import styled from 'styled-components'
import {lightBeigeColor} from 'app/styles'
import {SpacesWrap} from 'app/Components/Layout/Display'
import {useQuery} from 'plugins/apollo'
import {_, useI18n} from 'plugins/i18n'
import {housesListQuery} from 'app/_server/queries/houses'
import {HousesList} from 'app/Components/Sections/HousesList'
import {urlForSearch} from 'app/lib'
import {HOUSE_GENERAL_KEY} from 'app/_server/database/constants'
import {LandingPage} from 'app/gql/graphql'

interface LandingPageHousesProps {
	readonly value: {
		regionId: string
		houseTypeId: string
		size: number
		features: Partial<SearchRentalsState['features']>
		priceRage: string
		specials: Partial<SearchRentalsState['specials']>
	}
	readonly templateData: LandingPage
}

const StyledSpacesWrap = styled(SpacesWrap)<{withBeigeBg?: boolean}>`
	background: ${p => p.withBeigeBg && lightBeigeColor};
`

/**
 * Shows first 4 search results for a landing page. Takes mapping values and
 * than templateData as fallback.
 */
const LandingPageHouses: React.FC<LandingPageHousesProps> = ({
	value,
	templateData,
}) => {
	const i18n = useI18n()
	const regionIds = (
		value.regionId ? [value.regionId] : templateData.search?.region
	) as I18nKey[]
	const houseType = value.houseTypeId || templateData.search?.houseType
	const size = value.size || templateData.search?.size
	const featuresAllIn = templateData.search?.features
	const priceBelow = templateData.search?.priceRange
	const specialsAllIn = templateData.search?.specials

	const {loading: housesLoading, data: housesData} = useQuery(housesListQuery, {
		variables: {
			regionIds,
			type: houseType,
			priceBelow,
			specialsAllIn,
			featuresAllIn,
			size,
			limit: 4,
		},
	})

	const showMorePath = urlForSearch({
		i18n,
		filters: {
			houseType: HOUSE_GENERAL_KEY,
			region: regionIds,
			...(size && {houseCapacity: size}),
		},
	})

	return (
		<StyledSpacesWrap volumes={[8, 2]}>
			<HousesList
				{...{
					houses: housesData?.houses?.items || [],
					title: _`ResultsForLandingPage`,
					showMoreLabel: _`ShowMoreLandingPageHouses`,
					showMorePath,
					loading: housesLoading,
				}}
			/>
		</StyledSpacesWrap>
	)
}

export default LandingPageHouses
