import React, {useState} from 'react'
import styled from 'styled-components'
import {Box} from 'app/Components/Layout'
import {HeadBox, SpacesBox, SpacesWrap} from 'app/Components/Layout/Display'
import {_, useI18n} from 'plugins/i18n'
import {
	lightBeigeColor,
	darkBeigeColor,
	capGoldColor,
	useResponsive,
} from 'app/styles'
import {useHistory} from 'plugins/react-router-deluxe'
import {Icon} from 'plugins/icons'
import {useHouseSearchOptions} from 'app/_server/queries/houses'
import Loading from 'app/Components/Loading'
import {urlForRegion} from 'app/lib'

const mapSvgs = import.meta.glob('assets/maps/map-*.svg', {
	query: '?raw',
	eager: false,
	import: 'default',
})

class MapSvgSplitter extends React.Component {
	state = {}

	P = {}

	maps = {}

	// eslint-disable-next-line react/no-unsafe
	UNSAFE_componentWillMount() {
		this.getMap(this.props.countryId)
	}

	componentDidUpdate() {
		this.getMap(this.props.countryId)
	}

	getMap(countryId) {
		if (!countryId) return
		if (!this.P[countryId]) {
			const mapP = mapSvgs[`/assets/maps/map-${countryId}.svg`]?.()
				.then(m => {
					this.maps[countryId] = m
					if (!this.gone)
						this.setState({
							// eslint-disable-next-line react/no-unused-state
							countryId,
							no: false,
						})
				})
				.catch(() => !this.gone && this.setState({no: true}))
			this.P[countryId] = mapP
		}
	}

	componentWillUnmount() {
		this.gone = true
	}

	handleMounted = el => {
		if (el !== this.el) {
			this.el = el
			if (el) this.props.onLoad(el)
		}
	}

	render() {
		const {regionId, inactiveRegionsIds, countryId, ...rest} = this.props
		const map = this.maps[countryId]
		const {no} = this.state
		return map ? (
			<div
				ref={this.handleMounted}
				key={countryId}
				{...rest}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{__html: map}}
			/>
		) : no ? (
			<div {...rest}>
				{_`noMapFor`} {_(countryId)}
			</div>
		) : (
			<Loading />
		)
	}
}

const MapSvg = styled(MapSvgSplitter)`
	#${p => p.regionId && p.regionId.slice(2)} {
		fill: ${capGoldColor};
	}
	width: 100%;
	height: 100%;
	svg {
		width: 100%;
		height: 100%;
	}
	// Regions without rentals
	.st0 {
		fill: #edebd6;
	}
	// Regular regions
	.st1 {
		fill: ${darkBeigeColor};
		&:hover {
			cursor: pointer;
		}
	}
	line.st0 {
		stroke: ${darkBeigeColor};
	}
	${p =>
		p.inactiveRegionsIds.map(rId => '#' + (rId && rId.slice(2))).join(',')} {
		fill: #edebd6;
		cursor: default;
	}
`
const FlagBox = styled(Box)`
	> div {
		display: none;
	}
`

const flagSvgs = import.meta.glob('icons/flags/flag-*.icon.svg', {
	eager: true,
	import: 'default',
})

const FlagSvg = styled(({countryId, active, ...rest}) => {
	try {
		const glyph = flagSvgs[`/assets/icons/flags/flag-${countryId}.icon.svg`]
		return <Icon glyph={glyph} size="1.5em" {...rest} />
	} catch {
		return <div {...rest}>{_(countryId)}</div>
	}
})`
	opacity: ${p => (p.active ? '1' : '0.5')};
	cursor: pointer;
`
const RegionItem = styled.div`
	font-weight: ${p => (p.hover ? 'bold' : 'normal')};
	cursor: pointer;
	${p =>
		p.withDots &&
		`
			font-size: 0.9em;
			:before {
				content: '・';
			}
		`};
`

const RegionSpacesBox = styled(({isPhone, ...rest}) => (
	<SpacesBox {...rest} indent={!isPhone} inline={isPhone} stretch />
))`
	${p =>
		p.isPhone &&
		`
			display: flex;
			flex-wrap: wrap;
			white-space: nowrap;
			background: ${lightBeigeColor};
		`};
`

const Map = ({
	countryId,
	hoveredRegionId,
	inactiveRegionsIds,
	onRegionChange,
	onRegionHover,
	onRegionUnhover,
	regionIdsCb,
	fetchedRegionsIds = [],
}) => {
	const setEventListeners = () => {
		const regionIds = []
		const regularRegionNodeList = document.querySelectorAll('.st1')

		const svgMapRegionsIds = [...regularRegionNodeList].map(elem => {
			const regionId = `r$${elem.id}`
			if (fetchedRegionsIds.includes(regionId)) {
				regionIds.push(regionId)
				elem.addEventListener('click', () => {
					onRegionChange(regionId)
				})
				elem.addEventListener('mouseenter', () => {
					onRegionHover(regionId)
				})
				elem.addEventListener('mouseleave', () => {
					onRegionUnhover()
				})
			}
			return regionId
		})

		const regionIdIntersection = svgMapRegionsIds.filter(rId =>
			fetchedRegionsIds.includes(rId)
		)
		const regionIdDifference = [
			...svgMapRegionsIds.filter(
				rId => rId && !fetchedRegionsIds.includes(rId)
			),
			...fetchedRegionsIds.filter(rId => !svgMapRegionsIds.includes(rId)),
		]

		regionIdsCb(regionIdIntersection, regionIdDifference)
	}

	return (
		<MapSvg
			regionId={hoveredRegionId}
			inactiveRegionsIds={inactiveRegionsIds}
			countryId={countryId}
			onLoad={setEventListeners}
		/>
	)
}

export const RegionList: React.FC<{
	readonly countryId?: string
	readonly hoveredRegionId?: string
	readonly isPhone?: boolean
	readonly onRegionChange: (regionId: string) => void
	readonly onRegionHover: (regionId: string) => void
	readonly onRegionUnhover: () => void
	readonly regionIds: string[]
}> = ({
	countryId,
	hoveredRegionId,
	isPhone,
	onRegionChange,
	onRegionHover,
	onRegionUnhover,
	regionIds,
}) => (
	<>
		<HeadBox lvl={3}>{_(countryId)}</HeadBox>
		<RegionSpacesBox {...{isPhone}}>
			{regionIds.map((rId, i) => (
				<RegionItem
					key={i}
					hover={hoveredRegionId === rId}
					withDots={!isPhone}
					onMouseEnter={() => onRegionHover(rId)}
					onMouseLeave={onRegionUnhover}
					onClick={() => onRegionChange(rId)}
				>
					{_(rId)}
				</RegionItem>
			))}
		</RegionSpacesBox>
	</>
)

const Description: React.FC<{
	readonly countries: string[]
	readonly countryId?: string
	readonly hoveredRegionId?: string
	readonly isPhone?: boolean
	readonly onCountryChange: (countryId: string) => void
	readonly onRegionChange: (regionId: string) => void
	readonly onRegionHover: (regionId: string) => void
	readonly onRegionUnhover: () => void
	readonly regionIds: string[]
}> = ({
	countries,
	countryId,
	hoveredRegionId,
	isPhone,
	onCountryChange,
	onRegionChange,
	onRegionHover,
	onRegionUnhover,
	regionIds,
}) => (
	<div className="flex w-full flex-col items-start justify-center gap-4">
		<HeadBox lvl={2} underline>{_`mapTitle`}</HeadBox>
		<FlagBox wrap left gap="2em">
			{countries.map(cId => (
				<FlagSvg
					key={cId}
					countryId={cId}
					active={countryId === cId}
					onClick={() => onCountryChange(cId)}
				/>
			))}
		</FlagBox>
		<RegionList
			{...{
				countryId,
				hoveredRegionId,
				isPhone,
				onRegionChange,
				onRegionHover,
				onRegionUnhover,
				regionIds,
			}}
		/>
	</div>
)

const RegionMapSection = () => {
	const i18n = useI18n()
	const history = useHistory()
	const {isPhone} = useResponsive('isPhone')
	const [hoveredRegionId, setHoveredRegionId] = useState(undefined)
	const [activeCountryId, setActiveCountryId] = useState(undefined)
	const [regionIds, setRegionIds] = useState([])
	const [inactiveRegionsIds, setInactiveRegionsIds] = useState([])
	const {data, loading} = useHouseSearchOptions()

	if (loading) {
		return null
	}
	const {searchOptions} = data

	const handleCountryChange = countryId => setActiveCountryId(countryId)

	const handleRegionHover = regionId => setHoveredRegionId(regionId)

	const handleRegionUnhover = () => setHoveredRegionId(undefined)

	const handleRegionChange = regionId => {
		const url = urlForRegion({regionId, lang: i18n.lang})
		if (url) history.push(url)
	}

	const {locations = []} = searchOptions || {}
	const fetchedCountries = locations.map(l => l.countryId)
	const activeCountry = activeCountryId || fetchedCountries[0]
	const countryData = locations.find(l => l.countryId === activeCountry)
	const fetchedRegionsIds = countryData && countryData.regionIds

	return isPhone ? (
		<div className="mr-20 ml-6">
			<h2 className="text-onBackground mb-1 text-xl leading-8 font-bold">
				{i18n.getText('mapTitle')}
			</h2>
			<p className="mb-4 text-[#5D5D5D]">{i18n.getText('mapSubtitle')}</p>
			<div className="mb-5 flex flex-wrap gap-2">
				{fetchedCountries.map(cId => {
					return (
						<button
							key={cId}
							type="button"
							role="tab"
							className={`border-primary flex flex-row gap-2 rounded-full border border-solid px-3 py-2 whitespace-nowrap ${activeCountry === cId ? 'bg-primary text-onPrimary' : 'bg-onPrimary text-primary'}`}
							onClick={() => handleCountryChange(cId)}
						>
							<FlagSvg
								key={cId}
								countryId={cId}
								active={activeCountry === cId}
								onClick={() => handleCountryChange(cId)}
							/>
							{i18n.getText(cId)}
						</button>
					)
				})}
			</div>
			<div className="flex flex-col space-y-3">
				{fetchedRegionsIds.map((rId, i) => (
					<div
						className={`text-onBackground cursor-pointer text-sm leading-5 ${hoveredRegionId === rId ? 'font-bold' : 'font-normal'}`}
						key={i}
						onMouseEnter={() => handleRegionHover(rId)}
						onMouseLeave={handleRegionUnhover}
						onClick={() => handleRegionChange(rId)}
					>
						{_(rId)}
					</div>
				))}
			</div>
			<Map
				countryId={activeCountry}
				hoveredRegionId={hoveredRegionId}
				inactiveRegionsIds={inactiveRegionsIds}
				fetchedRegionsIds={fetchedRegionsIds}
				regionIdsCb={(regionIdsV, inactiveRegionsIdsV) => {
					setRegionIds(regionIdsV)
					setInactiveRegionsIds(inactiveRegionsIdsV)
				}}
				onRegionChange={handleRegionChange}
				onRegionHover={handleRegionHover}
				onRegionUnhover={handleRegionUnhover}
			/>
		</div>
	) : (
		<SpacesBox fullWidth vertical volume={8}>
			<SpacesWrap volumes={[8, 4]}>
				<div className="flex min-h-[44rem] w-full items-start justify-start">
					<Description
						countries={fetchedCountries}
						countryId={activeCountry}
						hoveredRegionId={hoveredRegionId}
						isPhone={isPhone}
						onCountryChange={handleCountryChange}
						onRegionChange={handleRegionChange}
						onRegionHover={handleRegionHover}
						onRegionUnhover={handleRegionUnhover}
						regionIds={regionIds}
					/>
					<Map
						countryId={activeCountry}
						hoveredRegionId={hoveredRegionId}
						inactiveRegionsIds={inactiveRegionsIds}
						fetchedRegionsIds={fetchedRegionsIds}
						regionIdsCb={(regionIdsV, inactiveRegionsIdsV) => {
							setRegionIds(regionIdsV)
							setInactiveRegionsIds(inactiveRegionsIdsV)
						}}
						onRegionChange={handleRegionChange}
						onRegionHover={handleRegionHover}
						onRegionUnhover={handleRegionUnhover}
					/>
				</div>
			</SpacesWrap>
		</SpacesBox>
	)
}
export default RegionMapSection
