import React, {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

const ScrollToTop: React.FC<{
	readonly targetId?: string
}> = ({targetId}) => {
	const history = useHistory()
	const location = useLocation()

	useEffect(() => {
		if (history.action !== 'POP') {
			if (targetId) {
				const el = document.querySelector('#' + targetId)
				if (el) {
					el.scrollTop = 0
					el.scrollLeft = 0
				}
			} else {
				window.scrollTo(0, 0)
			}
		}
	}, [location, targetId, history.action])

	return null
}

export default ScrollToTop
