import {ApolloClient, InMemoryCache, ApolloLink, HttpLink} from '@apollo/client'
import {BatchHttpLink} from '@apollo/client/link/batch-http'
import {createPersistedQueryLink} from '@apollo/client/link/persisted-queries'
import wrapApp from './wrapApp'
import {sha256} from 'crypto-hash'

const {prefix, graphqlPath} = __CONFIG__

const init = context => {
	const uri = `${location.protocol}//${location.host}${prefix}${graphqlPath}`

	const httpLink = new HttpLink({
		uri,
		credentials: 'same-origin',
	})

	const batchHttpLink = new BatchHttpLink({
		uri,
		batchInterval: 10,
		batchMax: 25,
		credentials: 'same-origin',
	})

	const noBatchLink = ApolloLink.split(
		({getContext}) => getContext().noBatch,
		httpLink,
		batchHttpLink
	)

	// Web Crypto API is not allowed in non-secure connections
	const persistedLink =
		location.protocol !== 'https:' && location.hostname !== 'localhost'
			? noBatchLink
			: // eslint-disable-next-line unicorn/prefer-spread
				createPersistedQueryLink({sha256}).concat(noBatchLink)

	const apollo = new ApolloClient({
		link: persistedLink,
		// Any force fetches are taken from initial state
		ssrForceFetchDelay: 100,
		cache: new InMemoryCache().restore(context.state.apollo),
		assumeImmutableResults: true,
	})

	context.apollo = apollo
}

const initClientHooks = (/** @type {Hooks} */ hooks) => {
	hooks.render.initRenderContext.tap('apollo', init)
	hooks.render.wrapApp.tap('apollo', wrapApp)
}

export default initClientHooks
