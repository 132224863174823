import React from 'react'
import PropTypes from 'prop-types'
import {LangLink} from 'plugins/i18n'
import styled from 'styled-components'
import Box from 'app/Components/Layout/Box'
import {getFg, getHoverFg} from 'app/styles'

const NavSection = styled(Box)`
	font-weight: lighter;
	color: ${getFg};
	&:hover {
		color: ${getHoverFg};
		transition: 0.2s;
	}
`

const Nav = ({routes, onChange = undefined, ...rest}) => (
	<NavSection {...rest} wrap spaceAround>
		{routes &&
			routes.map((route, i) =>
				route.path ? (
					<div key={i}>
						<LangLink
							className="whitespace-nowrap"
							onClick={e => {
								if (route.onChange) route.onChange(e)
								if (onChange) onChange()
							}}
							to={route.path}
						>
							{route.label}
						</LangLink>
					</div>
				) : (
					<div key={i}>{route.label}</div>
				)
			)}
	</NavSection>
)
Nav.propTypes = {
	routes: PropTypes.arrayOf(PropTypes.object),
	width: PropTypes.string,
	column: PropTypes.bool,
	mobile: PropTypes.bool,
}

export default Nav
