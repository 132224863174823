import React from 'react'
import {TopLabelTheme} from 'app/styles'
import {_} from 'plugins/i18n'
import {InputField, SelectInput, NumericInput} from 'app/Components/Inputs'

const NewOrPromoForm = () => (
	<TopLabelTheme>
		<div className="flex w-full flex-col items-start justify-center gap-4">
			<InputField
				name="newOrPromo"
				optionValues={['isNew', 'isPromo']}
				placeholder={_('newOrPromo')}
				Input={SelectInput}
			/>
			<InputField name="limit" Input={NumericInput} />
		</div>
	</TopLabelTheme>
)

export default NewOrPromoForm
