import React from 'react'
import I18nProvider from './I18nProvider'

const wrapWithI18n = context => {
	context.app = (
		<I18nProvider
			lang={context.lang}
			addMissing={context.i18nAddMissing}
			onLang={context.i18nOnLang}
			MetaForm={context.I18nMetaForm}
		>
			{context.app}
		</I18nProvider>
	)
}

export default wrapWithI18n
