import React, {Component, FC, InputHTMLAttributes} from 'react'
import PropTypes from 'prop-types'
import {makeSplitPoint} from 'plugins/react'
import Icon, {glyphs, CloseIcon} from 'app/Components/Icon'
import {styled, css, getBg, getFg, getHint} from 'app/styles'
import rat from 'react-autosize-textarea'
const TextareaAutosize: typeof rat = (rat as any).default || rat

export {default as FormInput} from 'app/Components/Layout/Box'

const MaskedInput = makeSplitPoint(() => import('react-text-mask'))

const inputWrapCss = css<{isFocused?: boolean}>`
	box-sizing: border-box;
	color: ${p => getFg(p)};
	background-color: ${p => getBg(p)};
	font-size: 1rem;
	line-height: 1.5rem;
	min-width: 5rem;
	width: 100%;
	min-height: ${p => p.theme.inputHeight}rem;
	padding: 0.4rem;
	border: ${p => p.theme.noBorder || `thin solid ${getHint(p)}`};
	border-radius: ${p => p.theme.radius}rem;
	${p => p.theme.input && p.theme.input.textInputCss};
	${p => p.isFocused && p.theme.focusCss};
`
const InputWrap = styled.div`
	position: relative;
	${inputWrapCss};
`
const InputIcon = styled(Icon).attrs({size: '1.2rem'})`
	margin-right: 0.5rem;
`
const InnerWrap = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
`
export const textInputCss = css<{type?: string}>`
	width: 100%;
	outline: none;
	color: ${p => getFg(p)};
	/* text-align: ${p => (p.type === 'number' ? 'right' : undefined)}; */
`
const InputDom = styled.input`
	display: inline-block;
	${textInputCss};
`
const PlaceholderDom = styled.div`
	${textInputCss};
	white-space: nowrap;
	line-height: 1.5rem;
	color: ${p => getHint(p)};
	vertical-align: baseline;
	position: absolute;
	pointer-events: none;
	background-color: transparent;
	${p => p.theme.input && p.theme.input.placeholder};
`
export const ClearButton = styled(({show, ...p}) => (
	<CloseIcon size="0.6em" hint {...p} />
))`
	opacity: ${p => (p.show ? 1 : 0)};
	cursor: pointer;
`

const maskRender = (ref, {defaultValue, ...renderProps}) => (
	<input ref={ref} value={defaultValue} {...renderProps} />
)

class LineInput extends Component<{
	value?: string | null
	onChange?: (newValue: string | null) => void
	className?: string
	icon?: string
	placeholder?: React.ReactNode
	onEnterKey?: Function
	onKeyUp?: Function
	onIconClick?: Function
	onFocus?: Function
	onBlur?: Function
	defaultValue?: string
	mask?: string | number
	_forwardRef: any
	Icon?: FC<any> | null
}> {
	state = {isFocused: false}

	handleFocus = ev => {
		const {onFocus} = this.props
		this.setState({isFocused: true})
		if (onFocus) onFocus(ev)
	}

	handleBlur = ev => {
		const {onBlur} = this.props
		this.setState({isFocused: false})
		if (onBlur) onBlur(ev)
	}

	handleClear = e => {
		e.preventDefault()
		const {onChange, _forwardRef} = this.props
		if (onChange) onChange(null)
		if (_forwardRef.current.focus) _forwardRef.current.focus()
	}

	render() {
		const {
			className,
			onEnterKey,
			onKeyUp,
			onIconClick,
			icon,
			placeholder,
			_forwardRef,
			Icon,
			...rest
		} = this.props
		const {isFocused} = this.state
		const {onChange: oc, defaultValue} = rest
		const onChange =
			oc &&
			(ev => {
				// We don't want any other handlers to fire. Somehow the event fires twice
				// in I18nInput, once directly on the parent onChange, breaking the object
				ev.stopPropagation()
				oc(ev.target.value)
			})
		const value = defaultValue ? undefined : rest.value || ''
		const handleKeyUp =
			onEnterKey &&
			(e => {
				if (e.key === 'Enter') {
					e.stopPropagation()
					return onEnterKey(e.target.value)
				}

				if (onKeyUp) return onKeyUp(e)
			})
		/** @type {React.ComponentProps<InputDom>} */
		const inputProps = {
			...rest,
			ref: _forwardRef,
			onFocus: this.handleFocus,
			onBlur: this.handleBlur,
			onKeyUp: handleKeyUp,
			onChange,
			value,
		} as any
		if (rest.mask) {
			inputProps.as = MaskedInput
			// Workaround for https://github.com/text-mask/text-mask/issues/530
			inputProps.render = maskRender
		}
		return (
			<div className="flex w-full items-center justify-center">
				<InputWrap {...{isFocused}} className={className}>
					{icon && (
						<InputIcon
							onClick={onIconClick}
							glyph={typeof icon === 'string' ? glyphs[icon] : icon}
							secondary
						/>
					)}
					{Icon && <Icon onClick={onIconClick} />}
					<InnerWrap>
						{placeholder && !value && (
							<PlaceholderDom>{placeholder}</PlaceholderDom>
						)}
						<InputDom {...inputProps} />
					</InnerWrap>
					<ClearButton show={!!value} onClick={this.handleClear} />
				</InputWrap>
			</div>
		)
	}
}

export const StyledInput = styled(
	React.forwardRef((props, ref) => (
		<LineInput {...props} _forwardRef={ref || React.createRef()} />
	))
)`
	display: flex;
	align-items: center;
` as unknown as React.ComponentType<
	Omit<InputHTMLAttributes<string>, 'onChange' | 'value'> &
		Omit<React.ComponentPropsWithRef<typeof LineInput>, '_forwardRef'>
>

export const Label = styled.label<{
	inline?: boolean
	fitChildren?: boolean
	together?: boolean
	top?: boolean
	gap?: boolean | string
	right?: boolean
	theme: Object
}>`
	width: ${p => !p.inline && !p.fitChildren && '100%'};
	display: ${p => (p.inline ? 'inline-flex' : 'flex')};
	align-items: center;
	justify-content: ${p =>
		p.together ? (p.right ? 'flex-start' : 'flex-end') : 'space-between'};
	${p =>
		(typeof p.top === 'boolean' ? p.top : p.theme.labelTop) && !p.inline
			? `
			flex-direction: column;
			align-items: flex-start;
			> *:not(:first-child) {
				margin-top: ${p.gap == null || p.gap === true ? p.theme.labelGap : p.gap};
			}`
			: `
			> *:not(:first-child) {
				margin-${p.right ? 'right' : 'left'}: ${
					p.gap == null ? p.theme.labelGap : p.gap
				};
			}
			`};
`

export const LabelText = styled.label<{
	error?: React.ReactNode
	bigLabel?: boolean
	mediumLabel?: boolean
	textSize?: string
	bold?: boolean
	noWrap?: boolean
	together?: boolean
	fullWidth?: boolean
	inline?: boolean
	top?: boolean
	right?: boolean
	theme: Object
}>`
	white-space: ${p => p.noWrap && 'nowrap'};
	flex: ${p =>
		!(p.top || p.theme.labelTop || p.fullWidth || p.inline || p.right) && `0`};
	width: ${p => p.fullWidth && '100%'};
	min-width: ${p =>
		!(p.top || p.theme.labelTop || p.fullWidth || p.inline || p.right) &&
		p.theme.labelWidth};
	display: ${p => (p.children ? 'inline-block' : 'none')};
	color: ${p => (p.error ? 'red' : getFg(p))};
	font-size: ${p => {
		if (p.bigLabel) return '1.5rem'
		if (p.mediumLabel) return '1rem'
		if (p.textSize) return p.textSize
		return '.9rem'
	}};
	text-overflow: ellipsis;
	padding: ${p => !p.fullWidth && (p.right ? '0 0 0 0.5em' : '0 0.5em 0 0')};
	text-align: ${p => !!p.right !== !!p.together && 'right'};
	font-weight: ${p => (p.bold && 'bold') || p.theme.fontWeight || 'normal'};
	${p => p.theme.input && p.theme.input.labelTextCss};
`
export const ErrorText = styled.span`
	color: red;
	${p => p.theme.input && p.theme.input.errorTextCss};
`

const TAPlaceholderDom = styled(PlaceholderDom)`
	top: 0;
	padding: 0.4rem; /* Same as inputs */
	background: transparent !important;
	${p => p.theme.input && p.theme.input.textInputCss};
	${p => p.theme.input && p.theme.input.placeholderCss};
`
const TextareaDom = styled(TextareaAutosize)<{resize?: string}>`
	${inputWrapCss};
	resize: ${p => p.resize || 'vertical'};
	${p => p.theme.input && p.theme.input.textareaInputCss};
	&:focus {
		${p => p.theme.focusCss};
	}
`
const TextareaWrap = styled.div`
	position: relative;
	line-height: 1.5rem;
	width: 100%;
	display: flex;
	${TAPlaceholderDom} {
		display: none;
	}
	${TextareaDom}:empty + ${TAPlaceholderDom} {
		display: block;
	}
`

export const StyledTextarea = ({placeholder, ...props}) => {
	const {onChange: oc} = props
	const onChange =
		oc &&
		(ev => {
			// We don't want any other handlers to fire
			ev.stopPropagation()
			oc(ev.target.value)
		})
	return (
		<TextareaWrap>
			<TextareaDom async {...{...props, onChange}} />
			{placeholder && <TAPlaceholderDom>{placeholder}</TAPlaceholderDom>}
		</TextareaWrap>
	)
}
StyledTextarea.propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.node,
}

export const Well = styled.div<{horizontal?: boolean; vertical?: boolean}>`
	width: 100%;
	border: ${p => p.theme.noBorder || `thin solid ${getHint(p)}`};
	border-radius: ${p => p.theme.radius}rem;
	padding: ${p =>
		p.horizontal ? '0 .5em' : p.vertical ? '.1em 0' : '.1em .5em'};
`
