import {useI18n} from 'plugins/i18n'
import React from 'react'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {setSort} from 'app/redux/ducks/searchRentals-duck'
import Text from '../twComponents/Text'
import {SelectInput} from '../Inputs'
import {ThemeProvider} from 'styled-components'

/** Split by the position before any uppercase letter */
const splitByCapital = (str: string): string[] => str.split(/(?=[A-Z])/)

type SortOption = `relevance` | `${string}Asc` | `${string}Desc`
const options: SortOption[] = [
	'relevance',
	'priceAsc',
	'priceDesc',
	'capacityAsc',
	'capacityDesc',
] as const
export const allowedSortKeys = options.slice(1).map(o => splitByCapital(o)[0])
const defaultSortInputValue = options[0]!

const calcValueFromSortObj = (
	sortObj?: SearchRentalsState['sort']
): SortOption => {
	const sortBy = Object.keys(sortObj || {})[0]
	const value: SortOption | undefined = sortBy
		? `${sortBy}${sortObj![sortBy] === 1 ? 'Asc' : 'Desc'}`
		: undefined
	if (!value || !options.includes(value)) return defaultSortInputValue
	return value
}

const SortInput = () => {
	const {getText} = useI18n()
	const dispatch = useDispatch()
	const sort = useSelector(state => state.searchRentals.sort)

	const onChange = (v: SortOption) => {
		let newSort
		const [sortBy, direction] = splitByCapital(v) as [string, 'Asc' | 'Desc']
		if (v !== 'relevance') {
			newSort = {[sortBy]: direction === 'Asc' ? 1 : -1}
		}
		dispatch(setSort({sort: newSort}))
	}

	return (
		<div className="flex w-full items-center justify-end gap-1">
			<Text variant="small" className="text-tertiary">
				{getText('Sort')}
			</Text>
			<div className="w-full max-w-40">
				<ThemeProvider theme={{input: {border: 'none'}}}>
					<SelectInput
						placeholder=""
						noLabel
						value={calcValueFromSortObj(sort)}
						clearable={false}
						options={options.map(o => ({
							label: getText(o),
							value: o,
						}))}
						onChange={onChange}
					/>
				</ThemeProvider>
			</div>
		</div>
	)
}
export default SortInput
