// helpers.ts

import {colors} from 'app/backoffice/House/ReservationsView'
import {calcFirstAvailableDate} from 'app/lib/calcPrice'
import {
	DTRG_BLOCK,
	DTRG_CONTRACT,
	DTRG_PRICE,
} from 'app/_server/database/constants'

interface DateRangeItem {
	beginDate: HousePrice['date']
	buy?: HousePrice['buy']
	contractId?: Contract['id']
	endDate: HousePrice['date']
	fmId?: Contract['fmId']
	houseId: House['id']
	id: number
	isOption?: boolean
	margin?: HousePrice['margin']
	sell?: HousePrice['sell']
	type: number | string
	updateTs?: DateISO
}

export interface DateRangeItemWithColor extends DateRangeItem {
	color?: string
	start?: HousePrice['date']
	end?: HousePrice['date']
}

/** Creates a style object based on the house's switch day (if any). */
export function makeSwitchDayStyle(
	switchDay: House['switchDay']
): {day: number; color: string} | undefined {
	if (switchDay == null) return undefined
	return {
		day: switchDay,
		color: 'rgba(0, 128, 0, 0.5)',
	}
}

/**
 * Returns either the given beginDate as a Date, or the first available date
 * from the house prices.
 */
export function makeInitialDate(
	beginDate: Contract['beginDate'] | undefined | null,
	prices: HousePrice[] = []
) {
	return beginDate ? new Date(beginDate) : calcFirstAvailableDate(prices)
}

/** Filters and transforms date ranges for the calendar. */
export function makeDateStates(
	dateRanges: DateRangeItem[]
): DateRangeItemWithColor[] {
	const dateStates: DateRangeItemWithColor[] = []

	for (const item of dateRanges) {
		const isContractOrBlock =
			item.type === DTRG_CONTRACT || item.type === DTRG_BLOCK
		const isPriceNoSell = item.type === DTRG_PRICE && item.sell === 0

		if (isContractOrBlock || isPriceNoSell) {
			// Decide what color label to use
			const desiredColor = item.isOption
				? 'option'
				: item.type === DTRG_PRICE
					? 'noSell'
					: item.type

			dateStates.push({
				...item,
				type: String(desiredColor),
				start: item.beginDate,
				end: item.endDate,
				color: colors[desiredColor] && `rgba(${colors[desiredColor]})`,
			})
		}
	}

	return dateStates
}
