import React, {useState} from 'react'
import styled from 'styled-components'
import {Box} from 'app/Components/Layout'
import {Button} from 'app/Components/Buttons'
import {
	GreenWhiteTheme,
	SubscribeTheme,
	BeigeGreenTheme,
	getFg,
	getBg,
	WhiteTheme,
	useResponsive,
} from 'app/styles'
import {Line, SpacesWrap} from 'app/Components/Layout/Display'
import {TextInput} from 'app/Components/Inputs'
import {gtag} from 'plugins/trackers'
import {_, useI18n} from 'plugins/i18n'

export const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

const StyledSpacesWrap = styled(SpacesWrap)`
	background: ${getBg};
	width: 100%;
`
const MiniHeader = styled.div`
	color: ${getFg};
	margin-bottom: 0.25em;
	font-size: 0.8em;
`
const StyledBox = styled(Box)`
	> * {
		flex: 1;
	}
`

export const Form: React.FC<{
	readonly email?: string
	readonly isPhone?: boolean
	readonly link?: string
	readonly onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	readonly valid?: boolean
}> = ({isPhone, email, valid, link, onChange}) => (
	<form
		className="w-full"
		onSubmit={e => {
			if (!valid) {
				e.preventDefault()
			}
			gtag('event', 'Ingeschreven', {event_category: 'Nieuwsbrief'})
		}}
		action={`${link}`}
		method="post"
		name="mc-embedded-subscribe-form"
	>
		<Box gap={isPhone && '1em'} column={isPhone}>
			<WhiteTheme>
				<TextInput
					fullWidth
					icon="MAIL"
					name="EMAIL"
					onChange={onChange}
					placeholder={_('email')}
					value={email}
				/>
			</WhiteTheme>
			<GreenWhiteTheme>
				<Button fullWidth={isPhone} large type="submit">
					{_('newsletterSubBtn')}
				</Button>
			</GreenWhiteTheme>
		</Box>
	</form>
)

export const getNewsletterUrl = (lang: I18nContext['lang']) => {
	switch (lang) {
		case 'nl': {
			return '//cap.us12.list-manage.com/subscribe/post?u=8d2354e1c10c8dde6c3f25f32&amp;id=13d02f930a'
		}
		case 'en': {
			return '//cap.us12.list-manage.com/subscribe/post?u=8d2354e1c10c8dde6c3f25f32&amp;id=28017c13ce'
		}
		case 'fr': {
			return '//cap.us12.list-manage.com/subscribe/post?u=8d2354e1c10c8dde6c3f25f32&amp;id=fa43f87888'
		}
		default: {
			return '//cap.us12.list-manage.com/subscribe/post?u=8d2354e1c10c8dde6c3f25f32&amp;id=28017c13ce'
		}
	}
}

const SubscribeSection = () => {
	const {lang} = useI18n()
	const {isPhone} = useResponsive('isPhone')
	const [email, setEmail] = useState('')
	const [valid, setValid] = useState(false)

	const handleEmail = input => {
		const isValid = mailFormat.test(input)
		setEmail(input)
		setValid(isValid)
	}
	const link = getNewsletterUrl(lang)

	return (
		<div>
			<SubscribeTheme>
				<StyledSpacesWrap volumes={[14, 4]}>
					<div className="flex w-full flex-col items-start justify-center gap-4">
						{!isPhone && (
							<BeigeGreenTheme>
								<div>
									<MiniHeader>{_`subscribeHeader`}</MiniHeader>
									<Line horizontal length="3em" opacity="0.5" />
								</div>
							</BeigeGreenTheme>
						)}
						<StyledBox left column={isPhone} spaceBetween gap="1em">
							{_`subscribeText`}
							<Form
								isPhone={isPhone}
								email={email}
								valid={valid}
								link={link}
								onChange={handleEmail}
							/>
						</StyledBox>
					</div>
				</StyledSpacesWrap>
			</SubscribeTheme>
		</div>
	)
}

export default SubscribeSection
