import {gql} from 'graphql-tag'
import {removeTypenameFromObj, useMutation} from 'plugins/apollo'
import {makeQueryHook} from './helpers'
import {QueryHousesArgs} from 'app/gql/graphql'

// Queries
const housesArgsPart1 = `
		$name: ShortString
		$regionId: ID
		$regionIds: [ID]
		$size: Int
		$type: ID
		$beginDate: Date
		$endDate: Date
		$featuresAllIn: [ID]
		$typeIn: [ID]
		$specialsAllIn: [ID]
		$priceBelow: Int
		$isNew: Boolean
		$isPromo: Boolean
		$specialId: ID
		$isFree: Boolean
		$limit: Int
		$cursor: ShortString
		$domainId: ID
		$houseIds: [ID]
		$idNotIn: [ID]
		$sort: JSON
`
const housesArgsPart2 = `
			name: $name
			regionId: $regionId
			regionIds: $regionIds
			size: $size
			type: $type
			beginDate: $beginDate
			endDate: $endDate
			featuresAllIn: $featuresAllIn
			typeIn: $typeIn
			specialsAllIn: $specialsAllIn
			priceBelow: $priceBelow
			isNew: $isNew
			isPromo: $isPromo
			specialId: $specialId
			isFree: $isFree
			limit: $limit
			cursor: $cursor
			domainId: $domainId
			houseIds: $houseIds
			idNotIn: $idNotIn
			sort: $sort
`

export const housesListQuery = gql`
	query HousesListQuery(
		${housesArgsPart1}
	) {
		houses(
			${housesArgsPart2}
		) {
			items {
				id
				blockedRanges
				bathroomCount
				bedroomCount
				calcCapacity
				capacity
				category
				countryId
				description
				distances {
					km
					min
					name
					timeId
					typeId
				}
				extras {
					condition
					max
					optionId
					per
					price
					required
					pet
				}
				features
				icons
				isNew
				isPromo
				isFree
				images {
					url
					h
					w
					blurhash
					tags
					upload {
						id
					}
				}
				mainImage {
					h
					w
					blurhash
					tags
					upload {
						id
					}
				}
				name
				domainName
				petsAllowed
				petsMax
				placeName
				prices {
					date
					sell
				}
				promotions {
					promoStartDate
					promoEndDate
					discountPercentage
				}
				regionId
				rooms {
					building
					floor
					room
					equipment {
						type
						count
					}
				}
				score
				slug
				type
				urlI18n
			}
			cursor
			total
		}
	}
`

export const useHouseListQuery = makeQueryHook(housesListQuery)

const housesCountQuery = gql`
	query housesCountQuery(
		${housesArgsPart1}
	) {
		housesCount(
			${housesArgsPart2}
		)
	}
`
export const useHousesCountQuery = makeQueryHook<
	{housesCount: number},
	QueryHousesArgs
>(housesCountQuery)

const housesFilterCountersQuery = gql`
	query housesFilterCountersQuery(
		${housesArgsPart1}
	) {
		housesFilterCounters(
			${housesArgsPart2}
		) {
			types
			features
			specials
			priceBelow
		}
	}
`
export const useHousesFilterCountersQuery = makeQueryHook<
	{housesFilterCounters: HouseFilterCountersType},
	QueryHousesArgs
>(housesFilterCountersQuery)

export const houseQuery = gql`
	query houseQuery($houseId: ID, $slug: ID) {
		house(id: $houseId, slug: $slug) {
			id
			geo
			bathroomCount
			bedroomCount
			blockedRanges
			capacity
			category
			city
			countryId
			guarantee
			description
			metaDescription
			distances {
				km
				min
				name
				timeId
				typeId
			}
			domainId
			extras {
				condition
				max
				optionId
				per
				price
				required
				pet
			}
			features
			hours
			icons
			images {
				blurhash
				h
				url
				w
				blurhash
				tags
				upload {
					id
				}
			}
			isPromo
			isFree
			isNew
			livingSurface
			localTax
			taxPlatformId
			taxCategory
			mainImage {
				blurhash
				h
				url
				w
				blurhash
				tags
				upload {
					id
				}
			}
			name
			domainName
			payThere
			petsAllowed
			petsMax
			placeName
			prices {
				date
				sell
			}
			promotions {
				promoStartDate
				promoEndDate
				discountPercentage
			}
			province
			regionId
			rooms {
				building
				equipment {
					type
					count
				}
				floor
				room
			}
			score
			slogan
			slug
			specials
			street
			switchDay
			tips
			totalSurface
			type
			reviews {
				name
				countryId
				text
				textI18n
				score
				startDate
				endDate
			}
			urlI18n
		}
	}
`

export const useHouseQuery = makeQueryHook<
	{house: House},
	{houseId: string; slug?: never} | {houseId?: never; slug: string}
>(houseQuery)

export const basicHouseQuery = gql`
	query houseQuery($houseId: ID, $slug: ID) {
		fullHouse(id: $houseId, slug: $slug) {
			id
			name
		}
	}
`

export const useBasicHouseQuery = makeQueryHook<
	{fullHouse: Pick<House, 'id' | 'name'>},
	{houseId: string; slug?: never} | {houseId?: never; slug: string}
>(basicHouseQuery)

export const basicHousesListQuery = gql`
	query HousesListQuery(
		$name: ShortString
		$hasAgentChanges: Boolean
		$isOnline: Boolean
		$limit: Int
		$cursor: ShortString
	) {
		fullHouses(
			name: $name
			limit: $limit
			cursor: $cursor
			hasAgentChanges: $hasAgentChanges
			isOnline: $isOnline
		) {
			items {
				id
				name
				agentId
			}
			cursor
			total
		}
	}
`

export const useBasicHousesListQuery = makeQueryHook<
	{fullHouses: {items: Pick<House, 'id' | 'name' | 'agentId'>[]}},
	{
		hasAgentChanges?: Boolean
		isOnline?: Boolean
		limit?: number
		name?: string
		cursor?: string
	}
>(basicHousesListQuery)

export const houseDetailFields = `
	id
	agentChanges
	createdAt
	updatedAt
	name
	slug
	isOnline
	isPromo
	rating
	category
	petsAllowed
	type
	note
	domainId
	agentId
	agent {
		id
		name
		contacts
		hours
		margin
		address {
			suite
			unit
			street
			pcode
			city
			region
			countryId
		}
		comms {
			type
			data
			note
		}
	}
	onlinePayment
	owner {
		name
		address {
			suite
			unit
			street
			pcode
			city
			region
			countryId
		}
	}
	address {
		suite
		unit
		street
		pcode
		city
		region
		countryId
		geo
	}
	keyInfo {
		info
		phone
		address {
			suite
			unit
			street
			pcode
			city
			region
			countryId
		}
	}
	agentRef
	hours
	margin
	regionId
	placeName
	memos {
		ts
		memo
		email {
			subject
			body
		}
	}
	sloganI18n
	descriptionI18n
	metaDescriptionI18n
	descrNote
	distances {
		typeId
		km
		min
		timeId
		name
		noteI18n
	}
	routeI18n
	voucherAttachmentsI18n
	tipsI18n
	livingSurface
	totalSurface
	livingFloors
	totalFloors
	guarantee
	payThere
	localTax
	taxPlatformId
	taxCategory
	taxRegistration
	accessTime
	leaveTime
	capacity
	calcCapacity
	rooms {
		building
		floor
		room
		equipment {
			type
			count
		}
	}
	prices {
		date
		buy
		sell
		margin
	}
	blockedRanges
	promotions {
		promoStartDate
		promoEndDate
		discountPercentage
	}
	extras {
		price
		condition
		optionId
		per
		max
		required
		pet
	}
	specials
	bathroomCount
	bedroomCount
	images {
		url w h blurhash
		upload {
			id
			ts
			filename
			meta
		}
		show isMain
		alt title note
		tags
	}
	switchDay
	dateRanges
	reviews {
		name
		countryId
		textI18n
		score
		startDate
		endDate
	}
`
export const HouseDataQuery = gql`
  query HouseData($houseId: ID!) {
  	fullHouse(id: $houseId) {${houseDetailFields}}
  }
`

export const useHouseDataQuery = makeQueryHook<
	{fullHouse: House},
	{houseId: string}
>(HouseDataQuery)

export const featuredHouses = gql`
	query featuredHouses(
		$newOrPromo: Boolean
		$type: ID
		$size: Int
		$regionId: ID
		$beginDate: Date
		$endDate: Date
	) {
		featuredHouses(
			newOrPromo: $newOrPromo
			type: $type
			size: $size
			regionId: $regionId
			beginDate: $beginDate
			endDate: $endDate
		) {
			id
			bathroomCount
			bedroomCount
			blockedRanges
			capacity
			countryId
			description
			isNew
			isPromo
			prices {
				sell
				date
			}
			mainImage {
				tags
				url
				w
				h
				blurhash
				upload {
					id
					path
				}
			}
			name
			placeName
			regionId
			slug
			type
			urlI18n
		}
	}
`
export const useFeaturedHousesQuery = makeQueryHook<
	{featuredHouses: House[]},
	{
		newOrPromo?: boolean
		type?: string
		size?: number
		regionId?: string
		beginDate?: Date
		endDate?: Date
	}
>(featuredHouses)

export const houseSearchOptions = gql`
	query searchOptions {
		searchOptions
	}
`

export const RESERVATIONS_QUERY = gql`
	query reservations($houseId: ID!) {
		fullHouse(id: $houseId) {
			id
			dateRanges
		}
	}
`
export const useReservationsQuery = makeQueryHook(RESERVATIONS_QUERY)

export const newOrPromoQuery = gql`
	query newOrPromo(
		$isNew: Boolean
		$isPromo: Boolean
		$limit: Int!
		$regionId: ID
	) {
		newOrPromo(
			isNew: $isNew
			isPromo: $isPromo
			limit: $limit
			regionId: $regionId
		) {
			id

			slug
			type
			name
			regionId
			placeName
			countryId
			bathroomCount
			bedroomCount
			blockedRanges
			capacity
			description
			isNew
			prices {
				sell
				date
			}
			mainImage {
				w
				h
				blurhash
				upload {
					id
				}
				tags
			}
			urlI18n
		}
	}
`

export const useNewOrPromoQuery = makeQueryHook<
	{newOrPromo: House[]},
	{isNew?: boolean; isPromo?: boolean; limit?: number; regionId?: string}
>(newOrPromoQuery)

export const agentChangesListCount = gql`
	query agentChangesListCount {
		agentChangesCount
	}
`

export const regionCountryMapQuery = gql`
	query {
		regionCountryMap
	}
`

export const useHouseSearchOptions = makeQueryHook(houseSearchOptions)

// Mutations

const SAVE_HOUSE_MUTATION = gql`
		mutation saveHouse($house: RentalInput!) {
				saveHouse(house: $house) {${houseDetailFields}}
			}
	`

export const saveHouse = [
	SAVE_HOUSE_MUTATION,
	{
		props: ({mutate}) => ({
			// Note that we are only sending what changed
			saveHouse: (house: House) => {
				if (house.images) {
					// only send allowed values
					const images = house.images.map(
						// @ts-ignore -- TODO gql input types
						({url, uploadId, title, alt, note, tags, show, isMain}) => ({
							url,
							uploadId,
							title,
							alt,
							note,
							tags,
							show,
							isMain,
						})
					)
					house = {...house, images}
				}
				try {
					return mutate({
						variables: {
							house: removeTypenameFromObj(house, ['petsMax', 'blurhash']),
						},
						refetchQueries: [
							'HousesListQuery',
							{query: houseQuery, variables: {houseId: house.id}},
						],
					})
				} catch (err) {
					alert(`Kon huis niet opslaan: ${err.message}`)
					throw err
				}
			},
		}),
	},
] as const

export const useSaveHouse = () => {
	const [mutate, {loading, error, data}] = useMutation(SAVE_HOUSE_MUTATION, {
		refetchQueries: ['HousesListQuery'],
	})

	// eslint-disable-next-line no-shadow
	const saveHouse = async (house: any) => {
		try {
			if (house.images) {
				const images = house.images.map(
					({url, uploadId, title, alt, note, tags, show, isMain}: any) => ({
						url,
						uploadId,
						title,
						alt,
						note,
						tags,
						show,
						isMain,
					})
				)
				house = {...house, images}
			}

			const cleanedHouse = removeTypenameFromObj(house, ['petsMax', 'blurhash'])

			const result = await mutate({
				variables: {house: cleanedHouse},
				refetchQueries: [
					'HousesListQuery',
					{query: houseQuery, variables: {houseId: house.id}},
				],
			})

			return result
		} catch (err) {
			alert(`Kon huis niet opslaan: ${err.message}`)
			throw err
		}
	}

	return {saveHouse, loading, error, data}
}

const ACCEPT_HOUSE_CHANGES = gql`
  mutation acceptHouseChanges($id: ID!) {
    acceptHouseChanges(id: $id) {
      ${houseDetailFields}
    }
  }
`

export const useAcceptHouseChanges = () => {
	const [acceptHouseChangesMutation] = useMutation(ACCEPT_HOUSE_CHANGES, {
		refetchQueries: ['HousesListQuery', 'agentChangesListCount'],
	})

	const acceptHouseChanges = (id: string) => {
		return acceptHouseChangesMutation({
			variables: {id},
		})
	}

	return {acceptHouseChanges}
}

const REJECT_HOUSE_CHANGES = gql`
	mutation rejectHouseChanges($id: ID!) {
		rejectHouseChanges(id: $id) {
			id
			agentChanges
		}
	}
`

export const useRejectHouseChanges = () => {
	const [rejectHouseChangesMutation] = useMutation(REJECT_HOUSE_CHANGES, {
		refetchQueries: ['HousesListQuery', 'agentChangesListCount'],
	})

	const rejectHouseChanges = (id: string) => {
		return rejectHouseChangesMutation({
			variables: {id},
		})
	}

	return {rejectHouseChanges}
}

export const useRegionCountryMapQuery = makeQueryHook(regionCountryMapQuery)

const recalcHousesMutation = gql`
	mutation {
		recalcHouses
	}
`

export const useRecalcHouses = () => {
	const [mutate] = useMutation(recalcHousesMutation)

	return () => {
		mutate()
	}
}
