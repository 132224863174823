import React from 'react'
import SectionValue from './SectionValue'
import {useTemplate} from '../queries'
import EditorWithSave, {TemplateEditor} from './TemplateEditor'
import {useEditing} from './Editable'
import {useSelector} from 'plugins/redux/hooks'

export const WidthLimiter = ({children}) => {
	return (
		<div className="flex w-full flex-col items-center justify-start">
			{React.Children.map(children, child =>
				child.props.className?.includes('wide') ? (
					child
				) : (
					<div className="w-full max-w-[1600px]">{child}</div>
				)
			)}
		</div>
	)
}

const ErrorWrap = ({children}) => (
	<div className="flex w-full items-center justify-center">{children}</div>
)

const Template: React.FC<{
	readonly allowedSectionsTag?: string
	readonly id?: string
	readonly allowEmpty?: boolean
	readonly fragment?: boolean
	readonly templateData?: Record<string, any>
	readonly value?: Record<string, any>
	readonly onChange?: (value: Record<string, any>) => void
}> = ({
	id,
	value: givenTemplate,
	onChange,
	allowEmpty = false,
	fragment = false,
	templateData = {},
	allowedSectionsTag,
}) => {
	const {isEditing} = useEditing()
	const {data, loading, error} = useTemplate(id, {skip: !id || !!givenTemplate})
	const isPhone = useSelector(state => state.responsive.isPhone)
	const isServer = useSelector(state => state.responsive.isServer)

	if (!(id || givenTemplate)) {
		return <ErrorWrap>ERROR: no templateId given</ErrorWrap>
	}

	if (error) {
		return <ErrorWrap>Failed to load template "{id}"</ErrorWrap>
	}

	if (!givenTemplate && loading) {
		return <ErrorWrap>…</ErrorWrap>
	}

	// Grab template data
	const template = givenTemplate || data?.template || {id}

	if (isEditing) {
		return givenTemplate ? (
			<TemplateEditor
				value={template}
				templateData={templateData}
				allowedSectionsTag={allowedSectionsTag}
				onChange={onChange}
			/>
		) : (
			<EditorWithSave
				value={template}
				templateData={templateData}
				allowedSectionsTag={allowedSectionsTag}
			/>
		)
	}

	const content = template.sections?.length ? (
		<>
			{template.sections.map((section: any, i: number) => (
				<SectionValue
					key={i}
					value={section}
					isNarrow={isPhone}
					isServer={isServer}
					templateData={templateData}
				/>
			))}
		</>
	) : allowEmpty ? null : (
		<ErrorWrap>
			<span>
				Empty template "<code>{id}</code>".
				<br />
				<br />
				To leave empty, use an "Empty"-type section.
			</span>
		</ErrorWrap>
	)

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return fragment ? <>{content}</> : <WidthLimiter>{content}</WidthLimiter>
}

export default Template
