import React from 'react'
import styled from 'styled-components'
import {fixDate} from 'app/lib'
import {Query} from 'plugins/apollo'
import weekBgImg from 'assets/images/weekbg.png'
import {useReservationsQuery} from 'app/_server/queries/houses'
import {contractQuery} from 'app/_server/queries/contracts'
import {differenceInCalendarDays, addDays, getDay} from 'date-fns'
import {makeCssUrl} from 'app/styles'
import Tooltip from 'react-tooltip'
import {get, memoize} from 'lodash-es'
import {DateRangeValue} from 'plugins/i18n'

export const colors = {
	0: '0, 0, 255', // PRICE
	1: '220, 240, 220', // FREE
	2: '255, 0, 0', // BLOCK
	3: '90, 90, 90', // CONTRACT
	option: '255, 199, 0', // CONTRACT
	noSell: '0, 0, 0', // PRICE
	unknown: '255, 255, 255',
}

export const ReservationRange = styled.div.attrs(({offset, length}) => ({
	style: {
		left: offset * 10 + `px`,
		width: length * 10 + `px`,
	},
}))`
	position: absolute;
	z-index: ${p => (p.type === 0 ? 0 : 1)};
	height: ${p => (p.type === 0 ? '100%' : '50%')};
	top: ${p => (p.type === 0 ? 0 : '25%')};
	background-color: ${p =>
		`rgba(${colors[p.isOption ? 'option' : p.type]}, ${
			p.type === 0 ? 0.2 : 0.8
		})`};
	&:hover {
		background-color: ${p =>
			`rgba(${colors[p.isOption ? 'option' : p.type]}, 1)`};
	}
	transition: 0.5s background-color;
`

const ReservationsViewDom = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: ${p => p.days * 10}px;
	background-color: white;
	background-image: ${makeCssUrl(weekBgImg)};
	background-repeat: repeat-x repeat-y;
	background-position: ${p => -(p.weekday - 1) * 10}px 0;
	overflow: hidden;
`

export const ContractSummary = ({data}) => {
	const {contract} = data || {}
	return (
		!!contract && (
			<div>
				{get(contract, 'client.fullName', 'CAP Client name missing')},{' '}
				{contract.capId || 'CAP contract ID missing!'}
			</div>
		)
	)
}

/**
 * @param {{
 * 	value: {
 * 		type: number
 * 		sell: number
 * 		contractId: Contract['id']
 * 		beginDate: Contract['beginDate']
 * 		endDate: Contract['endDate']
 * 	}
 * }} args
 */
export const TooltipContent = ({value}) => {
	const {type, sell, contractId, beginDate, endDate} = value

	const sellToolTipTxt = ` - Sell: ${sell}`

	return (
		<div>
			<DateRangeValue value={{start: beginDate, end: endDate}} />
			{type === 0 && sellToolTipTxt}
			{contractId && (
				<Query query={contractQuery} variables={{id: contractId}}>
					{ContractSummary}
				</Query>
			)}
		</div>
	)
}

const getRanges = memoize(({data: {fullHouse}, beginDate: beginD, days}) => {
	/** @type {DateRangeItem[]} */
	const dateRanges = fullHouse?.dateRanges
	if (!dateRanges) return []

	const beginDateIso = new Date(beginD)
	const beginStr = fixDate(beginDateIso)

	const endDateIso = addDays(beginDateIso, days)
	const endStr = fixDate(endDateIso)

	return dateRanges
		.filter(
			({beginDate, endDate, type, sell}) =>
				beginDate <= endStr &&
				endDate >= beginStr &&
				// Only show active prices, not free ranges
				(type === 0 ? sell : type !== 1)
		)
		.map(r => {
			return {
				...r,
				contractId: r.contractId,
				type: r.type,
				offset: differenceInCalendarDays(
					beginStr > r.beginDate ? beginDateIso : new Date(r.beginDate),
					beginDateIso
				),
				length:
					differenceInCalendarDays(
						r.endDate > endStr ? endDateIso : new Date(r.endDate),
						r.beginDate > beginStr ? new Date(r.beginDate) : beginDateIso
					) + 1,
			}
		})
})

const ReservationsView: React.FC<{
	readonly houseId: House['id']
	readonly days: number
	readonly beginDate: Date
}> = ({houseId, days, beginDate}) => {
	const {data, loading} = useReservationsQuery({houseId})
	const fullHouse = data?.fullHouse
	if (loading || !fullHouse) return null

	const tooltip = (ids: string) => {
		if (!ids || !data?.fullHouse) return false
		const [houseId, i] = ids.split(',').map(e => e.trim())
		if (houseId !== data.fullHouse.id) return false
		const range = getRanges({days, beginDate, data})[i]
		return range ? <TooltipContent value={range} /> : false
	}

	const house = data.fullHouse
	const beginDateObj = new Date(beginDate)
	const ranges = getRanges({days, beginDate, data})
	const content = ranges.map((r, i) => (
		<ReservationRange
			key={r.id}
			data-tip={`${house.id}, ${i}`}
			data-for={house.id}
			{...r}
		/>
	))

	return (
		<ReservationsViewDom days={days} weekday={getDay(beginDateObj)}>
			{content}
			<Tooltip id={house.id} multiline getContent={tooltip} />
		</ReservationsViewDom>
	)
}

export default ReservationsView
