import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {Box} from 'app/Components/Layout'
import SectionValue from '../../SectionValue'
import ColumnsForm from './ColumnsFormAsync'

const Wrapper = styled(Box)`
	width: 100%;
	@media (max-width: ${p => p.breakpoint && p.breakpoint}px) {
		flex-direction: column;
	}
`

const ColumnsValue = ({
	value: {left, right, breakpoint} = {},
	templateData,
	inEditor,
	isNarrow,
}) =>
	inEditor ? (
		<ColumnsForm />
	) : (
		<Wrapper stretch breakpoint={breakpoint}>
			<SectionValue
				value={left}
				isNarrow={isNarrow}
				templateData={templateData}
			/>
			<SectionValue
				value={right}
				isNarrow={isNarrow}
				templateData={templateData}
			/>
		</Wrapper>
	)
ColumnsValue.propTypes = {
	value: PropTypes.object,
	templateData: PropTypes.object,
	inEditor: PropTypes.bool,
	isNarrow: PropTypes.bool,
}

export default ColumnsValue
