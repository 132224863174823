import React, {ReactElement} from 'react'
import {getBg, getFg, CapBrownTheme, styled} from 'app/styles'
import ShareButton from 'app/Components/Buttons/ShareButton'
import {_, LangLink} from 'plugins/i18n'
import Box from 'app/Components/Layout/Box'
import {useSelector} from 'plugins/redux/hooks'

const Wrapper = styled(({narrowRight, ...p}) => <Box {...p} />)`
	height: 3em;
	background-color: ${getBg};
	color: ${getFg};
	font-size: 12px;
	${p => p.narrowRight && 'padding-right: 30em;'};
	li {
		list-style: none;
		display: inline;
	}
`

const Separator = styled.span`
	margin: 0 0.5em;
`

const BreadcrumbsLayout: React.FC<{
	readonly breadcrumbs: {
		link: string
		label: ReactElement | string
		onClick?: () => void
	}[]
	readonly narrowRight?: boolean
}> = ({breadcrumbs, narrowRight}) => {
	const isMobile = useSelector(state => state.responsive.isMobile)
	return (
		<CapBrownTheme>
			<Wrapper
				spaceBetween={!isMobile}
				padding="0 2.5rem"
				narrowRight={!isMobile && narrowRight}
			>
				<div className="flex w-auto items-center justify-center">
					<span>
						{_`youAreHere`}
						:&nbsp;
					</span>
					<ol itemScope itemType="https://schema.org/BreadcrumbList">
						{breadcrumbs.map((breadcrumb, i) => {
							return (
								<li
									key={i}
									itemProp="itemListElement"
									itemScope
									itemType="https://schema.org/ListItem"
								>
									<LangLink
										onClick={breadcrumb?.onClick}
										to={breadcrumb.link}
										itemType="https://schema.org/WebPage"
										itemProp="item"
									>
										<span itemProp="name">{breadcrumb.label}</span>
									</LangLink>
									<meta itemProp="position" content={String(i + 1)} />
									{i + 1 < breadcrumbs.length && <Separator>&gt;</Separator>}
								</li>
							)
						})}
					</ol>
				</div>
				<ShareButton />
			</Wrapper>
		</CapBrownTheme>
	)
}

export default BreadcrumbsLayout
