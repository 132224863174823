import {round} from 'lodash-es'
import React, {FunctionComponent, ReactNode, useState} from 'react'

import {StyledInput} from './Components/InputComponents'
import InputLayout, {LayoutProps} from './InputLayout'

const calcStr = (
	{value, percent, min, max, allowDecimal}: NumberInputProps,
	str?: string
) => {
	if (str == null)
		str =
			(value != null && String(percent ? round(value * 100, 6) : value)) || ''

	let val = Number.parseFloat(str)
	// Assume they started typing a decimal after entering a valid value
	// We'll leave the .000 part alone while they're typing it
	const valid = Number.isFinite(val) && !/[,.](|\d*0+)$/.test(str)
	if (!valid) return {valid, str}

	if (min != null) val = Math.max(val, min)
	if (max != null) val = Math.min(val, max)

	val = round(
		val,
		// 6 digits to prevent epsilon errors but allow high precision
		allowDecimal ? 6 : 0
	)
	str = String(val)
	if (percent) val = round(val / 100, 6)
	return {valid, str, val}
}

type NumberInputProps = {
	readonly onChange: (v: number) => any
	readonly value?: number
	readonly label?: ReactNode
	readonly error?: ReactNode
	readonly percent?: boolean
	readonly min?: number
	readonly max?: number
	readonly allowDecimal?: boolean
}

const NumberInput: FunctionComponent<
	NumberInputProps &
		Omit<LayoutProps<typeof StyledInput>, keyof NumberInputProps>
> = props => {
	const [prevValue, setPrevValue] = useState<number | undefined>(props.value)
	const [str, setStr] = useState<string>(() => calcStr(props).str)

	const handleChange = (newStr: string | null) => {
		// clear
		if (newStr === null) newStr = ''

		const result = calcStr(props, newStr)
		if (result.valid) {
			props.onChange(result.val)
			if (prevValue !== result.val) setPrevValue(result.val)
		}
		if (!newStr) newStr = result.str
		setStr(newStr)
	}
	const handleBlur = () => setStr(calcStr(props).str)

	if (props.value !== prevValue) {
		setPrevValue(props.value)
		setStr(calcStr(props).str)
	}
	const {percent, allowDecimal, ...rest} = props
	return (
		<InputLayout
			type={allowDecimal ? 'text' : 'number'}
			inputMode={allowDecimal ? 'decimal' : 'numeric'}
			suffix={percent ? '%' : undefined}
			placeholder={String(Math.max(0, props.min || 0))}
			{...rest}
			value={str}
			onChange={handleChange}
			onBlur={handleBlur}
			Input={StyledInput}
		/>
	)
}

export default NumberInput
