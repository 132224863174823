import PropTypes from 'prop-types'
import React from 'react'
import {Field} from 'app/Components/Form'
import {PlainText} from 'plugins/i18n'
import type {BaseFieldProps} from 'redux-form'

type IFProps = {
	readonly label?: React.ReactNode
	readonly labelOnTop?: boolean
	readonly noLabel?: boolean
	readonly placeholder?: React.ReactNode
	readonly required?: boolean
}

type InputFieldProps<Props extends InputProps<unknown>> =
	BaseFieldProps<Props> &
		IFProps &
		Omit<Props, keyof IFProps | 'value' | 'onChange' | 'Input'>

const fields = new WeakMap<React.ComponentType, React.ComponentType>()

const checkTruthy = value => (value ? undefined : 'required')

type FieldInput<
	Props extends InputProps<Value>,
	Value = Props['value'],
> = InputComponent<Props & {error?: React.ReactNode; label?: React.ReactNode}>
const getWrappedInput = <
	Props extends InputProps<Value>,
	Value = Props['value'],
>(
	Input: FieldInput<Props>
) => {
	if (fields.has(Input)) return fields.get(Input)

	const WrappedInput = ({
		name,
		input,
		meta: {touched, error},
		required,
		...rest
	}) => {
		// Seems that sometimes `name` is given and other times, not…
		const label = rest.noLabel
			? null
			: rest.label || <PlainText trId={`${name || input.name}Field`} />
		const labelReq = required && label ? <>{label}*</> : label
		const handleBlur = rest.onBlur || input.onBlur
		// Make sure only proper values end up in the form data,
		// not stray input callbacks. Redux-form ignores undefined onBlurs
		const onBlur = handleBlur && (() => handleBlur())
		return (
			<Input
				{...{
					error:
						touched && error ? (
							typeof error === 'string' ? (
								<PlainText trId={error} />
							) : (
								error
							)
						) : undefined,
					...input,
					// Note that this allows overriding `error`
					...rest,
					label: labelReq,
					onBlur,
				}}
			/>
		)
	}
	WrappedInput.displayName = `${Input.displayName || Input.name}Field`
	WrappedInput.propTypes = {
		name: PropTypes.string,
		input: PropTypes.object.isRequired,
		meta: PropTypes.object.isRequired,
		noLabel: PropTypes.bool,
		required: PropTypes.bool,
	}
	fields.set(Input, WrappedInput)
	return WrappedInput
}

/** Wrapper for redux form field. */
export const InputField = <
	InputC extends InputComponent<Props, Value>,
	Props extends InputProps<Value> = React.ComponentPropsWithoutRef<InputC>,
	Value = Props['value'],
>({
	validate,
	Input,
	...props
}: InputFieldProps<Props> & {readonly Input: InputC}) => {
	const validateReq = props.required && !validate ? checkTruthy : validate
	return (
		// @ts-ignore
		<Field
			// format is null because we don't want default empty string values
			format={null}
			{...props}
			validate={validateReq}
			component={getWrappedInput(Input)}
		/>
	)
}

/** Makes a redux-form Field from an Input. */
const makeField =
	<Props extends InputProps<Value>, Value = Props['value']>(
		Input: InputComponent<Props, Value>
	) =>
	(props: InputFieldProps<Props>) => <InputField {...props} Input={Input} />

export default makeField
