import React, {useState, useContext, useRef} from 'react'
import PropTypes from 'prop-types'

const IconContext = React.createContext()

export const Use = ({symbol}) => {
	const ctx = useContext(IconContext)

	if (!symbol || !ctx) return false

	ctx.addSymbol(symbol)

	return <use xlinkHref={'#' + symbol.id} />
}
Use.propTypes = {symbol: PropTypes.object.isRequired}

// Note: this has to stay a separate component so it gets evaluated at the end of the render
// that way the uses object will contain all the used symbols (through mutation)
const Symbols = () => {
	const context = useContext(IconContext)
	const [, refresh] = useState()

	const {uses, onAdd} = context
	if (!onAdd) context.onAdd = refresh
	const icons = Object.values(uses).map(s => s.text)
	return icons.length ? (
		<svg
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: icons.join(''),
			}}
			style={{display: 'none'}}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		/>
	) : null
}

const IconProvider = ({children}) => {
	const {current: context} = useRef({})

	if (!context.addSymbol) {
		// Initialize our context
		context.uses = {}
		// We don't remove symbols, it's extra work and the browser will be fine
		context.addSymbol = symbol => {
			const has = context.uses[symbol.id]
			if (has) {
				if (has === symbol) return

				// eslint-disable-next-line no-console
				console.error(
					new Error(`Symbol ID ${symbol.id} has to be unique (hot reload ok)`)
				)
			}
			context.uses[symbol.id] = symbol
			// This causes Symbols to rerender
			// TODO React doesn't like this - put in useEffect, and for SSR keep a string to attach to render result
			if (context.onAdd) context.onAdd(symbol)
		}
	}

	return (
		<IconContext.Provider value={context}>
			<>
				{children}
				<Symbols />
			</>
		</IconContext.Provider>
	)
}

IconProvider.propTypes = {children: PropTypes.any}

// eslint-disable-next-line react/jsx-no-useless-fragment
const IconProviderBypass = ({children}) => <>{children}</>
export default IconProviderBypass
