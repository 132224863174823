import React, {useState, useEffect, useCallback} from 'react'
import {useDispatch} from 'plugins/redux/hooks'
import {useQuery} from 'plugins/apollo'
import {houseQuery} from 'app/_server/queries/houses'
import {
	HTTP404,
	PermanentRedirect,
	useLocation,
} from 'plugins/react-router-deluxe'
import {_, useI18n} from 'plugins/i18n'
import Loading from 'app/Components/Loading'
import {addVisited as addVisitedFn} from 'app/redux/ducks/visitedHouses-duck'
import {urlForHouse} from 'app/lib'
import DomainPage from './DomainPage'
import {setupAnalytics} from './helpers'
import HousePage from './HousePage'

const HouseOrDomainPage: React.FC<{
	readonly houseId: string
	readonly forceUrl?: boolean
}> = ({houseId, forceUrl = true}) => {
	const {getText, lang, loading: i18nLoading} = useI18n()
	const location = useLocation()
	const dispatch = useDispatch()

	const [redirectNow, setRedirectNow] = useState<string | null>(null)
	const {data: houseData, loading: houseDataLoading} = useQuery(houseQuery, {
		variables: {houseId},
	})
	const house: House = houseData?.house

	const checkUrl = useCallback(() => {
		if (!forceUrl || houseDataLoading || !house || i18nLoading) return
		const shouldBeUrl = house && urlForHouse({house, lang})
		if (shouldBeUrl && location.pathname !== shouldBeUrl) {
			setRedirectNow(shouldBeUrl)
		}
	}, [forceUrl, house, houseDataLoading, i18nLoading, lang, location.pathname])

	useEffect(() => {
		checkUrl()
		const timeoutId = setTimeout(
			() => dispatch(addVisitedFn(house?.id)),
			10_000
		)

		return () => clearTimeout(timeoutId)
	}, [checkUrl, dispatch, house?.id])

	useEffect(() => {
		if (!house || !lang || house.domainId) return
		setupAnalytics({house, getText, lang})
	}, [getText, house, lang])

	if (redirectNow) return <PermanentRedirect to={redirectNow} />

	if (!house) {
		if (houseDataLoading) return <Loading />
		return (
			<>
				<HTTP404 />
				{_('missingHouse')}
			</>
		)
	}

	return house.domainId ? (
		<DomainPage house={house} />
	) : (
		<HousePage house={house} />
	)
}

export default HouseOrDomainPage
