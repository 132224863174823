import {makeSplitPoint} from 'plugins/react'
import Value from './TextSection'

export default {
	label: 'Text + Image',
	group: 'content',
	Value,
	defaultValue: {
		sizing: {left: 50, right: 50},
		headingI18n: undefined,
		greetingI18n: undefined,
		textI18n: undefined,
		imageId: undefined,
	},
	Form: makeSplitPoint(() => import('./TextForm'), {ssr: false}),
}
