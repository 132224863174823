import React from 'react'
import PropTypes from 'prop-types'
import {styled, css, getFg, colorCss, getHint, getBg} from 'app/styles'
import {useSelector} from 'plugins/redux/hooks'
import {ArrowDownIcon} from 'app/Components/Icon'
import {Box, ThemedBox} from 'app/Components/Layout'
import {useId} from 'plugins/id-generator'

const defaultPaneCss = css`
	border-top: thin solid ${getHint};
`

const defaultTabLabelCss = css`
	padding: 0.5em;
	${colorCss};
	border: thin solid ${getHint};
	border-bottom: none;
	& + & {
		border-left: none;
	}
`

const defaultActiveTabLabelCss = css`
	color: ${p => getFg(p, 'accent')};
	background-color: ${p => getBg(p, 'accent')};
`

export const Pane = styled(ThemedBox).attrs(p => ({className: `pane_${p.i}`}))`
	display: none;
	${p => ('paneCss' in p.theme ? p.theme.paneCss : defaultPaneCss)};
`

const TabsContainer = styled.div`
	position: relative;
	width: 100%;
	${p => p.theme.tabsCss};

	${p =>
		p.tabs.map(
			(tab, index) => css`
				#tab_${p.name}_${index}:checked {
					~ .pane_${index} {
						display: block;
					}
					~ .tabLabel_${index} {
						${p =>
							'activeTabLabelCss' in p.theme
								? p.theme.activeTabLabelCss
								: defaultActiveTabLabelCss};
						margin-bottom: 0;
						z-index: 1;
						.icon_${index} {
							transform: rotate(180deg);
						}
					}
				}
			`
		)};
`

const TabWrapper = styled.label`
	cursor: pointer;
	${p => ('tabLabelCss' in p.theme ? p.theme.tabLabelCss : defaultTabLabelCss)};
	position: relative;
	display: ${p => (p.isNarrow ? 'block' : 'inline-block')};
`

const TabInput = styled.input`
	position: absolute;
	opacity: 0;
`

const getIsNarrow = state => state.responsive.isPhone

const Tabs = ({onChange, isNarrow, children, useSet = 'tabs'}) => {
	const name = useId()
	const shouldNarrow = useSelector(getIsNarrow)
	if (shouldNarrow) isNarrow = true

	const handleChange = ev => {
		const {target} = ev
		if (isNarrow && target.checked && target.scrollIntoView) {
			// hack: wait a bit for the page to rerender
			setTimeout(
				() =>
					target.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'center',
					}),
				100
			)
		}
		if (!onChange) return
		const {value} = target
		onChange(Number(value))
	}

	const inputs = []
	const tabs = []
	const panes = []
	let paneIndex = 0
	React.Children.forEach(children, (pane, i) => {
		if (!pane) return
		// Broken somehow
		// if (
		// 	isDev &&
		// 	!require('react-hot-loader').areComponentsEqual(pane.type, Pane)
		// )
		// 	// eslint-disable-next-line no-console
		// 	console.error('Tabs can only have Pane children', pane.type)
		const paneClone = React.cloneElement(pane, {
			i: paneIndex,
			key: paneIndex,
			useSet: pane.props.useSet || 'pane',
			label: undefined,
		})
		inputs.push(
			<TabInput
				className={pane.props.className}
				type="radio"
				name={name}
				value={paneIndex}
				key={paneIndex}
				id={`tab_${name}_${paneIndex}`}
				defaultChecked={paneIndex === 0 && 'true'}
				onChange={handleChange}
				Tabs
			/>
		)
		tabs.push(
			<React.Fragment key={paneIndex}>
				<TabWrapper
					useSet={useSet}
					className={`tabLabel_${paneIndex}`}
					htmlFor={`tab_${name}_${paneIndex}`}
					isNarrow={isNarrow}
				>
					{isNarrow ? (
						<Box left spaceBetween>
							{pane.props.label}
							<ArrowDownIcon className={`icon_${paneIndex}`} size="1.2em" />
						</Box>
					) : (
						pane.props.label
					)}
				</TabWrapper>
				{isNarrow && paneClone}
			</React.Fragment>
		)
		if (!isNarrow) panes.push(paneClone)

		paneIndex++
	})
	return (
		<TabsContainer tabs={tabs} name={name} isNarrow={isNarrow}>
			{inputs}
			{tabs}
			{panes}
		</TabsContainer>
	)
}
Tabs.propTypes = {
	useSet: PropTypes.string,
	children: PropTypes.any,
	onChange: PropTypes.func,
	isNarrow: PropTypes.bool,
}

export {Tabs}
