import {useFeaturedHousesQuery} from 'app/_server/queries/houses'
import {
	FeaturedHousesSlider,
	NewHousesSlider,
	PromoHousesSlider,
} from 'app/Components/Carousels/HouseSlider'
import {LangLink, _} from 'plugins/i18n'
import React, {useState} from 'react'

const HighlightedHousesTabs = () => {
	const {data, loading} = useFeaturedHousesQuery()
	const [activeTab, setActiveTab] = useState(0)

	if (loading || !data?.featuredHouses || !data.featuredHouses.length) {
		return null
	}

	return (
		<div className="my-10 flex w-full flex-col items-start bg-[#BBB]/30 pt-10">
			<div role="tablist" className="mb-8 ml-6 flex space-x-2">
				<button
					type="button"
					role="tab"
					className={`border-primary rounded-full border border-solid px-3 py-2 text-base leading-6 whitespace-nowrap ${activeTab === 0 ? 'bg-primary text-onPrimary' : 'bg-onPrimary text-primary'}`}
					onClick={() => setActiveTab(0)}
				>
					{_(`featured`)}
				</button>
				<button
					type="button"
					role="tab"
					className={`border-primary rounded-full border border-solid px-3 py-2 text-base leading-6 whitespace-nowrap ${activeTab === 1 ? 'bg-primary text-onPrimary' : 'bg-onPrimary text-primary'}`}
					onClick={() => setActiveTab(1)}
				>
					{_(`new`)}
				</button>
				<button
					type="button"
					role="tab"
					className={`border-primary rounded-full border border-solid px-3 py-2 text-base leading-6 whitespace-nowrap ${activeTab === 2 ? 'bg-primary text-onPrimary' : 'bg-onPrimary text-primary'}`}
					onClick={() => setActiveTab(2)}
				>
					{_(`lastMinute`)}
				</button>
			</div>
			{activeTab === 0 && <FeaturedHousesSlider />}
			{activeTab === 1 && <NewHousesSlider />}
			{activeTab === 2 && <PromoHousesSlider />}
			<LangLink to="xx/search" className="my-8 flex w-full justify-center">
				<button
					type="button"
					className="border-primary bg-onPrimary text-primary w-5/6 rounded-md border border-solid p-2"
				>
					{_('allVacationHouses')}
				</button>
			</LangLink>
		</div>
	)
}
export default HighlightedHousesTabs
