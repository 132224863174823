import {isValid} from 'date-fns'
import dfntz from 'date-fns-tz'
import type {Moment} from 'moment'
const {
	format: formatFns,
	utcToZonedTime: origUtcToZonedTime,
	zonedTimeToUtc: origZonedTimeToUtc,
} = dfntz

export const TZ_FIXED = __CONFIG__.tz || 'Europe/Brussels'

export const DATE_TIME_ISO_FORMAT = 'yyyy-MM-dd HH:mm'
export const DATE_ISO_FORMAT = 'yyyy-MM-dd'

export const DATE_REVERSE_1_FORMAT = 'dd/MM/yyyy'
export const DATE_REVERSE_2_FORMAT = 'd MMM yyyy'
export const DATE_TIME_REVERSE_FORMAT = 'dd/MM/yyyy HH:mm'

export const DAY_MONTH_1_FORMAT = 'd/MM'
export const DAY_MONTH_2_FORMAT = 'd MMM'

export const TIME_FORMAT = 'H:mm'

const ISODateRegex = /(\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
const ISODateTimeRegex = /(\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))\b/

export const checkISOStringDate = (
	str: DateISO | Moment,
	isShortISOString?: boolean
): str is Extract<DateISO, string> => {
	if (!str)
		throw new Error(`checkISOStringDate: string date value is undefined.`)
	if (typeof str !== 'string') return false

	if (isShortISOString) return ISODateTimeRegex.test(str)
	return ISODateRegex.test(str)
}

export const getValidDate = (value: DateISO | Moment): Date => {
	const date = new Date(value as string)
	const isValidDate = !date || !isValid(date)
	if (isValidDate) throw new Error(`getValidDate: date "${value}" is invalid.`)

	return date
}

export const ISODateToNumArr = (
	str: Extract<DateISO, string>
): [year: number, month: number, day: number] => {
	getValidDate(str)

	const isShortISOStringDate = checkISOStringDate(str, true)
	if (!isShortISOStringDate)
		throw new Error(`ISODateToNumArr: "${str}" is not short ISO string date.`)

	const [y, m, d] = str.split('-').map(n => Number.parseInt(n, 10))
	return [y, m - 1, d]
}

export const dateFormatter = (
	value: DateFormatter['dateVal'] | Moment,
	// eslint-disable-next-line default-param-last
	format: DateFormatter['format'] = DATE_TIME_ISO_FORMAT,
	options?: DateFormatter['options']
): string => {
	if (!value) throw new Error(`dateFormatter: date value is undefined.`)
	const forceTz = options?.forceTz ?? true
	let date = getValidDate(value)

	const isShortISODate = checkISOStringDate(value, true)

	if (isShortISODate) {
		// do nothing, take short date as it is
		if (format === DATE_ISO_FORMAT) return value

		// if short into long date => set begin of the day in UTC
		if ([DATE_TIME_ISO_FORMAT, DATE_REVERSE_1_FORMAT].includes(format)) {
			date = new Date(...ISODateToNumArr(value))
		}
	}

	date = forceTz ? utcToZonedDate(date) : date

	return formatFns(date, format, options)
}

export const utcToZonedDate = (date = new Date(), tz = TZ_FIXED, ...args) => {
	if (!tz) throw new Error(`utcToZonedDate: timezone is undefined.`)
	return origUtcToZonedTime(date, tz, ...args)
}

export const zonedToUtcDate = (date = new Date(), tz = TZ_FIXED, ...args) => {
	if (!tz) throw new Error(`zonedToUtcDate: timezone is undefined.`)
	return origZonedTimeToUtc(date, tz, ...args)
}
