import {useI18n} from 'plugins/i18n'
import React from 'react'

export const mapImages = import.meta.glob('assets/images/maps/*.png', {
	eager: true,
	import: 'default',
})

const MiniMap = ({regionId}: {readonly regionId: string}) => {
	const {getText} = useI18n()
	if (!regionId || regionId === 'r$unknown') return null

	try {
		const miniMapPath = mapImages[`/assets/images/maps/${regionId}.png`]
		return (
			<div>
				<img
					className="w-full"
					src={miniMapPath as string}
					alt={getText('regionMap')}
				/>
			</div>
		)
	} catch {
		return null
	}
}
export default MiniMap
