import gql from 'graphql-tag'
import {makeQueryHook} from './helpers'
import {useMutation} from 'plugins/apollo'

// Queries
export const fullBlogPostQuery = gql`
	query fullBlogPostQuery($postId: ID!, $isEditing: Boolean!) {
		blogPost(id: $postId) {
			id
			date
			title(fallback: false)
			titleI18n @include(if: $isEditing)
			text
			summaryI18n @include(if: $isEditing)
			summary
			isOnline
			regionIds
			images {
				w
				h
				blurhash
				upload {
					id
				}
				show
			}
			mainImage {
				w
				h
				blurhash
				upload {
					id
				}
			}
			template
		}
	}
`

export const useFullBlogPost = makeQueryHook<
	{blogPost: BlogPost},
	{postId: BlogPost['id']; isEditing: boolean}
>(fullBlogPostQuery)

export const BLOG_POSTS_LIST_QUERY = gql`
	query blogPostsListQuery(
		$onlyOnline: Boolean!
		$limit: Int
		$evenEmpty: Boolean
		$regionIds: [ShortString]
	) {
		blogPosts(
			onlyOnline: $onlyOnline
			limit: $limit
			evenEmpty: $evenEmpty
			regionIds: $regionIds
		) {
			items {
				id
				date
				title(fallback: false)
				isOnline
			}
		}
	}
`

export const useBlogPostsListQuery = makeQueryHook<
	{blogPosts: {items: Pick<BlogPost, 'id' | 'date' | 'title' | 'isOnline'>[]}},
	{
		onlyOnline?: boolean
		limit?: number
		evenEmpty?: boolean
		regionIds?: string[]
	}
>(BLOG_POSTS_LIST_QUERY)

export const blogPostsQuery = gql`
	query blogPostsQuery(
		$onlyOnline: Boolean!
		$limit: Int
		$evenEmpty: Boolean
		$regionIds: [ShortString]
	) {
		blogPosts(
			onlyOnline: $onlyOnline
			limit: $limit
			evenEmpty: $evenEmpty
			regionIds: $regionIds
		) {
			items {
				id
				date
				title(fallback: false)
				titleI18n
				text
				isOnline
				mainImage {
					w
					h
					blurhash
					upload {
						id
					}
				}
				template
			}
			total
		}
	}
`

export const useBlogPostsQuery = makeQueryHook<
	{blogPosts: {items: any[]}},
	{
		onlyOnline?: boolean
		limit?: number
		evenEmpty?: boolean
		regionIds?: string[]
	}
>(blogPostsQuery)

// Mutations
export const SAVE_BLOGPOST = gql`
	mutation saveBlogPost(
		# Variables we are taking from client
		$id: ID
		$date: Date!
		$titleI18n: I18nStringInput!
		$summaryI18n: I18nStringInput!
		$isOnline: Boolean!
		$images: [ImageInput]
		$mainImage: ImageInput
		$regionIds: [ShortString]
		$template: JSON
	) {
		# then passing them to the server and exectuting the mutation saveBlogPost
		saveBlogPost(
			id: $id
			date: $date
			titleI18n: $titleI18n
			summaryI18n: $summaryI18n
			isOnline: $isOnline
			images: $images
			mainImage: $mainImage
			regionIds: $regionIds
			template: $template
		) {
			# That's what we are getting back
			id
			date
			title
			titleI18n
			text
			summary
			isOnline
			images {
				w
				h
				blurhash
				upload {
					id
				}
			}
			mainImage {
				w
				h
				blurhash
				upload {
					id
				}
			}
			regionIds
		}
	}
`

export const useSaveBlogPost = () => {
	const [saveBlogPostMutation] = useMutation(SAVE_BLOGPOST, {
		refetchQueries: ['blogPostsListQuery', 'fullBlogPostQuery'],
	})

	const saveBlogPost = ({
		id,
		date,
		images,
		isOnline,
		mainImage,
		regionIds,
		summaryI18n,
		template,
		titleI18n,
	}: BlogPost) => {
		return saveBlogPostMutation({
			variables: {
				id,
				date,
				titleI18n,
				summaryI18n,
				isOnline,
				images,
				mainImage,
				regionIds,
				template,
			},
		})
	}

	return {saveBlogPost}
}

export const REMOVE_BLOG_POST = gql`
	mutation removeBlogPost($id: ID!) {
		removeBlogPost(id: $id)
	}
`
export const useRemoveBlogPost = () => {
	const [removeBlogPostMutation] = useMutation<
		{removeBlogPost: boolean},
		{id: string}
	>(REMOVE_BLOG_POST, {
		refetchQueries: ['blogPostsListQuery', 'fullBlogPostQuery'],
	})

	const removeBlogPost = (id: string) => {
		return removeBlogPostMutation({
			variables: {id},
		})
	}

	return {removeBlogPost}
}

export const SAVE_BLOG_POST_TEMPLATE = gql`
	mutation saveBlogPostTemplate($id: ID!, $template: JSON!) {
		saveBlogPostTemplate(id: $id, template: $template)
	}
`

export const useSaveBlogPostTemplate = () => {
	const [saveBlogPostTemplateMutation] = useMutation<
		{saveBlogPostTemplate: boolean},
		{id: string; template: any}
	>(SAVE_BLOG_POST_TEMPLATE, {
		refetchQueries: ['blogPostsListQuery', 'fullBlogPostQuery'],
	})

	const saveBlogPostTemplate = ({
		id,
		template,
	}: {
		id: string
		template: any
	}) => {
		return saveBlogPostTemplateMutation({
			variables: {id, template},
		})
	}

	return {saveBlogPostTemplate}
}
