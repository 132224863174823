import React from 'react'
import SearchRentalsForm from 'app/Components/SearchRentals'
import {SearchResults} from 'app/Pages/SearchResults'
import {Template} from 'plugins/templates'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {
	useHouseListQuery,
	useHousesFilterCountersQuery,
} from 'app/_server/queries/houses'
import {useRegionsQuery} from 'app/_server/queries/region'
import {fixDate} from 'app/lib'
import {styled, getSecondary, useResponsive} from 'app/styles'
import {_, useI18n} from 'plugins/i18n'
import RegionDescriptionOrig from 'app/Sections/regionDescription/RegionDescriptionSection'
import Loading from 'app/Components/Loading'
import ActiveFeatureFilters from './ActiveFeatureFilters'
import {makeSearchPageTitle, makeSearchPageDescription} from './searchPageMeta'
import SearchRentalsMoreFilters from './SearchRentalsMoreFilters'
import RelatedInternalLinks from './RelatedInternalLinks'
import {setPage} from 'app/redux/ducks/searchRentals-duck'
import SearchRentalsPageMobile from './SearchRentalsPageMobile'

const RegionDescription = styled(RegionDescriptionOrig)`
	color: ${getSecondary};
`

const RegionsDescription = ({
	regionIds: ids,
	houseType,
}: {
	readonly regionIds?: House['regionId'][]
	readonly houseType?: House['type']
}) => {
	const {data, loading} = useRegionsQuery({ids}, {skip: !ids?.length})

	if (!data && loading) return <Loading />
	if (!data) return null

	return data.regions?.map(region => (
		<RegionDescription
			key={region.id}
			value={{
				...region,
				regionId: region.id,
				houseType,
				countryTitleI18n: region.countryTitle,
				descriptionI18n: region && (region.regionDesc || region.countryDesc),
			}}
		/>
	))
}

const truthyKeys = obj => obj && Object.keys(obj).filter(key => obj[key])

export const filterToGqlVars = ({
	searchRentals: {
		features,
		houseCapacity,
		houseName,
		houseType,
		isNew,
		isPromo,
		page,
		period,
		priceRange,
		region,
		special,
		specials,
		types,
		sort,
	},
	pageSize,
}: {
	searchRentals: SearchRentalsState
	pageSize?: number
}) => {
	return {
		variables: {
			beginDate: period && fixDate(period.start),
			endDate: period && fixDate(period.end),
			featuresAllIn: truthyKeys(features),
			isNew,
			isPromo,
			// TODO use cursor, but also make it work in SSR
			// perhaps use id of first & last in URL?
			limit: pageSize ? page * pageSize : undefined,
			name: houseName,
			priceBelow: priceRange,
			// regionId: region[0],
			regionIds: region,
			size: houseCapacity,
			specialId: special,
			specialsAllIn: truthyKeys(specials),
			type: houseType,
			typeIn: truthyKeys(types),
			...(sort && {sort}),
		},
	}
	// TODO
	// skip: ({
	// 	match: {
	// 		params: {param1: visibilityFilter},
	// 	},
	// }) => visibilityFilter === 'favorites',
}

const SearchRentalsPage: React.FC<{
	readonly data: Record<string, any>
	readonly previousData?: Record<string, any>
	readonly loading: boolean
	readonly hasMore: boolean
	readonly fetchMore: any
}> = props => {
	const i18n = useI18n()
	const dispatch = useDispatch()
	const {isPhone} = useResponsive('isPhone')

	const searchRentals = useSelector(state => state.searchRentals)

	const pageSize = isPhone ? 8 : 12
	const features = useSelector(state => state.searchRentals.features)
	const houseCapacity = useSelector(state => state.searchRentals.houseCapacity)
	const houseType = useSelector(state => state.searchRentals.houseType)
	const page = useSelector(state => state.searchRentals.page)
	const priceRange = useSelector(state => state.searchRentals.priceRange)
	const regionIds = useSelector(state => state.searchRentals.region)
	const specials = useSelector(state => state.searchRentals.specials)

	const variablesWithLimit = filterToGqlVars({
		searchRentals,
		pageSize,
	})?.variables

	const queryProps = useHouseListQuery(variablesWithLimit)
	const {data, loading, previousData, hasMore, fetchMore} = queryProps

	const variables = filterToGqlVars({searchRentals})?.variables
	const {data: countersData} = useHousesFilterCountersQuery(variables)

	if (!data && !previousData && loading) return <Loading />

	const handleSetPage = (newPage: number) => {
		const {
			houses: {items: houses},
		} = data

		if (hasMore && newPage * pageSize > houses.length - 1) {
			fetchMore()
		}
		dispatch(setPage(newPage))
	}

	const {items = [], total} = data?.houses || previousData?.houses || {}

	const templateData = {
		title: makeSearchPageTitle(
			{
				houseType,
				featureIds: Object.entries(features)
					.filter(i => i[1])
					.map(i => i[0]),
				capacity: houseCapacity,
				regionIds,
				i18n,
			},
			60
		),
		description: makeSearchPageDescription(
			{
				houseType,
				featureIds: Object.entries(features)
					.filter(i => i[1])
					.map(i => i[0]),
				capacity: houseCapacity,
				regionIds,
				i18n,
			},
			169
		),
	}

	return isPhone ? (
		<SearchRentalsPageMobile
			total={total}
			items={items}
			handleSetPage={handleSetPage}
			pageSize={pageSize}
			counters={countersData?.housesFilterCounters}
		/>
	) : (
		<>
			<Template id="searchTop" templateData={templateData} />
			<div className="flex w-full flex-col justify-start gap-12 px-10 py-8 md:flex-row">
				<div className="flex flex-col items-center justify-start md:w-1/3">
					<div className="absolute top-0 right-0 left-0 w-full px-8 md:relative md:px-0">
						<SearchRentalsForm column noLabel showRentals />
					</div>
					<div className="flex w-full items-center justify-center p-2">
						<span>
							{total} {_('results')}
						</span>
					</div>
					<SearchRentalsMoreFilters
						{...{
							features,
							priceRange,
							specials,
							counters: countersData?.housesFilterCounters,
						}}
					/>
				</div>
				<div className="flex w-full flex-col items-start justify-start gap-4">
					<ActiveFeatureFilters />
					<SearchResults
						{...{
							houseCapacity,
							regionIds,
							houseType,
							items,
							total,
							page,
							setPage: handleSetPage,
							features,
							pageSize,
						}}
					/>
					<RelatedInternalLinks />
					<RegionsDescription {...{regionIds, houseType}} />
				</div>
			</div>
			<Template id="searchBottom" templateData={templateData} />
		</>
	)
}

export default SearchRentalsPage
