import React from 'react'
import {FieldArray, FormSection} from 'app/Components/Form'
import {Label, LabelText} from './Components/InputComponents'
import {Box} from 'app/Components/Layout'
import type {FieldArrayFieldsProps} from 'redux-form'
import {Button} from '../Buttons'
import {MinusIcon, PlusIcon} from '../Icon'

const DefaultAddButton = props => (
	<Button accent small compact {...props}>
		<PlusIcon />
	</Button>
)
const DefaultRemoveButton = props => (
	<Button danger small compact {...props}>
		<MinusIcon />
	</Button>
)

type ArrayItemsArgs<T> = {
	readonly fields: FieldArrayFieldsProps<T>
	readonly max?: number
	readonly children: React.ReactNode
	readonly AddButton?: React.ComponentType<{onClick: () => void}>
	readonly RemoveButton?: React.ComponentType<{onClick: () => void}>
	readonly initialAddValue?: T
}
const ArrayItems = <T,>({
	fields,
	max,
	children,
	AddButton = DefaultAddButton,
	RemoveButton = DefaultRemoveButton,
	initialAddValue = {} as T,
}: ArrayItemsArgs<T>) => (
	<div className="flex w-full flex-col items-start justify-center gap-4">
		{fields.length > 0 ? (
			fields.map((name, i) => (
				<FormSection key={i} name={name}>
					<Box column bottom gap left>
						<Box gap bottom>
							<div className="flex w-full flex-col items-start justify-center gap-4">
								{children}
							</div>
							<RemoveButton onClick={() => fields.remove(i)} />
						</Box>
						{i === fields.length - 1 && (!max || max > i + 1) && (
							<div className="flex w-full items-center justify-center">
								<AddButton onClick={() => fields.push(initialAddValue)} />
							</div>
						)}
					</Box>
				</FormSection>
			))
		) : (
			<div className="flex w-full items-center justify-center">
				<AddButton onClick={() => fields.push(initialAddValue)} />
			</div>
		)}
	</div>
)

type ArrayFieldArgs<T> = Omit<ArrayItemsArgs<T>, 'fields'> & {
	readonly label?: React.ReactNode
	readonly name: string
}
const ArrayField = <T,>({name, label, ...props}: ArrayFieldArgs<T>) => (
	<div className="flex w-full flex-col items-start justify-center gap-4">
		{label && (
			<Label>
				<LabelText>{label}</LabelText>
			</Label>
		)}
		<FieldArray<T> name={name} component={ArrayItems} {...props} />
	</div>
)

export default ArrayField
