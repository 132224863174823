import React from 'react'
import PropTypes from 'prop-types'
import RentalImage from './Image'
import {Box} from 'app/Components/Layout'
import {useSelector, useDispatch} from 'plugins/redux/hooks'
import {toggleFavorites} from 'state/ducks/favoritesAndShare-duck'
import {useI18n, LangLink} from 'plugins/i18n'
import {urlForHouse} from 'app/lib'

const Preview = ({
	children,
	column,
	house,
	noFav,
	ratio,
	to: toOrg,
	preventRedirect,
	maxWidth,
}) => {
	const {getText, lang} = useI18n()
	const favorites = useSelector(state => state.favoritesAndShare)
	const dispatch = useDispatch()
	const withLink = !preventRedirect

	const {id, name, mainImage, countryId, regionId} = house
	const tagsTranslated =
		(mainImage && mainImage.tags && mainImage.tags.map(t => getText(t))) || []
	const alt = [
		getText(countryId),
		getText(regionId),
		tagsTranslated.join(''),
		name,
	].join(' – ')

	const rentalImg = (
		<RentalImage
			{...{
				alt,
				id,
				image: mainImage,
				favorites,
				noFav,
				ratio,
				tags: tagsTranslated,
				toggleFavorites: hId => dispatch(toggleFavorites(hId)),
			}}
		/>
	)
	return (
		<Box gap="1em" column={column} maxWidth={maxWidth} relative>
			<Box stretch>
				{withLink ? (
					<LangLink
						className="w-full"
						to={toOrg || urlForHouse({house, lang})}
						aria-label={alt}
					>
						{rentalImg}
					</LangLink>
				) : (
					rentalImg
				)}
			</Box>
			{children && (
				<div className="flex w-full flex-col items-stretch gap-4">
					{children}
				</div>
			)}
		</Box>
	)
}

Preview.propTypes = {
	children: PropTypes.any,
	column: PropTypes.bool,
	house: PropTypes.object.isRequired,
	noFav: PropTypes.bool,
	ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	to: PropTypes.string,
	preventRedirect: PropTypes.bool,
	maxWidth: PropTypes.string,
}

export default Preview
