export const removeTypenameFromObj = (obj, toRemove) => {
	if (!obj || typeof obj !== 'object') return obj
	if (Array.isArray(obj))
		return obj.map(v => removeTypenameFromObj(v, toRemove))
	const out = {}
	for (const [key, value] of Object.entries(obj))
		if (key !== '__typename' && !toRemove?.includes(key))
			out[key] = removeTypenameFromObj(value, toRemove)
	return out
}
