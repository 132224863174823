import {FT_POOL} from 'app/_server/database/constants'
import {makeUrl} from 'plugins/images'
import {rawText} from 'plugins/rich-text'
import {isBrowser} from 'stratokit/build'

const makeHousePageTitle = ({i18n, name, region, hasPool, capacity}) => {
	const {getText} = i18n
	const baseComponent = getText('holidayRental')
	const poolComponent = hasPool
		? `${getText('with')} ${getText(FT_POOL)}`.toLowerCase()
		: ''
	const nameComponent = name
	const regionComponent = `${getText('in')} ${getText(region)}`
	const capacityComponent = `${getText('for')} ${capacity} ${getText(
		'people'
	)}`.toLowerCase()

	const displayOrder = [
		baseComponent,
		poolComponent,
		nameComponent,
		regionComponent,
		capacityComponent,
	].filter(Boolean)

	const removalOrder = [
		capacityComponent,
		poolComponent,
		regionComponent,
		baseComponent,
		nameComponent,
	].filter(Boolean)

	let title = displayOrder.join(' ')
	const toRemove: string[] = []
	while (title.length >= 60 && removalOrder.length) {
		toRemove.push(removalOrder.shift())
		title = displayOrder.filter(s => !toRemove.includes(s)).join(' ')
	}
	return title
}

export const makeHouseTemplateData = (i18n: I18nContext, house: House) => {
	const {
		regionId,
		countryId,
		type,
		name,
		metaDescription,
		mainImage = '',
		capacity,
		features,
	} = house

	const imageUrl =
		mainImage &&
		(mainImage.url ||
			(mainImage.upload &&
				makeUrl(mainImage.upload, {
					mid: true,
					isServer: true,
				})))

	const metaData = {
		title: makeHousePageTitle({
			i18n,
			name,
			region: regionId,
			capacity,
			hasPool: features.includes(FT_POOL),
		}),
		description: metaDescription
			? metaDescription.length > 300
				? [...metaDescription.slice(0, 300), '…']
				: metaDescription
			: 'No description',
		imageUrl,
	}
	const interpolationValues = {
		name,
		type: i18n.getText(type),
		region: i18n.getText(regionId),
		country: countryId && i18n.getText(countryId),
	}
	const templateData = {
		...house,
		metaData,
		interpolationValues,
	}
	return templateData
}

export const setupAnalytics = ({
	house,
	getText,
	lang,
}: {
	house: House
	getText: I18nContext['getText']
	lang: I18nContext['lang']
}): void => {
	const {
		regionId,
		countryId,
		bedroomCount,
		bathroomCount,
		type,
		name,
		id,
		isPromo,
		capacity,
		category,
		petsAllowed,
		placeName,
		slogan,
	} = house

	if (isBrowser && globalThis.window.dataLayer && globalThis.postedId !== id) {
		globalThis.postedId = id
		globalThis.window.dataLayer.push({
			event: 'houseDetail',
			id,
			name,
			placeName,
			region: getText(regionId),
			country: getText(countryId),
			isPromo,
			capacity,
			category,
			petsAllowed,
			slogan: typeof slogan === 'string' ? slogan : rawText(slogan),
			type: getText(type),
			bedroomCount,
			bathroomCount,
			lang,
		})
	}
}
