export const DEFAULT_LANG = 'fr'
export const DEFAULT_CAP_LANG = 'nl'
export const DEFAULT_SWITCH_DAY = 6

export const BLOCK_CANCELLED = 'BLOCK_DEL'
export const BLOCK_SET = 'BLOCK_SET'
export const CON_GUARANTEE = 'CON_GUARANTEE'
export const CON_GUARANTEE_TO_PAY = 'CON_GUARANTEE_TO_PAY'
export const CON_OVERDUE = 'CON_OVERDUE'
export const CON_STATE_CANCELLED = 'cs$CANCELLED'
export const CON_STATE_CLOSED = 'cs$CLOSED'
export const CON_STATE_CONFIRMED = 'cs$CONFIRMED'
export const CON_STATE_OPTION = 'cs$OPTION'
export const CON_VOUCHER = 'CON_VOUCHER'
export const COND_BRINGOWN = 'bringOwn'
export const COND_INCLUDED = 'included'
export const COND_PAYINADVANCE = 'payInAdvance'
export const COND_PAYTHERE = 'payThere'
export const CONTRACT_CANCELLED = 'CONTRACT_CANCELLED'
export const CONTRACT_CHANGED = 'CONTRACT_CHANGED'
export const CONTRACT_CLOSED = 'CONTRACT_CLOSED'
export const CONTRACT_CONFIRMED = 'CONTRACT_CONFIRMED'
export const DERIVE_ALL = 'app/deriveAllHouses'
export const DOMAIN_CHANGED = 'DOMAIN_CHANGED'
export const TAXES_CHANGED = 'TAXES_CHANGED'

export const DTRG_BLOCK = 3
export const DTRG_CONTRACT = 2
export const DTRG_FREE = 1
export const DTRG_PRICE = 0

export const AGENT = 'agent'
export const CLIENT = 'client'

export const EMAIL_FAILED = 'EMAIL_FAILED'
export const EMAIL_QUEUE = 'EMAIL_QUEUE'
export const EMAIL_SENDING = 'EMAIL_SENDING'
export const EMAIL_SENT = 'EMAIL_SENT'

export const EMAIL_STATE_FAILED = 'FAILED'
export const EMAIL_STATE_HOLD = 'HOLD'
export const EMAIL_STATE_QUEUE = 'QUEUE'
export const EMAIL_STATE_SENDING = 'SENDING'
export const EMAIL_STATE_SENT = 'SENT'
export const ERR_FM_CHECK_DELETED = 'ERR_FM_CHECK_DELETED'
export const ERR_FM_DB = 'ERR_FM_DB'
export const ERR_FM_DL_PHOTOS = 'ERR_FM_DL_PHOTOS'
export const ERR_FM_LOOP = 'ERR_FM_LOOP'
export const ERR_FM_PROCESS_DATA = 'ERR_FM_PROCESS_DATA'
export const ERR_FM_UNHANDLED_REJECTION = 'ERR_FM_UNHANDLED_REJECTION'
export const GUARANTEE_PAID = 'GUARANTEE_PAID'
export const GUARANTEE_RETURNED = 'GUARANTEE_RETURNED'
export const HOUSE_CHANGED = 'es/houses'
export const LANDING_PAGE_CHANGED = 'es/landingPages'
export const TRANSLATION_CHANGED = 'es/translations'
export const SPECIAL_CHANGED = 'es/specials'
export const INFINITY = '9999-01-01'
export const INFINITY_PAST = '1970-01-01'

export const OPTION_EXPIRED = 'OPTION_EXPIRED'
export const OPTION_REQUESTED = 'OPTION_REQUESTED'
export const OPTION_REQUESTED_BY_CAP = 'OPTION_REQUESTED_BY_CAP'

export const PAYMENT_DONE = 'PAYMENT_DONE'
export const PAYMENT_CHANGED = 'PAYMENT_CHANGED'
export const PAYMENT_FAILED = 'PAYMENT_FAILED'

export const PER_FIXED = 'FIXED'
export const PER_PERSON = 'PERSON'
export const PER_PERSONWEEK = 'PERSONWEEK'
export const PER_PERSONWEEK_PRORATED = 'PERSONWEEKPRORATED'
export const PER_WEEK = 'WEEK'
export const PER_WEEK_PRORATED = 'WEEKPRORATED'
// TODO unused, delete
export const PRICE_DELETED = 'PRICE_DEL'
// TODO unused, delete
export const PRICES_SET = 'PRICES_SET'
export const RANDOMIZE = 'app/randomizeHouseOrder'
export const RECALC_FREE = 'RECALC_FREE'
export const REMOVE_DERIVED_HOUSE = 'REMOVE_DERIVED_HOUSE'
export const SALDOS_CHANGED = 'SALDOS_CHANGED'

export const SEND_CONTACT = 'SEND_CONTACT'
export const SEND_CONTRACT_CONFIRMATION_TO_AGENT =
	'SEND_CONTRACT_CONFIRMATION_TO_AGENT'
export const SEND_CONTRACT_CONFIRMATION_TO_CLIENT =
	'SEND_CONTRACT_CONFIRMATION_TO_CLIENT'
export const SEND_CONTRACT_CONFIRMATION_TO_CAP =
	'SEND_CONTRACT_CONFIRMATION_TO_CAP'
export const SEND_EMAIL_AGAIN = 'SEND_EMAIL_AGAIN'

export const SEND_OPTION_REQUESTED = 'SEND_OPTION_REQUESTED'
export const SEND_PAYMENT_DONE = 'SEND_PAYMENT_DONE'

export const IS_NOT_SALDO_PAYMENT = 'IS_NOT_SALDO_PAYMENT'
export const IS_DEPOSIT_PAYMENT = 'IS_DEPOSIT_PAYMENT'
export const IS_SALDO_PAYMENT = 'IS_SALDO_PAYMENT'
export const IS_GUARANTEE_BLOCK = 'IS_GUARANTEE_BLOCK'
export const IS_GUARANTEE_REFUND = 'IS_GUARANTEE_REFUND'
export const IS_GUARANTEE_PAYMENT = 'IS_GUARANTEE_PAYMENT'
export const IS_AGENT_PAYMENT = 'IS_AGENT_PAYMENT'
export const CREATE_TEMP_OPTION = 'CREATE_TEMP_OPTION'

export const EMAIL_TYPE_MANUAL_OPTION_NOTIFICATION_AGENT =
	'agentManualOptionNotification'
export const EMAIL_TYPE_CONTRACT_CONFIRMATION_CAP = 'capConfirmation'
export const EMAIL_TYPE_CONTRACT_CONFIRMATION_CLIENT = 'clientConfirmation'
export const EMAIL_TYPE_CONTRACT_CONFIRMATION_AGENT = 'agentConfirmation'
export const EMAIL_TYPE_VOUCHER_CLIENT = 'clientVoucher'

export const EMAIL_TYPE_PAY_CONFIRMATION_AGENT = 'agentPaymentConfirmation'
export const EMAIL_TYPE_YEARLY_REPORT_AGENT = 'agentYearlyReport'

// Make sure that reminder emails match client.*Reminder
export const EMAIL_TYPE_OPTION_REMINDER_CLIENT = 'clientOptionReminder'
export const EMAIL_TYPE_PAY_REMINDER_CLIENT = 'clientPaymentReminder'
export const EMAIL_TYPE_EARLY_PAY_REMINDER_CLIENT = 'clientEarlyPaymentReminder'

export const EMAIL_TYPE_SCHED_PAY_NOTIFY_CLIENT =
	'clientScheduledPaymentNotification'
export const EMAIL_TYPE_SCHED_GUARANTY_PAY_NOTIFY_CLIENT =
	'clientScheduledGuarantyPaymentNotification'
export const EMAIL_TYPE_PAY_CONFIRMATION_CLIENT = 'clientPaymentConfirmation'
export const EMAIL_TYPE_SALDO_PAY_CONFIRMATION_CLIENT =
	'clientSaldoPaymentConfirmation'
export const EMAIL_TYPE_PAY_GUARANTEE_CONFIRMATION_CLIENT =
	'clientGuaranteePaymentConfirmation'
export const EMAIL_TYPE_BLOCK_GUARANTEE_CONFIRMATION_CLIENT =
	'clientGuaranteeBlockConfirmation'
export const EMAIL_TYPE_MANUAL_OPTION_CONFIRMATION_CLIENT =
	'clientManualOptionConfirmation'
export const EMAIL_TYPE_OPTION_CONFIRMATION_CLIENT = 'clientOptionConfirmation'

export const EMAIL_TYPE_STRIPE_FAIL_CLIENT = 'clientStripeFail'
export const EMAIL_TYPE_REFUND_CLIENT = 'clientRefund'

export const EMAIL_TYPE_CONTACT_CONFIRMATION_AGENT = `agentContactConfirmation`
export const EMAIL_TYPE_CONTACT_CONFIRMATION_CLIENT = `clientContactConfirmation`
export const EMAIL_TYPE_AGENT_CONTACT_CAP = `agentContactCap`
export const EMAIL_TYPE_CLIENT_CONTACT_CAP = `clientContactCap`

export const FT_NO_POOL = 'ft$noPool'
export const FT_POOL = 'ft$pool'
export const FT_HEATED_POOL = 'ft$heatedPool'
export const FT_FENCED_POOL = 'ft$fencedPool'
export const FT_PRIVATE_POOL = 'ft$privatePool'
export const FT_SHARED_POOL = 'ft$sharedPool'

export const FT_CAR_CHARGING = 'ft$carCharging'
export const FT_CAR_CHARGING_AT_HOUSE = 'ft$carChargingAtHouse'
export const FT_CAR_CHARGING_LT_5KM = 'ft$carChargingLt5km'
export const FT_CAR_CHARGING_WITH_CABLE = 'ft$carChargingWithCable'

// NOTE: a$vakantiehuis refers to general country house i18n tag, but ht$vakantiehuisVilla is one of the house types.
export const HOUSE_GENERAL_KEY = 'ht$vakantiehuisVilla'
