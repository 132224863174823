import {randomInRange} from 'app/lib'
import React, {useState} from 'react'
import DefaultModal from './Modal'

export const createUniqRandomId = (existIds: string[] = [], prefix = '') => {
	let newId
	let isUnique = false

	do {
		newId = `${prefix}${randomInRange()}`
		isUnique = !existIds.includes(newId)
	} while (!isUnique)

	return newId
}

export type ModalInputConfig = {
	modalId: string
	Modal?: typeof DefaultModal
	Body?: typeof DefaultBody
	bodyProps?: Record<string, any>
	onSubmit?: (value: any) => void | Promise<void>
	[extraProp: string]: any
}
export type ModalConfig = ForSure<ModalInputConfig, 'Modal' | 'Body'> & {
	resolveModalPromise?: (content?: any) => void
}

export type ModalContextType = {
	modals: Record<string, ModalConfig | undefined>
	closeModal: (modalId: string) => void
	openModal: (cfg: ModalInputConfig) => void
	onModalSubmit: (modalId: string, content: any) => Promise<void>
}

export const ModalContext = React.createContext<ModalContextType>(
	undefined as unknown as ModalContextType
)

const DefaultBody = ({
	modalProps,
	body,
}: {
	readonly modalProps: any
	readonly body: React.ReactNode
}) => <span>{body || modalProps.body}</span>

const ModalProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ({
	children,
}) => {
	const [modals, setModals] = useState<ModalContextType['modals']>({})

	const addModal = (p: ModalConfig) => setModals(m => ({...m, [p?.modalId]: p}))

	const openModal = ({
		modalId = createUniqRandomId(modals && Object.keys(modals)),
		Modal = DefaultModal,
		Body = DefaultBody,
		bodyProps,
		...modalProps
	}: ModalInputConfig) =>
		new Promise(resolveModalPromise => {
			addModal({
				modalId,
				Modal,
				Body,
				bodyProps,
				...modalProps,
				resolveModalPromise,
			})
		})

	const closeModal = (modalId: string) => {
		const modal = modals[modalId]
		if (!modal) return

		// resolve modal promise as empty
		modal.resolveModalPromise?.()

		setModals(m => ({...m, [modalId]: undefined}))
	}

	const handleModalSubmit = async (modalId: string, content: any) => {
		const modal = modals[modalId]
		if (!modal) return
		const {resolveModalPromise, bodyProps} = modal

		// callback
		const onSubmit = bodyProps?.formProps?.onSubmit || bodyProps?.onSubmit

		// resolve modal promise with optional callback
		resolveModalPromise?.(onSubmit ? await onSubmit(content) : content)

		closeModal(modalId)
	}

	return (
		<ModalContext.Provider
			{...{
				value: {
					modals,
					closeModal,
					openModal,
					onModalSubmit: handleModalSubmit,
				},
			}}
		>
			{children}
		</ModalContext.Provider>
	)
}

export default ModalProvider
