import React from 'react'
import gql from 'graphql-tag'
import Image from './Image'
import Loading from 'app/Components/Loading'
import {useQuery} from 'plugins/apollo'
import {useI18n} from 'plugins/i18n'

export const imageQuery = gql`
	query upload($value: ID!) {
		upload(id: $value) {
			id
			meta
		}
	}
`

const ImageFromId = ({value, ...rest}) => {
	const {lang} = useI18n()
	const {loading, data} = useQuery(imageQuery, {
		variables: {value},
		skip: !value,
	})
	if (loading) return <Loading />

	const imageData = data?.upload && {
		...data.upload.meta,
		alt: data.upload.meta.altI18n?.[lang],
		id: data.upload.id,
	}
	return <Image {...rest} value={imageData} />
}

export default ImageFromId
