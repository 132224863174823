import React from 'react'
import PropTypes from 'prop-types'
import Description from './Description'
import Features from './Features'
import {Preview} from '.'
import {useI18n} from 'plugins/i18n'
import {useDispatch} from 'plugins/redux/hooks'
import {useHistory} from 'plugins/react-router-deluxe'
import {useResponsive} from 'app/styles'
import {setHouseName} from 'app/redux/ducks/searchRentals-duck'
import {urlForHouse} from 'app/lib'

export const RegionBase = ({house, onReadMore}) => {
	const {isPhone, isMobileNew} = useResponsive('isPhone', 'isMobileNew')
	const {bathroomCount, bedroomCount, capacity, name, regionId, placeName} =
		house

	const houseFeatures = [
		{value: capacity, icon: 'peopleNew'},
		{value: bedroomCount, icon: 'bedNew'},
		{value: bathroomCount, icon: 'bathNew'},
	]

	return (
		<Preview {...{house, column: !isMobileNew, ratio: 1.33}}>
			<Description
				{...{
					fontResize: 0.8,
					name,
					onClick: () => isPhone && onReadMore(),
					regionId,
					placeName,
					bottom: (
						<Features
							small
							noBorder
							{...{houseFeatures, colorSet: 'capGrey', row: true}}
						/>
					),
				}}
			/>
		</Preview>
	)
}
RegionBase.propTypes = {
	house: PropTypes.object,
	isMobileNew: PropTypes.bool,
	isMobilePromo: PropTypes.bool,
	isPhone: PropTypes.bool,
	onReadMore: PropTypes.func,
	setHouseName: PropTypes.func,
}

const Region = ({house}) => {
	const history = useHistory()
	const i18n = useI18n()
	const dispatch = useDispatch()

	const handleClearNameSearch = () => {
		dispatch(setHouseName(''))
	}

	const handleReadMore = () => {
		history.push(urlForHouse({house, lang: i18n.lang}))
		handleClearNameSearch()
	}

	return <RegionBase {...{history, i18n, house}} onReadMore={handleReadMore} />
}
export default Region
