import Box from './Box'
import {styled, colorCss, hoverColorCss} from 'app/styles'

export const ThemedBox = styled(Box)`
	${colorCss};
	${p => ({borderRadius: p.round && '50%'})};
`
export const ThemedHoverBox = styled(ThemedBox)`
	${hoverColorCss};
`
