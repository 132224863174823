import React from 'react'
import {Button} from 'app/Components/Buttons'
import {_} from 'plugins/i18n'
import type {OperationVariables, QueryResult} from '@apollo/client'

/**
 * @template {Object} T
 * @param {import('@apollo/client').QueryResult} apolloResult
 * @returns {import('@apollo/client').QueryResult<T> & {
 * 	reactError?: JSX.Element
 * }}
 */
const wrapError = <TData, TVariables extends OperationVariables>(
	apolloResult: QueryResult<TData, TVariables>
): QueryResult<TData, TVariables> & {reactError?: JSX.Element} => {
	if (apolloResult.error) {
		const {error, refetch} = apolloResult
		// eslint-disable-next-line no-console
		console.error('query failed', error)
		// @ts-ignore
		apolloResult.reactError = (
			<div className="flex w-full items-center justify-center gap-4">
				{_`QueryFailed`}
				<Button onClick={() => refetch()}>{_`Retry`}</Button>
			</div>
		)
	}

	return apolloResult
}

export default wrapError
