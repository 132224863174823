// Use illegal hostname if not defined so we don't match accidentally
globalThis.hostname ||= '\\'

const fullUriRegex = /^\w+:\/{0,2}\S+/
const relativeUriRegex = /^(?:\.{1,2}|\w*)(?:[/?]|$)/
/** @param {string} link */
export const isInternalLink = link => {
	if (!link) return false

	if (fullUriRegex.test(link)) {
		if (!link.startsWith('http')) return false

		const hostIdx = link.toLowerCase().indexOf(globalThis.hostname)
		return hostIdx === -1
			? false
			: link.slice(hostIdx + globalThis.hostname.length)
	}

	if (relativeUriRegex.test(link)) return link

	return false
}
