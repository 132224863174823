import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from 'plugins/redux/storeFactory'

interface ModalState {
	id: string | null
	data: any | null
}

const initialState: ModalState = {id: null, data: null}

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		open(state, action: PayloadAction<{id: string; data: any}>) {
			state.id = action.payload.id
			state.data = action.payload.data
		},
		close() {
			return initialState
		},
	},
})

export const {open, close} = modalSlice.actions

export default modalSlice.reducer

export const closeModal = () => (dispatch: any, getState: () => RootState) => {
	const {modal} = getState()
	if (modal.id === null) {
		return
	}
	dispatch(close())
}

export const openModal = (id: string, data?: any) => open({id, data})
