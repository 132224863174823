import React from 'react'
import PropTypes from 'prop-types'
import {Img} from 'app/Components/Layout/Display'
import {join} from 'lodash-es'

const PostImage = ({post: {date, title, mainImage}, ratio}) => {
	const alt = join([date, title], '-')
	return (
		<Img
			ratio={ratio || 16 / 9}
			cut
			cutSize={2}
			value={{...mainImage, alt, title}}
		/>
	)
}
PostImage.propTypes = {
	ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	post: PropTypes.object,
}

export default PostImage
