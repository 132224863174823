import {
	getNewsletterUrl,
	mailFormat,
} from 'app/Sections/subscribe/SubscribeSection'
import {useI18n} from 'plugins/i18n'
import {gtag} from 'plugins/trackers'
import React from 'react'

const Newsletter = () => {
	const {getText, lang} = useI18n()
	const [email, setEmail] = React.useState('')
	const [accepted, setAccepted] = React.useState(false)
	const url = getNewsletterUrl(lang)

	const valid = accepted && mailFormat.test(email)

	return (
		<div className="flex w-full flex-col px-6 py-8">
			<div className="flex flex-col space-y-2 pr-36">
				<h2 className="text-onBackground text-2xl leading-8">
					{getText('newsletterTitle')}
				</h2>
				<p className="text-onTertiary text-sm leading-5">
					{getText('newsletterSubtitle')}
				</p>
			</div>
			<form
				className="w-full"
				onSubmit={e => {
					if (!valid) {
						e.preventDefault()
					}
					gtag('event', 'Ingeschreven', {event_category: 'Nieuwsbrief'})
				}}
				action={url}
				method="post"
				name="mc-embedded-subscribe-form"
			>
				<div className="flex flex-col">
					<p className="text-onBackground my-2 text-base leading-6 font-bold">
						{getText('email')}
					</p>

					<input
						type="text"
						name="EMAIL"
						className="h-12 w-full rounded-md border border-solid border-gray-300 p-2 text-black"
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<div className="my-2">
						<input
							type="checkbox"
							checked={accepted}
							className="h-5 w-5"
							onChange={() => setAccepted(!accepted)}
						/>
						<span className="mx-2">{getText('privacyStatement')}</span>
					</div>
					<button
						className="bg-primary text-onPrimary mt-3 w-full rounded-md p-2"
						type="submit"
						disabled={!valid}
					>
						{getText('confirm')}
					</button>
					<span className="text-tertiary mt-1 text-sm leading-5">
						{getText('newsletterTip')}
					</span>
				</div>
			</form>
		</div>
	)
}

export default Newsletter
