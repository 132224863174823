import {styled, css} from 'app/styles'
import Box from './Box'

export const TextSpan = styled(Box).attrs({inline: true})`
	${p => p.noWrap && `white-space: nowrap`};
	${p => p.wrapWords && `word-wrap: break-word`};
	${p => p.cursor && `cursor: pointer`};
	${p => p.bold && `font-weight: 600`};

	${p => p.uppercase && `text-transform: uppercase`};
	transition: 0.5s;
	${p =>
		p.underlineOnHover &&
		css`
			:hover {
				text-decoration: underline;
			}
		`};
`
