import React from 'react'
import {makeSplitPoint} from 'plugins/react'
import {
	Switch,
	Route,
	ScrollToTop,
	HTTP404,
	PermanentRedirect,
	useLocation,
} from 'plugins/react-router-deluxe'
import Pdf from './pdf'
import {newDefaultTheme, ThemeProvider, capGreenColor} from 'app/styles'
import {Helmet} from 'plugins/react-helmet'
import ModalProvider from 'app/Components/Modal/Provider'
import appIcon from 'assets/app-icon.png'
import {_, LangRoute} from 'plugins/i18n'
import {Hotjar, GoogleTagManager, GoogleAnalytics} from 'plugins/trackers'
import AdminButtons from './Components/AdminButtons'
import Pages from './Pages'
import {Template, SectionsProvider} from 'plugins/templates'
import * as sections from './Sections'
import Preconnect from './Components/Preconnect'
import NewModalProvider from 'plugins/modals/ModalProvider'
import useDisplayModals from 'plugins/modals/useDisplayModals'
import ComponentsPlayground from './Pages/ComponentsPlayground'
import {LangUrlsProvider} from 'plugins/i18n/react/langUrlsContext'
import './styles/styles.css'
import {isDev} from 'stratokit/build'

const BackOffice = makeSplitPoint(() => import('./backoffice'), {ssr: false})

const DisplayModals = () => {
	const Modals = useDisplayModals()
	return Modals
}

const WebSite = () => (
	<div className="bg-white">
		{/* Keep GA early; we only want it in frontend but with scripts in head */}
		<GoogleTagManager />
		<GoogleAnalytics />
		<Pages />
		<AdminButtons adminUrl="/xx/backoffice" />
		<Hotjar />
	</div>
)

const CheckPagePage = () => {
	const location = useLocation()
	// Some clients go into a redirect loop
	if (/page=1&page=1/.test(location.search))
		return <PermanentRedirect to={location.pathname.slice(0, 4)} />
	return null
}

const Root = () => {
	return (
		<LangUrlsProvider>
			<ThemeProvider themes={[newDefaultTheme]}>
				<SectionsProvider sections={sections} merge>
					<ScrollToTop />
					<CheckPagePage />
					<Helmet>
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1.0, minimum-scale=1"
						/>
						<link rel="icon" href={appIcon} />
						<meta name="theme-color" content={capGreenColor} />
						<meta
							name="facebook-domain-verification"
							content="hsdnvt37jt4w5k3tce3ulips9b6j8s"
						/>
						<link rel="preconnect" href="https://fonts.googleapis.com" />
						<link
							rel="preconnect"
							href="https://fonts.gstatic.com"
							crossOrigin="anonymous"
						/>
						<link
							href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700,800,900&display=swap"
							rel="stylesheet"
						/>
					</Helmet>
					<Preconnect />
					<NewModalProvider>
						<ModalProvider>
							<Switch>
								{isDev && (
									<LangRoute
										path="/:lang/Components"
										Component={ComponentsPlayground}
									/>
								)}
								<LangRoute path="/:lang/pdf" Component={Pdf} />
								<LangRoute path="/:lang/backoffice" Component={BackOffice} />
								<Route path="/404">
									<HTTP404 />
									<Template id="404" />
								</Route>
								<LangRoute Component={WebSite} />
							</Switch>
							<DisplayModals />
						</ModalProvider>
					</NewModalProvider>
				</SectionsProvider>
			</ThemeProvider>
		</LangUrlsProvider>
	)
}

export default Root
