import React, {useContext} from 'react'
import {ModalConfig, ModalContext} from './ModalProvider'

const useDisplayModals = () => {
	const ctx = useContext(ModalContext)
	const {modals, closeModal, onModalSubmit} = ctx
	const toRender = Object.values(modals).filter(Boolean) as ModalConfig[]
	return (
		<>
			{toRender.map(({Modal, modalId, ...props}, i) => (
				<Modal
					key={i}
					{...{
						...props,
						open: true,
						onClose: () => closeModal(modalId),
						onSubmit: v => onModalSubmit(modalId, v),
					}}
				/>
			))}
		</>
	)
}

export default useDisplayModals
