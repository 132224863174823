import React, {useMemo} from 'react'
import styled from 'styled-components'
import {lightBeigeColor} from 'app/styles'
import {SpacesWrap} from 'app/Components/Layout/Display'
import {useQuery} from 'plugins/apollo'
import {_, useI18n} from 'plugins/i18n'
import {houseQuery, housesListQuery} from 'app/_server/queries/houses'
import {sortHousesByPriceAndCapacity} from '../helpers'
import {HousesList} from 'app/Components/Sections/HousesList'
import {urlForSearch} from 'app/lib'
import {HOUSE_GENERAL_KEY} from 'app/_server/database/constants'

interface SimilarHousesProps {
	readonly value: {
		house?: FullHouse
		houseId?: House['id']
	}
	readonly templateData: FullHouse & Record<string, any>
}

const StyledSpacesWrap = styled(SpacesWrap)<{withBeigeBg?: boolean}>`
	background: ${p => p.withBeigeBg && lightBeigeColor};
`

/**
 * Shows similar houses sorted by price and capacity. Takes mapping values and
 * than templateData as fallback.
 */
const SimilarHouses: React.FC<SimilarHousesProps> = ({value, templateData}) => {
	const i18n = useI18n()

	// derive house data needed to fetch similar houses
	// sources possible:
	// 1. mapping the whole house
	// 2. mapping houseId
	// 3. templateData (used only if no mapping is provided)
	const {loading: houseLoading, data: houseData} = useQuery(houseQuery, {
		variables: {
			houseId: value.houseId,
		},
		skip: !!value?.house || !value?.houseId,
	})
	const house: House | undefined =
		value?.house || houseData?.house || (!value?.houseId && templateData)

	const sellPrices =
		house?.prices &&
		house.prices.length > 0 &&
		house.prices.map(p => p.sell).filter(Boolean)
	const price = sellPrices && sellPrices.length > 0 && Math.min(...sellPrices)

	const {loading: housesLoading, data: housesData} = useQuery(housesListQuery, {
		variables: {
			regionId: house?.regionId,
			idNotIn: [house?.id],
			size: house?.capacity,
			limit: 20,
			...(price && {priceBelow: Math.round(price + 0.3 * price)}), // up to 30% more expensive
		},
		skip: !house,
	})

	const houses: FullHouse[] = useMemo(() => {
		return sortHousesByPriceAndCapacity({
			houses: (housesData?.houses?.items || []).filter(h => h.id !== house?.id), // filter out current house
			price,
			capacity: house?.capacity,
		})
	}, [housesData?.houses?.items, price, house?.capacity, house?.id])

	const showMorePath =
		house &&
		urlForSearch({
			i18n,
			filters: {
				houseType: HOUSE_GENERAL_KEY,
				houseCapacity: house?.capacity,
				region: [house.regionId as I18nKey],
			},
		})

	return (
		<StyledSpacesWrap volumes={[8, 2]}>
			<HousesList
				{...{
					houses,
					title: _`similarHouses`,
					showMoreLabel: _`showMoreSimilarHouses`,
					showMorePath,
					loading:
						(houseLoading && !houseData) || (housesLoading && !housesData),
				}}
			/>
		</StyledSpacesWrap>
	)
}

export default SimilarHouses
