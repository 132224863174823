import React from 'react'
import styled, {css} from 'styled-components'
import {FileFromId} from 'plugins/images'
import {get} from 'lodash-es'
import {Link as origLink} from 'plugins/react-router-deluxe'
import {isInternalLink} from './helpers'

const FFIWrap = styled.div`
	${p => get(p, 'theme.htmlInput.imageCss')};
`

const marginBottomCss = css`
	margin-bottom: 1rem;
	&:last-child {
		margin-bottom: 0;
	}
`
const alignCss = css`
	${p => p.align && `text-align:${p.align}`}
`

export const P = styled.p`
	${marginBottomCss};
	${alignCss};
	${p => get(p, 'theme.htmlInput.pCss')};
`
export const B = styled.strong`
	font-weight: bold;
	${p => get(p, 'theme.htmlInput.bCss')};
`
export const I = styled.em`
	font-style: italic;
	${p => get(p, 'theme.htmlInput.iCss')};
`
export const U = styled.u`
	text-decoration: underline;
	${p => get(p, 'theme.htmlInput.uCss')};
`
const linkCss = css`
	color: rgb(0, 169, 234);
	text-decoration: underline;
	${p => get(p, 'theme.htmlInput.aCss')};
`
export const A = styled.a`
	${linkCss}
`
export const Link = styled(origLink)`
	${linkCss}
`
export const OL = styled.ol`
	list-style: decimal;
	margin: 0.5em 0 0.5em 1.5em;
	${p => get(p, 'theme.htmlInput.olCss')};
`
export const UL = styled.ul`
	list-style: disc;
	margin: 0.5em 0 0.5em 1.5em;
	${p => get(p, 'theme.htmlInput.ulCss')};
`
export const H1 = styled.h1`
	${marginBottomCss};
	${alignCss};
	font-size: 1.4em;
	${p => get(p, 'theme.htmlInput.h1Css')};
`
export const H2 = styled.h2`
	${marginBottomCss};
	${alignCss};
	font-size: 1.3em;
	${p => get(p, 'theme.htmlInput.h2Css')};
`
export const H3 = styled.h3`
	${marginBottomCss};
	${alignCss};
	font-size: 1.2em;
	${p => get(p, 'theme.htmlInput.h3Css')};
`
export const H4 = styled.h4`
	${marginBottomCss};
	${alignCss};
	font-size: 1.1em;
	${p => get(p, 'theme.htmlInput.h4Css')};
`
export const H5 = styled.h5`
	${marginBottomCss};
	${alignCss};
	font-size: 1.1em;
	${p => get(p, 'theme.htmlInput.h5Css')};
`
export const H6 = styled.h6`
	${marginBottomCss};
	${alignCss};
	font-size: 1.1em;
	${p => get(p, 'theme.htmlInput.h6Css')};
`
export const PB = styled.span`
	${marginBottomCss};
	page-break-after: always;
	display: block;
`

const BLOCK_TAGS = {
	p: 'P',
	li: 'LI',
	ul: 'UL',
	ol: 'OL',
	h1: 'H1',
	h2: 'H2',
	h3: 'H3',
	h4: 'H4',
	h5: 'H5',
	h6: 'H6',
	upload: 'upload',
	PB: 'PB',
}
export const blockEl = {
	paragraph: P, // HTML parser used to create this
	P,
	LI: 'li',
	UL,
	OL,
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	PB,
}

const MARK_TAGS = {
	strong: 'B',
	em: 'I',
	u: 'U',
}
export const markEl = {
	B,
	I,
	U,
}

const RULES = [
	{
		serialize(el) {
			if (el.type !== 'upload' || el.object !== 'block') return
			const data = el.data.toJS ? el.data.toJS() : el.data
			return (
				<div className="flex w-full items-center justify-center">
					<FFIWrap>
						<FileFromId {...data} />
					</FFIWrap>
				</div>
			)
		},
	},
	{
		deserialize(el, next) {
			const block = BLOCK_TAGS[el.tagName.toLowerCase()]
			if (!block) return

			// TODO extract style
			return {
				object: 'block',
				type: block,
				nodes: next(el.childNodes),
			}
		},
		serialize(el, children) {
			if (el.object !== 'block') return
			const El = blockEl[el.type]
			const data = el.data.toJS ? el.data.toJS() : el.data
			if (El) return <El {...data}>{children}</El>
		},
	},
	{
		deserialize(el, next) {
			const mark = MARK_TAGS[el.tagName.toLowerCase()]
			if (!mark) return

			return {
				object: 'mark',
				type: mark,
				nodes: next(el.childNodes),
			}
		},
		serialize(el, children) {
			if (el.object !== 'mark') return
			const El = markEl[el.type]
			const data = el.data.toJS ? el.data.toJS() : el.data
			if (El) return <El {...data}>{children}</El>
		},
	},
	{
		// Special case for links, to grab their href.
		deserialize(el, next) {
			if (el.tagName.toLowerCase() !== 'a') return

			return {
				object: 'inline',
				type: 'link',
				nodes: next(el.childNodes),
				data: {
					href: el.attributes.href && el.attributes.href.value,
					target:
						el.attributes.target && el.attributes.target.value === '_blank'
							? 'y'
							: 'n',
				},
			}
		},
		serialize(el, children) {
			if (el.type !== 'link' || el.object !== 'inline') return
			const data = el.data.toJS ? el.data.toJS() : {...el.data}
			data.target = data.target === 'y' ? '_blank' : '_self'
			if (data.target === '_blank')
				data.rel = `${data.rel ? `${data.rel} ` : ''}noopener`
			if (!data.title) data.title = data.href
			const internalLink = isInternalLink(data.href)
			if (internalLink) {
				return <Link to={internalLink}>{children}</Link>
			}
			return (
				<A {...data} rel="nofollow">
					{children}
				</A>
			)
		},
	},
]

export default RULES
