import React, {useRef} from 'react'
import {HeadBox} from 'app/Components/Layout'
import ResultsList from './List'
import {_, useI18n} from 'plugins/i18n'
import ItemPager from 'app/Components/Pager'
import {makeSearchPageTitle} from 'app/Components/ShowRentals/searchPageMeta'

type SearchResultsProps = {
	readonly page: number
	readonly setPage: (page: number) => void
	readonly regionIds?: I18nKey[]
	readonly houseType?: string
	readonly houseCapacity?: number
	readonly items: any[]
	readonly total: number
	readonly pageSize?: number
	readonly features: {[key: string]: boolean}
}

const SearchResults: React.FC<SearchResultsProps> = ({
	page,
	setPage,
	regionIds,
	houseType,
	houseCapacity,
	items,
	total,
	pageSize = 12,
	features,
}) => {
	const i18n = useI18n()
	const listRef = useRef<HTMLDivElement>(null)

	const handlePageClick = (index: number) => {
		const el = listRef.current
		setPage(index)
		el?.scrollIntoView?.({
			behavior: 'smooth',
			block: 'start',
			inline: 'center',
		})
	}

	if (!total) {
		return <h1>{i18n.getText('noRentals')}</h1>
	}

	const index = page - 1
	const houses = items.slice(index * pageSize, index * pageSize + pageSize)

	const title = makeSearchPageTitle({
		houseType,
		featureIds: Object.entries(features)
			.filter(i => i[1])
			.map(i => i[0]),
		capacity: houseCapacity,
		regionIds,
		i18n,
		suffixText: '',
		hasForRentLabel: false,
	})

	return (
		<div className="flex w-full flex-col items-start justify-center gap-4">
			<div>
				<HeadBox as="h1" underline noMargin>
					{title}
				</HeadBox>
			</div>
			<ResultsList ref={listRef} houses={houses} />
			<ItemPager
				current={page}
				total={Math.ceil(total / pageSize)}
				onPageClick={handlePageClick}
			/>
		</div>
	)
}

export default SearchResults
