import {filter, values} from 'lodash-es'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {createSelector} from 'plugins/react'

const initialState: Record<string, boolean> = {}

const favoritesAndShareSlice = createSlice({
	name: 'favoritesAndShare',
	initialState,
	reducers: {
		toggleFavorites: (state, action: PayloadAction<string>) => {
			const houseId = action.payload
			state[houseId] = !state[houseId]
		},
	},
})

export const {toggleFavorites} = favoritesAndShareSlice.actions

export default favoritesAndShareSlice.reducer

const getFavoritesObj = (state: {favoritesAndShare: Record<string, boolean>}) =>
	state.favoritesAndShare

export const selectors = {
	getFavoritesObj,
	getFavoritesLength: createSelector(
		getFavoritesObj,
		(favoritesObj: Record<string, boolean>) =>
			filter(values(favoritesObj), Boolean).length
	),
}
