import {useMutation} from 'plugins/apollo'
import {graphql} from 'app/gql'

export const COMMON_FIELDS = graphql(`
	fragment CommonFields on LandingPage {
		id
		urlI18n
		url
		imageId
		houseId
		regionId
		template
		search {
			region
			houseType
			size
			features
			priceRange
			specials
		}
	}
`)

export const landingPageByUrlUserQuery = graphql(`
	query landingPageByUrl($url: ShortString!, $lang: ShortString!) {
		landingPageByUrl(url: $url, lang: $lang) {
			metaDesc
			metaKeywords
			metaTitle
			redirectUrl

			...CommonFields
		}
	}
`)

export const landingPageByUrlAdminQuery = graphql(`
	query landingPageByUrlAdmin($url: ShortString!, $lang: ShortString!) {
		landingPageByUrl(url: $url, lang: $lang, followRedirects: false) {
			isOnline
			metaDescI18n
			metaKeywordsI18n
			metaTitleI18n
			redirectId
			redirectUrlI18n

			...CommonFields
		}
	}
`)

export const landingPageAdminQuery = graphql(`
	query landingPageAdmin($id: Int!) {
		landingPage(id: $id) {
			isOnline
			metaDescI18n
			metaKeywordsI18n
			metaTitleI18n
			redirectId
			redirectUrlI18n

			...CommonFields
		}
	}
`)

export const landingPageAdminListQuery = graphql(`
	query landingPagesAdminQuery($onlyOnline: Boolean!, $limit: Int) {
		landingPagesAdmin(onlyOnline: $onlyOnline, limit: $limit) {
			items {
				isOnline
				redirectId
				metaTitleI18n
				redirectUrlI18n

				...CommonFields
			}
		}
	}
`)

export const saveLandingPageMutation = graphql(`
	mutation saveLandingPage($landingPage: LandingPageInput!) {
		saveLandingPage(landingPage: $landingPage) {
			id
			urlI18n
			imageId
			houseId
			template

			isOnline
			metaDescI18n
			metaKeywordsI18n
			metaTitleI18n
			redirectId
			redirectUrlI18n

			search {
				region
				houseType
				size
				features
				priceRange
				specials
			}
		}
	}
`)

export const useSaveLandingPage = (options?: Record<string, any>) =>
	useMutation(saveLandingPageMutation, {
		refetchQueries: ['landingPagesAdminQuery'],
		...options,
	})
