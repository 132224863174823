import React, {Component, useEffect, useRef} from 'react'
import {styled, css} from 'app/styles'
import NoImageIcon from './NoImageIcon'
import {isBrowser} from 'stratokit/build'

declare module 'csstype' {
	interface Properties {
		'--fit'?: Properties['contain']
		'--ratio'?: string | number
	}
}

let decode:
	| ((hash: string, width: number, height: number) => Uint8ClampedArray)
	| undefined

if (isBrowser)
	import('fast-blurhash').then(
		bh => (decode = bh.decodeBlurHash),
		console.error
	)

const blurhashToCanvas = (
	canvas: HTMLCanvasElement | null,
	hash: string,
	width = 64,
	height = 64
): void => {
	if (!(globalThis.document && decode && canvas)) return
	canvas.width = width
	canvas.height = height
	const pixels = decode(hash, width, height)
	const ctx = canvas.getContext('2d')
	if (!ctx) return
	const imageData = ctx.createImageData(width, height)
	imageData.data.set(pixels)
	ctx.putImageData(imageData, 0, 0)
}

const digit =
	'0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz#$%*+,-.:;=?@[]^_{|}~'

const decode83 = (str: string, start: number, end: number): number => {
	let value = 0
	while (start < end) {
		value *= 83
		value += digit.indexOf(str[start++])
	}
	return value
}

const calcAverageRGB = (blurHash?: string): string | undefined => {
	if (!blurHash) return undefined
	let value = decode83(blurHash, 2, 6)
	const r = value >> 16
	const g = (value >> 8) & 255
	const b = value & 255
	return `rgb(${r},${g},${b})`
}

const coverCss = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`

const Blurhash = styled(
	({value, className}: {value: string; className?: string}) => {
		const ref = useRef<HTMLCanvasElement>(null)
		useEffect(() => blurhashToCanvas(ref.current, value), [value])
		return <canvas ref={ref} className={className} />
	}
)`
	${coverCss};
	z-index: 0;
`

const ImageDom = styled.div`
	width: 100%;
	@supports (aspect-ratio: 1) {
		height: 100%;
		aspect-ratio: var(--ratio);
	}
	@supports not (aspect-ratio: 1) {
		height: 0;
		padding-top: calc(100% / var(--ratio));
	}
	overflow: hidden;
	position: relative;
`

const Img = styled.img`
	${coverCss};
	object-fit: var(--fit);
	min-height: 1px;
	min-width: 1px;
	transition: opacity 0.1s;
	opacity: 1;
	&.loading {
		opacity: 0;
	}
`

interface ImageContentProps {
	src?: string
	srcSet?: string
	sources?: {type: string; srcSet: string}[]
	title?: string
	alt?: string
	contain?: boolean
	ratio?: number
	eager?: boolean
	w?: number
	h?: number
	blur?: number
	blurhash?: string
	children?: React.ReactNode
}

interface ImageContentState {
	loadState: number
	avg?: string
	ratio: string | number
}

class ImageContent extends Component<ImageContentProps, ImageContentState> {
	state: ImageContentState = {
		loadState: 0,
		avg: calcAverageRGB(this.props.blurhash),
		ratio: this.getRatio(this.props.w, this.props.h),
	}

	imgRef = React.createRef<HTMLImageElement>()
	private _isMounted = false

	private getRatio(width?: number, height?: number): number {
		const ratio = this.props.ratio || (width && height ? width / height : 3 / 2)
		return Math.round(ratio * 10_000) / 10_000
	}

	private handleError = (): void => {
		if (this._isMounted) {
			this.setState({loadState: 2})
		}
	}

	private handleLoad = (ev: React.SyntheticEvent<HTMLImageElement>): void => {
		const img = ev.currentTarget
		const ratio = this.getRatio(img.width, img.height)
		if (this._isMounted) {
			this.setState({loadState: 3, ratio})
		}
	}

	componentDidMount(): void {
		this._isMounted = true
		this.handleImgState()
	}

	componentDidUpdate(prevProps: ImageContentProps): void {
		const {src, blurhash} = prevProps
		if (src !== this.props.src || blurhash !== this.props.blurhash) {
			const avg = calcAverageRGB(this.props.blurhash)
			const ratio = this.getRatio(this.props.w, this.props.h)
			if (this._isMounted) {
				this.setState(s => ({...s, avg, ratio}))
				this.handleImgState()
			}
		}
	}

	componentWillUnmount(): void {
		this._isMounted = false
	}

	private enableBlurhash = (): void => {
		const {blurhash} = this.props
		if (!blurhash) return
		if (this._isMounted) {
			this.setState(state => (state.loadState ? null : {loadState: 1}))
		}
	}

	private handleImgState(): void {
		const {src, blurhash} = this.props
		const img = this.imgRef.current
		let loadState: number

		if (img?.complete) {
			const isOk = !!img.naturalWidth
			if (isOk) {
				this.handleLoad({
					currentTarget: img,
				} as React.SyntheticEvent<HTMLImageElement>)
			} else {
				this.handleError()
			}
			return
		} else {
			loadState = src ? 0 : 1
		}

		if (this._isMounted) {
			this.setState(s => (s.loadState === loadState ? null : {loadState}))
		}

		if (isBrowser && loadState < 3 && blurhash) {
			setTimeout(() => {
				if (this._isMounted) {
					this.enableBlurhash()
				}
			}, 100)
		}
	}

	render(): JSX.Element {
		const {
			alt,
			blur,
			contain,
			w,
			h,
			blurhash,
			children,
			src,
			srcSet,
			eager,
			...rest
		} = this.props
		const {loadState, ratio, avg} = this.state

		return (
			<ImageDom
				{...rest}
				style={{
					'--ratio': ratio,
					'--fit': contain ? 'contain' : 'cover',
					backgroundColor: avg,
				}}
				role="img"
				aria-label={alt}
			>
				{blurhash && loadState > 0 && loadState < 3 && (
					<Blurhash value={blurhash} />
				)}
				{loadState === 2 || !src ? (
					<div className="absolute top-0 left-0 flex h-full min-h-full w-full flex-col items-center justify-center text-center">
						<NoImageIcon />
						{alt}
					</div>
				) : (
					<Img
						ref={this.imgRef}
						{...{src, srcSet, alt}}
						className={
							isBrowser && loadState > 0 && loadState < 3 ? 'loading' : ''
						}
						style={blur ? {filter: `blur(${blur}px)`} : undefined}
						loading={eager ? undefined : 'lazy'}
						onLoad={this.handleLoad}
						onError={this.handleError}
						width={w}
						height={h}
					/>
				)}
				{children}
			</ImageDom>
		)
	}
}

export default ImageContent
