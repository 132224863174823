import React from 'react'

const FadeBox: React.FC<{
	readonly children?: React.ReactNode
	readonly fadeColor?: string
	readonly className?: string
}> = ({children, fadeColor, className}) => {
	return (
		<div
			className={`relative max-h-32 w-full cursor-default overflow-hidden ${className}`}
		>
			{children}
			<div
				className={`absolute bottom-0 left-0 m-0 w-full p-[30px_0] text-center ${className}`}
				style={{
					backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${fadeColor} 100%)`,
				}}
			/>
		</div>
	)
}

export default FadeBox
