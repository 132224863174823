import React, {FunctionComponent} from 'react'
import {_} from 'plugins/i18n'
import {isWithinInterval} from 'date-fns'
import {styled, darkGreen} from 'app/styles'
import {FixDateValue} from 'app/lib/dateHelpersReact'

const PromoItem = styled.div<{active?: boolean}>`
	color: ${p => (p.active ? darkGreen : 'inherit')};
	${p => p.active && 'font-weight: bold'};
`

const promoActive = (dateRange: RangeMomentType, promotion) => {
	const startInPromo = isWithinInterval(new Date(dateRange.start), {
		start: new Date(promotion.promoStartDate),
		end: new Date(promotion.promoEndDate),
	})
	const endInPromo = isWithinInterval(new Date(dateRange.end), {
		start: new Date(promotion.promoStartDate),
		end: new Date(promotion.promoEndDate),
	})
	return startInPromo || endInPromo
}

const Promotions: FunctionComponent<{
	readonly value: Promotion[]
	readonly dateRange?: RangeMomentType
}> = ({value, dateRange}) => {
	if (!value?.length) return null
	return (
		<div>
			<p>{_`PromotionsList`}:</p>
			{value.map((promotion, i) => {
				const active = dateRange && promoActive(dateRange, promotion)
				return (
					<PromoItem key={i} active={active}>
						{`${promotion.discountPercentage}%`}
						{` - `}
						{_`from`}
						{` `}
						<FixDateValue value={promotion.promoStartDate} />
						{` `}
						{_`till`}
						{` `}
						<FixDateValue value={promotion.promoEndDate} />
					</PromoItem>
				)
			})}
		</div>
	)
}

export default Promotions
