import React from 'react'
import styled from 'styled-components'
import {getFg} from 'app/styles'
import {_} from 'plugins/i18n'

/**
 * Active: true => X Icon
 *
 * Active: false => Hamburger Icon
 */
const BurgerAndXIcon = styled.div<{active: boolean}>`
	transition-duration: 0.275s;
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	display: block;
	width: 25px;
	height: 2px;
	background-color: ${getFg};
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	${p =>
		p.active &&
		`
	transform: translate3d(0, 10px, 0) rotate(135deg);
	transition-delay: 0.075s;
	`};
	&::before {
		width: 25px;
		height: 2px;
	}
	&::after {
		width: ${p => (p.active ? '25px' : '20px')};
		height: 2px;
		right: 0;
	}
	&::before,
	&::after {
		content: '';
		display: block;
		background-color: ${getFg};
		border-radius: 4px;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
	}
	&::before {
		top: 7px;
		transition: opacity 0.125s 0.275s ease;
		${p =>
			p.active &&
			`
		transition-delay: 0s;
		opacity: 0;
		`};
	}
	&::after {
		top: 14px;
		transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
		${p =>
			p.active &&
			`
		transform: translate3d(0, -15px, 0) rotate(-270deg);
		transition-delay: 0.075s;
		`};
	}
`

const Hamburger: React.FC<{
	readonly active: boolean
	readonly onClick: () => void
}> = ({active, onClick}) => (
	<div
		onClick={onClick}
		className="transition-filter font-inherit m-0 flex cursor-pointer flex-col items-center overflow-visible border-0 bg-transparent text-inherit normal-case transition-opacity duration-150 ease-linear"
	>
		<div className="relative inline-block h-5 w-10">
			<BurgerAndXIcon active={active} />
		</div>
		<div className="-mt-0.5 -ml-1.5 flex w-full items-center justify-center self-start text-sm font-light">
			{!active && _('menu')}
		</div>
	</div>
)

export default Hamburger
