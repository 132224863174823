import PropTypes from 'prop-types'
import React from 'react'
import {styled, getBg, getFg} from 'app/styles'
import {HtmlContent} from 'plugins/rich-text'
import Box from 'app/Components/Layout/Box'
import Promotions from 'app/Components/House/Promotions'

const SloganContent = styled(Box).attrs({
	volume: 1,
	self: true,
	colorSet: 'capLightGreenDarkGreen',
})`
	font-size: 1rem;
	font-style: italic;
	background-color: ${getBg};
	color: ${getFg};
	display: inline-block;
	max-width: 40em;
	padding: p {
		margin-bottom: 0;
	}
`

export const SloganBox = ({slogan, promotions}) => {
	return (
		<SloganContent column padding="1rem 1.5rem" gap="1rem">
			{slogan && (
				<div className="flex w-full flex-col items-center justify-center">
					<HtmlContent value={slogan} />
				</div>
			)}
			<Promotions value={promotions} />
		</SloganContent>
	)
}
SloganBox.propTypes = {
	slogan: PropTypes.object,
	promotions: PropTypes.array,
}

export default SloganBox
