import {createSlice} from '@reduxjs/toolkit'

interface MobileNavState {
	open: boolean
}

const initialState: MobileNavState = {
	open: false,
}

const mobileNavSlice = createSlice({
	name: 'mobileNav',
	initialState,
	reducers: {
		triggerSidebar(state) {
			state.open = !state.open
		},
	},
})

export const {triggerSidebar} = mobileNavSlice.actions

export default mobileNavSlice.reducer
