import React from 'react'
import PropTypes from 'prop-types'
import {Box} from 'app/Components/Layout'
import {SubmitButton, CancelButton} from 'app/Components/Buttons'
import {Prompt} from 'plugins/react-router-deluxe'
import {_, withI18n} from 'plugins/i18n'
import {makeSplitPoint} from 'plugins/react'
import {styled} from 'app/styles'
import type {GetFormState, ErrorSelector} from 'redux-form'

import {formValueSelector as _formValueSelector} from 'redux-form/es'
import {default as _formValues} from 'redux-form/es/formValues'
import {default as _getFormSyncErrors} from 'redux-form/es/getFormSyncErrors'
import {default as _isValid} from 'redux-form/es/isValid'
import {default as _reducer} from 'redux-form/es/reducer'
import {default as _actions} from 'redux-form/es/actions'
export const formValueSelector =
	_formValueSelector as (typeof import('redux-form'))['formValueSelector']
export const formValues =
	_formValues as (typeof import('redux-form'))['formValues']
// urgh types are broken in redux-form
export const getFormSyncErrors = <
	FormData = {},
	State = {},
	ErrorType = string,
>(
	formName: string,
	getFormState?: GetFormState
) =>
	(_getFormSyncErrors as ErrorSelector<FormData, State, ErrorType>)(
		formName,
		getFormState
	)
export const isValid = _isValid as import('redux-form').BooleanSelector
export const reducer = _reducer as (typeof import('redux-form'))['reducer']
export const actions =
	_actions as (typeof import('redux-form/lib/actions'))['default']

export const Field: import('redux-form').Field = makeSplitPoint(
	() => import('redux-form/es/Field')
)
export const Fields: import('redux-form').Fields = makeSplitPoint(
	() => import('redux-form/es/Fields')
)
const FormSectionOrig: import('redux-form').FormSection = makeSplitPoint(
	() => import('redux-form/es/FormSection')
)
export const FormSection = props => (
	// @ts-ignore
	<FormSectionOrig component={React.Fragment} {...props} />
)
export const FieldArray = makeSplitPoint(
	() => import('redux-form/es/FieldArray')
)

export const validators = {
	required: value => (value ? undefined : _('required')),
	email: value =>
		value && !/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i.test(value)
			? _('invalidEmail')
			: undefined,
	number: value =>
		value && Number.isNaN(Number(value)) ? _`valueIsNotNumber` : undefined,
	password: value => {
		if (value) {
			if (!/(?=.{8,})/i.test(value)) return _`passwordTooShort`
			if (!/(?=.*\d)/i.test(value)) return _`passwordRequiresDigit`
		}
	},
	url: value =>
		value &&
		!/^(?:https?:)?\/\/(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[01])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4])|(?:(?:[\da-z\u00A1-\uFFFF][\w\u00A1-\uFFFF-]{0,62})?[\da-z\u00A1-\uFFFF]\.)+[a-z\u00A1-\uFFFF]{2,}\.?)(?::\d{2,5})?(?:[#/?]\S*)?$/i.test(
			value
		)
			? _('invalidUrl')
			: undefined,
}

const ButtonsBar = styled(p => <Box {...p} />)`
	height: 4em;
	padding: 1em;
	${p =>
		p.theme.formButtonsBar === 'fixedBottom' &&
		`
			& > ${Box} {
				position: fixed;
				height: 4em;
				background: rgba(255, 255, 255, .9);
				box-shadow: 0 0 15px 0px rgba(0, 0, 0, .3);
				bottom: 0;
				left: 0;
				padding: .5em 0;
				margin: 0 auto;
				width: 100%;
				z-index: 10;
			}
	`};
	${p => p.theme.formButtonsBar === 'modal' && `position: relative;`};
	${p => p.theme.formButtonsBarCss};
`

export const ButtonSet = ({
	buttonTextCancel, // already in CancelButton
	buttonTextConfirm = _`save`,
	buttonTextRemove = _`remove`,
	buttonsSubmit,
	disabled,
	onClose,
	onConfirm,
	onRemove,
	pristine,
	submitDanger,
	...rest
}) => (
	<ButtonsBar>
		<Box spaceBetween={!!onRemove} right={!onRemove}>
			{onRemove && (
				<SubmitButton small danger onClick={onRemove}>
					{buttonTextRemove}
				</SubmitButton>
			)}
			<Box gap fitChildren>
				{onClose && (
					<CancelButton {...rest} onClick={onClose} autoFocus={submitDanger}>
						{buttonTextCancel}
					</CancelButton>
				)}
				<SubmitButton
					{...rest}
					accent={!pristine}
					danger={submitDanger}
					disabled={disabled}
					onClick={onConfirm}
					pristine={pristine}
					type={buttonsSubmit ? 'submit' : 'button'}
				>
					{buttonTextConfirm}
				</SubmitButton>
			</Box>
		</Box>
	</ButtonsBar>
)
ButtonSet.propTypes = {
	buttonTextCancel: PropTypes.node,
	buttonTextConfirm: PropTypes.node,
	buttonTextRemove: PropTypes.node,
	buttonsSubmit: PropTypes.bool,
	disabled: PropTypes.bool,
	onClose: PropTypes.func,
	onConfirm: PropTypes.func.isRequired,
	onRemove: PropTypes.func,
	pristine: PropTypes.bool,
	submitDanger: PropTypes.bool,
}

const FormView = <T,>({
	Buttons = ButtonSet,
	allowPristineSubmit,
	buttonProps,
	children,
	form: formId,
	handleSubmit: onConfirm,
	i18n: {getText},
	noButtons,
	onClose,
	onRemove,
	preventNavigation = true,
	pristine,
	withHtmlForm,
	className,
	destroy,
	reset,
	resetOnSubmit = false,
	invalid,
}: {
	readonly form: string
	readonly handleSubmit?: (value: T) => Promise<void>
	readonly Buttons?: React.ElementType
	readonly allowPristineSubmit?: boolean
	readonly buttonProps?: {[x: string]: any}
	readonly buttonsSubmit?: boolean
	readonly children: React.ReactNode
	readonly className?: string
	readonly i18n: I18nContext
	readonly noButtons?: boolean
	readonly onClose?: () => void
	readonly onRemove?: () => void
	readonly preventNavigation?: boolean
	readonly pristine?: boolean
	readonly resetOnSubmit?: boolean
	readonly withHtmlForm?: boolean
	readonly reset: () => void
	readonly destroy: () => void
	readonly invalid?: boolean
}) => {
	const handleSubmit =
		resetOnSubmit && onConfirm
			? data => {
					let out = onConfirm(data)
					if (invalid) return out
					if (out && typeof out.then === 'function') {
						out = out.then(result => {
							reset()
							return result
						})
					} else {
						reset()
					}
					return out
				}
			: onConfirm
	const handleClose = () => {
		destroy()
		if (onClose) onClose()
	}

	const buttons = !noButtons && Buttons && (
		<div className="flex w-full items-center justify-between">
			<Buttons
				{...{
					buttonsSubmit: withHtmlForm,
					disabled:
						(buttonProps && buttonProps.disabled) ||
						(!allowPristineSubmit && pristine),
					onConfirm: handleSubmit,
					onClose: handleClose,
					onRemove,
					pristine,
					...buttonProps,
				}}
			/>
		</div>
	)
	const props = {className} as any
	if (withHtmlForm) {
		props.as = 'form'
		props.name = formId
		props.onSubmit = handleSubmit
	}
	return (
		<Box column gap stretch {...props}>
			{preventNavigation && (
				<Prompt when={!pristine} message={getText('unsavedChangesWarning')} />
			)}
			{children}
			{buttons}
		</Box>
	)
}
const ConnectedForm: React.FunctionComponent<
	Omit<
		React.ComponentProps<typeof FormView>,
		'form' | 'i18n' | 'reset' | 'destroy'
	>
> = makeSplitPoint(() =>
	import('redux-form/es/reduxForm').then(
		m => m.default({})(withI18n(FormView)) as typeof FormView
	)
)

type FormProps<T> = Omit<
	import('redux-form').ConfigProps<T>,
	'form' | 'onChange'
> &
	Omit<React.ComponentProps<typeof ConnectedForm>, 'form'> & {
		value?: T
		onChange?: (value: T) => Promise<void> | void
		formId: string
	}
const Form = <T, P extends FormProps<T> = FormProps<T> & Record<string, any>>({
	value: initialValues,
	onChange: onSubmit,
	formId,
	...rest
}: P) => {
	// Allow loading new data in same form (listdetail etc)
	if (rest.enableReinitialize == null) rest.enableReinitialize = true
	// Throw away changes when new data
	if (rest.keepDirtyOnReinitialize == null) rest.keepDirtyOnReinitialize = false
	// Clear out form fields when not mounted
	if (rest.forceUnregisterOnUnmount == null)
		rest.forceUnregisterOnUnmount = true
	// Clear out form data when not mounted
	if (rest.destroyOnUnmount == null) rest.destroyOnUnmount = true

	return (
		<ConnectedForm
			{...{
				initialValues,
				form: formId,
				onSubmit,
				...rest,
			}}
		/>
	)
}

export default Form
