import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import OrigSelectInput from './SelectInput'
import Icon, {glyphs} from 'app/Components/Icon'
import {Box} from 'app/Components/Layout'
import styled from 'styled-components'

const SelectInput = styled(OrigSelectInput)`
	.Select-multi-value-wrapper {
		display: flex;
	}
`

const IconValue = ({value, ...rest}) => (
	<Box left gap {...rest}>
		<Icon glyph={glyphs[value.value]} />
		<span>{value.label}</span>
	</Box>
)
IconValue.propTypes = {
	value: PropTypes.object,
}

class IconOption extends PureComponent {
	static propTypes = {
		onSelect: PropTypes.func,
		onFocus: PropTypes.func,
		isFocused: PropTypes.bool,
		option: PropTypes.object,
	}

	handleMouseDown = e => {
		const {onSelect, option} = this.props
		e.preventDefault()
		e.stopPropagation()
		onSelect(option, e)
	}

	handleMouseEnter = e => {
		const {onFocus, option} = this.props
		onFocus(option, e)
	}

	handleMouseMove = e => {
		const {isFocused, onFocus, option} = this.props
		if (isFocused) {
			return
		}
		onFocus(option, e)
	}

	render() {
		const {option, onSelect, onFocus, isFocused, ...rest} = this.props
		return (
			<IconValue
				onMouseDown={this.handleMouseDown}
				onMouseEnter={this.handleMouseEnter}
				onMouseMove={this.handleMouseMove}
				value={option}
				{...rest}
			/>
		)
	}
}

const IconInput = props => {
	return (
		<SelectInput
			optionComponent={IconOption}
			valueComponent={IconValue}
			{...props}
		/>
	)
}
IconInput.propTypes = {
	label: PropTypes.node,
	error: PropTypes.node,
}
export default IconInput
