import {makeSplitPoint} from 'plugins/react'
import Value from './ImageSection'

export default {
	label: 'Image',
	group: 'content',
	Value,
	defaultValue: {
		imageId: null,
		titleI18n: null,
		height: '17em',
		mobileHeight: '9em',
	},
	defaultTheme: 'capDarkTheme',
	Form: makeSplitPoint(() => import('./ImageForm'), {ssr: false}),
}
