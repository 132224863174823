import {glyphs, RisingArrowIcon, SearchIcon, XIcon} from 'app/Components/Icon'
import {useSearch} from 'app/hooks'
import {cn} from 'app/lib'
import {useI18n, _, LangLink} from 'plugins/i18n'
import React, {useEffect, useRef, useState} from 'react'

const SidebarContent = ({open, closeSidebar}) => {
	const {handleChange, handleSearch, houseName} = useSearch()
	const inputRef = useRef<HTMLInputElement>(null)
	const {getText} = useI18n()

	useEffect(() => {
		if (open && inputRef.current) {
			inputRef.current.focus()
		}
	}, [open])

	return (
		<div
			style={{
				clipPath: 'polygon(1.5em 1.5em, 100% 1.5em, 100% 100%, 0% 100%, 0% 0%)',
			}}
			className={cn(
				'bg-secondary text-onSecondary fixed top-[4.9em] left-0 h-[calc(100vh-4em)] w-screen transform p-8 pt-4 transition-transform duration-500 ease-in-out',
				open ? '-z-10 translate-x-0' : 'z-0 -translate-x-full'
			)}
		>
			<div className="flex w-full flex-col space-y-10 py-10">
				<div>
					<h1 className="my-2 leading-[24px] font-bold">{_`searchByNameRegionLocation`}</h1>
					<label className="input input-bordered mb-[10px] flex h-10 items-center gap-2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 16 16"
							fill="currentColor"
							className="h-4 w-4 opacity-70"
						>
							<path
								fillRule="evenodd"
								d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
								clipRule="evenodd"
							/>
						</svg>
						<input
							ref={inputRef}
							onChange={e => handleChange(e.target.value)}
							value={houseName}
							type="text"
							className="grow text-black"
							placeholder={getText('a$startTyping')}
						/>
					</label>
					<button
						className="bg-primary text-onPrimary w-full rounded-md p-2"
						type="button"
						onClick={() => {
							handleSearch()
							closeSidebar()
						}}
					>
						{_`search`}
					</button>
				</div>
				<div className="space-y-2">
					<h2 className="leading-[24px] font-bold">{_`popularSearches`}</h2>
					<ul className="space-y-1 text-sm leading-5">
						<li>
							<LangLink to="/xx/search?houseCapacity=6&region=!c*SFR">
								<div className="flex flex-row items-center space-x-1">
									<RisingArrowIcon widht="1em" height="0.643em" />
									{_`a$southFrFor6People`}
								</div>
							</LangLink>
						</li>
						<li>
							<LangLink to="/xx/search?features=(ft*Spool&region=!">
								<div className="flex flex-row items-center space-x-1">
									<RisingArrowIcon widht="1em" height="0.643em" />
									{_`stayWithPool`}
								</div>
							</LangLink>
						</li>
						<li>
							<LangLink to="/xx/search?specials=(sp-pets">
								<div className="flex flex-row items-center space-x-1">
									<RisingArrowIcon widht="1em" height="0.643em" />
									{_`eml$petsAllowed`}
								</div>
							</LangLink>
						</li>
					</ul>
				</div>
				<div className="space-y-2">
					<h2 className="leading-[24px] font-bold">{_`popularDestinations`}</h2>
					<ul className="space-y-1 text-sm leading-5">
						<li>
							<LangLink to="/xx/algarve-country-house">
								<div className="flex flex-row items-center space-x-1">
									<RisingArrowIcon widht="1em" height="0.643em" />
									<span>Algarve, {_`c$PT`}</span>
								</div>
							</LangLink>
						</li>
						<li>
							<LangLink to="/xx/costa-blanca-country-house">
								<div className="flex flex-row items-center space-x-1">
									<RisingArrowIcon widht="1em" height="0.643em" />
									<span>Costa Blanca, {_`c$ES`}</span>
								</div>
							</LangLink>
						</li>
						<li>
							<LangLink to="/xx/piemonte-vakantiehuis">
								<div className="flex flex-row items-center space-x-1">
									<RisingArrowIcon widht="1em" height="0.643em" />
									<span>Piemonte, {_`c$IT`}</span>
								</div>
							</LangLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

const FlipTransitionWrapper = ({isOpen, children}) => {
	const flipStyles = {
		transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
		transform: isOpen ? 'rotateY(360deg)' : 'rotateY(0deg)',
		opacity: isOpen ? 1 : 1,
		display: 'inline-block',
	}

	return <div style={flipStyles}>{children}</div>
}

const MobileSearch = () => {
	const [open, setOpen] = useState(false)

	return (
		<div className="flex min-w-8 items-center">
			<FlipTransitionWrapper isOpen={open}>
				{open ? (
					<XIcon onClick={() => setOpen(false)} />
				) : (
					<SearchIcon
						className="cursor-pointer"
						onClick={() => setOpen(prev => !prev)}
						glyph={glyphs.search}
						size="1.7rem"
					/>
				)}
			</FlipTransitionWrapper>
			<SidebarContent open={open} closeSidebar={() => setOpen(false)} />
		</div>
	)
}
export default MobileSearch
