import React from 'react'
import {makeSplitPoint} from 'plugins/react'
import {InputField} from './makeField'
// TODO get rid of these imports for Fields
import {default as CheckboxInput} from './CheckboxInput'
import {I18nTextInput, I18nHtmlInput, SectionSelect} from 'plugins/i18n'
import {default as IconInput} from './IconInput'
import {default as SelectInput} from './SelectInput'
import {default as TextareaInput} from './TextareaInput'
import {default as TextInput} from './TextInput'
import {UploadInput, UploadZoneInput} from 'plugins/uploads'

export {default as makeField, InputField} from './makeField'
export {default as AddressForm} from './AddressForm'
export {default as AddTextInput} from './AddTextInput'
export {default as ArrayField} from './ArrayField'
export {default as ArrayInput} from './ArrayInput'
export {default as BankField} from './BankField'
export {default as CalendarInput} from './CalendarInput'
export {default as CheckboxInput} from './CheckboxInput'
export {default as BrowserDateInput} from './BrowserDateInput'
export {default as CommInput} from './CommInput'
export {default as CountryInput} from './CountryInput'
export {default as DateInput} from './DateInput'
export {
	I18nInput,
	I18nTextInput,
	I18nTextareaInput,
	I18nHtmlInput,
	SectionSelect,
} from 'plugins/i18n'
export {default as I18nMetaForm} from './I18nMetaForm'
export {default as IconInput} from './IconInput'
export {default as ImgInput} from './ImgInput'
export {default as InputLayout} from './InputLayout'
export {default as NameField} from './NameField'
export {default as NumberInput, default as NumericInput} from './NumberInput'
// Wait until https://github.com/vlad-ignatov/react-numeric-input/issues/94 resolved
// export {default as NumericInput} from './NumericInput'
export {default as PasswordInput} from './PasswordInput'
export {default as PushButtonInput, PushButtonGroup} from './PushButtonInput'
export {default as RadioInput} from './RadioInput'
export {default as RatingInput} from './RatingInput'
export {default as SelectInput} from './SelectInput'
export {default as TagInput} from './TagInput'
export {default as TextareaInput} from './TextareaInput'
export {default as TextInput} from './TextInput'
export {default as LangSelectInput} from './LangSelectInput'
export {UploadInput, UploadZoneInput} from 'plugins/uploads'

export const HtmlInput = makeSplitPoint(
	() => import('plugins/rich-text/HtmlInput'),
	{ssr: false}
)
export const BigCalendarInput = makeSplitPoint(
	() => import('./BigCalendarInput')
)

export const ImagesInput = makeSplitPoint(() => import('./ImagesInput'), {
	ssr: false,
})

// TODO get rid of these fields
export const CheckboxField = props => (
	<InputField {...props} Input={CheckboxInput} />
)
export const I18nHtmlField = props => (
	<InputField {...props} Input={I18nHtmlInput} />
)
export const I18nTextField = props => (
	<InputField {...props} Input={I18nTextInput} />
)
export const IconField = props => <InputField {...props} Input={IconInput} />
export const SectionSelectField = props => (
	<InputField {...props} Input={SectionSelect} />
)
export const SelectField = props => (
	<InputField {...props} Input={SelectInput} />
)
export const TextField = props => <InputField {...props} Input={TextInput} />
export const TextareaField = props => (
	<InputField {...props} Input={TextareaInput} />
)
export const UploadField = props => (
	<InputField {...props} Input={UploadInput} />
)
export const UploadZoneField = props => (
	<InputField {...props} Input={UploadZoneInput} />
)
