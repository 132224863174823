import React from 'react'
import Description from './Description'
import Features from './Features'
import {Preview} from '.'
import ThumbnailLabel from './ThumbnailLabel'

export const NewOrPromo = ({house, label, preventRedirect}) => {
	const {
		bathroomCount,
		bedroomCount,
		capacity,
		description,
		name,
		type,
		regionId,
		placeName,
		isNew,
		isPromo,
	} = house

	const houseFeatures = [
		{value: capacity, icon: 'peopleNew'},
		{value: bedroomCount, icon: 'bedNew'},
		{value: bathroomCount, icon: 'bathNew'},
	]

	return (
		<Preview {...{house, column: true, ratio: 1.33, preventRedirect}}>
			<Description
				{...{
					description,
					fontResize: 0.8,
					name,
					type,
					regionId,
					placeName,
					bottom: <Features small noBorder {...{houseFeatures, row: true}} />,
				}}
			/>
			{label && (isNew || isPromo) && <ThumbnailLabel isNew={isNew} />}
		</Preview>
	)
}

export default NewOrPromo
