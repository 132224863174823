// Precedence of language selection: URL > cookie > browser language
import React, {PureComponent, useMemo} from 'react'
import PropTypes from 'prop-types'
import {gql} from 'graphql-tag'
import {langs} from './lib'
import {Helmet} from 'plugins/react-helmet'
import I18nEditModal from './I18nEditModalAsync'
import {Query} from 'plugins/apollo'
import {I18nContext} from './I18nContext'
import {calcI18n} from './calcI18n'
import {pick} from 'in-browser-language'
import {isBrowser} from 'stratokit/build'
import Cookies from 'js-cookie'

const readLangCookie = (): string | undefined => {
	try {
		return Cookies.get('lang')
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to read cookie:', error)
		return undefined
	}
}
const setLangCookie = lang => {
	try {
		Cookies.set('lang', lang, {path: '/', sameSite: 'Strict'})
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to set cookie:', error)
	}
}

// For now, get all translations in one go, only 8KB gzipped
export const allTranslations = [
	gql`
		query allTranslations($lang: String) {
			allTranslations(lang: $lang)
		}
	`,
	{skip: ({lang}) => !lang},
]

const I18nProviderBase = props => {
	const {lang, loading, allTranslations, isEditing, edit} = props
	const i18n = useMemo(
		() => calcI18n(props),
		[lang, loading, allTranslations, isEditing, edit]
	)
	let modal
	if (isEditing) {
		const {MetaForm, metaFormProps, chooseTr} = props
		modal = (
			<I18nEditModal
				edit={edit}
				chooseTr={chooseTr}
				MetaForm={MetaForm}
				metaFormProps={metaFormProps}
			/>
		)
	}
	return (
		<I18nContext.Provider value={i18n}>
			<>
				{props.children}
				{modal}
			</>
		</I18nContext.Provider>
	)
}

I18nProviderBase.propTypes = {
	children: PropTypes.any,
	MetaForm: PropTypes.elementType,
	metaFormProps: PropTypes.any,
	chooseTr: PropTypes.func,
	edit: PropTypes.any,
	isEditing: PropTypes.bool,
	allTranslations: PropTypes.array,
	loading: PropTypes.bool,
	lang: PropTypes.string,
}

class I18nProvider extends PureComponent {
	static propTypes = {
		children: PropTypes.any,
		lang: PropTypes.string,
		onLang: PropTypes.func,
		MetaForm: PropTypes.elementType,
		metaFormProps: PropTypes.any,
		addMissing: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.toggleEdit = this.toggleEdit.bind(this)
		let lang
		if (isBrowser) {
			// hacky sidechannel via i18n/initClient
			lang = globalThis.ssrLang
			if (!lang) lang = readLangCookie()
			if (!lang) lang = props.lang
			if (!lang) lang = pick(langs)
		} else lang = props.lang

		if (!lang || !langs.includes(lang)) lang = langs[0]

		// eslint-disable-next-line react/state-in-constructor
		this.state = {lang, edit: {}, isEditing: false}

		if (isBrowser) setLangCookie(lang)

		const {onLang} = this.props
		if (onLang) onLang(lang)
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyDown)
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown)
	}
	handleKeyDown = event => {
		if (event.ctrlKey && event.shiftKey && event.key === 'L') {
			this.toggleEdit()
		}
	}

	componentDidUpdate(prev) {
		if (prev.lang !== this.props.lang) {
			this.setLang(this.props.lang)
		}
	}

	setLang = lang => {
		// Do some debouncing on the language setting
		if (lang === this.state.lang) return
		if (this.settingLang === lang) return
		if (!langs.includes(lang)) return

		this.settingLang = lang
		// We can be called whenever, so change state later
		clearTimeout(this.changeLangTimer)
		this.changeLangTimer = setTimeout(() => {
			this.settingLang = null
			// set state at same time as resetStore so they don't interfere
			this.setState({lang})

			// hack: we steal the client from the Query component result
			if (this.apolloClient) this.apolloClient.resetStore()
		}, 0)

		if (isBrowser) setLangCookie(lang)

		const {onLang} = this.props
		if (onLang) onLang(lang)
	}

	chooseTr = ({section, trKey} = {}) => {
		const edit = section && trKey ? {section, trKey} : {}
		this.setState({edit})
	}

	toggleEdit() {
		this.setState(({isEditing}) => ({isEditing: !isEditing}))
	}

	render() {
		const {lang} = this.state
		const {setLang, toggleEdit, chooseTr} = this
		const baseProps = {
			...this.props,
			...this.state,
			setLang,
			toggleEdit,
			chooseTr,
		}
		return (
			<>
				<Helmet>
					<html lang={lang} />
				</Helmet>
				<Query query={allTranslations[0]} variables={{lang}} skip={!lang}>
					{({data, loading, client}) => {
						// hack: prevent import loop; we can't use hooks yet
						this.apolloClient = client
						return (
							<I18nProviderBase
								{...baseProps}
								loading={loading || !lang}
								allTranslations={data && data.allTranslations}
							/>
						)
					}}
				</Query>
			</>
		)
	}
}

export default I18nProvider
