import React from 'react'
import {Helmet} from 'plugins/react-helmet'
import {interpolate} from 'app/_server/database/outbox/helpers'
import {useI18n} from 'plugins/i18n'

const Dt = ({children}) => <dt className="font-bold">{children}</dt>
const Dd = ({children}) => <dd className="ml-4">{children}</dd>

const SeoSection = ({
	value = {
		title: '',
		description: '',
		keywords: [],
	},
	inEditor,
	templateData = {
		interpolationValues: {},
	},
}: {
	readonly value?: {
		title?: string
		description?: string
		keywords?: string[]
		titleI18n?: {[key: string]: string}
		titlePrefixI18n?: {[key: string]: string}
		titleSuffixI18n?: {[key: string]: string}
		descriptionI18n?: {[key: string]: string}
		keywordsI18n?: {[key: string]: string}
	}
	readonly inEditor?: boolean
	readonly templateData?: {
		interpolationValues?: {[key: string]: string}
	}
}) => {
	const {lang} = useI18n()
	const {interpolationValues} = templateData

	const title =
		typeof value.titleI18n === 'object'
			? value.titleI18n && value.titleI18n[lang]
				? value.titleI18n[lang]
				: ''
			: value.titleI18n

	const titlePrefix =
		value.titlePrefixI18n && value.titlePrefixI18n[lang]
			? value.titlePrefixI18n[lang]
			: ''

	const titleSuffix =
		value.titleSuffixI18n && value.titleSuffixI18n[lang]
			? value.titleSuffixI18n[lang]
			: ''

	let description =
		typeof value.descriptionI18n === 'object'
			? value.descriptionI18n && value.descriptionI18n[lang]
				? value.descriptionI18n[lang]
				: ''
			: value.descriptionI18n

	if (interpolationValues) {
		description = interpolate(description, interpolationValues)
	}

	const keywords =
		value.keywordsI18n && value.keywordsI18n[lang]
			? value.keywordsI18n[lang]
			: ''

	const finalTitle = `${titlePrefix}${title}${titleSuffix}`

	return (
		<>
			<Helmet>
				<title>{finalTitle}</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={keywords} />
				<meta property="og:title" content={finalTitle} />
				<meta property="og:description" content={description} />
			</Helmet>
			{inEditor && (
				<dl>
					<Dt>title</Dt>
					<Dd>{finalTitle}</Dd>
					<Dt>description</Dt>
					<Dd>{description}</Dd>
					<Dt>keywords</Dt>
					<Dd>{keywords}</Dd>
				</dl>
			)}
		</>
	)
}
export default SeoSection
