import PropTypes from 'prop-types'
import React from 'react'
import {HeadBox} from 'app/Components/Layout/Display'
import {_} from 'plugins/i18n'

const DomainTitle = ({title, regionId, fontResize}) => {
	const hBoxLvls = {
		title: 1,
		region: 3,
	}
	return (
		<>
			{/* a wrapper needed because of noMargin which removes margin set up by the parent */}
			<div>
				<HeadBox noMargin lvl={hBoxLvls.title} resize={fontResize}>
					{title}
				</HeadBox>
			</div>
			<div>
				<HeadBox noMargin lvl={hBoxLvls.region} resize={fontResize}>
					{regionId !== 'r$unknown' && _(regionId)}
				</HeadBox>
			</div>
		</>
	)
}
DomainTitle.propTypes = {
	title: PropTypes.string,
	regionId: PropTypes.string,
	fontResize: PropTypes.number,
}

export default DomainTitle
