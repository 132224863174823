import React, {Fragment} from 'react'
import Box from 'app/Components/Layout/Box'
import styled from 'styled-components'
import {getBg, getFg, CapBrownTheme, useResponsive} from 'app/styles'
import ShareButton from 'app/Components/Buttons/ShareButton'
import {withRouter} from 'plugins/react-router-deluxe'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {LangLink, _, useI18n} from 'plugins/i18n'
import {makeUrl} from 'plugins/images'
import {clearSearch as clearSearchFn} from 'state/ducks/searchRentals-duck'
import {searchBasePath} from 'app/lib'

const Wrapper = styled(({narrowRight, ...p}) => <Box {...p} />)`
	height: 3em;
	background-color: ${getBg};
	color: ${getFg};
	font-size: 12px;
	${p => p.narrowRight && 'padding-right: 30em;'};
`

const Separator = styled.span`
	margin: 0 0.5em;
`

const Breadcrumbs = ({
	value,
	location: {pathname, search},
	templateData: {metaData = {}, templateId} = {},
	clearSearch,
}) => {
	const {isMobile} = useResponsive('isMobile')
	const i18n = useI18n()
	const {lang, getTrSlug} = i18n
	const {breadcrumbs = [], imageId} = value
	const lastParam = pathname.split('/').at(-1)
	const defaultImagePath =
		imageId &&
		makeUrl(
			{id: imageId},
			{
				mid: true,
				isServer: true,
			}
		)
	const defaultImageUrl =
		defaultImagePath &&
		`${
			globalThis.location
				? globalThis.location.origin
				: `https://${__CONFIG__.hostname}`
		}${defaultImagePath}`

	return (
		<CapBrownTheme>
			<Wrapper
				spaceBetween={!isMobile}
				padding="0 2.5rem"
				narrowRight={!isMobile && templateId === 'houseInfoTop'}
			>
				{!isMobile && (
					<div className="flex w-auto items-center justify-center">
						<span>
							{_`youAreHere`}
							:&nbsp;
						</span>
						{breadcrumbs.map((breadcrumb, i) => {
							if (breadcrumb.labelI18n?.[lang]) {
								return (
									<Fragment key={i}>
										<LangLink to={searchBasePath(i18n)} onClick={clearSearch}>
											{breadcrumb.labelI18n[lang]}
										</LangLink>
										{i + 1 < breadcrumbs.length && <Separator>&gt;</Separator>}
									</Fragment>
								)
							}
							return false
						})}
						{lastParam !== getTrSlug('regionUrl') && (
							<>
								<Separator>&gt;</Separator>
								<LangLink to={`${pathname}${search}`}>{lastParam}</LangLink>
							</>
						)}
					</div>
				)}
				<ShareButton imageUrl={metaData.imageUrl || defaultImageUrl} />
			</Wrapper>
		</CapBrownTheme>
	)
}

Breadcrumbs.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string,
	}),
	value: PropTypes.shape({
		breadcrumb: PropTypes.array,
	}),
	templateData: PropTypes.object,
}

export default withRouter(
	connect(null, {
		clearSearch: clearSearchFn,
	})(Breadcrumbs)
)
