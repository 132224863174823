import React from 'react'
import {styled, lightBeigeColor, mediumGreyColor, capDarkGrey} from 'app/styles'
import PropTypes from 'prop-types'
import TextCarousel from 'app/Components/Carousels/TextCarousel'
import TextTruncate from 'react-text-truncate'
import {QuoteIcon} from 'app/Components/Icon'
import Box from 'app/Components/Layout/Box'
import {useI18n} from 'plugins/i18n'

const Carousel = styled(TextCarousel)`
	height: 6rem;
	width: 100%;
	padding: 0.5rem;
`
const QuoteMark = styled(Box)`
	position: absolute;
	color: ${lightBeigeColor};
	z-index: -1;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
`
const Text = styled(TextTruncate)`
	text-align: center;
	font-style: italic;
	color: ${mediumGreyColor};
`
const Name = styled.span`
	color: ${capDarkGrey};
`

const switchTab = () => {
	// Quick fix - prone to id changes
	document.querySelector('.reviewsTabSelector').checked = true
}

const ReviewsCarousel = ({value: reviews}) => {
	const {lang} = useI18n()
	if (!reviews || !reviews.length) return null
	const content = reviews
		.filter(r => r.textI18n?.[lang] && r.name)
		.map((review, i) => {
			return (
				<div
					key={i}
					onClick={switchTab}
					className="flex h-24 w-full items-center justify-center"
				>
					<a href={`#review-${i}`} style={{display: 'block', width: '100%'}}>
						<div className="relative flex w-full items-center justify-center">
							<QuoteMark fitChildren>
								<QuoteIcon size="4rem" />
							</QuoteMark>
							<div className="flex w-full items-center justify-center gap-2">
								<Text line={2} truncateText="…" text={review.text} />
								<div className="flex w-full items-center justify-end">
									<Name>{review.name}</Name>
								</div>
							</div>
						</div>
					</a>
				</div>
			)
		})

	return <Carousel content={content} />
}
ReviewsCarousel.propTypes = {
	value: PropTypes.array,
}

export default ReviewsCarousel
