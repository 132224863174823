import React from 'react'
import {
	InputField,
	TextInput,
	SelectInput,
	IconInput,
	NumericInput,
	CheckboxInput,
	SectionSelect,
	UploadZoneInput,
	ArrayField,
	I18nTextInput,
	CountryInput,
} from 'app/Components/Inputs'
import {glyphs} from 'app/Components/Icon'
import {Button} from 'app/Components/Buttons'
import {_, I18nHtmlInput} from 'plugins/i18n'
import {useQuery} from 'plugins/apollo'
import gql from 'graphql-tag'
import Loading from '../Loading'
import {useGetAllTranslations} from 'plugins/i18n/graphql/queries'

const houseTypesQuery = gql`
	query {
		houseTypes
	}
`

const AddButton = props => (
	<div className="flex w-full items-center justify-start">
		<Button {...props}>{_`addSuggestion`}</Button>
	</div>
)

const HouseTypeDesc = () => {
	const {data, loading} = useQuery(houseTypesQuery)
	if (loading || !data.houseTypes) return <Loading />
	const {houseTypes} = data || {}
	return (
		<div className="flex w-full flex-col items-start justify-center gap-4">
			<h2>House type descriptions:</h2>
			<ArrayField name="houseTypeDescs" max={20}>
				<>
					<InputField
						Input={SelectInput}
						options={houseTypes.map(houseType => ({
							value: houseType,
							label: _`${houseType}`,
						}))}
						label={_`HouseType`}
						name="houseType"
					/>
					)
					<InputField
						Input={I18nHtmlInput}
						name="textI18n"
						label={_`HouseTypeDescription`}
					/>
				</>
			</ArrayField>
		</div>
	)
}

const AssignToFeatureGroup = () => {
	const {data, loading} = useGetAllTranslations()
	if (!data?.translations || loading) return <Loading />

	const fGroups = []
	for (const o of data.translations) {
		if (o.section === 'FEATUREGROUP') {
			fGroups.push(o.id)
		}
	}

	return (
		<InputField
			Input={SelectInput}
			name="featureGroup"
			label={_('featureGroup')}
			optionValues={fGroups}
		/>
	)
}

const I18nMetaForm: React.FC<{readonly section: I18nSection}> = ({section}) => {
	return (
		<div className="flex w-full flex-col items-start justify-center gap-4">
			{section === 'BUILDINGS' && (
				<>
					<InputField Input={NumericInput} name="people" label={_('people')} />
					<InputField
						Input={CheckboxInput}
						name="isBathroom"
						label={_('isBathroom')}
					/>
					<InputField
						Input={CheckboxInput}
						name="isBedroom"
						label={_('isBedroom')}
					/>
					<InputField
						Input={IconInput}
						name="icon"
						optionValues={Object.keys(glyphs)}
						label={_('icon')}
					/>
					<InputField
						Input={SectionSelect}
						inputProps={{multi: true}}
						section="FEATURE"
						name="featureIds"
						label={_('facilitySearch')}
					/>
				</>
			)}
			{section === 'FEATURE' ? (
				<>
					<InputField
						Input={IconInput}
						name="icon"
						optionValues={Object.keys(glyphs)}
						label={_('icon')}
					/>
					<AssignToFeatureGroup />{' '}
				</>
			) : null}
			{section === 'REGION' && (
				<>
					<div className="my-4 flex w-full flex-col items-start justify-center">
						<InputField
							label="Main Image"
							Input={UploadZoneInput}
							name="imageId"
							labelOnTop
						/>
						<InputField Input={CountryInput} name="countryId" />
						<InputField
							Input={CheckboxInput}
							name="isPopular"
							label={_('isPopularDestinationField')}
						/>
						<InputField Input={I18nHtmlInput} name="regionDescI18n" />
					</div>
					<HouseTypeDesc />
					<div className="my-12 flex w-full items-center justify-center">
						<ArrayField
							label="Suggestions"
							name="suggestions"
							labelOnTop
							buttonBelow
							max={20}
							AddButton={AddButton}
						>
							<div className="my-4 flex w-full flex-col items-center justify-center gap-4">
								<InputField
									label="Suggestion Image"
									Input={UploadZoneInput}
									name="imageId"
								/>
								<InputField Input={I18nTextInput} name="titleI18n" />
								<InputField Input={I18nHtmlInput} name="descI18n" />
								<InputField Input={I18nTextInput} name="linkI18n" />
							</div>
						</ArrayField>
					</div>
				</>
			)}
			{section === 'COUNTRY' && (
				<>
					<InputField
						Input={I18nTextInput}
						name="countryTitleI18n"
						label={_`titleForCountry`}
					/>
					<InputField
						Input={I18nHtmlInput}
						name="countryDescI18n"
						label={_`descriptionForCountry`}
					/>
					<HouseTypeDesc />
				</>
			)}
			{section !== 'REGION' && section !== 'COUNTRY' && (
				<>
					<InputField Input={TextInput} name="title" label={_('title')} />
					<InputField
						Input={TextInput}
						name="description"
						label={_('description')}
					/>
					<InputField
						Input={SelectInput}
						multi
						creatable
						name="keywords"
						label={_('keywords')}
					/>
				</>
			)}
		</div>
	)
}

export default I18nMetaForm
