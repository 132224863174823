import React from 'react'
import {styled, getFg, getBg, getHint} from 'app/styles'
import {CloseIcon} from 'app/Components/Icon'
import Box from 'app/Components/Layout/Box'
import {Button} from 'app/Components/Buttons'
import {default as FormOrig, ButtonSet} from 'app/Components/Form'

const Background = styled(Box)`
	background-color: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	padding: 2em;

	// Win the stacking context contest in a template
	z-index: 1000;

	${p => p.theme.modalBackgroundCss};
`

const ModalWindow = styled(Box).attrs(p => ({
	column: true,
	maxWidth: '90%',
	width: p.width || 'auto',
}))`
	color: ${p => getFg(p, 'modal')};
	background-color: ${p => getBg(p, 'modal')};

	${p => p.height && `height: ${p.height}`};
	max-height: 100%;

	width: ${p => p.width};
	@media screen and (max-width: '50em') {
		width: 90%;
	}

	border-radius: 5px;

	${p => p.theme.modalWindowCss};
`
const ModalHeader = styled(Box)`
	flex: 0;
	padding: 1em;
	border-bottom: thin solid ${p => getHint(p, 'modal')};
	${p => p.theme.modalHeaderCss};
`
const Form = styled(FormOrig)`
	height: 100%;
	overflow-y: auto;
`
const ModalBody = styled.div`
	flex: 1 1 auto;
	padding: 2em 1em;
	line-height: 1.6;
	height: 100%;
	width: 100%;

	overflow-y: auto;
	// Don't scroll the rest of the page on scroll
	overscroll-behavior: contain;

	${p => p.theme.modalBodyCss};
`

export const DefaultBody = ({
	modalProps,
	body,
}: {
	readonly modalProps: any
	readonly body: React.ReactNode
}) => <span>{body || modalProps.body}</span>

const DefaultTitle: React.FC<{readonly title: string}> = ({title}) => (
	<h2>{title}</h2>
)

interface ModalProps {
	readonly Title?: React.ElementType
	readonly Body: React.ElementType
	readonly bodyProps?: Record<string, any>
	readonly buttonProps?: Record<string, any>
	readonly Buttons?: React.ElementType
	readonly noButtons?: boolean
	readonly buttonTextCancel?: React.ReactNode
	readonly buttonTextConfirm?: React.ReactNode
	readonly danger?: boolean
	readonly width?: string
	readonly height?: string
	readonly formId?: string
	readonly formProps?: Record<string, any>
	readonly onChange?: (confirmed: boolean) => void
	readonly onClose?: () => void
	readonly confirmOnly?: boolean
}

const Modal: React.FC<ModalProps> = ({
	Title = DefaultTitle,
	Body = DefaultBody,
	bodyProps,
	buttonProps,
	Buttons = ButtonSet,
	noButtons,
	buttonTextCancel,
	buttonTextConfirm,
	danger: submitDanger,
	width,
	height,
	formId,
	formProps: baseFormProps,
	onSubmit,
	onClose,
	confirmOnly,
}) => {
	const handleConfirm = () => {
		onSubmit?.(true)
	}
	const handleSubmit = v => {
		onSubmit?.(v)
	}

	const formProps = formId
		? {
				noButtons,
				...baseFormProps,
				...(bodyProps && bodyProps.formProps),
				formId,
				onSubmit,
				onClose,
			}
		: null

	const title = <Title {...bodyProps} />

	const body = (
		<ModalBody>
			<Body
				onChange={handleSubmit}
				onClose={onClose}
				modalProps={{...bodyProps}}
				{...bodyProps}
			/>
		</ModalBody>
	)

	return (
		<Background onClick={onClose}>
			<ModalWindow
				width={width}
				height={height}
				onClick={(ev: MouseEvent) => ev.stopPropagation()}
			>
				<ModalHeader spaceBetween gap>
					<div>{title}</div>
					{onClose && (
						<Button small secondary onClick={onClose}>
							<CloseIcon />
						</Button>
					)}
				</ModalHeader>

				{formProps ? (
					<Form
						withHtmlForm
						{...formProps}
						buttonProps={{
							submitDanger,
							buttonTextConfirm,
							buttonTextCancel,
							...formProps?.buttonProps,
							...buttonProps,
						}}
						onChange={handleSubmit}
						onClose={confirmOnly ? undefined : onClose}
					>
						{body}
					</Form>
				) : (
					<>
						{body}
						{!noButtons && Buttons && (
							<Buttons
								submitDanger={submitDanger}
								buttonTextConfirm={buttonTextConfirm}
								buttonTextCancel={buttonTextCancel}
								{...buttonProps}
								onConfirm={handleConfirm}
								onClose={confirmOnly ? undefined : onClose}
							/>
						)}
					</>
				)}
			</ModalWindow>
		</Background>
	)
}

export default Modal
