import logTimes from './logTimes'
import {AsyncHook} from '../tapable'
import plugins from 'app/clientPlugins'
import {isDev, isServer} from 'stratokit/build'

if (isServer) {
	// Just making sure
	throw new Error(
		'This is the browser entry point and should not have been loaded!!!'
	)
}
// workaround for some old libraries
window.global = globalThis

if (!isDev) {
	/* safari 10 nomodule fix CC0-1.0
	this snippet needs to be at the beginning of the 
	<script type="module" src="main-as-module.js"> and associated
	<script nomodule src="main-as-script.js">
	by throwing an error early the rest of the code is not executed
	but only downloaded and compiled for Safari 10
	assumption main-as-module.js and main-as-script.js
	are both built from the same source

	advantage is that there is no js file that needs to be inlined
	at the top of the html

	issue: does not work with multiple indepent files without renaming the global used
	
	conclusion: worse for Safari10 better for everyone else
	*/
	if (globalThis.MAIN_EXECUTED) {
		throw new Error('warning main.js already executed - Safari v10 issue?')
	}
	globalThis.MAIN_EXECUTED = true
}

const init = async () => {
	const hooks = {
		load: new AsyncHook([], 'load'),
		start: new AsyncHook([], 'start'),
	}

	globalThis.baseUrl = window.location.origin
	globalThis.hostname = window.location.host

	for (const plugin of plugins) {
		const fn = 'then' in plugin ? await plugin : plugin
		if (fn) (fn.default || fn)(hooks)
	}
	logTimes('plugins loaded')

	if (!globalThis._clientInited) {
		globalThis._clientInited = true
		logTimes('client load')
		await hooks.load.seriesAsync()
	}

	await hooks.start.seriesAsync()
}

export default init()
