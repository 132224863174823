import React from 'react'
import PropTypes from 'prop-types'
import {gtag} from 'plugins/trackers'
import {styled} from 'app/styles'
import {Tabs, Pane} from 'app/Components/Layout/Tabs'
import {ThemedBox} from 'app/Components/Layout'
import {HtmlContent} from 'plugins/rich-text'
import Gmaps from 'app/Components/GoogleMaps'
import {
	Facilities,
	Availabilities,
	Surroundings,
	Offer,
	Reviews,
} from 'app/Components/Panels'
import {_} from 'plugins/i18n'

const SloganContent = styled(ThemedBox).attrs({
	useSet: 'accent',
	padding: true,
	inline: true,
	gap: true,
	column: true,
})`
	font-size: 0.9em;
	font-style: italic;
`

export const SloganBox: React.FC<{readonly value: string}> = ({value}) => (
	<SloganContent>
		<HtmlContent value={value} />
	</SloganContent>
)

export const MapWrapper = ({item}) => {
	if (!item) return null

	const {countryId, city, street, geo = []} = {...item, ...item.address} || {}

	const mapData = {
		q:
			street &&
			city &&
			countryId &&
			`${street}, ${city}, ${countryId.slice(2)}`,
		lng: geo?.[0],
		lat: geo?.[1],
	}
	return <Gmaps {...mapData} />
}
MapWrapper.propTypes = {
	item: PropTypes.object,
}

const setInfoGTag = action => gtag('event', action, {event_category: 'Info'})
const tabList = ['Faciliteiten', 'Prijzen', 'Beschikbaarheid', 'Streek']
const handleTabChange = value => tabList[value] && setInfoGTag(tabList[value])

const HousedetailTabs: React.FC<{readonly house: House}> = ({house}) => {
	if (!house) {
		return null
	}
	const item = house
	return (
		// Keep in sync with tabList above
		<Tabs name="tabs" onChange={handleTabChange}>
			<Pane column gap padding="2em" label={_`facilities`}>
				<Facilities {...{item}} />
			</Pane>
			<Pane column gap padding="2em" label={_`pricesPerWeek`}>
				{item.slogan && <SloganBox value={item.slogan} />}
				<Offer {...{item}} />
			</Pane>
			<Pane column gap padding="2em" label={_`availabilities`}>
				<Availabilities {...{item}} />
			</Pane>
			<Pane column gap padding="2em" label={_`surroundings`}>
				<Surroundings {...{item}} />
				<MapWrapper {...{item}} />
			</Pane>
			{item.tips && (
				<Pane column gap padding="2em" label={_`tips`}>
					<HtmlContent value={item.tips} />
				</Pane>
			)}
			{item.reviews && item.reviews.length && (
				<Pane
					className="reviewsTabSelector"
					column
					gap
					padding="2em"
					label={_`reviews`}
				>
					<Reviews {...{item}} />
				</Pane>
			)}
		</Tabs>
	)
}

export default HousedetailTabs
