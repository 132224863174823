import React from 'react'
import PropTypes from 'prop-types'
import OrigSlickSlider from 'app/Components/Carousels/SlickSlider'
import styled from 'styled-components'
import {Box} from 'app/Components/Layout'
import {HeadBox, SpacesWrap} from 'app/Components/Layout/Display'
import {PostPreview, PostDescription} from 'app/Components/Blog'
import {_, LangLink} from 'plugins/i18n'
import {blogPostsQuery} from 'app/_server/queries/blogPosts'
import {graphql} from 'plugins/apollo'
import {BeigeGreenTheme, lightBeigeColor, useResponsive} from 'app/styles'
import {compose} from 'redux'

const SlickSlider = styled(OrigSlickSlider)`
	.slick-slide:not(.slick-active) {
		transform: translateX(-6%);
	}
`
const StyledSpacesWrap = styled(SpacesWrap)`
	background-color: ${lightBeigeColor};
`
const PostsWrap = styled(Box)`
	align-items: start;
`

const RecentPostsList = ({posts, isPhone}) => (
	<div className="flex w-full flex-col items-start items-stretch justify-center gap-8">
		<div className="flex w-full items-center justify-between">
			<HeadBox resize={0.9} underline>
				{_`recentBlogpost`}
			</HeadBox>
			{!isPhone && (
				<div className="text-capGold">
					<LangLink to="/xx/blog">{_`showAllPosts`}</LangLink>
				</div>
			)}
		</div>
		{isPhone ? (
			<RecentPostsSlider {...{posts, isPhone}} />
		) : (
			<PostsWrap gap="2em">
				{posts.map(post => (
					<PostPreview key={post.id} {...{post, column: true}}>
						<PostDescription {...{post, isPhone, fontResize: 0.5}} />
					</PostPreview>
				))}
			</PostsWrap>
		)}
	</div>
)
RecentPostsList.propTypes = {
	posts: PropTypes.arrayOf(PropTypes.object),
	isPhone: PropTypes.bool,
}

const settings = {
	adaptiveHeight: false,
	arrows: 0,
	autoplay: 0,
	autoplaySpeed: 5000,
	dots: 0,
	draggable: 0,
	infinite: 1,
	lazyLoad: 0,
	pauseOnHover: 1,
	slidesToScroll: 1,
	slidesToShow: 1,
}

const RecentPostsSlider = ({posts, isPhone}) => (
	<div className="w-full">
		<SlickSlider {...settings}>
			{posts.map(post => (
				<div className="p-8" key={post.id}>
					<PostPreview key={post.id} {...{post, column: true}}>
						<PostDescription {...{post, isPhone}} />
					</PostPreview>
				</div>
			))}
		</SlickSlider>
	</div>
)
RecentPostsSlider.propTypes = {
	posts: PropTypes.arrayOf(PropTypes.object),
	isPhone: PropTypes.bool,
}

const RecentBlogPostsSection = ({data: {loading, blogPosts}}) => {
	const {isPhone} = useResponsive('isPhone')
	if (loading && !blogPosts) {
		return null
	}

	const {items: posts} = blogPosts

	return (
		<BeigeGreenTheme>
			<StyledSpacesWrap volumes={[8, 2]}>
				<RecentPostsList posts={posts} isPhone={isPhone} />
			</StyledSpacesWrap>
		</BeigeGreenTheme>
	)
}

RecentBlogPostsSection.propTypes = {
	data: PropTypes.object,
}

export default compose(
	graphql(blogPostsQuery, {
		options: () => ({
			variables: {limit: 3, onlyOnline: true},
		}),
	})
)(RecentBlogPostsSection)
