// This behaves like an input field with value and onChange, except that onChange can be called on mount
// Pass defaults so it knows which value keys to encode/decode
// Steps:
// Initial mount, or location changes: start at 1
// Value changes: start at 4
// 1. parse match into parsed
// 2. parse search, merge into parsed
// 3. post-process parsed => value; onChange(value)
// 4. pre-process value => toStore
// 5. create new pathname from toStore or keep current. This can mutate toStore
// 6. create new search from toStore or keep current
// 7. change URL accordingly
//
// Store the desired search path (pathname/country/region translated) from 7 so you know when location really changed

import React, {Component} from 'react'
import {Route, generatePath} from 'react-router'
import {isEqual} from 'lodash-es'
import {tryParse, stringify} from 'jsurl2'
import * as qs from 'query-string'
// Babel 7 can't handle it as import
const {parse: qsParse, stringify: qsStringify} = qs

const isNotSubsetOf = (obj, other) =>
	Object.entries(obj).some(([key, val]) => !isEqual(other[key], val))

export const _valueFromSearch = (defaults, search) => {
	const keys = Object.keys(defaults)
	const out = {}
	const obj = qsParse(search)
	for (const k of keys) {
		const val = obj[k]
		if (val === undefined) {
			// missing, default to current
			out[k] = defaults[k]
		} else if (val === null) {
			// `?val&` - boolean true
			out[k] = true
		} else {
			// If parsing failed, it was maybe a manual string from the user
			out[k] = tryParse(val as string, obj[k])
		}
	}
	return out
}

export const searchFromValue = (value, defaults, search = '') => {
	const obj = qsParse(search)
	for (const k of Object.keys(value)) {
		const v = value[k]
		const d = defaults[k]
		if (d == null ? v != null : !isEqual(v, d)) {
			// Differs from default
			obj[k] =
				v === true
					? null // encode booleans as `?bool&`
					: stringify(v, {short: true})
		} else {
			// Matches default; remove
			delete obj[k]
		}
	}
	const out = qsStringify(obj, {strict: false})
	return out && `?${out}`
}

export const deriveValue = props => {
	const {location, match, defaults, parseParams, parseQuery} = props
	const parsed = match
		? parseParams
			? parseParams(match.params) || {}
			: {...match.params}
		: {}
	const query = _valueFromSearch(
		{...defaults, ...props.value, ...parsed},
		location.search
	)
	const value = (parseQuery && parseQuery(query)) || query
	return value
}

export const deriveLocation = (props: any, value: any) => {
	const {path, defaults, makeParams, makeQuery} = props
	const location = {...props.location, key: undefined}
	let toStore = value
	if (path) {
		// This can mutate toStore (to strip already-added params)
		if (makeParams) toStore = {...toStore}
		const params =
			(makeParams && makeParams(toStore, props.match && props.match.params)) ||
			toStore
		location.pathname = generatePath(path, params)
	}
	const query = makeQuery ? makeQuery(toStore) : toStore
	// Note that we remove everything from search that we don't explicitly get
	location.search = searchFromValue(query, defaults)
	return location
}

class EnsureLocation<T> extends Component<{
	value: T
	onChange: (v: T) => void
	history: object
	// These are used in deriving state
	defaults: T
	parseParams?: (p: Record<string, any>) => Partial<T>
	makeParams?: (v: T) => Record<string, any> | undefined
	parseQuery?: (q: Record<string, any>) => Partial<T>
	makeQuery?: (v: T) => Record<string, any> | undefined
	children?: React.ReactNode
	location: object
}> {
	// Slight hack: we use this lifecycle hook to get changes to props ASAP
	static getDerivedStateFromProps(props, state) {
		let nextState
		if (props.value !== state.prevValue) nextState = {prevValue: props.value}

		// We can be called even if props didn't change
		let value =
			(isEqual(props.value, state.prevValue) && state.value) || props.value
		let {pathname: currentPath, search: currentSearch} = state
		if (state.key !== props.location.key) {
			// location really changed, see what the new value must be
			if (!nextState) nextState = {}
			nextState.key = props.location.key
			currentPath = props.location.pathname
			currentSearch = props.location.search
			const derived = deriveValue(props)
			if (derived && isNotSubsetOf(derived, value)) {
				const {onChange} = props
				value = derived
				nextState.value = value
				if (onChange) onChange(value)
			}
		}
		// check what the location should be based on props and (new/old) value
		const {pathname, search} = deriveLocation(props, value)
		if (pathname !== currentPath || search !== currentSearch) {
			// Our derived location is different
			if (!nextState) nextState = {}
			nextState.value = value
			nextState.pathname = pathname
			nextState.search = search
			// TODO push/replace depending on loc change or not
			props.history.replace({pathname, search}, {permanent: true})
		}

		return nextState || null
	}

	state = {key: 'init', prevValue: this.props.value}

	render() {
		return this.props.children || null
	}
}

const BoundRoute = <T,>(props) => {
	const {location, path} = props
	return (
		<Route {...{location, path}}>
			{args => <EnsureLocation<T> {...props} {...args} />}
		</Route>
	)
}

export default BoundRoute
