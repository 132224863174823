/* eslint-disable react/jsx-no-useless-fragment */
import React, {useMemo, ReactNode, useContext} from 'react'
import columns from './columns'
import empty from './empty'
import seo from './seo'
import richText from './richText'
import {isDev} from 'stratokit/build'

interface Section {
	label: string
	Value: React.ComponentType<any>
	thumbnail?: string
}

interface SectionsContextType {
	columns: Section
	empty: Section
	seo: Section
	richText: Section
	[key: string]: Section
}

export const SectionsContext = React.createContext<SectionsContextType>({
	columns,
	empty,
	seo,
	richText,
})

const SectionsProvider: React.FC<{
	readonly sections: {[key: string]: Section}
	readonly merge?: boolean
	readonly children: ReactNode
}> = ({sections, merge = false, children}) => {
	const prevSections = useContext(SectionsContext)

	const value = useMemo(() => {
		if (isDev) {
			for (const [id, section] of Object.entries(sections)) {
				if (!section || typeof section !== 'object') {
					throw new TypeError(`Section ${id} is required to be an object`)
				}
				const {label, Value} = section
				if (!Value) throw new TypeError(`section ${id}: Value is required`)
				if (!label) throw new TypeError(`section ${id}: label is required`)
			}
		}
		return merge ? {...prevSections, ...sections} : sections
	}, [prevSections, sections, merge]) as SectionsContextType

	return (
		<SectionsContext.Provider value={value}>
			<>{children}</>
		</SectionsContext.Provider>
	)
}

export default SectionsProvider
