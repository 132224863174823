import React from 'react'
import {styled, colorCss} from 'app/styles'

const calcHeight = ({value, theme: {isMobile}}) => {
	const height = value?.height
	return typeof height === 'number'
		? `${isMobile ? height / 2 : height}em`
		: height
}

const Filler = styled(
	({value, templateData, inEditor, isServer, isNarrow, ...p}) =>
		inEditor ? (
			<span>Filler: Will add {value?.height} height.</span>
		) : (
			<div {...p} />
		)
)`
	${colorCss};
	margin-bottom: ${calcHeight};
	width: 100%;
`

export default Filler
