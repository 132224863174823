import gql from 'graphql-tag'
import {makeQueryHook} from './helpers'

// Queries
export const specialQuery = gql`
	query specialQuery($specialId: ID!, $isEditing: Boolean!) {
		special(id: $specialId) {
			id
			title(fallback: false)
			titleI18n @include(if: $isEditing)
			image {
				w
				h
				blurhash
				upload {
					id
				}
			}
			thumbnail {
				w
				h
				blurhash
				upload {
					id
				}
			}
			icon
			showOnHomepage
		}
	}
`

export const specialsQuery = gql`
	query specialsQuery($showOnHomepage: Boolean) {
		specials(showOnHomepage: $showOnHomepage) {
			items {
				id
				title
				image {
					w
					h
					blurhash
					upload {
						id
					}
				}
				thumbnail {
					w
					h
					blurhash
					upload {
						id
					}
				}
				icon
				showOnHomepage
			}
		}
	}
`

export const useSpecialsQuery = makeQueryHook(specialsQuery)

// Mutations
export const saveSpecial = gql`
	mutation saveSpecial(
		$id: ID
		$titleI18n: I18nStringInput!
		$image: ImageInput
		$thumbnail: ImageInput
		$icon: ShortString
		$showOnHomepage: Boolean
	) {
		saveSpecial(
			id: $id
			titleI18n: $titleI18n
			image: $image
			thumbnail: $thumbnail
			icon: $icon
			showOnHomepage: $showOnHomepage
		) {
			id
			title
			titleI18n
			image {
				w
				h
				blurhash
				upload {
					id
				}
			}
			thumbnail {
				w
				h
				blurhash
				upload {
					id
				}
			}
			icon
			showOnHomepage
		}
	}
`
