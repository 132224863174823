import Value from './LandingPageHousesSection'

export default {
	label: 'Landing Page Houses',
	group: 'content',
	Value,
	defaultValue: {
		regionId: undefined,
		countryId: undefined,
		size: undefined,
	},
}
