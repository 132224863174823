import React, {ReactNode, useEffect, useState, useRef} from 'react'
import {useQuery} from '@apollo/client/react/hooks'
import type {QueryResult} from '@apollo/client'
import {isEqual, debounce} from 'lodash-es'
import {Button} from 'app/Components/Buttons'
import {_} from 'plugins/i18n'
import type {DocumentNode} from 'graphql'

const Query: React.FC<{
	readonly query: DocumentNode
	readonly variables?: Record<string, any>
	readonly skip?: boolean
	readonly children: (result: QueryResult<any, any>) => ReactNode
}> = ({query, variables, skip = false, children}) => {
	const [currentVariables, setCurrentVariables] = useState(variables)
	const [currentSkip, setCurrentSkip] = useState(skip)

	const debouncedSetVarsRef = useRef(
		debounce((newState: {variables?: any; skip?: boolean}) => {
			if (newState.variables !== undefined)
				setCurrentVariables(newState.variables)
			if (newState.skip !== undefined) setCurrentSkip(newState.skip)
		}, 200)
	)

	const {data, error, refetch, ...result} = useQuery(query, {
		variables: currentVariables,
		skip: currentSkip,
		partialRefetch: true,
	})

	useEffect(() => {
		const debouncedSetVars = debouncedSetVarsRef.current

		if (!isEqual(variables, currentVariables) || skip !== currentSkip) {
			debouncedSetVars({variables, skip})
		}

		return () => {
			debouncedSetVars.cancel()
		}
	}, [variables, skip, currentVariables, currentSkip])

	if (error) {
		console.error('query failed', error, {query, variables, skip}) // eslint-disable-line no-console
		return (
			<div className="flex w-full items-center justify-center gap-4">
				{_`QueryFailed`}
				<Button onClick={() => refetch(currentVariables)}>{_`Retry`}</Button>
			</div>
		)
	}

	if (!skip && !data) {
		result.data = {}
	}

	return <>{children({data, error, refetch, ...result})}</>
}

export default Query
