import React from 'react'
import {Icon} from 'plugins/icons'

import plus from 'icons/plus.icon.svg'
import minus from 'icons/minus.icon.svg'
import pin from 'icons/pin.icon.svg'
import home from 'icons/home.icon.svg'
import people from 'icons/people.icon.svg'
import calendar from 'icons/calendar.icon.svg'
import bath from 'icons/bath.icon.svg'
import bed from 'icons/bed.icon.svg'
import pool from 'icons/pool.icon.svg'
import search from 'icons/search.icon.svg'
import searchNew from 'icons/icn-search.icon.svg'
import menu from 'icons/icn-menu.icon.svg'
import peopleNew from 'icons/icn-people.icon.svg'
import bathNew from 'icons/icn-bathroom.icon.svg'
import bedNew from 'icons/icn-bedroom.icon.svg'
import x from 'icons/x.icon.svg'
import heart from 'icons/heart.icon.svg'
import share from 'icons/share.icon.svg'
import phone from 'icons/phone.icon.svg'
import compass from 'icons/compass.icon.svg'
import arrow from 'icons/arrow.icon.svg'
import arrowUp from 'icons/icon-arrow-up.icon.svg'
import arrowDown from 'icons/icon-arrow-down.icon.svg'
import arrowLeft from 'icons/arrow-left.icon.svg'
import arrowRight from 'icons/arrow-right.icon.svg'
import arrowShowAll from 'icons/arrowShowAll.icon.svg'
import MESSAGE from 'icons/backOffice/icn-message.icon.svg'
import SEARCH from 'icons/backOffice/icn-search.icon.svg'
import eye from 'icons/icon-eye.icon.svg'
import noEye from 'icons/icon-noEye.icon.svg'
import MAIL from 'icons/email.icon.svg'
// Summary
import BATHROOM from 'icons/backOffice/icn-bathroom.icon.svg'
import BEDROOM from 'icons/backOffice/icn-bedroom.icon.svg'
import CONTACT from 'icons/backOffice/icn-contact.icon.svg'
import PEOPLE from 'icons/backOffice/icn-people.icon.svg'
import PRICES from 'icons/backOffice/icn-prices.icon.svg'
import TEL from 'icons/icn-tel.icon.svg'
import TYPE from 'icons/backOffice/icn-type.icon.svg'
import ARROW from 'icons/backOffice/icn-arrow.icon.svg'
import ACCESIBILITY from 'icons/backOffice/icn-accesibility.icon.svg'
import GOLF from 'icons/backOffice/icn-golf.icon.svg'
import PETOK from 'icons/icn-pet.icon.svg'
import PETNO from 'icons/icn-pet-no.icon.svg'

import check from 'icons/check.icon.svg'
import dot from 'icons/dot.icon.svg'
import edit from 'icons/edit.icon.svg'
import facebook from 'icons/facebook.icon.svg'
import fb from 'icons/icn-fb.icon.svg'
import instagram from 'icons/instagram.icon.svg'
import link from 'icons/link.icon.svg'
import only from 'icons/logo-only.icon.svg'
import logout from 'icons/logout.icon.svg'
import emailOpened from 'icons/icon-emailOpened.icon.svg'
import messenger from 'icons/icn-messenger.icon.svg'
import pinterest from 'icons/pinterest.icon.svg'
import remove from 'icons/remove.icon.svg'
import cogwheel from 'icons/cogwheel.icon.svg'
import star from 'icons/star.icon.svg'
import translation from 'icons/translation.icon.svg'
import tw from 'icons/icn-tw.icon.svg'
import warning from 'icons/warning.icon.svg'
import whatsapp from 'icons/icn-whatsapp.icon.svg'
import youtube from 'icons/youtube.icon.svg'
import quote from 'icons/icon-quote.icon.svg'

// new icons (source: https://phosphoricons.com)
import facebookNew from 'icons/newIcons/facebook.icon.svg'
import instagramNew from 'icons/newIcons/instagram.icon.svg'
import arrowLeftNew from 'icons/newIcons/arrowLeft.icon.svg'
import arrowRightNew from 'icons/newIcons/arrowRight.icon.svg'
import risingArrow from 'icons/newIcons/risingArrow.icon.svg'
import fiveStarRating from 'icons/newIcons/fiveStarRating.icon.svg'
import destination from 'icons/newIcons/destination.icon.svg'
import capacity from 'icons/newIcons/capacity.icon.svg'
import bedNewNew from 'icons/newIcons/bed.icon.svg'
import shower from 'icons/newIcons/shower.icon.svg'
import tick from 'icons/newIcons/tick.icon.svg'
import starNew from 'icons/newIcons/star.icon.svg'
import google from 'icons/newIcons/google.icon.svg'
import location from 'icons/newIcons/location.icon.svg'
import calendarNew from 'icons/newIcons/calendar.icon.svg'
import filter from 'icons/newIcons/filter.icon.svg'
import plusNew from 'icons/newIcons/plus.icon.svg'
import shareNew from 'icons/newIcons/share.icon.svg'
import internet from 'icons/newIcons/internet.icon.svg'
import aircon from 'icons/newIcons/aircon.icon.svg'
import pets from 'icons/newIcons/pets.icon.svg'
import newPool from 'icons/newIcons/pool.icon.svg'
import wifi from 'icons/newIcons/wifi.icon.svg'
import footprints from 'icons/newIcons/footprints.icon.svg'
import waves from 'icons/newIcons/waves.icon.svg'
import panorama from 'icons/newIcons/panorama.icon.svg'
import television from 'icons/newIcons/television.icon.svg'
import carBattery from 'icons/newIcons/car-battery.icon.svg'
import tree from 'icons/newIcons/tree.icon.svg'
import circledCheck from 'icons/newIcons/circledCheck.icon.svg'

import {cn} from 'app/lib'

export {Icon, Icon as default} from 'plugins/icons'

// TODO export very common glyphs as separate components like ArrowIcon and move all the rest to where they are used => save 40KB
export const glyphs = {
	close: x,
	plus,
	minus,
	pin,
	home,
	people,
	calendar,
	bath,
	bed,
	pool,
	search,
	searchNew,
	menu,
	peopleNew,
	bathNew,
	bedNew,
	x,
	heart,
	share,
	phone,
	compass,
	arrowUp,
	arrowDown,
	arrowLeft,
	arrowRight,
	arrowShowAll,
	MESSAGE,
	SEARCH,
	eye,
	noEye,
	MAIL,
	// Summary
	BATHROOM,
	BEDROOM,
	CONTACT,
	PEOPLE,
	PRICES,
	TEL,
	TYPE,
	ARROW,
	ACCESIBILITY,
	GOLF,
	PETOK,
	PETNO,
	SWIM: pool,
	// add feature stuff
	internet,
	aircon,
	newPool,
	pets,
	circledCheck,
	wifi,
	tree,
	footprints,
	waves,
	panorama,
	television,
	carBattery,
}

export const ArrowDownIcon = props => (
	// Use flip so animations flip instead of rotate
	<Icon {...props} rot="l" flip="x" glyph={arrow} />
)
export const ArrowLeftIcon = props => <Icon {...props} flip="y" glyph={arrow} />
export const ArrowRightIcon = props => <Icon {...props} glyph={arrow} />
export const ArrowUpIcon = props => <Icon {...props} rot="l" glyph={arrow} />
export const PlusIcon = props => <Icon {...props} glyph={plus} />
export const MinusIcon = props => <Icon {...props} glyph={minus} />
export const ConfirmedIcon = props => <Icon {...props} glyph={check} />
export const CloseIcon = props => <Icon {...props} glyph={x} />
export const DropdownIcon = props => (
	<Icon
		{...props}
		up={undefined}
		rot="r"
		flip={props.up ? 'x' : undefined}
		glyph={arrow}
	/>
)
export const DotIcon = props => <Icon {...props} glyph={dot} />
export const EditIcon = props => <Icon {...props} glyph={edit} />
export const FacebookIcon = props => <Icon {...props} glyph={facebook} />
export const FacebookSecondIcon = props => <Icon {...props} glyph={fb} />
export const HeartIcon = props => <Icon {...props} glyph={heart} />
export const InstagramIcon = props => <Icon {...props} glyph={instagram} />
export const LinkIcon = props => <Icon {...props} glyph={link} />
export const LogoSmallIcon = props => <Icon {...props} glyph={only} />
export const LogoutIcon = props => <Icon {...props} glyph={logout} />
export const MailIcon = props => <Icon {...props} glyph={MAIL} />
export const MailPreviewIcon = props => <Icon {...props} glyph={emailOpened} />
export const MessengerIcon = props => <Icon {...props} glyph={messenger} />
export const PhoneIcon = props => <Icon {...props} glyph={phone} />
export const PagerArrowIcon = props => <Icon {...props} glyph={arrow} />
export const PinterestIcon = props => <Icon {...props} glyph={pinterest} />
export const RemoveIcon = props => <Icon {...props} glyph={remove} />
export const XIcon = props => <Icon {...props} glyph={x} />
export const SearchIcon = props => <Icon {...props} glyph={search} />
export const SettingsIcon = props => <Icon {...props} glyph={cogwheel} />
export const StarIcon = props => <Icon {...props} glyph={star} />
export const TranslationIcon = props => <Icon {...props} glyph={translation} />
export const TwitterIcon = props => <Icon {...props} glyph={tw} />
export const WarningIcon = props => <Icon {...props} glyph={warning} />
export const WhatsappIcon = props => <Icon {...props} glyph={whatsapp} />
export const YoutubeIcon = props => <Icon {...props} glyph={youtube} />
export const QuoteIcon = props => <Icon {...props} glyph={quote} />

export const CheckIcon = CloseIcon
export const RadioIcon = DotIcon

export const FacebookIconNew = props => <Icon {...props} glyph={facebookNew} />
export const InstagramIconNew = props => (
	<Icon {...props} glyph={instagramNew} />
)
export const ArrowLeftIconNew = props => (
	<Icon {...props} glyph={arrowLeftNew} />
)
export const ArrowRightIconNew = props => (
	<Icon {...props} glyph={arrowRightNew} />
)
export const RisingArrowIcon = props => <Icon {...props} glyph={risingArrow} />
export const FiveStarRatingIcon = props => (
	<Icon {...props} glyph={fiveStarRating} />
)
export const DesinationIcon = props => <Icon {...props} glyph={destination} />
export const CapacityIcon = props => <Icon {...props} glyph={capacity} />
export const BedIcon = props => <Icon {...props} glyph={bedNewNew} />
export const ShowerIcon = props => <Icon {...props} glyph={shower} />
export const TickIcon = props => <Icon {...props} glyph={tick} />
export const StarIconNew = props => <Icon {...props} glyph={starNew} />
export const GoogleIcon = props => <Icon {...props} glyph={google} />
export const LocationIcon = props => <Icon {...props} glyph={location} />
export const CalendarIcon = props => <Icon {...props} glyph={calendarNew} />
export const FilterIcon = props => <Icon {...props} glyph={filter} />
export const PlusIconNew = props => <Icon {...props} glyph={plusNew} />

export const FavIcon: React.FC<{readonly active?: boolean}> = ({
	active = undefined,
	...props
}) => {
	return (
		<Icon
			{...props}
			className={cn(
				'cursor-pointer stroke-[2px] text-red-600',
				active ? 'text-red-600! hover:fill-red-300!' : ''
			)}
			glyph={glyphs.heart}
			outline={!active}
		/>
	)
}

export const ShareIcon = props => <Icon {...props} glyph={shareNew} />
