import React from 'react'
import {
	DateValue,
	DATE_REVERSE_1_FORMAT,
	DATE_TIME_REVERSE_FORMAT,
} from 'plugins/i18n'

/**
 * Default way of displaying short date in CAP.
 *
 * @param {DateValueInput} p
 * @returns {JSX.Element}
 */
export const FixDateValue = p => (
	<DateValue {...p} format={DATE_REVERSE_1_FORMAT} />
)

/**
 * Default way of displaying long date in CAP.
 *
 * @param {DateValueInput} p
 * @returns {JSX.Element}
 */
export const FixDateTimeValue = p => (
	<DateValue {...p} format={DATE_TIME_REVERSE_FORMAT} />
)
