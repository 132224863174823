import React, {useState} from 'react'
import {useBlogPostsQuery} from 'app/_server/queries/blogPosts'
import {Line, SpacesBox} from 'app/Components/Layout/Display'
import ItemPager from 'app/Components/Pager'
import {useLocation} from 'plugins/react-router-deluxe'
import {useI18n, _, LangLink} from 'plugins/i18n'
import {urlForPost} from 'app/lib'
import List from './List'
import {Box} from 'app/Components/Layout'
import {getFg, getBg, BeigeGreenTheme, useResponsive} from 'app/styles'
import styled from 'styled-components'
import {PostPreview, PostDescription} from 'app/Components/Blog'
import {Template} from 'plugins/templates'
import {makeUrl} from 'plugins/images'
import Loading from 'app/Components/Loading'
import {CapGreenButton} from 'app/Components/Buttons'
import RegionsSelectInput from 'app/Components/Inputs/RegionsSelectInput'
import {useRouterQuery} from 'app/lib/useRouterQuery'
import {Helmet} from 'plugins/react-helmet'

const StyledSpacesBox = styled(SpacesBox)`
	background: ${getBg};
	font-size: 0.9em;
`
const MiniHeader = styled.div`
	color: ${getFg};
	margin-bottom: 0.25em;
	font-size: 0.8em;
`

const pageSize = 6

const BlogFiltersPanel = ({setSearchParams, searchParams, regionIds}) => {
	return (
		<div className="flex w-full items-center justify-center gap-4">
			<RegionsSelectInput
				{...{
					regionIds,
					onChange: v => setSearchParams({...searchParams, regionIds: v}),
				}}
			/>
			<CapGreenButton
				{...{
					onClick: () =>
						setSearchParams({...searchParams, regionIds: undefined}),
				}}
			>
				{_('ClearButton')}
			</CapGreenButton>
		</div>
	)
}

const Blog = () => {
	const {isPhone} = useResponsive('isPhone')
	const location = useLocation()
	const [page, setPage] = useState(1)
	const i18n = useI18n()
	const {searchParams, setSearchParams} = useRouterQuery()

	const {data, loading} = useBlogPostsQuery({
		onlyOnline: true,
		regionIds: searchParams?.regionIds || [],
	})

	if (loading && !data) return <Loading />
	if (!data) return null

	const {items: posts, total} = data.blogPosts

	const currentPageIndex = page - 1
	const numOfPages = Math.ceil(total / pageSize)

	const firstPost = posts?.[0]

	const blogPostMetaTitle = firstPost?.title
	const blogPostMetaImagePath =
		firstPost?.mainImage?.upload &&
		makeUrl(firstPost?.mainImage.upload, {
			mid: true,
			isServer: true,
		})

	const postsToShow = posts.slice(
		currentPageIndex * pageSize,
		currentPageIndex * pageSize + pageSize
	)

	const desiredTitle = `Blog | ${blogPostMetaTitle || ''}`

	const metaData = {
		title: desiredTitle.length < 60 ? desiredTitle : 'Blog',
		description: i18n.getText(`${currentPageIndex}_bPostDescription`),
		imageUrl: location && `${location.origin}${blogPostMetaImagePath}`,
	}

	const handleNextPage = () => {
		setPage(p => p + 1)
	}

	const handlePrevPage = () => {
		setPage(p => p - 1)
	}

	const handlePageClick = (p: number) => {
		setPage(p)
	}

	return (
		<>
			<meta itemProp="name" content={firstPost?.title} />
			<meta itemProp="image" content={blogPostMetaImagePath} />
			<Helmet>
				<link
					rel="canonical"
					href={`https://${globalThis.hostname}/${i18n.lang}/blog`}
				/>
			</Helmet>
			<Template id="blogTop" templateData={{metaData}} />
			<StyledSpacesBox fullWidth vertical volume={8}>
				<div className="flex w-full flex-col items-center justify-center gap-4 p-5">
					<BlogFiltersPanel
						{...{
							setSearchParams,
							searchParams,
							regionIds: searchParams?.regionIds,
						}}
					/>
					<BeigeGreenTheme>
						{total ? (
							<Box column gap="2em" right={!isPhone}>
								<PostPreview gap="2em" column={isPhone} post={firstPost}>
									<PostDescription
										fontResize={0.75}
										post={firstPost}
										withText
										withLink={false}
										top={
											!isPhone && (
												<div>
													<MiniHeader as="h1">{_`mostRecent`}</MiniHeader>
													<Line horizontal length="3em" opacity="0.5" />
												</div>
											)
										}
										bottom={
											<LangLink
												className="text-capGold"
												to={urlForPost(i18n, firstPost, '')}
											>
												{_`readMore`}→
											</LangLink>
										}
									/>
								</PostPreview>
								<List posts={postsToShow} />
								<ItemPager
									current={page}
									total={numOfPages}
									onNext={handleNextPage}
									onPrev={handlePrevPage}
									onPageClick={handlePageClick}
								/>
							</Box>
						) : (
							_('noBlogPosts')
						)}
					</BeigeGreenTheme>
				</div>
			</StyledSpacesBox>
			<Template id="blogBottom" templateData={{metaData}} />
		</>
	)
}

export default Blog
