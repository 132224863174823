import React from 'react'

import {isValid} from 'date-fns'
import {useI18n} from './I18nContext'
import {calcNumDays} from 'app/lib'
import {
	dateFormatter,
	DATE_REVERSE_2_FORMAT,
	DAY_MONTH_2_FORMAT,
	ISODateToNumArr,
} from '..'
import {nl, fr, de, enGB as en} from 'date-fns/locale'

const locale = {en, nl, fr, de}

/**
 * @param {DateISORange} value
 * @param {string} lang
 * @param {boolean} [showNights]
 * @returns {string}
 */
export const formatRange = ({start, end}, lang, showNights) => {
	let beginVal, endVal, beginValid, endValid

	if (start) {
		if (typeof start === 'string' && start >= '9999') {
			beginVal = '∞'
		} else {
			beginVal = new Date(start)
			if (isValid(beginVal)) {
				beginValid = true
			} else {
				beginVal = '?'
			}
		}
	} else {
		beginVal = '…'
	}
	if (end) {
		if (typeof end === 'string' && end >= '9999') {
			endVal = '∞'
		} else {
			endVal = new Date(end)
			if (isValid(endVal)) {
				endValid = true
			} else {
				endVal = '?'
			}
		}
	} else {
		endVal = '…'
	}

	let text, beginText, endText

	if (beginValid) {
		const beginFormat =
			start && end && ISODateToNumArr(start)?.[0] === ISODateToNumArr(end)?.[0]
				? DAY_MONTH_2_FORMAT
				: DATE_REVERSE_2_FORMAT
		beginText = dateFormatter(start, beginFormat, {locale: locale[lang]})
	} else {
		beginText = beginVal
	}

	if (endValid) {
		const endFormat = DATE_REVERSE_2_FORMAT
		endText = dateFormatter(end, endFormat, {locale: locale[lang]})
	} else {
		endText = endVal
	}

	text = `${beginText} - ${endText}${
		start && beginValid && end && endValid && showNights
			? ` (${calcNumDays(start, end)}n)`
			: ''
	}`

	return text
}

/**
 * Default way of displaying date range in CAP.
 *
 * @param {{
 * 	value: DateISOStringRange
 * 	showNights?: boolean
 * 	lang?: string
 * }} props
 * @returns {JSX.Element}
 */
const DateRangeValue = ({value, showNights = true, lang: pLang, ...rest}) => {
	const i18n = useI18n()
	const lang = pLang || i18n.lang

	return <span {...rest}>{value && formatRange(value, lang, showNights)}</span>
}
export default DateRangeValue
