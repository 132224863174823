import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState: string = ''

const topBarSlice = createSlice({
	name: 'topBar',
	initialState,
	reducers: {
		setFilter(state, action: PayloadAction<string>) {
			return action.payload
		},
	},
})

export const {setFilter} = topBarSlice.actions

export default topBarSlice.reducer
