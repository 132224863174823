import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {styled} from 'app/styles'
import {fbTrack} from 'plugins/trackers'
import {Box, ThemedBox} from 'app/Components/Layout'
import {MoneyValue} from 'app/Components/Numbers'
import {HeadBox} from 'app/Components/Layout/Display'
import {groupBy} from 'lodash-es'
import {_, DateRangeValue} from 'plugins/i18n'
import {calcPriceRanges} from 'app/lib'
import {connect} from 'react-redux'
import {EqList} from './Facilities'
import {COND_INCLUDED} from 'app/_server/database/constants'

/** @param {{prices: HousePrice[]}} prices */
const OfferPrices = ({prices}) => {
	const ranges = calcPriceRanges(prices)
	return (
		<ThemedBox
			colorSet="capBeigeBlack"
			column
			left
			gap
			maxWidth="20em"
			padding="1em"
		>
			<HeadBox lvl={3}>{_('prices')}</HeadBox>
			{ranges.map((range, i) => (
				<Box key={i} gap spaceBetween>
					<DateRangeValue value={range} showNights={false} />
					<MoneyValue value={range.price} />
				</Box>
			))}
		</ThemedBox>
	)
}

const ExtrasGrid = styled.div`
	align-items: flex-start;
	display: grid;
	grid-column-gap: 2em;
	grid-row-gap: 1em;
	grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
	justify-content: left;
	width: 100%;
`

export const OfferExtras = ({extras, className}) => {
	const groupedValues = groupBy(extras, 'condition')
	return (
		<ExtrasGrid className={className}>
			{Object.keys(groupedValues).map((conditionId, i) => (
				<Box key={i} column left gap>
					<HeadBox lvl={3}>{_(conditionId)}</HeadBox>
					<EqList>
						{groupedValues[conditionId]
							.map(({optionId, price, per, required, max}, i) => (
								<li key={i}>
									{required || conditionId === COND_INCLUDED ? (
										_(optionId)
									) : (
										<>
											{_('optional')} {_(optionId)}
										</>
									)}
									{price ? (
										<>
											{` `}
											(<MoneyValue value={price} />
											{per && <> {_(`a$per:${per}`)}</>})
										</>
									) : null}
								</li>
							))
							.filter(Boolean)}
					</EqList>
				</Box>
			))}
		</ExtrasGrid>
	)
}

class Offer extends Component {
	static propTypes = {
		isPhone: PropTypes.bool,
		item: PropTypes.object,
	}

	componentDidMount() {
		fbTrack('Lead')
	}

	render() {
		const {
			item: {extras, prices},
			isPhone,
		} = this.props
		return (
			<Box gap={isPhone ? '2em' : '4em'} top column={isPhone}>
				{prices && !!prices.length && <OfferPrices {...{prices, isPhone}} />}
				{extras && !!extras.length && <OfferExtras {...{extras}} />}
			</Box>
		)
	}
}

export default connect(({responsive: {isPhone}}) => ({isPhone}))(Offer)
