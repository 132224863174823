import ReviewsSlider from 'app/Components/Carousels/ReviewsSlider'
import React from 'react'
import {_} from 'plugins/i18n'

const capGoogleReviews: House['reviews'] = [
	{
		name: 'Lien',
		countryId: 'c$BE',
		// score: '5/5',
		score: 10,
		textI18n: {
			nl: 'Een prachtige vakantie bij CAP, is als een fles rosé van het bovenste schap! Top vakantie en top team, voor herhaling vatbaar 🤍 ',
		},
	},
	{
		name: 'Deboosere',
		countryId: 'c$BE',
		score: 10,
		textI18n: {
			nl: 'Reeds enkele keren hebben wij geboekt via CAP. En steeds was dit een zeer aangename ervaring. Deze mensen denken mee met u als u bijzondere vragen of wensen hebt.',
		},
	},
	{
		name: 'Monique',
		countryId: 'c$NL',
		score: 10,
		textI18n: {
			nl: 'Het contact met Cap is zeer snel ,vriendelijk en professioneel hele fijne organisatie, niets op aan te merken ,we gaan zeker vaker iets huren.',
		},
	},
	{
		name: 'Gregoir',
		countryId: 'c$BE',
		score: 10,
		textI18n: {
			nl: 'Deze zomer voor het eerst via CAP, een huisje met zwembad gehuurd in Cadenet. Het was TOP. Een echte aanrader. Dankjewel team CAP !',
		},
	},
	{
		name: 'Pien',
		countryId: 'c$NL',
		score: 10,
		textI18n: {
			nl: 'Als verhuurder heb ik zeer goede ervaring met CAP. Staan altijd voor je klaar, denken goed mee, en hun service is buitengewoon vriendelijke en hoog. De mensen zijn eerlijk, betrouwbaar en volgen alle mailtjes en telefoontjes snel op.',
		},
	},
	{
		name: 'Jennifer',
		countryId: 'c$UK',
		score: 10,
		textI18n: {
			nl: "We had a fantastic holiday. I can't recommend highly enough.",
		},
	},
	{
		name: 'Jolien',
		countryId: 'c$BE',
		score: 10,
		textI18n: {
			nl: 'Het was onze 1e reis via Cap en het was een plezier om via Cap te boeken. Je wordt snel via mail verder geholpen. Je merkt ook de zeer persoonlijke service ipv de "robots" waarmee je contact hebt op andere websites. Het gaf een zeer vertrouwd gevoel waardoor je zonder zorgen op reis kan vertrekken.',
		},
	},
	{
		name: 'Michel',
		countryId: 'c$FR',
		score: 10,
		textI18n: {
			nl: "J'apprécie beaucoup le grand professionnalisme de CAP . C'est une équipe qui recherche la qualité pour ses clients. Notre partenariat repose sur la rigueur , la confiance, une communica4on interac4ve et la réactivité.",
		},
	},
	{
		name: 'Lauwers',
		countryId: 'c$BE',
		score: 10,
		textI18n: {
			nl: "Onlangs op reis geweest naar de Provence, super reis gehad. Echt een heel mooi huis met zwembad. Echt gelijk op de foto's! Cap is een echte aanrader!",
		},
	},
]

const ReviewsSection = ({
	house,
	viaGoogle,
}: {
	house?: House
	viaGoogle?: boolean
}) => {
	const reviews = house?.reviews || (viaGoogle && capGoogleReviews) || undefined
	if (!reviews?.length) return null
	return (
		<div
			id="reviews-section"
			className="flex w-full flex-col bg-[#BBB]/30 py-6"
		>
			<h2 className="text-onBackground mb-4 ml-5 text-2xl leading-8 font-bold">
				{_('Reviews')}
			</h2>
			<ReviewsSlider reviews={reviews} viaGoogle={viaGoogle} />
		</div>
	)
}
export default ReviewsSection
