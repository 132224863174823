import type {DocumentNode} from 'graphql'
import {useQuery, useMutation} from 'plugins/apollo'
import type {
	MutationHookOptions,
	OperationVariables,
	QueryHookOptions,
} from '@apollo/client'

import wrapError from '../wrapError'

export const makeQueryHook = <TData, TVariables extends OperationVariables>(
	query: DocumentNode,
	defaultOptions?: QueryHookOptions<TData, TVariables>,
	defaultOptionsFn?: (
		variables?: TVariables
	) => QueryHookOptions<TData, TVariables>
) => {
	return (
		variables?: TVariables,
		options?: QueryHookOptions<TData, TVariables>
	) => {
		const defaults = {
			...defaultOptions,
			...defaultOptionsFn?.(variables),
		}
		const result = useQuery<TData, TVariables>(query, {
			...defaults,
			...options,
			variables: {...defaults?.variables, ...variables} as TVariables,
		})

		return wrapError(result)
	}
}

export const makeMutationHook = <TData, TVariables>(
	mutation: DocumentNode,
	hookDefaults?: MutationHookOptions<TData, TVariables>,
	functionDefaults?: (
		variables?: TVariables
	) => MutationHookOptions<TData, TVariables>
) => {
	return (hookOptions?: MutationHookOptions<TData, TVariables>) => {
		const [mutate, result] = useMutation<TData, TVariables>(mutation, {
			...hookDefaults,
			...hookOptions,
		})
		const fn = (
			variables?: TVariables,
			functionOptions?: MutationHookOptions<TData, TVariables>
		) => {
			const defaults = functionDefaults?.(variables)
			return mutate({
				...defaults,
				...functionOptions,
				variables: {...defaults?.variables, ...variables} as TVariables,
			})
		}
		// `as` so it's a tuple and not an array
		return [fn, result] as [typeof fn, typeof result]
	}
}
