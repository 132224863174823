// dateHelpers.ts

import moment from './moment-with-range'
import type {Moment} from 'moment'
import {createSelector} from 'reselect'
import {
	dateFormatter,
	DATE_ISO_FORMAT,
	DATE_REVERSE_1_FORMAT,
	DATE_TIME_ISO_FORMAT,
	ISODateToNumArr,
} from 'plugins/i18n/react/dateFormatter'
import {differenceInCalendarDays} from 'date-fns'
import type {DateRange} from 'moment-range'
import {DateRangeItemWithColor} from 'app/Components/Reservation/helpers'

interface DateFormatterOptions {}

/** Short date in ISO format. */
export const fixDate = (
	date: DateISO | Moment,
	options?: DateFormatterOptions
): DateISOString => {
	return dateFormatter(date, DATE_ISO_FORMAT, options) as DateISOString
}

export const fixDateValue = (date: any, options?: DateFormatterOptions) => {
	return dateFormatter(date, DATE_REVERSE_1_FORMAT, options)
}

/** Full date in ISO format. */
export const fixFullDate = (date: any): DateTimeISOString => {
	return dateFormatter(date, DATE_TIME_ISO_FORMAT) as DateTimeISOString
}

/** Default way of displaying date range in CAP - pure string. */
export const fixDateRangeValue = (start: any, end: any): DateRangeReverse => {
	return `${dateFormatter(start, DATE_REVERSE_1_FORMAT)} - ${dateFormatter(
		end,
		DATE_REVERSE_1_FORMAT
	)}` as DateRangeReverse
}

export const makeValidRange = (beginDate: any, endDate: any) => {
	if (!beginDate || !endDate) return undefined
	return `${fixDate(beginDate)}/${fixDate(endDate)}` as Contract['prices']['validRange']
}

/** Example class for date formatting. Adjust or remove if you have other logic. */
class DateTimeFormatter {
	date: string | Date | Moment
	constructor(date: string | Date | Moment) {
		this.date = date
	}
	euFormat(sep = '/') {
		return moment(this.date).format(`DD${sep}MM${sep}YYYY HH:mm`)
	}
	euFormatNoTime(sep = '/') {
		return moment(this.date).format(`DD${sep}MM${sep}YYYY`)
	}
	usFormat(sep = '/') {
		return moment(this.date).format(`YYYY${sep}MM${sep}DD HH:mm`)
	}
	usFormatNoTime(sep = '/') {
		return moment(this.date).format(`YYYY${sep}MM${sep}DD`)
	}
}

export const dateTimeFormatter = (date: any) => {
	return new DateTimeFormatter(date)
}

export const addDays = (date: Date, days: number) => {
	const time = date.getTime()
	const dateCpy = new Date(time)
	dateCpy.setDate(dateCpy.getDate() + days)
	return dateCpy
}

// Suppose reservations is an array of objects with start, end, type

export const reservationsToRanges = createSelector(
	[({reservations}: {reservations: DateRangeItemWithColor[]}) => reservations],
	reservations =>
		reservations.map(({start, end, type: state}, i) => ({
			state,
			range: rangeWithId(i, moment(start), moment(end)),
		}))
)

/** Converts a moment to year, month, day -> returns a new moment. */
export const formatToDateMoment = (sDate: Moment): Moment => {
	const year = sDate.format('YYYY')
	const month = sDate.format('MM')
	const day = sDate.format('DD')
	return moment(new Date(Number(year), Number(month) - 1, Number(day)))
}

/** Example parse with UTC. */
export const parseMomentUTC = (date: Moment, format: string) => {
	return moment.utc(date.format(format), format)
}

export const momentToString = (date: Moment | string | Date) => {
	return moment(date).format('DD/MM/YYYY')
}

export const stringToMoment = (
	string: string,
	format = 'DD/MM/YYYY'
): Moment => {
	return moment(string, format)
}

export const rangeWithId = (id: number, begin: Moment, end: Moment) => {
	const range = moment.range(begin, end) as DateRange & {id: number}
	range.id = id
	return range
}

export const toStringRange = (
	range: DateRange | string,
	format = 'DD/MM/YYYY'
) => {
	if (!range) return range
	if (typeof range === 'string') {
		return range
	}
	const startStr = moment(range.start).format(format)
	const endStr = moment(range.end).format(format)
	return `${startStr} - ${endStr}`
}

export const makeRange = (s: DateISO, d: DateISO) => {
	const startMoment = moment(s, 'YYYY-MM-DD').clone()
	const endMoment = moment(d, 'YYYY-MM-DD').clone()
	const newRange = moment.range(startMoment, endMoment)
	return newRange.clone()
}

export const makePlainRange = (
	startDate: any,
	endDate: any
): DateISOStringRange => ({
	start: fixDate(startDate),
	end: fixDate(endDate),
})

const calcAvailableRanges = (
	reservations: DateRangeItemWithColor[],
	minDays: number
) => {
	return reservations
		.map((r, i, arr) => {
			// if first reservation
			if (i === 0) {
				return {
					start: moment(r.start).add(-1, 'years').format('YYYY-MM-DD'),
					end: r.start,
				}
			}
			if (
				arr[i + 1] &&
				moment.range(r.end, arr[i + 1].start).diff('days') >= minDays
			) {
				// if it's between start and end and the gap is bigger than minDays
				return {start: r.end, end: arr[i + 1].start}
			}
			if (i === arr.length - 1) {
				// if last reservation
				return {
					start: r.end,
					end: moment(r.end).add(1, 'years').format('YYYY-MM-DD'),
				}
			}
			return false
		})
		.filter(Boolean)
}

export const calcNumDays = (
	startDate: DateTimeISOString,
	endDate: DateTimeISOString
) => {
	return differenceInCalendarDays(
		new Date(...ISODateToNumArr(endDate)),
		new Date(...ISODateToNumArr(startDate))
	)
}

export const isHouseAvailable = (
	range: DateRange | string,
	reservations: DateRangeItemWithColor[]
) => {
	if (typeof range === 'string') {
		const [start, end] = range.split('/')
		range = makeRange(start, end)
	}
	const rangeDays = range.diff('days')
	const minDays = rangeDays < 7 ? rangeDays : 7
	const availableRanges = calcAvailableRanges(reservations, minDays)
	for (const r of availableRanges) {
		const intersection = range.intersect(moment.range(r.start, r.end))
		if (intersection && intersection.diff('days') >= minDays) {
			return true
		}
	}
	return false
}

export const calcSwitchRange = (
	origRange: DateISOStringRange,
	switchDay: House['switchDay']
) => {
	const start = moment(origRange.start)
	const end = moment(origRange.end)
	if (switchDay == null) {
		return {
			start: new Date(start.toString()),
			end: new Date(end.toString()),
		}
	}
	const startWeekDay = start.isoWeekday()
	const endWeekDay = end.isoWeekday()
	let switchDateStart: Moment
	let switchDateEnd: Moment

	if (start.isoWeek() === end.isoWeek()) {
		if (startWeekDay >= switchDay) {
			switchDateStart = start.clone().isoWeekday(switchDay)
			switchDateEnd = end.clone().isoWeekday(switchDay + 7)
		} else {
			switchDateStart = start.clone().isoWeekday(switchDay - 7)
			switchDateEnd =
				endWeekDay > switchDay
					? end.clone().isoWeekday(switchDay + 7)
					: end.clone().isoWeekday(switchDay)
		}
	} else {
		switchDateStart =
			startWeekDay >= switchDay
				? start.clone().isoWeekday(switchDay)
				: start.clone().isoWeekday(switchDay - 7)
		switchDateEnd =
			endWeekDay > switchDay
				? end.clone().isoWeekday(switchDay + 7)
				: end.clone().isoWeekday(switchDay)
	}

	return {
		start: new Date(switchDateStart.toString()),
		end: new Date(switchDateEnd.toString()),
	}
}

export const ensureRange = (
	r:
		| string
		| {
				start: Moment | Date | DateISOString
				end: Moment | Date | DateISOString
		  }
): DateISORange | RangeMomentType => {
	if (!r) return r
	if (typeof r === 'string') {
		// This is what browser autocomplete keeps
		// Example: "DD/MM/YYYY - DD/MM/YYYY"
		const match = /((?:\d{2}\/){2}\d{4}) - ((?:\d{2}\/){2}\d{4})/.exec(r)
		if (match) {
			return moment.range(
				moment(match[1], 'DD-MM-YYYY'),
				moment(match[2], 'DD-MM-YYYY')
			)
		}
		return moment.range(r)
	}
	return moment.range(r.start, r.end).clone()
}

interface PriceRange {
	start: DateISOString
	end: DateISOString
	price: HousePrice['sell']
}

export const calcPriceRanges = (prices: HousePrice[]): PriceRange[] => {
	const priceRanges: PriceRange[] = []
	for (let i = 0; i < prices.length - 1; ++i) {
		if (prices[i].sell) {
			const start = prices[i].date
			const end = fixDate(addDays(new Date(prices[i + 1].date), -1))

			priceRanges.push({
				start,
				end,
				price: prices[i].sell,
			})
		}
	}
	return priceRanges
}
