import React from 'react'
import {I18nHtmlField, SectionSelectField} from 'app/Components/Inputs'

export const RegionDescriptionForm = () => (
	<div className="flex w-full items-center justify-center">
		<SectionSelectField section="REGION" name="regionId" />
		<I18nHtmlField name="descriptionI18n" />
	</div>
)

export default RegionDescriptionForm
