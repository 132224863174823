import React from 'react'
import PropTypes from 'prop-types'
import {styled} from 'app/styles'

const Grid = styled.div`
	align-items: start;
	display: -ms-grid;
	display: grid;
	grid-column-gap: 2em;
	grid-row-gap: 2em;
	-ms-grid-columns: ${p => '1fr '.repeat(p.itemNum)};
	& > div:nth-of-type(1) {
		-ms-grid-column: 1;
	}
	& > div:nth-of-type(2) {
		-ms-grid-column: 2;
	}
	& > div:nth-of-type(3) {
		-ms-grid-column: 3;
	}
	& > div:nth-of-type(4) {
		-ms-grid-column: 4;
	}
	grid-template-columns: repeat(${p => p.itemNum}, 1fr);
	justify-content: center;
	width: 100%;
`

const SmallThumbnailGrid = ({itemNum, children, ...rest}) => {
	return (
		<Grid {...rest} itemNum={itemNum}>
			{children}
		</Grid>
	)
}
SmallThumbnailGrid.propTypes = {
	itemNum: PropTypes.number,
	children: PropTypes.node,
}

export default SmallThumbnailGrid
