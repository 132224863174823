/*

 ____                      _
|  _ \  ___    _ __   ___ | |_   _   _ ___  ___
| | | |/ _ \  | '_ \ / _ \| __| | | | / __|/ _ \
| |_| | (_) | | | | | (_) | |_  | |_| \__ \  __/
|____/ \___/  |_| |_|\___/ \__|  \__,_|___/\___|

This approach is kinda neat but causes issues with context not being available

This will be removed soon

*/

import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {CloseIcon} from 'app/Components/Icon'
import styled from 'styled-components'
import rtg from 'react-transition-group'
const {CSSTransitionGroup} = rtg

// TODO Modal component that tracks mounted state and sends prop updates so you can have in-place <Modal.../>
// TODO move DOM implementation into separate file so it can be changed
const AppWrap = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
`

const ModalBackgroundDom = styled.div`
	opacity: ${p => (p.transparent ? 0 : 0.65)};
	background-color: black;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transition: opacity 0.3s;
	cursor: pointer;
`
const ModalBackgroundWrap = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
`

const ModalWrapDom = styled.div`
	max-width: 90%;
	max-height: 90%;
	overflow: auto;
	position: relative;
`

// This probably belongs in a separate modal component so that it can be themed
const CloseButton = styled(CloseIcon)`
	position: absolute;
	top: 0%;
	right: 0%;
`

const ModalWrap = ({transparent, onClose, modal, Component, props}) => (
	<ModalBackgroundWrap>
		<ModalBackgroundDom onClick={onClose} transparent={transparent} />
		<ModalWrapDom>
			<CloseButton onClick={onClose} />
			{Component ? <Component {...props} onClose={onClose} /> : modal}
		</ModalWrapDom>
	</ModalBackgroundWrap>
)

class ModalProvider extends Component {
	static childContextTypes = {
		modal: PropTypes.object,
	}

	nextId = 1

	state = {
		modals: [],
	}

	openModal = props => {
		if (!props.modal && !props.Component) {
			throw new Error('modal or Component is required')
		}
		this.setState(({modals}) => ({
			modals: [
				...modals,
				{
					...props,
					modal:
						props.modal &&
						React.cloneElement(props.modal, {onClose: this.closeModal}),
					id: this.nextId,
				},
			],
		}))
		return this.nextId++
	}

	handleClose = () => {
		const {modals} = this.state
		if (modals.length) {
			const data = modals[modals.length - 1]
			if (data.onClose) {
				data.onClose()
			}
			this.setState({modals: modals.slice(0, -1)})
		}
	}

	updateModal = (id, props) => {
		const {modals} = this.state
		for (let i = modals.length - 1; i >= 0; i--) {
			const data = modals[i]
			if (data.id === id) {
				return this.setState({
					modals: [
						...modals.slice(0, i),
						{...data, props: {...data.props, ...props}},
						...modals.slice(i + 1),
					],
				})
			}
		}
	}

	childContext = {
		modal: {
			open: this.openModal,
			close: this.handleClose,
			update: this.updateModal,
		},
	}

	getChildContext() {
		return this.childContext
	}

	render() {
		const {children, ...rest} = this.props
		const {modals} = this.state
		return (
			<AppWrap {...rest}>
				{children}
				<div>
					<CSSTransitionGroup
						transitionName="toggleModal"
						transitionEnterTimeout={150}
						transitionLeaveTimeout={150}
					>
						{modals.map((props, i) => (
							<ModalWrap key={i} {...props} onClose={this.handleClose} />
						))}
					</CSSTransitionGroup>
				</div>
			</AppWrap>
		)
	}
}

export default ModalProvider
