import React, {
	createContext,
	useState,
	useContext,
	ReactNode,
	useMemo,
} from 'react'

type LangUrls = {en: string; nl: string; fr: string; de: string} | null

type LangUrlsContextType = {
	langUrls: LangUrls
	setLangUrls: (langUrls: LangUrls) => void
}

const LangUrlsContext = createContext<LangUrlsContextType | undefined>(
	undefined
)

const initialState: LangUrls = {en: '', nl: '', fr: '', de: ''}

export const LangUrlsProvider: React.FC<{
	readonly children: ReactNode
}> = ({children}) => {
	const [langUrls, setLangUrls] = useState<LangUrls>(initialState)

	const value: LangUrlsContextType = useMemo(
		() => ({langUrls, setLangUrls}),
		[langUrls]
	)

	return (
		<LangUrlsContext.Provider value={value}>
			{children}
		</LangUrlsContext.Provider>
	)
}

export const useLangUrls = () => {
	const context = useContext(LangUrlsContext)
	if (context === undefined) {
		throw new Error('useLangUrls must be used within a LangUrlsProvider')
	}
	return context
}
