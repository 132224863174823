import React from 'react'
import {useI18n} from './I18nContext'

/** Provides `i18n` prop to the component. */
const withI18n = <T extends React.ComponentType<any>>(Cmp: T): T => {
	if (!Cmp) throw new Error('need a component')

	const name = Cmp.displayName || Cmp.name || 'Component'
	const MyCmp = Cmp as any
	const I18nWrap = props => <MyCmp {...props} i18n={useI18n()} />
	I18nWrap.displayName = `i18n(${name})`

	return I18nWrap as T
}

export default withI18n
