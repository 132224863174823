import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import {_} from 'plugins/i18n'

const timeout = 1500
const CLEAR = 0
const BUSY = 1
const DONE = 2
const ERROR = 3

class SubmitButton extends React.PureComponent {
	static propTypes = {
		onClick: PropTypes.func,
		children: PropTypes.node,
		busyMsg: PropTypes.node,
		doneMsg: PropTypes.node,
		errorMsg: PropTypes.node,
	}

	state = {state: CLEAR}

	mounted = true

	componentWillUnmount() {
		this.mounted = false
		clearTimeout(this.timer)
	}

	mark = (state, error) => {
		if (!this.mounted) return
		if (error) this.setState({error})
		this.setState({state})
		if (state !== CLEAR && state !== BUSY)
			this.timer = setTimeout(this.mark, timeout, CLEAR)
	}

	markDone = result => {
		// For some insane reason, @graphql resolves errors instead of rejecting
		if (result instanceof Error) {
			this.markError(result)
		} else {
			this.mark(DONE)
		}
	}

	markError = error => {
		// eslint-disable-next-line no-console
		console.error('SubmitButton: submit failed:', error)
		this.mark(ERROR, error)
	}

	handleClick = ev => {
		const {onClick} = this.props
		if (!onClick) return
		this.mark(BUSY)
		setTimeout(() => {
			try {
				const result = onClick(ev)
				if (result && result.then) {
					// eslint-disable-next-line promise/catch-or-return
					result.then(this.markDone, this.markError)
				} else {
					// Don't mark as DONE, it can be a sync return from redux-form when invalid
					this.mark(CLEAR)
				}
				return result
			} catch (error) {
				this.markError(error)
				throw error
			}
		}, 0)
	}

	render() {
		const {busyMsg, doneMsg, errorMsg, children} = this.props
		const {state, error} = this.state
		const onClick = state === BUSY ? null : this.handleClick
		let label
		switch (state) {
			case BUSY: {
				label = <span>⏳ {busyMsg || _('busy')}</span>
				break
			}
			case DONE: {
				label = <span>👍 {doneMsg || _('done')}</span>
				break
			}
			case ERROR: {
				label = <span title={error.message}>👎 {errorMsg || _('error')}</span>
				break
			}
			default: {
				label = children
			}
		}

		return (
			<Button {...this.props} onClick={onClick}>
				{label}
			</Button>
		)
	}
}

export default SubmitButton
