import PropTypes from 'prop-types'
import React, {useState, useRef, useCallback, useEffect} from 'react'
import {useId} from 'plugins/id-generator'
import TextInput from 'app/Components/Inputs/TextInput'
import {getFg, getBg, getHint, colorCss, styled, silverColor} from 'app/styles'
import {Button} from 'app/Components/Buttons'
import {DropdownIcon} from 'app/Components/Icon'
import Box from 'app/Components/Layout/Box'

const IconButton = styled(Button)`
	${colorCss};
	padding-right: 0.5em;
	& > *:last-child {
		margin-left: 0.5em;
	}
`

const DropdownButton = ({open, children, ...rest}) => (
	<IconButton {...rest}>
		{children}
		<DropdownIcon up={open} />
	</IconButton>
)
DropdownButton.propTypes = {open: PropTypes.bool, children: PropTypes.node}

const DefaultContent = styled.div`
	&:empty {
		display: none;
	}
	border: thin solid ${getHint};
	border-radius: ${p => p.radius}rem;
	box-sizing: border-box;
	padding: 1em;
	min-width: 5em;
	min-height: 1em;
	background-color: ${getBg};
	color: ${getFg};
	z-index: 2;
	// upward triangle
	&:not(:empty):before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -0.5rem) rotate(45deg);
		content: '';
		display: inline-block;
		background-color: ${getBg};
		width: 1rem;
		height: 1rem;
		border-top: thin solid ${getHint};
		border-left: thin solid ${getHint};
	}
`
const Text = styled(Box).attrs({left: true, gap: '0.5em'})`
	cursor: pointer;
	white-space: nowrap;
	color: ${getFg};
`

export const ContentWrap = styled.div`
	display: flex;
	z-index: 2;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	max-width: 90vw;
	position: ${p => !p.theme.isMobile && 'absolute'};
	margin-top: ${p => !p.theme.isMobile && '1rem'};
	border: thin solid ${silverColor};
`

const DropdownWrap = styled.div`
	position: relative;
	display: inline-block;
	width: ${p => p.width};
	> input:not(:checked) + ${ContentWrap} {
		display: none;
	}
`

const Dropdown = props => {
	const id = useId()
	const wrapperRef = useRef()
	const prevValue = useRef(props.value)
	const [open, setOpen] = useState(false)

	if (props.value !== prevValue.current) {
		prevValue.current = props.value
		if (props.value !== '' && open) setOpen(false)
	}

	const handleBodyClick = useCallback(({target}) => {
		do {
			// Don't close if clicking on ourself
			if (target === wrapperRef.current) return
			target = target.parentElement
		} while (target)
		setOpen(false)
	}, [])

	useEffect(() => {
		const listen = () =>
			globalThis.document.addEventListener('click', handleBodyClick)
		const unlisten = () =>
			globalThis.document.removeEventListener('click', handleBodyClick)

		if (open) {
			listen()
			return unlisten
		}
		unlisten()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	const handleToggle = () => setOpen(!open)

	const {
		children,
		placeholder,
		label,
		value,
		type,
		width,
		Content = DefaultContent,
		contentProps,
		mobile,
		className,
		...rest
	} = props
	let dropdown
	switch (type) {
		case 'arrowButton': {
			dropdown = (
				<DropdownButton
					{...rest}
					as="label"
					htmlFor={id}
					onClick={handleToggle}
					open={open}
				>
					{label || placeholder}
				</DropdownButton>
			)
			break
		}
		case 'button': {
			dropdown = (
				<Button {...rest} as="label" htmlFor={id} onClick={handleToggle}>
					{label || placeholder}
				</Button>
			)
			break
		}
		case 'text': {
			dropdown = (
				<label htmlFor={id}>
					<Text onClick={handleToggle} {...rest}>
						<span>{label || placeholder}</span>
						<DropdownIcon secondary up={open} />
					</Text>
				</label>
			)
			break
		}
		default: {
			dropdown = (
				<TextInput
					{...{
						...rest,
						type,
						onClick: handleToggle,
						placeholder,
						label,
						value,
					}}
				/>
			)
		}
	}
	return (
		<DropdownWrap ref={wrapperRef} width={width} className={className}>
			{dropdown}
			<input
				type="checkbox"
				style={{display: 'none'}}
				id={id}
				checked={open}
				// no onChange - done in JS click handlers or plain HTML label
				onChange={() => {}}
			/>
			<ContentWrap>
				<Content
					onClick={e => e.stopPropagation()}
					{...contentProps}
					handleToggle={handleToggle}
				>
					{typeof children === 'function'
						? open
							? children({handleToggle}) || null
							: null
						: children}
				</Content>
			</ContentWrap>
		</DropdownWrap>
	)
}
Dropdown.propTypes = {
	value: PropTypes.any,
	label: PropTypes.node,
	error: PropTypes.node,
	children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	placeholder: PropTypes.node,
	type: PropTypes.string,
	width: PropTypes.string,
	className: PropTypes.string,
	Content: PropTypes.elementType,
	contentProps: PropTypes.object,
	mobile: PropTypes.bool,
}

export default Dropdown
