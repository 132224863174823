import PropTypes from 'prop-types'
import React from 'react'
import {styled, getFg, getBg, getHoverFg, getHoverBg} from 'app/styles'

const flips = {
	x: 'rotateX(180deg)',
	y: 'rotateY(180deg)',
}
const rots = {
	r: 'rotate(90deg)',
	l: 'rotate(-90deg)',
	rr: 'rotate(180deg)',
	ll: 'rotate(-180deg)',
	r45: 'rotate(45deg)',
	r90: 'rotate(90deg)',
	l90: 'rotate(-90deg)',
	r180: 'rotate(180deg)',
	l180: 'rotate(-180deg)',
}
const EmptyGlyph = ({width, height}) => (
	<svg viewBox="0 0 50 50" width={width} height={height}>
		<rect width="50" height="50" stroke="currentColor" fill="none" />
	</svg>
)
const IconBase = styled(
	({
		primary,
		secondary,
		hint,
		fgColor,
		useSet,
		colorSet,
		outline,
		round,
		bg,
		invert,
		padding,
		flip,
		rot,
		move,
		hoverable,
		glyph: Glyph = EmptyGlyph, // Glyph is now handled here
		...rest // Spread only valid props to the <svg>
	}) => {
		return typeof Glyph === 'function' ? (
			<Glyph {...rest} />
		) : (
			<svg {...rest}>{Glyph}</svg>
		)
	}
)`
	${p => {
		const useColor =
			p.primary ||
			p.secondary ||
			p.hint ||
			p.fgColor ||
			p.useSet ||
			p.colorSet ||
			p.invert

		return {
			stroke: p.outline && 'currentColor',
			fill: p.outline ? 'transparent' : 'currentColor',
			color: useColor && (p.invert ? getBg(p) : getFg(p)),
			borderRadius: p.round ? `50%` : `0`,
			backgroundColor: (p.invert || p.bg) && (p.invert ? getFg(p) : getBg(p)),
			padding: (p.bg || p.round) && (p.padding || `0.2ex`),

			transition: '.33s',

			transform:
				[
					flips[p.flip],
					rots[p.rot],
					p.move && `translate(${p.move.x || 0}, ${p.move.y || 0})`,
				]
					.filter(Boolean)
					.join(' ') || null,

			'&:hover': p.hoverable && {
				color: useColor && getHoverFg(p),
				backgroundColor: p.bg && getHoverBg(p),
			},
		}
	}};
`

const Icon = p => {
	const width = p.width || p.size || `1.1em`
	const height = p.height || p.size || `1.1em`

	return <IconBase {...p} width={width} height={height} />
}
Icon.propTypes = {
	glyph: PropTypes.any,
	// Show only the paths
	outline: PropTypes.bool,
	// Use primary text color
	primary: PropTypes.bool,
	// Round background - note that the icon must fit in a circle
	// if it gets cut off, adjust the viewBox
	round: PropTypes.bool,
	// Apply background from theme
	bg: PropTypes.bool,
	// Switch background and foreground colors
	invert: PropTypes.bool,
	// Padding on top of icon size
	padding: PropTypes.string,
	// Change color on hover
	hoverable: PropTypes.bool,
	className: PropTypes.string,
}

export default Icon
