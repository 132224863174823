import React from 'react'
import {useDispatch} from 'plugins/redux/hooks'
import {useLocation} from 'react-router-dom'
import {LangLink, useI18n} from 'plugins/i18n'
import {
	setSearch as setSearchFn,
	clearSearch as clearSearchFn,
} from 'state/ducks/searchRentals-duck'
import {searchBasePath} from 'app/lib'
import Text from 'app/Components/twComponents/Text'
import {ArrowRightIconNew} from 'app/Components/Icon'

const HousePageBreadcrumbs: React.FC<{
	readonly house: House
}> = ({house}) => {
	const location = useLocation()
	const dispatch = useDispatch()
	const i18n = useI18n()
	const {getText, getTrSlug} = i18n

	const {pathname} = location
	const {regionId, countryId, type, name} = house || {}

	const houseTypeParam = `/${getTrSlug(type)}`
	const countryParam = `/${getTrSlug(countryId)}`
	const regionParam = `/${getTrSlug(regionId)}`

	const clearSearch = () => dispatch(clearSearchFn())
	const setSearch = (search: Record<string, string>) =>
		dispatch(setSearchFn(search))

	const handleClick = (search: Record<string, string>) => {
		clearSearch()
		setSearch(search)
	}

	const breadcrumbs = [
		{
			label: getText('searchPage'),
			link: searchBasePath(i18n),
			onClick: clearSearch,
		},
		{
			link: `/xx${houseTypeParam}`,
			label: getText(type),
			onClick: () => handleClick({type}),
		},
		{
			link: `/xx${houseTypeParam}${countryParam}`,
			label: getText(countryId),
			onClick: () => handleClick({type, region: countryId}),
		},
		{
			link: `/xx${houseTypeParam}${countryParam}${regionParam}`,
			label: getText(regionId),
			onClick: () => handleClick({type, region: regionId}),
		},
		{
			link: pathname,
			label: name,
		},
	]

	return (
		<div className="bg-secondary flex flex-row items-center px-2 pt-8 pb-2">
			<Text variant="small" className="pr-3 font-bold whitespace-nowrap">
				{getText(`youAreHere`)}:
			</Text>
			<ol
				className="flex flex-row overflow-auto pr-2"
				itemScope
				itemType="https://schema.org/BreadcrumbList"
			>
				{breadcrumbs.map((breadcrumb, i) => {
					return (
						<li
							key={i}
							className="flex flex-row items-center"
							itemProp="itemListElement"
							itemScope
							itemType="https://schema.org/ListItem"
						>
							<LangLink
								onClick={breadcrumb?.onClick}
								to={breadcrumb.link}
								itemType="https://schema.org/WebPage"
								itemProp="item"
								className="h-6"
							>
								<Text
									variant="small"
									itemProp="name"
									className="h-full whitespace-nowrap"
								>
									{breadcrumb.label}
								</Text>
							</LangLink>
							<meta itemProp="position" content={String(i + 1)} />
							{i + 1 < breadcrumbs.length ? (
								<ArrowRightIconNew
									height="0.5em"
									width="0.25em"
									className="mx-2"
								/>
							) : null}
						</li>
					)
				})}
			</ol>
		</div>
	)
}

export default HousePageBreadcrumbs
