import React, {useState} from 'react'
import {cn, searchBasePath} from 'app/lib'
import {
	useHousesCountQuery,
	useHouseSearchOptions,
} from 'app/_server/queries/houses'
import {_, LangLink, useI18n} from 'plugins/i18n'
import RentalRegionInput from './RegionInput'
import PeriodInput from './PeriodInput'
import SizeInput from './SizeInput'
import CalendarModal from './CalendarModal'
import CompactSearchView from './CompactSearchView'
import {useSelector} from 'plugins/redux/hooks'
import {filterToGqlVars} from '../ShowRentals/SearchRentalsPage'

export const modalId = 'rentalSearch'

const SearchButton: React.FC<{readonly total?: number}> = ({total}) => {
	const i18n = useI18n()
	return (
		<LangLink
			to={{
				pathname: searchBasePath(i18n),
			}}
		>
			<button
				type="button"
				className="bg-primary text-onPrimary w-full cursor-pointer rounded-lg px-8 py-3"
			>
				{i18n.getText('show')} {total} {i18n.getText('results').toLowerCase()}
			</button>
		</LangLink>
	)
}

const SearchRentalsMobile: React.FC<{
	readonly disableCompact?: boolean
	readonly hasTitle?: boolean
	readonly hasEndNote?: boolean
	readonly getTotal?: boolean
	readonly total?: number
	readonly className?: string
}> = ({
	getTotal = false,
	hasTitle = false,
	hasEndNote = false,
	disableCompact = false,
	...rest
}) => {
	const {getText} = useI18n()
	const searchRentals = useSelector(state => state.searchRentals)
	const [isCompact, setIsCompact] = useState<boolean>(true)
	const {data, loading} = useHouseSearchOptions()
	const {data: countData, previousData: prevCountData} = useHousesCountQuery(
		filterToGqlVars({searchRentals}).variables,
		{skip: !getTotal}
	)
	const total = getTotal
		? (countData || prevCountData)?.housesCount
		: rest.total

	if (loading) {
		return null
	}

	const {locations, maxCapacity} = data.searchOptions || {}

	const compact = !disableCompact && isCompact

	return (
		<>
			<div
				className={cn(
					'mx-6 flex max-w-11/12 flex-col rounded-lg border border-solid border-gray-300 bg-white px-4',
					isCompact ? 'py-4' : 'py-6',
					rest.className
				)}
			>
				{hasTitle ? (
					<h2 className="mb-4 text-2xl leading-8 font-semibold">{_`findYourHolidayHouse`}</h2>
				) : null}
				<div
					className="flex w-full flex-col items-center justify-start"
					onClick={e => e.stopPropagation()}
				>
					<div className="flex w-full flex-col gap-4">
						{compact ? (
							<CompactSearchView onSetCompact={() => setIsCompact(false)} />
						) : (
							<>
								<RentalRegionInput locations={locations} />
								<PeriodInput />
								<SizeInput maxCapacity={maxCapacity} />
							</>
						)}
						{compact ? null : <SearchButton total={total} />}
						{hasEndNote ? (
							<div className="text-onTertiary">{getText(`question`)}</div>
						) : null}
					</div>
				</div>
			</div>
			<CalendarModal />
		</>
	)
}
export default SearchRentalsMobile
