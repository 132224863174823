import {toggleFavorites} from './redux/ducks/favoritesAndShare-duck'
import {useMemo} from 'react'
import {dateTimeFormatter, searchBasePath} from 'app/lib'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {useHistory} from 'plugins/react-router-deluxe'
import {clearSearch, setHouseName} from './redux/ducks/searchRentals-duck'
import {useI18n} from 'plugins/i18n'

export const useFavoriteToggle = (houseId: string) => {
	const isFavorite = useSelector(state => state.favoritesAndShare[houseId])
	const dispatch = useDispatch()

	const handleToggleFavorite = () => {
		dispatch(toggleFavorites(houseId))
	}

	return {isFavorite, onToggleFavorite: handleToggleFavorite}
}

export const useReservationPath = (slug: string) => {
	const selectedRange = useSelector(
		state => state.form?.reservationCard?.values?.dateRange
	)

	return useMemo(() => {
		const basePath = `/xx/reservation/${slug}`
		const reservationPath = selectedRange
			? `${basePath}?start=${dateTimeFormatter(
					selectedRange.start
				).usFormatNoTime('-')}&end=${dateTimeFormatter(
					selectedRange.end
				).usFormatNoTime('-')}`
			: basePath

		return reservationPath
	}, [slug, selectedRange])
}

export const useSearch = () => {
	const history = useHistory()
	const searchRentals = useSelector(({searchRentals}) => searchRentals)
	const houseName = searchRentals.houseName
	const dispatch = useDispatch()
	const i18n = useI18n()

	const handleSearch = () => {
		history.push(searchBasePath(i18n))
	}

	const handleChange = searchStr => {
		if (searchStr == null) {
			dispatch(setHouseName(''))
		}
		if (!houseName) {
			dispatch(clearSearch())
		}
		dispatch(setHouseName(searchStr))
	}
	return {handleChange, handleSearch, houseName}
}
