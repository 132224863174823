import React from 'react'
import {SelectInput} from 'app/Components/Inputs'
import {DesinationIcon} from 'app/Components/Icon'
import {useDispatch, useSelector} from 'plugins/redux/hooks'
import {setRentalRegions} from 'app/redux/ducks/searchRentals-duck'
import {_} from 'plugins/i18n'
import {ThemeProvider} from 'app/styles'

export const makeCountriesAndRegionsOptions = (
	searchLocations: {countryId: string; regionIds?: string[]}[] | undefined
): {value: string; label: JSX.Element}[] => {
	const countriesAndRegions: {value: string; label: JSX.Element}[] = []

	if (searchLocations) {
		for (const country of searchLocations) {
			countriesAndRegions.push({
				value: country.countryId,
				label: (
					<span className="font-bold whitespace-nowrap">{_`${country.countryId}`}</span>
				),
			})
			if (country.regionIds)
				for (const regionId of country.regionIds) {
					countriesAndRegions.push({
						value: regionId,
						label: (
							<span className="ml-1 whitespace-nowrap">{_`${regionId}`}</span>
						),
					})
				}
		}
	}

	return countriesAndRegions
}

const RentalRegionInput: React.FC<{
	readonly locations: any
}> = ({locations}) => {
	const region = useSelector(state => state.searchRentals.region)
	const options = makeCountriesAndRegionsOptions(locations)
	const dispatch = useDispatch()

	return (
		<ThemeProvider theme={{radius: '0.5'}}>
			<SelectInput
				value={region}
				noLabel
				options={options}
				onChange={v => dispatch(setRentalRegions({label: v.length ? v : null}))}
				placeholder={
					<div className="flex w-full items-center justify-start space-x-4">
						<DesinationIcon />
						{_('destination')}
					</div>
				}
				multi
			/>
		</ThemeProvider>
	)
}
export default RentalRegionInput
