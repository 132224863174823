import {makeQueryHook} from 'app/_server/queries/helpers'
import gql from 'graphql-tag'
import {useMutation} from 'plugins/apollo'

const SAVE_TRANSLATION_MUTATION = gql`
	mutation saveTranslation($translation: TranslationInput!) {
		saveTranslation(translation: $translation) {
			id
			key
			text
			textI18n
			html
			order
			meta
		}
	}
`

export const useSaveTranslation = () => {
	const [mutate, {loading, error, data}] = useMutation(
		SAVE_TRANSLATION_MUTATION,
		{
			refetchQueries: ['allTranslations'],
		}
	)

	const saveTranslation = async ({
		translation,
		lang,
	}: {
		translation: any
		lang: string
	}) => {
		if (!lang) {
			throw new Error('lang variable is required')
		}
		try {
			const result = await mutate({
				variables: {translation},
			})
			return result
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(`Error saving translation: ${err.message}`)
			throw err
		}
	}

	return {
		saveTranslation,
		loading,
		error,
		data,
	}
}

const REMOVE_TRANSLATION_MUTATION = gql`
	mutation removeTranslation($id: ID!) {
		removeTranslation(id: $id)
	}
`

export const useRemoveTranslation = () => {
	const [mutate, {loading, error, data}] = useMutation(
		REMOVE_TRANSLATION_MUTATION,
		{
			refetchQueries: ['allTranslations'],
		}
	)

	const removeTranslation = async (translation: {id: string}) => {
		try {
			const result = await mutate({
				variables: {id: translation.id},
			})
			return result
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(`Error removing translation: ${err.message}`)
			throw err
		}
	}

	return {
		removeTranslation,
		loading,
		error,
		data,
	}
}

export const getTranslationQuery = gql`
	query translation($trKey: ShortString, $section: Section) {
		translation(key: $trKey, section: $section) {
			id
			textI18n
			text
			html
			order
			meta
		}
	}
`

export const getTranslationsQuery = gql`
	query translations($lang: String, $section: Section) {
		translations(lang: $lang, section: $section) {
			id
			textI18n
			text
			html
			order
			meta
		}
	}
`

const getAllTranslationsQuery = gql`
	query allTranslations {
		translations {
			id
			key
			section
			order
			textI18n
			meta
		}
	}
`

export const useGetAllTranslations = makeQueryHook(getAllTranslationsQuery)

const getFeaturesQuery = gql`
	query getFeatures {
		features {
			id
			order
			featureGroup
			icon
		}
	}
`
export type Feature = {
	id: I18nKey
	order?: number
	featureGroup?: string
	icon?: string
}
export const useGetFeatures = makeQueryHook<{features: Feature[]}, {}>(
	getFeaturesQuery
)
