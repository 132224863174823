import React from 'react'
import DefaultApp from 'app/Root'

type WrapperFunction = (app: JSX.Element, context: any) => JSX.Element

// Handled by vite react plugin somehow
// if (import.meta.hot) {
// 	StratoRoot = require('react-hot-loader/root').hot(StratoRoot)
// 	import.meta.hot.accept('app/Root', () => require('app/Root'))
// }
type RenderAppProps = {
	App?: React.ElementType
}

let StratoRoot: React.FC<RenderAppProps> = ({App = DefaultApp}) => <App />

const renderApp = (wrappers: WrapperFunction[], context: any): JSX.Element =>
	wrappers.reduce(
		(app, wrapper) => (wrapper ? wrapper(app, context) : app),
		<StratoRoot {...context} />
	)

export default renderApp
